import React from 'react';
import {observer} from "mobx-react";
import {productStore} from "../../../ProductStore";
import Input from "../../../../../../../common/component/form/Input";
import {numberUtil} from "../../../../../../../common/utils/NumberUtil";
import {number_format} from "../../../../../../../common/utils/Utils";


const ExportProductByBatch = () => {
    return (
        <div className="modal fade" id="export_product_by_batch" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Xuất Kho Lô #{productStore.procedureBatchId}</h5>
                        <button type="button" className="close" id="close_import_product" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Số lượng
                                <span className="text-danger"> *</span></label>
                            <Input
                                type="number"
                                onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                value={productStore.dataRequestExportProductByBatch.quantity}
                                isError={productStore.errorsExportProductByBatch.quantity}
                                onChange={(e: any) => {
                                    if (numberUtil.regexNumber(e) <= productStore.totalRemaining) {
                                        productStore.dataRequestExportProductByBatch.quantity = numberUtil.regexNumber(e)
                                    }
                                }
                                }
                                placeholder={`Tối đa ${number_format(productStore.totalRemaining)}`}/>
                        </div>
                        <div className="form-group">
                            <label>Ghi chú <span className="text-danger">*</span></label>
                            <Input
                                value={productStore.dataRequestExportProductByBatch.note}
                                isError={productStore.errorsExportProductByBatch.note}
                                onChange={(e: any) => productStore.dataRequestExportProductByBatch.note = e.currentTarget.value}
                                placeholder="Thành phẩm"/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn button-close-export-batch mt-3 mr-2"
                                id="export_product_by_batch"
                                data-dismiss="modal"
                                aria-label="Close">
                            Hủy
                        </button>
                        <button type="button" className="btn button-export-batch mt-3"
                                onClick={() => productStore.submitExportProductByBatch()}
                                disabled={productStore.isLoadingBt}
                        >Xuất kho
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default observer(ExportProductByBatch);