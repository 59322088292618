import {getRequest, IApiResponse, patchRequest, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {IDataRequestEISuppliesPack} from "./PackStore";
import {IDataRequestEISuppliesManufacturing} from "../manufacturing/ManufacturingStore";

class PackService {
    public getDeatailJobByBatch(id: any, type: string): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure_batch/${id}/job?type=${type}`);
    }

    public changeStatusJob(id: any, data: any): Promise<IApiResponse> {
        return patchRequest(`/food/v1/procedure_batch/${id}/job/_bulk`, data);
    }

    public changeTypeJob(id: any, data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/warehouse_request?batchType=PRODUCT`, data);
    }

    public importExportSuppliesPack(data: IDataRequestEISuppliesPack): Promise<IApiResponse> {
        return postRequest(`/food/v1//warehouse_request`, data);
    }

    public editImportExportSuppliesManufacturing(data: IDataRequestEISuppliesManufacturing, id: number): Promise<IApiResponse> {
        return putRequest(`/food/v1//warehouse_request/${id}`, data);
    }
}

export const packService = new PackService();