import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../../common/component/form/Input";
import {procedureJobStore} from "../ProcedureJobStore";
import Select from "../../../../../common/component/form/Select";

const AddOrEditWork = () => {

    return (
        <div className="modal fade" id="add_or_edit_work" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập công việc</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Tên công việc <span className="text-danger">*</span></label>
                            <Input value={procedureJobStore.dataRequest.name} isError={procedureJobStore.errors.name}
                                   onChange={(e: any) => procedureJobStore.dataRequest.name = e.currentTarget.value}
                                   placeholder="Nhập công việc"/>
                        </div>
                        <div className="form-group">
                            <label>Nhập công đoạn <span className="text-danger">*</span></label>
                            <Input value={procedureJobStore.dataRequest.stage} isError={procedureJobStore.errors.stage}
                                   onChange={(e: any) => procedureJobStore.dataRequest.stage = e.currentTarget.value}
                                   placeholder="Nhập công đoạn"/>
                        </div>
                        <div className="form-group">
                            <label>Chọn bộ phận <span className="text-danger">*</span></label>
                           <Select value={procedureJobStore.dataRequest.type} isError={procedureJobStore.errors.type} onChange={(e: any) => procedureJobStore.dataRequest.type = e.currentTarget.value} options={procedureJobStore.listType} placeholder="Chọn bộ phận"/>
                        </div>
                        <div className="form-group">
                            <label>Trước công đoạn</label>
                            <div className="input-group">
                                <select className="form-control form-control-lg" value={procedureJobStore.dataRequest.sort} onChange={(e: any) => procedureJobStore.dataRequest.sort = e.currentTarget.value}>
                                    <option value="">Tự Động</option>
                                    {procedureJobStore.listProcedureJob && procedureJobStore.listProcedureJob.map((item, i) => {
                                        return <option value={item.sort} key={i}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <span className="font-italic">Nếu không chọn trước công đoạn nào sẽ tự động xếp cuối cùng.</span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => procedureJobStore.addOrEdit()}
                                disabled={procedureJobStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditWork);