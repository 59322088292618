import {observable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {IListJobByBatch} from "../preserve/PreserveStore";
import $ from "jquery";
import {toastUtil} from "../../common/utils/ToastUtil";
import {EIBatchService} from "../warehouse/expImpBatch/EIBatchService";
import {packService} from "./PackService";
import {detailBatchStore} from "../warehouse/expImpBatch/detailEIBatch/DetailEIBatchStore";

export enum ITabDetailPlanPack {
    job = "job",
    supplies = "supplies"
}

export interface IDataSupplies {
    name?: string;
    id?: string | number;
    unit?: string;
    estimatedQuantity: number | string;
    quantity: string | number;
    quantityUsed: string | number;
    err: boolean
    errQuantity?: boolean
}

export interface IDataRequestImportProduct {
    type?: string,
    procedureBatchId?: number | string
    quantity?: number | string
    note?: string
}

export interface IDataRequestEISuppliesPack {
    type?: string,
    procedureBatchId?: number | string
    suppliesInformation?:
        {
            id: number | string,
            quantityRequest: number,
        }[]

    note?: string
}

export interface IListEIByProcedureBatchId {
    id: number,
    name: string,
    timeRequest: number | string,
    type: string,
    batchType: string,
    procedureBatch: {
        name: string,
        product: {
            name: string,
            id: number
        }
    },
    sender?: {
        name: string
    },
    state: string,
    reasonReject: string
}

class PackStore {
    @observable id: any = ''
    @observable procedureBatchId: any = ''
    @observable isLoading: boolean = false
    @observable isLoadingChangeStatus: boolean = false
    @observable isLoadingBtn: boolean = false
    @observable checkCompletePlan: boolean = false
    @observable totalPage: number = 0
    @observable tabActiveDetailPack: string = ITabDetailPlanPack.job
    @observable listJobDetailPack: IListJobByBatch[] = [];
    @observable listSupplies: IDataSupplies[] = []
    @observable listEIByProcedureBatchId: IListEIByProcedureBatchId[] = []
    @observable typeEI: string = ''
    @observable typeModal: string = ''

    @observable dataRequestImportProduct: IDataRequestImportProduct = {
        type: '',
        procedureBatchId: '',
        quantity: '',
        note: ''
    }


    @observable dataRequestEISupplies: IDataRequestEISuppliesPack = {
        type: '',
        procedureBatchId: '',
        suppliesInformation: [],
        note: ''
    }

    @observable public errorsImportProductRequest: any = {
        supplies: '',
        note: '',
        errMax: ''
    }
    @observable public errorsImportBatchtRequest: any = {
        quantity: '',
        note: '',
    }

    clearForm() {
        packStore.errorsImportProductRequest = {
            supplies: '',
            note: '',
            errMax: ''

        }
        packStore.listSupplies.map((item, index) => {
            item.err = false
            item.errQuantity = false
            item.estimatedQuantity = ""
        })
        packStore.dataRequestEISupplies.note = ""
    }

    async getDetailPackJob(id: any) {
        packStore.isLoading = true
        const result = await packService.getDeatailJobByBatch(id, "PACK")
        packStore.isLoading = false
        let checkCount = 0
        packStore.checkCompletePlan = false
        if (result.status === HttpStatusCode.OK) {
            packStore.listJobDetailPack = result.body.data
            packStore.totalPage = result.body.metadata.totalPage
            if (packStore.listJobDetailPack.length > 0) {
                packStore.listJobDetailPack.forEach((item, index) => {
                    if (item.status === "COMPLETE") checkCount++
                })
            }
            packStore.checkCompletePlan = checkCount > 0 && (checkCount === packStore.listJobDetailPack.length);
        }
    }

    async changeStatusJob(id: any, data: any) {
        const result = await packService.changeStatusJob(id, data)
        packStore.isLoadingChangeStatus = false
        if (result.status === HttpStatusCode.OK) {
            packStore.isLoadingChangeStatus = true
            await packStore.getDetailPackJob(id)
            packStore.isLoadingChangeStatus = false
        }
    }

    async getWarehouseRequestByProcedureBatchId() {
        packStore.isLoading = true
        const result = await EIBatchService.getWarehouseRequest(packStore.id)
        packStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            packStore.listEIByProcedureBatchId = result.body.data
            packStore.totalPage = result.body.metadata.totalPage
        }
    }

    async changeTypeJob(id: any) {
        if (!packStore.dataRequestImportProduct.quantity) {
            packStore.errorsImportBatchtRequest.quantity = 'Vui lòng chọn số lượng !'
            return
        } else packStore.errorsImportBatchtRequest.quantity = ''

        packStore.errorsImportBatchtRequest.note = packStore.errorsImportBatchtRequest.note.trim()
        if (!packStore.dataRequestImportProduct.note) {
            packStore.errorsImportBatchtRequest.note = `Ghi chú không được để trống!`
            return
        } else packStore.errorsImportBatchtRequest.note = ''

        packStore.dataRequestImportProduct.type = "IMPORT"
        packStore.dataRequestImportProduct.procedureBatchId = packStore.id
        const result = await packService.changeTypeJob(id, packStore.dataRequestImportProduct)
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm_complete_pack').trigger('click')
            toastUtil.success('Tạo yêu cầu chuyển lô sản xuất sang kho chứa thành công', 3)
            await packStore.getDetailPackJob(id)
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async EISuppliesPack() {
        let checkSupplies = true
        const dataSupplies = packStore.listSupplies.map((item, index) => {
            if (!item.estimatedQuantity) checkSupplies = false
            item.err = !item.estimatedQuantity
            return {
                id: item.id,
                quantityRequest: item.estimatedQuantity
            }
        })
        if (!checkSupplies) {
            packStore.errorsImportProductRequest.supplies = 'Vui lòng chọn số lượng thành phần đầy đủ !'
            packStore.errorsImportProductRequest.note = ''
            return
        } else packStore.errorsImportProductRequest.supplies = ''

        let checkErrQuantity = packStore.listSupplies.map(item => item.errQuantity)
            .includes(true);
        if (checkErrQuantity) {
            packStore.errorsImportProductRequest.errMax = `Vui lòng không nhập quá số lượng tối đa!`
            return
        } else packStore.errorsImportProductRequest.errMax = ""

        packStore.errorsImportProductRequest.note = packStore.errorsImportProductRequest.note.trim()
        if (!packStore.dataRequestEISupplies.note) {
            packStore.errorsImportProductRequest.note = `Ghi chú không được để trống!`
            packStore.errorsImportProductRequest.supplies = ''
            return
        } else packStore.errorsImportProductRequest.note = ''
        const data: IDataRequestEISuppliesPack = {
            type: packStore.typeModal === "add" ? packStore.typeEI : detailBatchStore.listDataDetailEIBatch.type,
            note: packStore.dataRequestEISupplies.note,
            procedureBatchId: packStore.id.toString(),
            suppliesInformation: dataSupplies as any
        }
        packStore.isLoadingBtn = true
        const result = packStore.typeModal === "add" ? await packService.importExportSuppliesPack(data) : await packService.editImportExportSuppliesManufacturing(data, packStore.procedureBatchId)
        packStore.isLoadingBtn = false
        if (result.status === HttpStatusCode.OK) {
            if (packStore.typeModal === "add") {
                if (packStore.typeEI === "EXPORT") {
                    $('#modal-import-pack-supplies').trigger('click')
                    toastUtil.success('Tạo yêu cầu nhập thêm vật tư thành công', 3)
                } else {
                    $('#modal-export-pack-supplies').trigger('click')
                    toastUtil.success('Tạo yêu cầu xuất dư thành công', 3)
                }
            } else {
                if (packStore.typeEI === "EXPORT") {
                    $('#modal-import-pack-supplies').trigger('click')
                    toastUtil.success('Sửa yêu cầu nhập thêm vật tư thành công', 3)
                } else {
                    $('#modal-export-pack-supplies').trigger('click')
                    toastUtil.success('Sửa yêu cầu xuất dư thành công', 3)
                }
            }
            await packStore.getWarehouseRequestByProcedureBatchId()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
}

export const packStore = new PackStore()