import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {dateUtils} from "../../../../common/utils/DateUtils";
import {expImpBatchStore} from "../../../warehouse/expImpBatch/EIBatchStore";
import Loading from "../../../../common/component/Loading";
import NoContent from "../../../../common/component/NoContent";
import './DetailPackSupplies.scss'
import {packStore} from "../../PackStore";
import ModalDetailEIRequest
    from "../../../manufacturing/components/detailManufacturingSupplies/modalDetailEIRequest/ModalDetailEIRequest";
import Paginate from "../../../../common/component/Paginate";
import ModalExportSuppliesManufacturing
    from "../../../manufacturing/components/detailManufacturingSupplies/modalEI/modalExport/ModalExport";
import ModalImportSuppliesManufacturing
    from "../../../manufacturing/components/detailManufacturingSupplies/modalEI/modalImport/ModalImport";
import {manufacturingStore} from "../../../manufacturing/ManufacturingStore";
import {checkPermission} from "../../../../common/utils/Utils";
import {PermissionConst} from "../../../../common/constants/Constants";

const DetailPackSupplies = (): JSX.Element => {
    const changePage = async (data: any) => {
        expImpBatchStore.page = data.selected;
        await packStore.getWarehouseRequestByProcedureBatchId()
    }
    useEffect(() => {
        expImpBatchStore.page = 0
        packStore.getWarehouseRequestByProcedureBatchId()
    }, [])
    return (
        <div className="modal-body popup_detail pt-1">
            <div className="w-100 d-flex flex-wrap py-3 justify-content-between align-items-center">
                <div className="w-75 d-flex flex-column">
                    <div className="d-flex">Thành phần:
                        <div
                            className="d-flex flex-wrap ml-3">{
                            manufacturingStore.listSuppliesDetail && manufacturingStore.listSuppliesDetail.map((item, index) => {
                                return (
                                    <div className="d-flex mr-3">
                                        <label className="mr-1 fw-600">{`${index + 1}. ${item.name}`}</label>
                                        <span>{`(${item.estimatedQuantity})`}</span>
                                    </div>
                                )
                            })
                        }</div>
                    </div>
                    <div className="d-flex">Thành phần (Đã xuất):
                        <div
                            className="d-flex flex-wrap ml-3">{
                            manufacturingStore.listSuppliesDetail && manufacturingStore.listSuppliesDetail.map((item, index) => {
                                return (
                                    <div className="d-flex mr-3">
                                        <label className="mr-1 fw-600">{`${index + 1}. ${item.name}`}</label>
                                        <span>{`(${item.quantity})`}</span>
                                    </div>
                                )
                            })
                        }</div>
                    </div>
                </div>
                {checkPermission(PermissionConst.packaging_supplies_request) && <div className="d-flex flex-wrap">
                    <button className="btn button-detail-supplies btn-import-supplies mr-2"
                            data-toggle="modal" data-target="#modal-import-manufacturing-supplies"
                            onClick={() => {
                                manufacturingStore.clearForm()
                                manufacturingStore.typeEI = "EXPORT"
                                manufacturingStore.typeModal = "add"

                            }}>
                        <img src="/assets/ico/action/folder-output.svg"
                             className="icon-action icon-cancel-disable"
                             alt="" data-toggle="tooltip" data-placement="top"
                             title="Thiếu Vật Tư"/>
                        <span className="button-detail-supplies-title">Thiếu Vật Tư</span>
                    </button>
                    <button className="btn button-detail-supplies btn-export-supplies"
                            data-toggle="modal" data-target="#modal-export-manufacturing-supplies"
                            onClick={() => {
                                manufacturingStore.clearForm()
                                manufacturingStore.typeEI = "IMPORT"
                                manufacturingStore.typeModal = "add"
                            }}>
                        <img src="/assets/ico/action/folder-input.svg"
                             className="icon-action icon-cancel-disable"
                             alt="" data-toggle="tooltip" data-placement="top"
                             title="Xuất Dư"/>
                        <span className="button-detail-supplies-title">Xuất Dư</span>
                    </button>
                </div>}
            </div>
            {packStore.isLoading ? <Loading/> : <>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">Mã yêu cầu</th>
                        <th className="text-center">Thời gian</th>
                        <th className="text-center">Xuất/Nhập</th>
                        <th className="text-center">Người yêu cầu</th>
                        <th className="text-center">Tình trạng</th>
                        <th className="text-center">Lý do</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {packStore.listEIByProcedureBatchId && packStore.listEIByProcedureBatchId.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">#000{item.id}</td>
                                <td className="text-center">{item.timeRequest ? dateUtils.formatDate(Number(item.timeRequest), 'hh:mm DD/MM/YYYY') : '-'}</td>
                                <td className={`text-center ${item.type === "IMPORT" ? "type_import" : "type_export"}`}>
                                    {item.type === "IMPORT" ? "Nhập kho" : "Xuất kho"}
                                </td>
                                <td className="text-center">{item.sender?.name ?? "-"}</td>
                                <td className={`text-center ${item.state === "APPROVED" ? "statement_approved" : item.state === "REJECT" ? "statement_reject" : "statement_pendding"}`}>
                                    {item.state === "APPROVED" ? "Hoàn thành" : (item.state === "REJECT" ? "Từ chối" : "Đang chờ")}
                                </td>
                                <td className="text-center">{item.reasonReject ?? "-"}</td>
                                <td className="text-center" width="18%">
                                    <button className="view-detail-manufacturing"
                                            data-toggle="modal"
                                            data-target="#modal-detail-request-manufacturing-supplies"
                                            onClick={async () => {
                                                manufacturingStore.procedureBatchId = item.id
                                                await expImpBatchStore.getDetailWarehouseRequest(Number(manufacturingStore.procedureBatchId))
                                            }}
                                    >
                                        Chi tiết
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {packStore.listEIByProcedureBatchId && packStore.listEIByProcedureBatchId.length < 1 &&
                    <NoContent/>}
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={expImpBatchStore.page} totalPage={expImpBatchStore.totalPage}
                              changePage={changePage}/>
                </div>
                <ModalExportSuppliesManufacturing/>
                <ModalImportSuppliesManufacturing/>
                <ModalDetailEIRequest/>
            </>}
        </div>
    )

}

export default observer(DetailPackSupplies);

