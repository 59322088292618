import React from 'react';
import {observer} from "mobx-react";
import './AddPlanQuantity.scss'

import {preserveStore} from "../../PreserveStore";
import Input from "../../../../common/component/form/Input";
import {ActionButtons} from "../wizardAddPlan/Wizard";
import {numberUtil} from "../../../../common/utils/NumberUtil";

const AddPlanQuantity = (props: any) => {
    const handleLastStep = () => {
        props.lastStep();
        props.completeCallback();
    };
    return (
        <div className="add-plan-quantity-container">
            <div className="add-plan-quantity">
                <div className="w-50">
                    <p>Tên sản phẩm: <span
                        className="fw-600">{preserveStore.dataAddPlanOrderWork.productName || "---"}</span></p>
                    <p>Tên lô sản xuất: <span
                        className="fw-600">{preserveStore.dataAddPlanOrderWork.name || "---"}</span></p>
                    <p>Sản xuất :
                        <span className="fw-600">
                            {preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[0]?.selectPersonInCharge.map((item, index) => {
                                return <span>
                                                {
                                                    index !== 0 ?
                                                        `, ${item.label}` : ` ${item.label} `}
                                            </span>
                            })}
                        </span>
                    </p>
                    <p>Đóng gói :
                        <span className="fw-600">
                            {preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[1]?.selectPersonInCharge.map((item, index) => {
                                return <span>
                                                {
                                                    index !== 0 ?
                                                        `, ${item.label}` : ` ${item.label} `}
                                            </span>
                            })}
                        </span>
                    </p>
                    <p>Xuất nhập kho :
                        <span className="fw-600">
                            {preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[2]?.selectPersonInCharge.map((item, index) => {
                                return <span>
                                                {
                                                    index !== 0 ?
                                                        `, ${item.label}` : ` ${item.label} `}
                                            </span>
                            })}
                        </span>
                    </p>
                </div>
                <div className="w-50">
                    <p>Quy trình: <span
                        className="fw-600">{preserveStore.dataAddPlanOrderWork.procedureName || "---"}</span></p>
                    <p>Sản lượng dự kiến: <span
                        className="fw-600">{preserveStore.dataAddPlanOrderWork.quantity || "---"}</span></p>
                </div>
            </div>
            <div className="w-auto table-responsive custom-scrollbar">
                <table
                    className='table align-middle table-hover table-rounded border table-add-plan-quantity'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th className="text-center">Tên thành phần</th>
                        <th className="text-center">Đơn vị</th>
                        <th className="text-center">Số lượng cần</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!preserveStore.isLoading && preserveStore.dataAddPlanQuantity && preserveStore.dataAddPlanQuantity.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{item.name}</td>
                                <td className="text-center">{item.unit}</td>
                                <td className="text-center w-50 h-100">
                                    <Input value={item.estimatedQuantity}
                                           onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                           type="number"
                                           isError={item.errQuantityEstimate}
                                           isShowError={false}
                                           onChange={(e: any) => {
                                               if (e.currentTarget.value === "" || numberUtil.regexNumber(e) > 0)
                                                   item.estimatedQuantity = numberUtil.regexNumber(e)
                                           }}
                                           placeholder="Tối thiểu 1"
                                    />
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {preserveStore.errors.addPlanQuantity &&
                <span
                    className="error d-flex justify-content-end">{preserveStore.errors.addPlanQuantity}</span>}
            {preserveStore.errors.addPlanQuantityMin &&
                <span
                    className="error d-flex justify-content-end">{preserveStore.errors.addPlanQuantityMin}</span>}

            <div className="modal-footer">
                <ActionButtons {...props} lastStep={handleLastStep}/>
            </div>
        </div>
    );
}

export default observer(AddPlanQuantity);
