import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {accountService} from "../AccountService";
import {infoStore} from "../../info/InfoStore";
import StorageService from "../../../common/service/StorageService";
import {postRequest} from "../../../common/helpers/RequestHelper";
import {parseJwt} from "../../../common/utils/Utils";
import {toastUtil} from "../../../common/utils/ToastUtil";

interface IErrors {
    farm_name: string,
    type: string,
    address: string,
    province: string,
    district: string,
    ward: string
}


class SignUpStore {
    @observable public text_error: any = ''
    @observable public redirect: boolean = false
    @observable public confirmAcc: boolean = false
    @observable public errors: IErrors = {
        farm_name: "",
        type: "",
        address: "",
        province: "",
        district: "",
        ward: ''
    }
    @observable public listType: any = [
        {
            name: 'Hộ kinh doanh',
            type: "HOUSEHOLD"
        },
        {
            name: 'Hợp tác xã',
            type: "COOPERATIVE"
        },
        {
            name: 'Đơn vị gia công',
            type: "MACHINING_UNIT"
        },
        {
            name: 'Đơn vị sản xuất',
            type: "PRODUCTION_UNIT"
        },
        {
            name: 'Đơn vị thương mại',
            type: "COMMERCIAL_UNIT"
        }
    ]
    @observable public isLoading: boolean = false
    @observable public listProvince: any[] = []
    @observable public listDistrict: any[] = []
    @observable public listWard: any[] = []
    @observable public dataRequest: any = {
        name: "",
        type: "",
        address: "",
        provinceId: "",
        districtId: "",
        wardId: ""
    }

    clearForm() {
        signUpStore.errors = {
            farm_name: "",
            type: "",
            address: "",
            province: "",
            district: "",
            ward: ''
        }
        signUpStore.dataRequest = {
            name: "",
            type: "",
            address: "",
            provinceId: "",
            districtId: "",
            wardId: ''
        }
    }


    async changeProvince(provinceId: any) {
        signUpStore.dataRequest.provinceId = provinceId
        if (signUpStore.dataRequest.provinceId) {
            signUpStore.errors.province = ''
        }
        await signUpStore.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any) {
        signUpStore.dataRequest.districtId = districtId
        if (signUpStore.dataRequest.districtId) {
            signUpStore.errors.district = ''
        }
        await signUpStore.getWards(districtId)
    }


    async getProvinces() {
        const result = await accountService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            signUpStore.listProvince = result.body
        }
    }

    async getDistricts(provinceId: any) {
        const result = await accountService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            signUpStore.listDistrict = result.body
        }
    }

    async getWards(districtId: any) {
        const result = await accountService.getWards(districtId)
        if (result.status === HttpStatusCode.OK) {
            signUpStore.listWard = result.body
        }
    }

    async getToken(isAddFarm?: boolean) {
        signUpStore.isLoading = true
        const result = await postRequest('/sso/v1/auth/gettoken?service=food', {})
        if (result.status === HttpStatusCode.OK) {
            signUpStore.isLoading = false
            let token: string = result.body.token;
            StorageService.setToken(token);
            parseJwt(token)
            signUpStore.redirect = true
            isAddFarm && await infoStore.getInfo()
        }
    }

    async addFarm() {
        let {name, type, provinceId, districtId, wardId, address} = signUpStore.dataRequest
        let data = {
            name: name.trim(),
            type: type,
            address: address,
            provinceId: Number(provinceId),
            districtId: Number(districtId),
            wardId: Number(wardId)
        }

        if (!name) {
            signUpStore.errors.farm_name = 'Tên đơn vị không được để trống!';
            return false;
        }
        if (!type) {
            signUpStore.errors.type = 'Loại hình không được để trống!';
            return false;
        }
        if (!provinceId) {
            signUpStore.errors.province = "Tỉnh/Thành phố không được để trống!"
            return false;
        }
        if (!districtId) {
            signUpStore.errors.district = "Quận/Huyện không được để trống!"
            return false;
        }
        if (!wardId) {
            signUpStore.errors.ward = "Phường/Xã không được để trống!"
            return false;
        }

        signUpStore.isLoading = true
        const result = await postRequest(`/food/v1/product_facility`, data)
        signUpStore.isLoading = false

        if (result.status == 200) {
            toastUtil.success('Tạo thông tin HTX thành công', 2)
            await this.getToken()
        } else {
            toastUtil.error('Tạo thông tin HTX thất bại', 2)
        }
    }


}

export const signUpStore = new SignUpStore()