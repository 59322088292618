import React, {useRef} from 'react';
import {observer} from "mobx-react";
import "../TrackingStyle.scss"
import Input from "../../../common/component/form/Input";
import {Moment} from "../../../common/utils/Moment";
import DatePickerSingle from "../../../common/component/DatePickerSingle";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {trackingStore} from "../TrackingStore";
import Barcode from "react-barcode";
import './AddOrUpdateTracking.scss'
import {useReactToPrint} from "react-to-print";
import html2canvas from "html2canvas";
import {slug} from "../../../common/utils/Utils";
import ButtonGlobal from "../../../common/component/buttonGlobal/ButtonGlobal";

const AddOrUpdateTracking = observer(() => {
    const componentRef: any = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const handleStartDate = async (startDate: Date) => {
        trackingStore.dataRequest.trackingData.productInfo.dateManufacture = startDate.getTime()
        trackingStore.minEndDate = Moment.plusDays(startDate, 1)
    }


    const downloadBarcode = async () => {
        const element = componentRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/png');
        const link = document.createElement('a');

        link.href = data;
        link.download = 'barcodeTracking.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };

    let {productInfo, suppliesInfo} = trackingStore.dataRequest.trackingData

    return (
        <div className="modal fade modal_full" id="addUpdateAccess" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <button type="button" className="close d-flex align-items-center" id="close_form_access"
                            data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                        <p className="m-0">Đóng</p>
                    </button>
                    <div className="modal-header pt-0">
                        <h5 className="modal-title w-100 text-center">Truy xuất nguồn gốc</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="row">
                            <div className="col-6 pr-5">
                                <div className="info mb-5">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green">Thông tin sản phẩm</h5>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Tên sản phẩm <span className="text-danger">*</span></label>
                                        <Input value={productInfo.name}
                                               isError={trackingStore.errors.name}
                                               disabled={trackingStore.dataRequest.procedureBatchId}
                                               onChange={(e: any) => productInfo.name = e.currentTarget.value}
                                               placeholder="Nhập tên sản phẩm"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Mã số lô sản xuất <span className="text-danger">*</span></label>
                                        <Input
                                            value={productInfo.productionBatch}
                                            isError={trackingStore.errors.productionBatch}
                                            disabled={trackingStore.dataRequest.procedureBatchId}
                                            onChange={(e: any) => productInfo.productionBatch = e.currentTarget.value}
                                            placeholder="Nhập lô sản xuất"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Ngày sản xuất <span className="text-danger">*</span></label>
                                        <DatePickerSingle disabled={trackingStore.dataRequest.procedureBatchId}
                                                          selected={productInfo.dateManufacture}
                                                          isError={trackingStore.errors.dateManufacture}
                                                          onChange={handleStartDate} placeholder="Chọn ngày sản xuất"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Số lượng <span className="text-danger">*</span></label>
                                        <Input value={productInfo.quantity}
                                               isError={trackingStore.errors.quantity}
                                               disabled={trackingStore.dataRequest.procedureBatchId}
                                               onChange={(e: any) => productInfo.quantity = numberUtil.regexNumber(e)}
                                               placeholder="Nhập số lượng"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Hạn sử dụng (Tháng) <span className="text-danger">*</span></label>
                                        <Input
                                            value={productInfo.expiry}
                                            isError={trackingStore.errors.expiry}
                                            disabled={trackingStore.dataRequest.procedureBatchId}
                                            onChange={(e: any) => productInfo.expiry = numberUtil.regexNumber(e)}
                                            placeholder="Nhập hạn sử dụng"/>
                                    </div>
                                </div>

                                <div className="info">
                                    <div className="title_top mb-4">
                                        <h4 className="color-green">Thông tin chi tiết</h4>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Nguyên liệu <span className="text-danger">*</span></label>
                                        <Input value={suppliesInfo.ingredient}
                                               isError={trackingStore.errors.ingredient}
                                               onChange={(e: any) => suppliesInfo.ingredient = e.currentTarget.value}
                                               placeholder="Nhập nguyên liệu"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Đơn vị CC nguyên liệu</label>
                                        <Input
                                            value={suppliesInfo.supplierIngredient}
                                            onChange={(e: any) => suppliesInfo.supplierIngredient = e.currentTarget.value}
                                            placeholder="Nhập đơn vị cung cấp nguyên liệu"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Địa chỉ nguyên liệu </label>
                                        <Input
                                            value={suppliesInfo.addressIngredient}
                                            onChange={(e: any) => suppliesInfo.addressIngredient = e.currentTarget.value}
                                            placeholder="Nhập địa chỉ nguyên liệu"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Số ĐKKD đơn vị CCNL</label>
                                        <Input
                                            value={suppliesInfo.businessRegistrationIngredient}
                                            onChange={(e: any) => suppliesInfo.businessRegistrationIngredient = e.currentTarget.value}
                                            placeholder="Nhập số ĐKKD đơn vị cung cấp"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Phụ gia <span className="text-danger">*</span></label>
                                        <Input value={suppliesInfo.additives}
                                               isError={trackingStore.errors.additives}
                                               onChange={(e: any) => suppliesInfo.additives = e.currentTarget.value}
                                               placeholder="Nhập phụ gia"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 pl-5">
                                <div className="info pt-4">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Đơn vị CC phụ gia </label>
                                        <Input
                                            value={suppliesInfo.supplierAdditives}
                                            onChange={(e: any) => suppliesInfo.supplierAdditives = e.currentTarget.value}
                                            placeholder="Nhập đơn vị cung cấp"/>
                                    </div>

                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Số ĐKKD Đơn vị CCPG</label>
                                        <Input
                                            value={suppliesInfo.businessRegistrationAdditives}
                                            onChange={(e: any) => suppliesInfo.businessRegistrationAdditives = e.currentTarget.value}
                                            placeholder="Nhập số ĐKKD đơn vị cung cấp"/>
                                    </div>

                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Khối lượng tịnh <span className="text-danger">*</span></label>
                                        <Input value={suppliesInfo.pack} isError={trackingStore.errors.pack}
                                               onChange={(e: any) => suppliesInfo.pack = e.currentTarget.value}
                                               placeholder="Nhập khối lượng tịnh"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Công dụng <span className="text-danger">*</span></label>
                                        <Input value={trackingStore.dataRequest.instruction.uses}
                                               isError={trackingStore.errors.uses}
                                               onChange={(e: any) => trackingStore.dataRequest.instruction.uses = e.currentTarget.value}
                                               placeholder="Nhập công dụng"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Hướng dẫn sử dụng <span className="text-danger">*</span></label>
                                        <Input value={trackingStore.dataRequest.instruction.userManual}
                                               isError={trackingStore.errors.userManual}
                                               onChange={(e: any) => trackingStore.dataRequest.instruction.userManual = e.currentTarget.value}
                                               placeholder="Nhập hướng dẫn sử dụng"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Cảnh báo</label>
                                        <Input value={trackingStore.dataRequest.instruction.warning}
                                               onChange={(e: any) => trackingStore.dataRequest.instruction.warning = e.currentTarget.value}
                                               placeholder="Nhập cảnh báo"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Ghi chú</label>
                                        <Input value={trackingStore.dataRequest.instruction.note}
                                               onChange={(e: any) => trackingStore.dataRequest.instruction.note = e.currentTarget.value}
                                               placeholder="Nhập ghi chú"/>
                                    </div>
                                    <div className="barcode-container mt-4 mb-5">
                                        <div className="barcode-display-content">
                                            <div className="barcode">
                                                <div className="barcode-content" ref={componentRef}>
                                                    {trackingStore.dataRequest.barcode ?
                                                        <Barcode value={`${trackingStore.dataRequest.barcode}`}
                                                                 format="EAN13"/> :
                                                        <div className="no_barcode">
                                                            <span className="title">Bạn chưa có mã vạch</span>
                                                        </div>}
                                                </div>
                                            </div>
                                            <input type="text"
                                                   className="text-center mt-2 input_barcode border-right-0 border-top-0 border-left-0"
                                                   value={trackingStore.dataRequest.barcode ?? ''}
                                                   onChange={(e: any) => {
                                                       if (e.currentTarget.value.length <= 13) {
                                                           trackingStore.dataRequest.barcode = numberUtil.regexNumber(e)
                                                       }
                                                   }} placeholder="Nhập mã vạch ..."/>
                                        </div>
                                        <div className="barcode-display-btn">
                                            {trackingStore.dataRequest.id ?
                                                <a href={`${window.location.origin}/xem-ket-qua-truy-xuat/${slug(productInfo.name)}/${trackingStore.dataRequest.id}`}
                                                   target="_blank">
                                                    <ButtonGlobal text="Xem Kết Quả"/>
                                                </a> : <ButtonGlobal text="Xem Kết Quả" disabled={true}/>}
                                            <ButtonGlobal text="Tải Mã Vạch" ico="download"
                                                          disabled={!trackingStore.dataRequest.barcode}
                                                          onClick={downloadBarcode}/>
                                            <ButtonGlobal text="In Mã Vạch" ico="printer"
                                                          disabled={!trackingStore.dataRequest.barcode}
                                                          onClick={handlePrint}/>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="button" className="btn send-request"
                                                onClick={() => trackingStore.addOrUpdateAccess()}
                                                disabled={trackingStore.isLoadingBt}>Lưu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

})

export default AddOrUpdateTracking;