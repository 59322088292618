import {observable} from "mobx";
import {batchService} from "./BatchService";
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";
import {suppliesStore, TypeSupplies} from "../SuppliesStore";
import {toastUtil} from "../../../../common/utils/ToastUtil";
import $ from "jquery";

export enum ITabBatch {
    inventory = "inventory",
    history = "history"
}

interface IDataRequest {
    id: any,
    suppliesId: any,
    expirationDate: any,
    supplierBatchCode: any,
    productionBatchCode: string,
    batchCode: any,
    price: any,
    quantity: any,
    containedVolume: any
    supplierInformation: {
        companyName: any,
        address: any,
        plantingAreaCode: any,
        packingBaseCode: any
    },
    qualityEvaluation: {
        color: any,
        taste: any,
        status: any,
        impurities: any
    },
    inputTime: any
}

interface IError {
    expirationDate: any,
    supplierBatchCode: string,
    batchCode: string,
    productionBatchCode: string,
    quantity: string,
    containedVolume: string,
    companyName: string,
    address: string,
    inputTime: any,
    exportQuantity: any,
    exportNote: any
}

class BatchStore {
    @observable suppliesId: any = '';
    @observable id: any = '';
    @observable page: number = 0;
    @observable type: string = "add";
    @observable minDate: any = "";
    @observable keyword: any = "";
    @observable dataFilter: any = "";
    @observable typeSupplies: string = "";
    @observable tabActive: string = ITabBatch.inventory;
    @observable totalRemaining: number = 0;
    @observable totalPage: number = 0;
    @observable isLoading: boolean = false;
    @observable isLoadingBt: boolean = false;
    @observable listBatch: any[] = [];
    @observable listHistory: any[] = [];
    @observable errors: IError = {
        expirationDate: "",
        supplierBatchCode: "",
        batchCode: "",
        quantity: '',
        containedVolume: "",
        companyName: "",
        address: "",
        inputTime: "",
        exportQuantity: '',
        productionBatchCode: "",
        exportNote: ''
    };
    @observable dataExport: any = {
        quantity: "",
        action: "EXPORT",
        note: ""
    };
    @observable dataRequest: IDataRequest = {
        id: '',
        suppliesId: '',
        expirationDate: "",
        supplierBatchCode: "",
        batchCode: "",
        price: '',
        quantity: '',
        productionBatchCode: "",
        containedVolume: '',
        supplierInformation: {
            companyName: "",
            address: "",
            plantingAreaCode: "",
            packingBaseCode: ""
        },
        qualityEvaluation: {
            color: "",
            taste: "",
            status: "",
            impurities: ""
        },
        inputTime: ""
    }


    clearForm() {
        batchStore.type = "add"
        batchStore.dataRequest = {
            id: '',
            suppliesId: '',
            expirationDate: "",
            supplierBatchCode: "",
            batchCode: "",
            price: '',
            quantity: '',
            productionBatchCode: "",
            containedVolume: '',
            supplierInformation: {
                companyName: "",
                address: "",
                plantingAreaCode: "",
                packingBaseCode: ""
            },
            qualityEvaluation: {
                color: "",
                taste: "",
                status: "",
                impurities: ""
            },
            inputTime: ""
        }
        batchStore.dataExport = {
            quantity: "",
            action: "EXPORT",
            note: ""
        };
        batchStore.clearError()
    }

    clearFormExport() {
        batchStore.dataExport = {
            quantity: "",
            action: "EXPORT",
            note: ""
        }
    }

    clearError() {
        batchStore.errors = {
            expirationDate: "",
            supplierBatchCode: "",
            batchCode: "",
            quantity: '',
            productionBatchCode: "",
            containedVolume: "",
            companyName: "",
            address: "",
            inputTime: "",
            exportQuantity: '',
            exportNote: ''
        }
    }

    async getBatch(id: any) {
        batchStore.isLoading = true
        const result = await batchService.getBatch(id)
        batchStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            batchStore.listBatch = result.body.data
            batchStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getHistory() {
        batchStore.isLoading = true
        const result = await batchService.getHistory(batchStore.suppliesId)
        batchStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            batchStore.listHistory = result.body.data
            batchStore.totalPage = result.body.metadata.totalPage
        }
    }

    switchName() {
        switch (batchStore.typeSupplies) {
            case TypeSupplies.ingredient:
                return "nguyên liệu"
            case TypeSupplies.additives:
                return "phụ gia"
            case TypeSupplies.packaging:
                return "bao bì"
            default:
                return "nguyên liệu"
        }
    }

    async getDetail(id: any) {
        batchStore.clearError()
        batchStore.type = "edit"
        const result = await batchService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            batchStore.dataRequest = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async addOrEdit() {
        this.clearError()

        let {
            id,
            expirationDate,
            batchCode,
            qualityEvaluation,
            inputTime,
            price,
            productionBatchCode,
            quantity, containedVolume,
            supplierBatchCode,
            supplierInformation
        } = batchStore.dataRequest

        let data: any = {
            suppliesId: suppliesStore.dataRequest.id,
            expirationDate: expirationDate,
            supplierBatchCode: supplierBatchCode,
            productionBatchCode: productionBatchCode,
            batchCode: batchCode,
            price: price,
            quantity: quantity,
            supplierInformation: {
                companyName: supplierInformation.companyName,
                address: supplierInformation.address,
                plantingAreaCode: supplierInformation.plantingAreaCode,
                packingBaseCode: supplierInformation.packingBaseCode
            },
            qualityEvaluation: {
                color: qualityEvaluation.color,
                taste: qualityEvaluation.taste,
                status: qualityEvaluation.status,
                impurities: qualityEvaluation.impurities
            },
            inputTime: inputTime
        }

        if (batchStore.typeSupplies === TypeSupplies.packaging) {
            data['containedVolume'] = containedVolume
            delete data.supplierInformation.plantingAreaCode;
            delete data.qualityEvaluation.impurities;
            delete data.qualityEvaluation.taste;
        }

        if (!batchCode) {
            batchStore.errors.batchCode = "Mã lô không được để trống!"
            return
        }
        if (!inputTime) {
            batchStore.errors.inputTime = "Thời gian nhập không được để trống!"
            return
        }
        if (!expirationDate) {
            batchStore.errors.expirationDate = "Hạn sử dụng không được để trống!"
            return
        }
        if (!containedVolume && batchStore.typeSupplies === TypeSupplies.packaging) {
            batchStore.errors.containedVolume = "Khối lượng không được để trống!"
            return
        }
        if (!quantity) {
            batchStore.errors.quantity = "Số lượng không được để trống!"
            return
        }

        if (!supplierBatchCode) {
            batchStore.errors.supplierBatchCode = "Mã số lô nhà cung ứng không được để trống!"
            return
        }

        if (!productionBatchCode) {
            batchStore.errors.productionBatchCode = "Mã số lô nhà sản xuất không được để trống!"
            return
        }
        if (!supplierInformation.companyName) {
            batchStore.errors.companyName = "Tên công ty không được để trống!"
            return
        }
        if (!supplierInformation.address) {
            batchStore.errors.address = "Địa chỉ không được để trống!"
            return
        }

        let result: any
        if (batchStore.type == 'add') {
            result = await batchService.create(data)
        } else result = await batchService.update(id, data)
        batchStore.isLoadingBt = false

        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${batchStore.type === 'add' ? 'Thêm' : 'Cập nhật'} lô ${batchStore.switchName()} thành công`, 3)
            $('#close_form_batch').trigger('click')
            if (batchStore.type == 'add') {
                await suppliesStore.getDetail(batchStore.suppliesId)
            }
            await batchStore.getBatch(batchStore.suppliesId)
            batchStore.clearForm()
        } else {
            toastUtil.error(result.body.message, 1)
        }
    }

    addDuplicates(item: any) {
        batchStore.type = 'add'
        batchStore.dataRequest = {
            id: item.id,
            suppliesId: item.suppliesId,
            expirationDate: item.expirationDate,
            supplierBatchCode: item.supplierBatchCode,
            batchCode: item.batchCode,
            price: item.price,
            quantity: '',
            productionBatchCode: item.productionBatchCode,
            containedVolume: item.containedVolume,
            supplierInformation: {
                companyName: item.supplierInformation.companyName,
                address: item.supplierInformation.address,
                plantingAreaCode: item.supplierInformation.plantingAreaCode,
                packingBaseCode: item.supplierInformation.packingBaseCode
            },
            qualityEvaluation: {
                color: item.qualityEvaluation.color,
                taste: item.qualityEvaluation.taste,
                status: item.qualityEvaluation.status,
                impurities: item.qualityEvaluation.impurities
            },
            inputTime: item.inputTime
        }
    }

    async export() {
        batchStore.clearError()

        if (!batchStore.dataExport.quantity) {
            batchStore.errors.exportQuantity = 'Số lượng xuất không được để trống!'
            return
        }

        if (batchStore.dataExport.quantity > batchStore.totalRemaining) {
            batchStore.errors.exportQuantity = `Số lượng xuất tối đa là ${batchStore.totalRemaining}`
            return
        }

        if (!batchStore.dataExport.note) {
            batchStore.errors.exportNote = 'Ghi chú không được để trống'
            return
        }

        batchStore.isLoadingBt = true
        const result = await batchService.export(batchStore.id, batchStore.dataExport)
        batchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Xuất kho ${batchStore.switchName()} thành công`, 3)
            $('#close_export').trigger('click')
            await suppliesStore.getDetail(batchStore.suppliesId)
            await batchStore.getBatch(batchStore.suppliesId)

        } else {
            toastUtil.error(result.body.message, 1)
        }
    }

    async delete() {
        batchStore.isLoadingBt = true
        const result = await batchService.delete(batchStore.id)
        batchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success(`Xóa ${batchStore.switchName()} thành công`, 3)
            await batchStore.getBatch(batchStore.suppliesId)
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }

}

export const batchStore = new BatchStore()