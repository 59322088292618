import {IApiResponse} from "./RequestHelper";
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import {toastUtil} from "../utils/ToastUtil";
import axios from "axios";
import humps from "humps";


let API_DOMAIN = process.env.REACT_APP_API_DOMAIN

function sendUploadFile(path: string, params: any): Promise<IApiResponse> {
    var newHeaders: any = {
        'Type': 'IMAGE',
        'Content-Type': 'multipart/form-data',
        "Authorization": 'Bearer ' + StorageService.getToken()
    };

    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: params,
            headers: newHeaders,
            method: 'POST',
            url: API_DOMAIN + path
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });
            })
            .catch(function (error) {
                if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED) {
                    toastUtil.error('Token expire');
                    // StorageService.removeToken();
                    // window.location.href = "/login"
                }

                let bodyError: any;

                try {
                    if (error.response && error.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                        bodyError = {
                            errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                            message: "Internal server error, please try again later"
                        }
                    } else {
                        bodyError = {
                            errorCode: error.response.data.errorCode,
                            message: error.response.data.message
                        }
                    }

                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response.status,
                    body: bodyError
                };

                resolve(apiResponse);
            });

    });
}


class UploadImg {
    onChangeFileThumb = async (e: any, changeImg: any, width?: any, height?: any, type?: any) => {
        e.preventDefault()
        const reader = new FileReader();
        let file = e.target.files[0]

        if (file.size > 10000 * 1024) {
            toastUtil.warning("Ảnh của bạn quá lớn. Vui lòng chọn ảnh có kích thước nhỏ hơn 10MB.", 1)
            return
        }
        reader.readAsDataURL(file)
        if (!width && !height) {
            reader.onload = async () => {
                await this.sendUploadThumb(file, changeImg)
            }
        } else {
            let image = new Image()
            image.src = window.URL.createObjectURL(file)
            image.onload = async () => {
                if (type === 'logo') {
                    if (image.width != width && image.height != height) {
                        toastUtil.warning(`Vui lòng chọn ảnh có kích thước ${width} x ${height}.`, 3)
                        return
                    }
                } else {
                    if (image.width > width || image.height > height) {
                        toastUtil.warning(`Vui lòng chọn ảnh có kích thước nhỏ hơn ${width} x ${height}.`, 2)
                        return
                    }
                }
                await this.sendUploadThumb(file, changeImg)
            }
        }
    }

    sendUploadThumb = async (files: any, changeImg: any) => {
        let data = {
            files: files
        }
        let result = await sendUploadFile('/v1/storage', data)

        if (result.status === 200) {
            let image = result.body
            await changeImg(image[0])
        }
    }

}


export const uploadImgHelper = new UploadImg()