import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {dashboardStore} from "./DashboardStore";
import {number_format} from "../../common/utils/Utils";
import "./DashboardStyle.scss"
// @ts-ignore
import PieChart from 'react-pie-graph-chart';
// @ts-ignore
import {CanvasJSChart} from 'canvasjs-react-charts'

const Dashboard = observer(() => {

    useEffect(() => {
        dashboardStore.getDashboard()
    }, [])


    let {
        countProduct,
        countAdditives,
        countEquipment,
        countIngredient,
        countPackaging,
        countSumUser,
        countUserManufacture,
        countUserPack,
        countUserWarehouse,
        countUserManage
    } = dashboardStore.dataDashboard

    let totalUser: any = Number(countUserManufacture) + Number(countUserPack) + Number(countUserWarehouse) + Number(countUserManage)

    let countManufacture = (Number(countUserManufacture)/totalUser)*100
    let countWarehouse = (Number(countUserWarehouse)/totalUser)*100
    let countPack = (Number(countUserPack)/totalUser)*100
    let countManage = (Number(countUserManage)/totalUser)*100

    const configDoughnut = {
        data: [{
            type: "doughnut",
            legendText: "{label}",
            toolTipContent: "{label}: <strong>{y}%</strong>",
            indexLabel: "{y}%",
            indexLabelFontColor: 'white',
            indexLabelPlacement: "inside",
            dataPoints: [
                {y: countManufacture.toFixed(1), label: "Sản Xuất", color: "#008100"},
                {y: countWarehouse.toFixed(1), label: "Kho hàng", color: "#38B138"},
                {y: countPack.toFixed(1), label: "Đóng gói", color: "#93C82A"},
                {y: countManage.toFixed(1), label: "Quản lý", color: "#F69320"},
            ]
        }]
    };


    return (
        <div className="dashboard d-flex justify-content-center">
            <div className="d-flex">
                <div className="labors">
                    <div className="title d-flex justify-content-between">
                        <span>Tài khoản</span>
                        <img src="/assets/ico/setup/ico_labor.svg" alt=""/>
                    </div>
                    <div className="count_user position-relative">
                        <div className="d-flex justify-content-center">
                            <div className="count_labor position-absolute">
                                <p>{number_format(countSumUser)}</p>
                                <span>Tài khoản</span>
                            </div>
                        </div>
                        <div className="line"/>
                        <CanvasJSChart options={configDoughnut} containerProps={{width: '280px', height: '220px'}}/>
                        <div className="info_user">
                            <div className="d-flex user_ align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="box"/>
                                    <span>Sản xuất</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="box warehouse"/>
                                    <span>Kho hàng</span>
                                </div>
                            </div>
                            <div className="d-flex user_1 user_ align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="box pack"/>
                                    <span>Đóng gói</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="box manage"/>
                                    <span>Quản lý</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="setups justify-content-start">
                    <div className="item">
                        <img src="assets/ico/setup/ico_product.svg" alt=""/>
                        <div>
                            <p>Sản phẩm</p>
                            <span>{number_format(countProduct)}</span>
                        </div>
                    </div>
                    <div className="item">
                        <img src="assets/ico/setup/ico_medicine.svg" alt=""/>
                        <div>
                            <p>Nguyên liệu</p>
                            <span>{number_format(countIngredient)}</span>
                        </div>
                    </div>
                    <div className="item">
                        <img src="assets/ico/setup/ico_equipment.svg" alt=""/>
                        <div>
                            <p>Thiết bị</p>
                            <span>{number_format(countEquipment)}</span>
                        </div>
                    </div>
                    <div className="item">
                        <img src="assets/ico/setup/ico_land.svg" alt=""/>
                        <div>
                            <p>Bao bì</p>
                            <span>{number_format(countPackaging)}</span>
                        </div>
                    </div>
                    <div className="item">
                        <img src="assets/ico/setup/ico_fertilizer.svg" alt=""/>
                        <div>
                            <p>Phụ gia</p>
                            <span>{number_format(countAdditives)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default Dashboard;