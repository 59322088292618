import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../../../common/component/form/Input";
import NoContent from "../../../../../../common/component/NoContent";
import './SurplusProduct.scss'
import {detailBatchStore, IListDataExportBatch} from "../../DetailEIBatchStore";
import Select from "../../../../../../common/component/form/Select";
import {expImpBatchStore} from "../../../EIBatchStore";
import {number_format, slug} from "../../../../../../common/utils/Utils";
import {Link} from "react-router-dom";
import $ from "jquery";
import {toastUtil} from "../../../../../../common/utils/ToastUtil";
import {numberUtil} from "../../../../../../common/utils/NumberUtil";

const SurplusProduct = () => {
    return (
        <div className="modal fade" id="surplus-product" role="dialog" aria-hidden="true">
            <div className="modal-dialog max-width-1008" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Nhập kho #{expImpBatchStore.id}</h5>
                        <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div>
                        <div
                            className="batch-name">
                            {detailBatchStore.listDataDetailEIBatch.nameProcedure ? detailBatchStore.listDataDetailEIBatch.nameProcedure : "-"}
                        </div>
                        <div className="supplies-exported">
                            <span>Thành phần (Đã xuất): {detailBatchStore.listDataDetailEIBatch.ingrendientExported?.map((item, index) => {
                                return <span key={index} className="mr-4">
                                    {index + 1 + ". "}
                                    <span className="fw-600">{item.name}</span>
                                    {": " + (item.quantity ? number_format(item.quantity) : '-')}
                                </span>
                            })}</span>
                        </div>
                    </div>
                    <div className="mx-3 w-auto table-responsive custom-scrollbar">
                        <table
                            className='table align-middle table-hover table-rounded border h-50'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th className="text-center">Tên thành phần</th>
                                <th className="text-center">Yêu cầu</th>
                                <th className="text-center">Đã chọn</th>
                                <th className="text-center">Lượng xuất dư</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!detailBatchStore.isLoadingGetDetail &&
                                detailBatchStore.listDataExportBatch && detailBatchStore.listDataExportBatch.map((item: IListDataExportBatch, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center">{item.name}</td>
                                            <td className="text-center">{number_format(item.request)}</td>
                                            <td className="text-center">{number_format(item.selected)}</td>
                                            <td className="text-center w-40 h-25 input-height-33">
                                                {
                                                    item.suppliesInformation && item.suppliesInformation.length > 0 && item.suppliesInformation.map((itemImport, indexImport) => {
                                                        item.selected = item.suppliesInformation.reduce((acc, v) => acc + Number(v.quantity), 0)
                                                        let indexQuantityMax = -1
                                                        return (
                                                            <div
                                                                key={indexImport}
                                                                className="d-flex justify-content-around  my-1">
                                                                <div className="import-surplus-select">
                                                                    <Select value={itemImport.id}
                                                                            isShowError={false}
                                                                            onChange={(e: any) => {
                                                                                if (!item.selectedBatch.map(x => Number(x.value)).includes(Number(e.currentTarget.value))) {
                                                                                    if (item.selectedBatch.map(x => x.index).includes(indexImport)) {
                                                                                        let indexDelete = item.selectedBatch.map(x => x.index).indexOf(indexImport)
                                                                                        item.selectedBatch.splice(indexDelete, 1)
                                                                                    }
                                                                                    itemImport.id = e.currentTarget.value
                                                                                    item.selectedBatch.push({
                                                                                        value: Number(e.currentTarget.value),
                                                                                        index: indexImport
                                                                                    })
                                                                                    itemImport.nameBatch = e.currentTarget.selectedOptions[0].text
                                                                                    indexQuantityMax = itemImport.options.findIndex(x => x.id === Number(e.currentTarget.value))
                                                                                    itemImport.maxQuantity = Number(itemImport.options[indexQuantityMax]?.maxQuantity)
                                                                                } else {
                                                                                    toastUtil.error("Lô sản xuất đã được chọn", 2)
                                                                                }
                                                                            }}
                                                                            placeholder="Chọn lô"
                                                                            options={itemImport.options}
                                                                    />
                                                                </div>
                                                                <div className="mr-1 w-60">
                                                                    <Input type="number"
                                                                           onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                           value={itemImport.quantity}
                                                                           placeholder={itemImport.id ? `Lớn nhất ${itemImport.maxQuantity}` : ""}
                                                                           isError={itemImport.err}
                                                                           disabled={!itemImport.id || !itemImport.maxQuantity}
                                                                           isShowError={false}
                                                                           onChange={(e: any) => {
                                                                               if (numberUtil.regexNumber(e) <= itemImport.maxQuantity) {
                                                                                   itemImport.quantity = numberUtil.regexNumber(e)
                                                                               }
                                                                           }
                                                                           }/>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        itemImport.icon === 'add' ?
                                                                            <button className="bt-action-accept"
                                                                                    onClick={() => {
                                                                                        item.suppliesInformation.push({
                                                                                            id: "",
                                                                                            nameBatch: "",
                                                                                            errQuantity: false,
                                                                                            err: false,
                                                                                            errBatch: false,
                                                                                            maxQuantity: "",
                                                                                            options: itemImport.options,
                                                                                            quantity: "",
                                                                                            icon: 'pop'
                                                                                        })
                                                                                    }}
                                                                            >
                                                                                <img
                                                                                    src="/assets/ico/action/plus-square.svg"
                                                                                    className="icon-action icon-cancel-disable"
                                                                                    alt="" data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title="Thêm"/>
                                                                            </button> : <button className="bt-action-accept"
                                                                                                onClick={() => {
                                                                                                    const index = item.selectedBatch.map(x => x.value).indexOf(Number(itemImport.id));
                                                                                                    if (index > -1) {
                                                                                                        item.selectedBatch.splice(index, 1);
                                                                                                    }
                                                                                                    item.suppliesInformation.splice(indexImport, 1)
                                                                                                }}
                                                                            >
                                                                                <img src="/assets/ico/action/xmark.svg"
                                                                                     className="icon-action icon-cancel-disable"
                                                                                     alt="" data-toggle="tooltip"
                                                                                     data-placement="top"
                                                                                     title="Xóa"/>
                                                                            </button>
                                                                    }

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        {detailBatchStore.listDataExportBatch && detailBatchStore.listDataExportBatch.length < 1 &&
                            <NoContent height={250}/>}
                    </div>
                    {detailBatchStore.errorsImportSurplus.surplus &&
                        <span
                            className="error d-flex justify-content-end px-5">{detailBatchStore.errorsImportSurplus.surplus}</span>}
                    {detailBatchStore.errorsImportSurplus.quantity &&
                        <span
                            className="error d-flex justify-content-end px-5">{detailBatchStore.errorsImportSurplus.quantity}</span>}
                    {detailBatchStore.errorsImportSurplus.quantityMax &&
                        <span
                            className="error d-flex justify-content-end px-5">{detailBatchStore.errorsImportSurplus.quantityMax}</span>}
                    <div className="form-group px-3">
                        <label>Ghi chú <span className="text-danger">*</span></label>
                        <Input isError={detailBatchStore.errorsImportSurplus.note}
                               value={detailBatchStore.dataRequestExportBatch.note}
                               onChange={(e: any) => detailBatchStore.dataRequestExportBatch.note = e.currentTarget.value}
                               placeholder="Nhập ghi chú"/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn button-close-export-batch mt-3 mr-2"
                                data-dismiss="modal"
                                aria-label="Close">
                            Hủy
                        </button>
                        <Link className="view-detail-eibatch"
                              onClick={() => {
                                  expImpBatchStore.suplieIds = ''
                              }}
                              to={detailBatchStore.validateLink() ? `/warehouse/batch/${slug(expImpBatchStore.procedureBatch)}/${expImpBatchStore.id}` : ""}>
                            <button type="button" className="btn button-import-surplus mt-3"
                                    onClick={() => {
                                        if (detailBatchStore.validateImportBatch()) {
                                            detailBatchStore.isGoToAccept = true
                                            $('#surplus-product').trigger('click')
                                        }
                                    }}
                            >Tiếp Theo
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(SurplusProduct);