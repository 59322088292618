import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../../../common/component/Loading";
import {checkPermission, number_format, slug, sttPage} from "../../../../common/utils/Utils";
import ButtonAdd from "../../../../common/component/buttonAdd/ButtonAdd";
import NoContent from "../../../../common/component/NoContent";
import Paginate from "../../../../common/component/Paginate";
import PopupConfirm from "../../../../common/component/PopupConfirm";
import {suppliesStore, TypeSupplies} from "../SuppliesStore";
import AddOrEditSupplies from "../components/AddOrEditSupplies";
import {Link} from "react-router-dom";
import FilterComponent from "../../../../common/component/filter/FilterComponent";
import {PermissionConst} from "../../../../common/constants/Constants";

const PackagingPage = () => {

    const changePage = async (data: any) => {
        suppliesStore.page = data.selected;
        await suppliesStore.getSupplies();
    }

    let data = [
        {
            label: 'Tên bao bì',
            type: 'search',
            placeholder: 'Nhập tên bao bì',
            value: suppliesStore.keyword,
            onChange: (e: any) => {
                suppliesStore.keyword = e
            },
            callback: async () => {
                await suppliesStore.getSupplies();
            }
        }
    ]


    useEffect(() => {
        suppliesStore.getSupplies();
        return () => {
            suppliesStore.keyword = ''
        }
    }, [])

    return (
        <>
            <FilterComponent permissionCode={checkPermission(PermissionConst.suppliesAdd)} data={data} label="Bao bì"
                             buttonAdd={<ButtonAdd text="Thêm bao bì" dataTarget="add_or_edit_supplies"
                                                   onClick={suppliesStore.clearForm}/>}/>

            <div className="packaging">
                {suppliesStore.isLoading ? <Loading/> : <>
                    {suppliesStore.listSupplies && suppliesStore.listSupplies.length > 0 ?
                        <div className='table-responsive'>
                            <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                                <thead>
                                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                    <th className="text-center">STT</th>
                                    <th>Quy cách</th>
                                    <th className="text-center">Tồn kho</th>
                                    <th className="text-center">Thao tác</th>
                                </tr>
                                </thead>
                                <tbody>
                                {suppliesStore.listSupplies.map((item, i) => {
                                    let totalRemaining = item.quantity - item.quantityUsed
                                    return (
                                        <tr key={i}>
                                            <td className="text-center">{sttPage(suppliesStore.page, i)}</td>
                                            <td>{item.name}</td>
                                            <td className="text-center">{number_format(totalRemaining)} {item.unit}</td>
                                            <td className="text-center" width="18%">
                                                <div className="action">
                                                    {checkPermission(PermissionConst.suppliesBatchView) && <button className="bt_action" title="Chi tiết">
                                                        <Link
                                                            to={`/warehouse/supplies/${slug(item.name)}/${item.id}?type=${TypeSupplies.packaging}`}>
                                                            <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                        </Link>
                                                    </button>}
                                                    {checkPermission(PermissionConst.suppliesUpdate) && <button className="bt_action" title="Sửa" data-toggle="modal"
                                                            data-target="#add_or_edit_supplies"
                                                            onClick={() => suppliesStore.getDetail(item.id)}>
                                                        <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                    </button>}
                                                    {checkPermission(PermissionConst.suppliesDelete) && <button className="bt_action" title="Xóa"
                                                            onClick={() => suppliesStore.id = item.id}
                                                            data-toggle="modal" data-target="#popup_confirm">
                                                        <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                    </button>}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <Paginate currentPage={suppliesStore.page} totalPage={suppliesStore.totalPage}
                                          changePage={changePage}/>
                            </div>
                        </div> : <NoContent/>}
                </>}
            </div>
            <AddOrEditSupplies/>
            <PopupConfirm isLoading={suppliesStore.isLoadingBt} action={suppliesStore.delete}/>
        </>
    )
}

export default observer(PackagingPage);