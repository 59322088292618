import {observable} from "mobx";
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";
import $ from "jquery";
import {toastUtil} from "../../../../common/utils/ToastUtil";
import {productService} from "./ProductService";
import {suppliesStore} from "../../supplies/SuppliesStore";
import {equipmentStore} from "../../supplies/equipment/EquipmentStore";
import {dateUtils} from "../../../../common/utils/DateUtils";

export enum ITabDetailProduct {
    inventory = "inventory",
    history = "history"
}

export interface IDataRequestProduct {
    id?: number;
    name: string;
    categoryId?: number;
    unit?: string;
    expireMonth?: number | string;
    supplies?: {
        id?: number;
        quantity?: number
    }[];
    equipments?: {
        id?: number;
        quantity?: number
    }[]

}

export interface IDataRequestExportProductBody {
    productBatch: {
        procedureBatchId: number | string,
        quantity: number | string
    }[],
    action: string,
    note?: string;
}

export interface IDataRequestExportProductByBatchBody {
    productId: number | string,
    procedureBatchId: number | string,
    quantity: number | string,
    action: string
    note: string;
}

export interface IListDataProductTemp {
    type: string,
    supplies: string,
    supplies_id: string | number
    quantity: string
    unit?: string
    quantity_error: boolean
    quantity_error_min: boolean
}

export interface IListDataExportProduct {
    exp: number,
    selectedBatch: { value: number, index: number }[]
    select_batch: {
        id_batch: number | string,
        quantity: number | string
        errQuantity: boolean,
        err: boolean,
        errBatch: boolean,
        maxQuantity: number | string,
        icon?: string,
        options: { id: number, name: string, maxQuantity: number }[]
    }[]
}

export interface IListBatchByProductId {
    batchCode: string,
    id: number,
    createdAt: number,
    quantity: number,
    quantityUsed: number,
    product: any,
    procedureBatch: any
}

export interface IListHistoryBatchByProductId {
    action?: string,
    productBatch?: any,
    procedureBatch?: any,
    receiver?: any,
    createdAt?: number,
    currentQuantity?: number,
    note?: string,
}

export interface IListSuppliesLogByProcedureBatch {
    supplies: {
        id: number,
        name: string
    },
    batchLogs: {
        batch: {
            id: number,
            batchCode: string
        }
        quantity: number
    }[]
}

export interface IOptionsBatchByProductId {
    name: string,
    id: number,
    maxQuantity: number
}

class ProductStore {
    @observable id: number = -1
    @observable type: string = ''
    @observable categoryId: number = -1
    @observable productId: number = -1
    @observable procedureBatchId: number = -1
    @observable categoryName: string = ''
    @observable productName: string = ''
    @observable quantityRemaining: number = 0
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable tabActiveDetailProduct: string = ITabDetailProduct.inventory;
    @observable listProduct: any[] = []
    @observable listDataExportProduct: IListDataExportProduct[] = []
    @observable listDataBatchByProductId: IListBatchByProductId[] = []
    @observable optionBatchByProductId: IOptionsBatchByProductId[] = []
    @observable listDataHistoryBatchByProductId: IListHistoryBatchByProductId[] = []
    @observable listDataSuppliesLogBatchByProcedureBatch: IListSuppliesLogByProcedureBatch[] = []

    @observable quantityProduct: number = 0;
    @observable quantityProductUsed: number = 0;

    @observable totalRemaining: number = 0

    @observable keyword: any = '';

    @observable dataRequest: IDataRequestProduct = {
        categoryId: undefined,
        name: "",
        expireMonth: undefined,
        unit: undefined,
        supplies: [
            {
                id: undefined,
                quantity: undefined
            }
        ],
        equipments: [
            {
                id: undefined,
                quantity: undefined
            }
        ]
    }
    @observable dataRequestExportProduct: IDataRequestExportProductBody = {
        productBatch: [],
        action: "",
        note: ''
    }
    @observable dataRequestExportProductByBatch: IDataRequestExportProductByBatchBody = {
        productId: '',
        procedureBatchId: '',
        quantity: '',
        action: '',
        note: ''
    }

    @observable listSupplies: any = [
        {
            name: "Kho nguyên liệu",
            id: "ingredient"
        },
        {
            name: "Kho phụ gia",
            id: "additives"
        },
        {
            name: "Bao bì",
            id: "packaging"
        },
        {
            name: "Thiết bị",
            id: "equipment"
        }
    ]
    @observable listDataProductTemp: IListDataProductTemp = {
        type: '',
        supplies: '',
        supplies_id: '',
        quantity: '',
        quantity_error: false,
        quantity_error_min: false
    }
    @observable listDataProduct: IListDataProductTemp[] = []
    @observable listDataEquipmentProduct: IListDataProductTemp[] = []
    @observable listDataAll: IListDataProductTemp[] = []

    @observable public errors: any = {
        name: '',
        expireMonth: '',
        unit: '',
        tabActive: '',
        supplies_id: '',
        data: '',
        quantity: '',
        quantityMin: ''
    }
    @observable public errorsExportProduct: any = {
        supplies: '',
        note: '',
        quantityMax: '',
        quantity: ''

    }
    @observable public errorsExportProductByBatch: any = {
        quantity: '',
        note: ''
    }
    addTempProduct = () => {
        const checkSupplies = suppliesStore.tabActive !== "equipment" && productStore.listDataAll.filter(x => x.type !== "Thiết bị").map(item => item.supplies_id)
            .includes(productStore.listDataProductTemp.supplies_id);
        const checkEquipment = suppliesStore.tabActive === "equipment" && productStore.listDataAll.filter(x => x.type === "Thiết bị").map(item => item.supplies_id)
            .includes(productStore.listDataProductTemp.supplies_id);
        if (productStore.listDataProductTemp.supplies_id) {
            if (!checkSupplies && !checkEquipment) {
                if (suppliesStore.tabActive !== "equipment") {
                    productStore.listDataProduct = [productStore.listDataProductTemp, ...productStore.listDataProduct]
                    productStore.listDataAll = [productStore.listDataProductTemp, ...productStore.listDataAll]
                } else {
                    productStore.listDataEquipmentProduct = [productStore.listDataProductTemp, ...productStore.listDataEquipmentProduct]
                    productStore.listDataAll = [productStore.listDataProductTemp, ...productStore.listDataAll]
                }
            } else toastUtil.error('Vật tư này đã tồn tại', 3)
        }
        if (suppliesStore.tabActive === '') {
            productStore.errors.tabActive = "Vui lòng chọn vật tư!"
            productStore.errors.supplies_id = ''
            return
        } else {
            productStore.errors.tabActive = ''
            if (!productStore.listDataProductTemp.supplies_id) {
                productStore.errors.supplies_id = "Vui lòng chọn tên vật tư!"
                return
            } else productStore.errors.supplies_id = ''
        }
        suppliesStore.tabActive = ""
        productStore.errors = {
            name: '',
            expireMonth: '',
            tabActive: '',
            supplies_id: ''
        }
        productStore.listDataProductTemp = {
            type: '',
            supplies: '',
            supplies_id: '',
            quantity: '',
            quantity_error: false,
            quantity_error_min: false
        }
    }

    clearForm() {
        productStore.listDataProduct = []
        productStore.listDataEquipmentProduct = []
        productStore.listDataAll = []
        suppliesStore.listSupplies = []
        equipmentStore.listEquipment = []
        productStore.type = 'add'
        suppliesStore.tabActive = ""
        productStore.errors = {
            name: '',
            expireMonth: '',
            tabActive: '',
            supplies_id: ''
        }
        productStore.errorsExportProduct = {
            supplies: '',
            note: ''
        }

        productStore.dataRequest = {
            categoryId: undefined,
            name: "",
            expireMonth: "",
            unit: "",
            supplies: [
                {
                    id: undefined,
                    quantity: undefined
                }
            ]
        }
        productStore.dataRequestExportProduct = {
            productBatch: [],
            action: "",
            note: ''
        }
        productStore.dataRequestExportProductByBatch = {
            productId: '',
            procedureBatchId: '',
            quantity: '',
            action: '',
            note: ''
        }
        suppliesStore.tabActive = ""
        productStore.errors = {
            name: '',
            expireMonth: '',
            tabActive: '',
            supplies_id: ''
        }
        productStore.listDataProductTemp = {
            type: '',
            supplies: '',
            supplies_id: '',
            quantity: '',
            quantity_error: false,
            quantity_error_min: false
        }

    }

    async getProducts(size?: number, isLoading = true, keyword?: string) {
        if (isLoading) productStore.isLoading = true
        const result = await productService.getProducts(size)
        productStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            productStore.listProduct = result.body.data
            productStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetailProduct(id: any) {
        const result = await productService.detailProduct(id)
        if (result.status === HttpStatusCode.OK) {
            productStore.categoryName = result.body.category?.name || ""
            productStore.categoryId = result.body.category?.id || ""
            productStore.dataRequest = result.body
            productStore.productName = result.body?.name || ""
            productStore.quantityRemaining = result.body?.quantity || 0
            productStore.listDataProduct = result.body.supplies.map((item: any, index: number) => {
                let typeSupply: string = '';
                switch (item.type) {
                    case 'INGREDIENT':
                        typeSupply = 'Kho nguyên liệu'
                        break;
                    case 'ADDITIVES':
                        typeSupply = 'Kho phụ gia'
                        break;
                    default:
                        typeSupply = 'Bao bì'
                }
                return {
                    type: typeSupply,
                    supplies: item.name,
                    supplies_id: Number(item.suppliesId),
                    unit: item.unit,
                    quantity: item.quantity,
                    quantity_error: !item.quantity
                }
            })
            productStore.listDataEquipmentProduct = result.body.equipments.map((item: any, index: number) => {
                return {
                    type: "Thiết bị",
                    supplies: item.name,
                    supplies_id: Number(item.equipmentId),
                    quantity: item.quantity,
                    quantity_error: !item.quantity
                }
            })
            productStore.listDataAll = [...productStore.listDataProduct, ...productStore.listDataEquipmentProduct]
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async submit() {
        const dataSupplies = productStore.listDataAll.filter(x => x.type !== "Thiết bị").map((item, index) => {
            return {
                id: Number(item.supplies_id),
                quantity: Number(item.quantity),
            }
        })
        const dataEquipment = productStore.listDataAll.filter(x => x.type === "Thiết bị").map((item, index) => {
            return {
                id: Number(item.supplies_id),
                quantity: Number(item.quantity),
            }
        })
        if (!productStore.dataRequest.name) {
            productStore.errors.name = `Tên sản phẩm không được để trống!`
            return
        } else productStore.errors.name = ''

        if (!productStore.dataRequest.expireMonth) {
            productStore.errors.expireMonth = "Hạn sử dụng không được để trống!"
            return
        } else productStore.errors.expireMonth = ''

        if (!productStore.dataRequest.unit) {
            productStore.errors.unit = "Đơn vị không được để trống!"
            return
        } else productStore.errors.unit = ''

        if (productStore.listDataProduct.length === 0 && productStore.listDataEquipmentProduct.length === 0) {
            if (suppliesStore.tabActive === '') {
                productStore.errors.tabActive = "Vui lòng chọn vật tư!"
                productStore.errors.supplies_id = ''
                return
            } else {
                productStore.errors.tabActive = ''
                if (!productStore.listDataProductTemp.supplies_id) {
                    productStore.errors.supplies_id = "Vui lòng chọn tên vật tư!"
                    return
                } else productStore.errors.supplies_id = ''
            }
        } else {
            productStore.errors.tabActive = ''
            productStore.errors.supplies_id = ''
        }
        if (productStore.listDataProduct.length === 0 && productStore.listDataEquipmentProduct.length === 0) {
            productStore.errors.data = 'Vui lòng thêm vật tư !'
            return
        } else productStore.errors.data = ''
        //
        productStore.listDataProduct.forEach((item, index) => {
            item.quantity_error = !item.quantity;
        })
        productStore.listDataEquipmentProduct.forEach((item, index) => {
            item.quantity_error = !item.quantity;
        })

        const checkErrQuantityDataProduct = productStore.listDataProduct.map(item => item.quantity_error)
            .includes(true);
        const checkErrQuantityDataEquipment = productStore.listDataEquipmentProduct.map(item => item.quantity_error)
            .includes(true);

        if (checkErrQuantityDataProduct || checkErrQuantityDataEquipment) {
            productStore.errors.quantity = 'Vui lòng nhập đầy đủ số lượng !'
            return
        } else productStore.errors.quantity = ''

        const data: IDataRequestProduct = {
            name: productStore.dataRequest.name,
            categoryId: productStore.categoryId,
            unit: productStore.dataRequest.unit,
            expireMonth: Number(productStore.dataRequest.expireMonth),
            supplies: dataSupplies,
            equipments: dataEquipment

        }
        productStore.isLoadingBt = true

        const result = productStore.type === "add" ? await productService.create(data) : await productService.update(productStore.id, data)
        productStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            productStore.type === "add" ? toastUtil.success('Thiết lập phẩm thành công', 3) : toastUtil.success('Cập nhật sản phẩm thành công', 3)
            $('#add_or_edit_product').trigger('click')
            await productStore.getProducts()
        } else {
            toastUtil.error(result.body.message, 4)
        }

    }

    async delete() {
        productStore.isLoadingBt = true
        const result = await productService.delete(productStore.id)
        productStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa sản phẩm thành công', 3)
            await productStore.getProducts()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async submitExportProduct() {
        let checkErrorExport = true;

        productStore.listDataExportProduct.forEach((item, index) => {
            item.select_batch.forEach((itemSupply, indexSupply) => {
                if (!itemSupply.id_batch) {
                    itemSupply.errBatch = true;
                    checkErrorExport = false;
                }
            })
        })
        if (!checkErrorExport) {
            productStore.errorsExportProduct.supplies = 'Vui lòng chọn đầy đủ các lô!'
            return
        } else productStore.errorsExportProduct.supplies = ''

        productStore.listDataExportProduct.map((item, index) => {
            item.select_batch.map((itemSupply, indexSupply) => {
                itemSupply.err = !itemSupply.quantity
            })
        })
        let checkErrQuantity = productStore.listDataExportProduct.map((item, index) => {
            return item.select_batch.map(item => item.err)
                .includes(true);
        }).includes(true);
        if (checkErrQuantity) {
            productStore.errorsExportProduct.quantity = `Vui lòng nhập số lượng đầy đủ !`
            return
        } else productStore.errorsExportProduct.quantity = ""


        let checkErrQuantityMax = productStore.listDataExportProduct.map((item, index) => {
            return item.select_batch.map(item => item.errQuantity)
                .includes(true);
        }).includes(true)
        if (checkErrQuantityMax) {
            productStore.errorsExportProduct.quantityMax = `Vui lòng không nhập quá số lượng tối đa!`
            return
        } else productStore.errorsExportProduct.quantityMax = ""

        productStore.dataRequestExportProduct.note = productStore.dataRequestExportProduct.note?.trim()
        if (!productStore.dataRequestExportProduct.note) {
            productStore.errorsExportProduct.note = `Ghi chú không được để trống!`
            return
        } else productStore.errorsExportProduct.note = ''
        const data: IDataRequestExportProductBody = {
            productBatch: productStore.listDataExportProduct[0].select_batch.map((item, index) => {
                return {
                    procedureBatchId: Number(item.id_batch),
                    quantity: item.quantity
                }
            }),
            action: "EXPORT",
            note: productStore.dataRequestExportProduct.note
        }
        productStore.isLoadingBt = true
        const result = await productService.exportProduct(data)
        productStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Xuất kho thành công', 3)
            $('#export_product').trigger('click')
            await productService.getProducts()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async submitExportProductByBatch() {
        if (!productStore.dataRequestExportProductByBatch.quantity) {
            productStore.errorsExportProductByBatch.quantity = `Số lượng sản phẩm không được để trống!`
            return
        } else productStore.errorsExportProductByBatch.quantity = ''
        productStore.errorsExportProductByBatch.note = productStore.errorsExportProductByBatch.note.trim()
        if (!productStore.dataRequestExportProductByBatch.note) {
            productStore.errorsExportProductByBatch.note = `Ghi chú không được để trống!`
            return
        } else productStore.errorsExportProductByBatch.note = ''

        const data: IDataRequestExportProductByBatchBody = {
            productId: productStore.productId,
            procedureBatchId: productStore.procedureBatchId,
            quantity: productStore.dataRequestExportProductByBatch.quantity,
            action: "EXPORT",
            note: productStore.dataRequestExportProductByBatch.note
        }
        productStore.isLoadingBt = true
        const result = await productService.exportProductByBatch(data)
        productStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Xuất kho thành công', 3)
            $('#export_product_by_batch').trigger('click')
            await productService.getProducts()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async getPreserves(productIds?: string) {
        productStore.isLoading = true
        const result = await productService.getProductBatch(productIds)
        productStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            productStore.listDataBatchByProductId = result.body.data
            productStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getProductBatchExport(productIds?: string) {
        const result = await productService.getBatchExportProduct(productIds)
        if (result.status === HttpStatusCode.OK) {
            productStore.optionBatchByProductId = result.body.map((item: any, index: any) => {
                return {
                    name: item.batchCode + ` - HSD :  ${dateUtils.formatDate(Number(item.createdAt + item.product.expireMonth * 30 * 24 * 60 * 60 * 1000))}`,
                    id: item.procedureBatch.id,
                    maxQuantity: Number(item.quantity - item.quantityUsed)
                }
            })
            productStore.listDataExportProduct = [
                {
                    exp: 24,
                    selectedBatch: [],
                    select_batch: [{
                        icon: 'add',
                        id_batch: "",
                        quantity: "",
                        errQuantity: false,
                        err: false,
                        errBatch: false,
                        maxQuantity: "",
                        options: productStore.optionBatchByProductId
                    }]
                }
            ]

        }
    }


    async getHistoryProductBatch(productId?: number, productBatchId?: number) {
        productStore.isLoading = true
        const result = await productService.getHistoryProductBatch(productId, productBatchId)
        productStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            productStore.listDataHistoryBatchByProductId = result.body.data
            productStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getSuppliesLogByProcedureBatch(productId?: number) {
        productStore.isLoading = true
        const result = await productService.getSuppliesLogByProcedureBatch(productId)
        productStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            productStore.listDataSuppliesLogBatchByProcedureBatch = result.body
        }
    }
}

export const productStore = new ProductStore()