import React, {useEffect} from 'react';
import "./App.scss";
import $ from 'jquery'
import {observer} from "mobx-react";
import StorageService from "./common/service/StorageService";
import 'react-toastify/dist/ReactToastify.css';
import {getUrlParameter, parseJwt, setCookie} from "./common/utils/Utils";
import {profileStore} from "./pages/profile/ProfileStore";
import RedirectAuth from "./pages/router/redirectAuth";
import SideBar from "./pages/layouts/SideBar";
import Nav from "./pages/layouts/Nav";
import SignUpPage from "./pages/auth/signUp/SignUpPage";
import Redirect from "./pages/router/router";
import InfoTracking from "./pages/infoTracking/InfoTracking";
import InfoTrackingBatchCode from "./pages/infoTracking/InfoTrackingBatchCode";
import PreviewTracking from "./pages/infoTracking/PreviewTracking";
import {infoStore} from "./pages/info/InfoStore";
import {signUpStore} from "./pages/auth/signUp/SignUpStore";
import {useNavigate} from "react-router-dom";

const App = () => {

    const navigate = useNavigate();

    const handleBackButton = (event: any) => {
        $('.modal-backdrop.fade.show').detach();
        event.preventDefault();
    };


    useEffect(() => {
        profileStore.path = window.location.pathname.split('/')[1]
        let token: any = getUrlParameter('token')
        let refreshToken: any = getUrlParameter('refreshToken')

        if (token) {
            setCookie('x-token', token, 7)
            StorageService.setToken(token);
            parseJwt(token)
            navigate({pathname: window.location.pathname});
        }
        if (refreshToken) {
            StorageService.setRefreshToken(refreshToken);
        }

        if (StorageService.getToken()) {
            signUpStore.getToken()
            parseJwt()
            if (profileStore.path !== 'truy-xuat') {
                infoStore.getInfo()
            }
        }
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [])

    let path = window.location.pathname.split('/')[1]

    if (!StorageService.getToken() && !getUrlParameter('token')) {
        return <RedirectAuth/>
    } else {
        if (path === 'truy-xuat') {
            return <InfoTracking/>
        } else if (path === 'truy-xuat-lo') {
            return <InfoTrackingBatchCode/>
        } else if (path === 'xem-ket-qua-truy-xuat') {
            return <PreviewTracking/>
        } else {
            if (profileStore.dataFarm.facilityId) {
                if (profileStore.dataFarm.status === 'ACTIVE') {
                    return (
                        <div className="d-flex justify-content-between w-100">
                            <SideBar/>
                            <div className={`body-wrapper`}>
                                <Nav/>
                                <div className={`main-content h-100`}>
                                    <Redirect/>
                                </div>
                            </div>
                        </div>
                    );
                } else return <SignUpPage/>
            } else return <SignUpPage/>
        }
    }

}


export default observer(App)

