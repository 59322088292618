import React from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {batchStore, ITabBatch} from "../BatchStore";
import {suppliesStore} from "../../SuppliesStore";
import {slug} from "../../../../../common/utils/Utils";


const TabHeader = () => {

    return (
        <div className="tab_header d-flex align-items-center">
            <Link
                to={`/warehouse/supplies/${slug(suppliesStore.dataRequest.name)}/${suppliesStore.dataRequest.id}?type=${batchStore.typeSupplies}&tab=${ITabBatch.inventory}`}
                className={`tab ${batchStore.tabActive === ITabBatch.inventory && 'active'}`}
                onClick={() => {
                    batchStore.page = 0;
                    batchStore.keyword = ''
                    batchStore.tabActive = ITabBatch.inventory;
                }}>
                <span>Tồn kho</span>
            </Link>
            <Link
                to={`/warehouse/supplies/${slug(suppliesStore.dataRequest.name)}/${suppliesStore.dataRequest.id}?type=${batchStore.typeSupplies}&tab=${ITabBatch.history}`}
                className={`tab ${batchStore.tabActive === ITabBatch.history && 'active'}`}
                onClick={() => {
                    batchStore.page = 0;
                    batchStore.keyword = ''
                    batchStore.tabActive = ITabBatch.history;
                }}>
                <span>Lịch sử</span>
            </Link>
        </div>
    );
}

export default observer(TabHeader);