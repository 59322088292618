import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {preserveStore} from "../../PreserveStore";
import {dateUtils} from "../../../../common/utils/DateUtils";
import NoContent from "../../../../common/component/NoContent";
import Loading from "../../../../common/component/Loading";

const TrackingPlan = (): JSX.Element => {
    useEffect(() => {
        preserveStore.getDetailTrackingBatch(preserveStore.id)
        preserveStore.getDetailPreserves(preserveStore.id)
    }, [])
    return (
        <div className="modal-body popup_detail pt-3">
            <div className="w-50 d-flex">
                <p>Tên sản phẩm: <span
                    className="fw-600 mr-5">{preserveStore.dataAddPlanOrderWork.productName || "---"}</span>
                </p>
                <p>Tên lô sản xuất: <span
                    className="fw-600">{preserveStore.dataAddPlanOrderWork.name || "---"}</span></p>
            </div>
            {preserveStore.isLoading ? <Loading/> : <>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th className="text-center">Công việc</th>
                        <th className="text-center">Giai đoạn</th>
                        <th className="text-center">Tình trạng</th>
                        <th className="text-center">Người phụ trách</th>
                        <th className="text-center">Thời gian hoàn thành</th>
                    </tr>
                    </thead>
                    <tbody>
                    {preserveStore.IListJobByBatch && preserveStore.IListJobByBatch.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td>{item?.name}</td>
                                <td className="text-center">{item?.state && item?.type && preserveStore.changeStateBatch(item?.status)}</td>
                                <td className={`text-center ${item?.status === "PENDING" ? "pending-status" : item?.status === "cancel-status" ? "Hủy" : "completed-status"}`}>{item?.status === "PENDING" ? "Chưa hoàn thành" : item?.status === "CANCEL" ? "Hủy" : "Hoàn thành"}</td>
                                <td className="text-center">{item?.user ? item.user.name : '-'}</td>
                                <td className="text-center">{item?.user ? dateUtils.formatDate(item.user.updatedAt) : '-'}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </>}
            {preserveStore.IListJobByBatch && preserveStore.IListJobByBatch.length < 1 && <NoContent/>}
        </div>
    )

}

export default observer(TrackingPlan);

