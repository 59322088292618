import {toastUtil} from "../utils/ToastUtil";
import {IApiResponse} from "./RequestHelper";
import axios from "axios";
import humps from "humps";
import HttpStatusCode from "../constants/HttpErrorCode";
import StorageService from "../service/StorageService";

let API_DOMAIN = process.env.REACT_APP_API_DOMAIN

function isImage(type: any) {
    return type.startsWith('image/');
}

function sendUploadFile(path: string, params: any): Promise<IApiResponse> {
    var newHeaders: any = {
        'Type': isImage(params.files.type) ? "IMAGE" : "FILE",
        'Content-Type': 'multipart/form-data',
        "Authorization": 'Bearer ' + StorageService.getToken()
    };
    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: params,
            headers: newHeaders,
            method: 'POST',
            url: API_DOMAIN + path
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });
            })
            .catch(function (error) {
                if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED) {
                    toastUtil.error('Token expire');
                    // StorageService.removeToken();
                    // window.location.href = "/login"
                }

                let bodyError: any;

                try {
                    if (error.response && error.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                        bodyError = {
                            errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                            message: "Internal server error, please try again later"
                        }
                    } else {
                        bodyError = {
                            errorCode: error.response.data.errorCode,
                            message: error.response.data.message
                        }
                    }

                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response.status,
                    body: bodyError
                };

                resolve(apiResponse);
            });

    });
}


async function uploadFileHelper(e: any, changeFile: any) {
    e.preventDefault()
    const reader = new FileReader();
    let file = e.target.files[0]

    if (isImage(file.type)) {
        if (file.size > 10000 * 1024) {
            toastUtil.warning("Ảnh của bạn quá lớn. Vui lòng chọn ảnh có kích thước nhỏ hơn 10MB.", 1)
            return
        }
    } else {
        if (file.size > 15000000) {
            toastUtil.warning("Vui lòng chọn files có kích thước không quá 15MB.", 1)
            return
        }
    }

    reader.readAsDataURL(file);

    await sendUpload(file, changeFile)
}

const sendUpload = async (files: any, changeFile: any) => {
    let data = {
        files: files
    }
    let result = await sendUploadFile('/v1/storage', data)

    if (result.status === 200) {
        await changeFile(result.body, files)
    }
}

export default uploadFileHelper