import React from 'react';
import {observer} from "mobx-react";
import './ExportProduct.scss'
import Input from "../../../../../../common/component/form/Input";
import NoContent from "../../../../../../common/component/NoContent";
import {IListDataExportProduct, productStore} from "../../ProductStore";
import Select from "../../../../../../common/component/form/Select";
import {number_format} from "../../../../../../common/utils/Utils";
import {toastUtil} from "../../../../../../common/utils/ToastUtil";

const ExportProduct = () => {
    let sumProductExport = 0;

    return (
        <div className="modal fade" id="export_product" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Xuất kho</h5>
                        <button type="button" className="close" id="close_add_edit_product" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="mx-3 w-auto table-responsive">
                        <table
                            className='table align-middle table-hover table-rounded border h-50'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th className="text-center">Chọn lô</th>
                            </tr>
                            </thead>
                            <tbody>
                            {productStore.listDataExportProduct && productStore.listDataExportProduct.map((item: IListDataExportProduct, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center input-height-33">
                                            {
                                                item.select_batch.map((itemExport, indexExport) => {
                                                    let indexQuantityMax = -1
                                                    sumProductExport += Number(itemExport.quantity);
                                                    return (
                                                        <div
                                                            key={indexExport}
                                                            className="d-flex justify-content-around my-1">
                                                            <div className="mr-1 w-100">
                                                                <Select value={itemExport.id_batch}
                                                                        isError={itemExport.errBatch}
                                                                        isShowError={false}
                                                                        className="select-align-left"
                                                                        onChange={(e: any) => {
                                                                            if (!item.selectedBatch.map(x => Number(x.value)).includes(Number(e.currentTarget.value))) {
                                                                                if (item.selectedBatch.map(x => x.index).includes(indexExport)) {
                                                                                    let indexDelete = item.selectedBatch.map(x => x.index).indexOf(indexExport)
                                                                                    item.selectedBatch.splice(indexDelete, 1)
                                                                                }
                                                                                item.selectedBatch.push({
                                                                                    value: Number(e.currentTarget.value),
                                                                                    index: indexExport
                                                                                })
                                                                                itemExport.id_batch = e.currentTarget.value
                                                                                indexQuantityMax = itemExport.options.findIndex(x => x.id === Number(e.currentTarget.value))
                                                                                itemExport.maxQuantity = Number(itemExport.options[indexQuantityMax]?.maxQuantity)
                                                                            } else {
                                                                                toastUtil.error("Lô sản xuất đã được chọn", 2)
                                                                            }
                                                                        }}
                                                                        placeholder="Chọn lô"
                                                                        options={itemExport.options}
                                                                />
                                                            </div>
                                                            <div className="text-end mr-2 w-50">
                                                                <Input type="number"
                                                                       onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                       value={itemExport.quantity}
                                                                       disabled={!itemExport.id_batch}
                                                                       placeholder={itemExport.id_batch ? `Lớn nhất ${itemExport.maxQuantity}` : ""}
                                                                       isError={itemExport.err}
                                                                       isShowError={false}
                                                                       isErrorMax={itemExport.errQuantity}
                                                                       onChange={(e: any) => {
                                                                           itemExport.quantity = e.currentTarget.value
                                                                           itemExport.errQuantity = itemExport.quantity > itemExport.maxQuantity
                                                                           itemExport.err = !itemExport.quantity
                                                                       }
                                                                       }/>
                                                            </div>
                                                            <div>
                                                                {
                                                                    itemExport.icon === 'add' ?
                                                                        <button
                                                                            className="bt-action-accept"
                                                                            onClick={() => {
                                                                                item.select_batch.push({
                                                                                    id_batch: "",
                                                                                    quantity: "",
                                                                                    errQuantity: false,
                                                                                    err: false,
                                                                                    errBatch: false,
                                                                                    maxQuantity: "",
                                                                                    icon: 'pop',
                                                                                    options: itemExport.options
                                                                                })
                                                                            }}
                                                                        ><img
                                                                            src="/assets/ico/action/plus-square.svg"
                                                                            className="icon-action icon-cancel-disable"
                                                                            alt="" data-toggle="tooltip"
                                                                            data-placement="top"
                                                                            title="Thêm"/>
                                                                        </button> :
                                                                        <button
                                                                            className="bt-action-accept"
                                                                            onClick={() => {
                                                                                const index = item.selectedBatch.map(x => x.value).indexOf(Number(itemExport.id_batch));
                                                                                if (index > -1) {
                                                                                    item.selectedBatch.splice(index, 1);
                                                                                }
                                                                                item.select_batch.splice(indexExport, 1)
                                                                            }}
                                                                        ><img src="/assets/ico/action/xmark.svg"
                                                                              className="icon-action icon-cancel-disable"
                                                                              alt="" data-toggle="tooltip"
                                                                              data-placement="top"
                                                                              title="Xóa"/>
                                                                        </button>
                                                                }

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td className="text-center" colSpan={1}></td>
                                <td>
                                    <div className="sum-product-export">
                                        <div className="sum-product-export-title">
                                            Tổng
                                        </div>
                                        <div style={{fontWeight: 700}} className="sum-product-export-number">
                                            {number_format(sumProductExport)}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        {productStore.errorsExportProduct.supplies &&
                            <span
                                className="error d-flex justify-content-end px-5">{productStore.errorsExportProduct.supplies}</span>}
                        {productStore.errorsExportProduct.quantity &&
                            <span
                                className="error d-flex justify-content-end px-5">{productStore.errorsExportProduct.quantity}</span>}
                        {productStore.errorsExportProduct.quantityMax &&
                            <span
                                className="error d-flex justify-content-end px-5">{productStore.errorsExportProduct.quantityMax}</span>}

                        {productStore.listDataExportProduct && productStore.listDataExportProduct.length < 1 &&
                            <NoContent height={250}/>}
                        <div className="form-group">
                            <label>Ghi chú <span className="text-danger">*</span></label>
                            <Input isError={productStore.errorsExportProduct.note}
                                   value={productStore.dataRequestExportProduct.note}
                                   onChange={(e: any) => productStore.dataRequestExportProduct.note = e.currentTarget.value}
                                   placeholder="Nhập ghi chú ..."/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn button-close-export-batch mt-3 mr-2"
                                id="close_export_batch"
                                data-dismiss="modal"
                                aria-label="Close">
                            Hủy
                        </button>
                        <button type="button" className="btn button-export-batch mt-3"
                                onClick={() => productStore.submitExportProduct()}
                        >Xuất kho
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ExportProduct);