import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent from "../../common/component/filter/FilterComponent";
import Loading from "../../common/component/Loading";
import {checkPermission, slug, sttPage} from "../../common/utils/Utils";
import NoContent from "../../common/component/NoContent";
import Paginate from "../../common/component/Paginate";
import UploadImages from "./components/UploadImages";
import {PermissionConst} from "../../common/constants/Constants";
import {trackingStore} from "./TrackingStore";
import AddOrUpdateTracking from "./components/AddOrUpdateTracking";
import {Link} from "react-router-dom";
import Button from "../../common/component/Button";


const TrackingPage = () => {

    const changePage = async (data: any) => {
        trackingStore.page = data.selected;
        await trackingStore.getTracking();
    }

    useEffect(() => {
        trackingStore.getTracking()

        trackingStore.dataFilter = [
            {
                label: 'Lô sản xuất',
                type: 'search',
                placeholder: 'Nhập tên lô sản xuất',
                value: trackingStore.keyword,
                onChange: (e: any) => {
                    trackingStore.keyword = e
                },
                callback: async () => {
                    await trackingStore.getTracking();
                }
            }
        ]
    }, [])

    return (
        <div className="tracking">
            {trackingStore.dataFilter &&
            <FilterComponent data={trackingStore.dataFilter} label='Truy xuất'
                             buttonAdd={<Button className="send-request bt_action" type="ico" content_ico="add"
                                                text="Thêm truy xuất" dataTarget={'addUpdateAccess'}
                                                onClick={trackingStore.clearForm}/>}
                             permissionCode={checkPermission(PermissionConst.trackingAdd)}/>}
            {trackingStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {trackingStore.listTracking && trackingStore.listTracking.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center">Lô sản xuất</th>
                                <th className="text-center">Sản phẩm</th>
                                <th className="text-center">Hình ảnh</th>
                                <th className="text-center">Mã vạch</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>

                            {trackingStore.listTracking && trackingStore.listTracking.map((item, i) => {
                                let pageHelper = `${window.location.origin}/truy-xuat/${slug(item.procedureBatchId ? item.procedureBatch?.product?.name : item.trackingData?.productInfo?.name)}/${item.id}`

                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(trackingStore.page, i)}</td>
                                        <td className="text-center">{item.procedureBatchId ? item.procedureBatch?.name : item.trackingData?.productInfo?.productionBatch}</td>
                                        <td className="text-center">{item.procedureBatchId ? item.procedureBatch?.product?.name : item.trackingData?.productInfo?.name}</td>
                                        <td className="text-center">
                                            <img className="cursor_pointer"
                                                 style={{width: 28, height: 28, borderRadius: 0}}
                                                 data-toggle="modal" data-target="#upload_images_access"
                                                 title="Hình ảnh nông trường sản phẩm"
                                                 onClick={async () => await trackingStore.getDetail(item.id)}
                                                 src="/assets/ico/ico_images.svg" alt=""/></td>
                                        <td className="text-center">
                                            <img className="cursor_pointer"
                                                 style={{width: 28, height: 28, borderRadius: 0}}
                                                 data-toggle="modal"
                                                 data-target="#addUpdateAccess"
                                                 onClick={async () => {
                                                     trackingStore.pageHelper = pageHelper;
                                                     await trackingStore.getDetail(item.id)
                                                 }} title="Mã in"
                                                 src="/assets/ico/ico_barcode.svg" alt=""/></td>
                                        <td width="20%" className="text-center">
                                            <div className="action">
                                                {checkPermission(PermissionConst.qrCodeView) &&<button className="bt_action" title='Chi tiết'>
                                                    <Link to={`/tracking-batch/${slug(item.procedureBatchId ? item.procedureBatch?.product?.name : item.trackingData?.productInfo?.name)}/${item.id}`}>
                                                        <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                    </Link>
                                                </button>}
                                                {checkPermission(PermissionConst.trackingUpdate) &&
                                                <button className="bt_action" data-toggle="modal"
                                                        data-target="#addUpdateAccess" title={'Sửa'}
                                                        onClick={async () => {
                                                            trackingStore.pageHelper = pageHelper;
                                                            await trackingStore.getDetail(item.id)
                                                        }}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}

                                                {checkPermission(PermissionConst.trackingUpdate) &&
                                                <button className="bt_action" data-toggle="modal"
                                                        data-target="#upload_images_access"
                                                        title="Hình ảnh nông trường sản phẩm"
                                                        onClick={async () => await trackingStore.getDetail(item.id)}>
                                                    <img src="/assets/ico/action/ico_image.svg" alt=""/>
                                                </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}

                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={trackingStore.page} totalPage={trackingStore.totalPage}
                                  changePage={changePage}/>
                    </div>
                </div>}
            <AddOrUpdateTracking/>
            <UploadImages/>
        </div>
    )
}

export default observer(TrackingPage);