import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery"
import {accountService} from "./AccountService";

interface IErrors {
    name: string
    role: string,
    birthDay: string,
    phone: string,
    password: string,
    confirmPass: string
}


class AccountStore {
    @observable step: any = 1
    @observable id: any = '';
    @observable keyword: any = '';
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable page: number = 0
    @observable dataFilter: any[] = []
    @observable public errors: IErrors = {
        name: "",
        role: '',
        birthDay: '',
        phone: "",
        password: "",
        confirmPass: ''
    }
    @observable totalPage: number = 0
    @observable isLoadingDetail: boolean = false
    @observable typeConfirmLock: string = ''
    @observable typeModal: string = ''
    @observable editStatus: boolean = false
    @observable dataRequest: any = {
        id: "",
        roles: [],
        name: '',
        birthday: '',
        phone: "",
        status: ""
    }
    @observable listAccount: any[] = []
    @observable listRole: any[] = []

    clearError() {
        accountStore.errors = {
            name: "",
            role: '',
            birthDay: '',
            phone: "",
            password: "",
            confirmPass: ''
        }
    }

    clearForm() {
        accountStore.typeModal = 'add'
        accountStore.clearError()
        accountStore.dataRequest = {
            id: "",
            name: "",
            roles: [],
            birthday: '',
            phone: "",
            password: "",
            confirmPass: ''
        }
    }

    async getAccount(size?: any) {
        accountStore.isLoading = true
        const result = await accountService.getAccount(size)
        accountStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            accountStore.listAccount = result.body.data
            accountStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getRole() {
        const result = await accountService.role()
        if (result.status === HttpStatusCode.OK) {
            accountStore.listRole = result.body.data
        }
    }

    async getDetailAccount(id: any) {
        accountStore.clearError()
        this.isLoadingDetail = true
        const result = await accountService.detailAccount(id)
        if (result.status === HttpStatusCode.OK) {
            let {id, name, birthday, phone, roles} = result.body;
            let arr: any = []
            roles && roles.length > 0 && roles.map((item: any) => {
                arr.push({label: item.name, value: item.id})
            })
            accountStore.dataRequest = {
                id: id,
                name: name,
                roles: arr,
                birthday: birthday,
                phone: phone
            }
            setTimeout(() => {
                this.isLoadingDetail = false
            }, 150)
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async add() {

        accountStore.clearError()
        let {roles, phone} = accountStore.dataRequest

        let roleIds = roles.map((item: any) => item.value)

        let data = {
            roleIds: roleIds,
            phone: phone,
        }

        if (!phone) {
            accountStore.errors.phone = 'Số điện thoại không được để trống!'
            return
        }

        if (phone && phone.length < 10) {
            toastUtil.warning('Số diện thoại không đúng định dạng!')
            return
        }

        if (!roleIds || roleIds && roleIds.length < 1) {
            accountStore.errors.role = 'Chức vụ không được để trống!'
            return
        }

        accountStore.isLoadingBt = true
        const result = await accountService.create(data)
        accountStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thiết lập tài khoản thành công', 1)
            $('#add_edit_account').trigger('click')
            accountStore.clearForm()
            await accountStore.getAccount()
        } else {
            toastUtil.error(result.body.message ?? 'Thiết lập tài khoản thất bại', 4)
        }
    }

    async update() {
        accountStore.clearError()
        let {id, name, roles, birthday, status} = accountStore.dataRequest

        let roleIds = roles.map((item: any) => item.value)

        let data = {
            birthday: birthday ?? null,
            name: name,
            roleIds: roleIds,
            status: status
        }

        if (!name) {
            accountStore.errors.name = 'Họ và tên không được để trống!'
            return
        }

        if (!roleIds || roleIds && roleIds.length < 1) {
            accountStore.errors.role = 'Chức vụ không được để trống!'
            return
        }

        accountStore.isLoadingBt = true
        const result = await accountService.update(id, data)
        accountStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            if (!accountStore.editStatus) {
                toastUtil.success('Chỉnh sửa tài khoản thành công', 2)
                $('#add_edit_account').trigger('click')
                accountStore.clearForm()
                await accountStore.getAccount()
            } else {
                accountStore.dataRequest.status === "BLOCK" ? toastUtil.success('Khóa tài khoản thành công', 2) : toastUtil.success('Mở khóa tài khoản thành công', 2)
                $('#popup_confirm_lock').trigger('click')
                accountStore.editStatus = false
                await accountStore.getAccount()
            }

        } else {
            toastUtil.error(result.body.message ?? 'Cập nhật tài khoản thất bại', 5)
        }
    }

    async changePassword() {
        let {id, password, confirmPass} = accountStore.dataRequest

        let data = {
            password: password
        }

        if (password || confirmPass) {
            if (!password) {
                accountStore.errors.password = 'Mật khẩu không trùng khớp!'
                return
            }
            if (password.length < 6 || password.length > 24) {
                accountStore.errors.password = 'Mật khẩu tối thiểu từ 6 đến 24 ký tự!'
                return
            }

            if (!confirmPass) {
                accountStore.errors.confirmPass = 'Mật khẩu không được để trống!'
                return
            }

            if (password !== confirmPass) {
                accountStore.errors.confirmPass = 'Mật khẩu không trùng khớp!'
                return
            }
        }

        accountStore.isLoadingBt = true
        const result = await accountService.update(id, data)
        accountStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Đổi mật khẩu thành công', 2)
            $('#close_change_pass').trigger('click')
            accountStore.clearForm()

            await accountStore.getAccount()
        } else {
            toastUtil.error(result.body.message ?? 'Đổi mật khẩu thất bại', 5)
        }
    }

    async delete() {
        accountStore.isLoadingBt = true
        const result = await accountService.delete(accountStore.id)
        accountStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa tài khoản thành công', 3)
            await accountStore.getAccount()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }


}

export const accountStore = new AccountStore()