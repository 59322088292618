import {getRequest, postRequest, deleteRequest, putRequest, IApiResponse} from "../../../../common/helpers/RequestHelper";
import {equipmentStore} from "./EquipmentStore";

class EquipmentService{

    public getEquipments(size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/equipment?page=${equipmentStore.page}&size=${size ?? 10}${equipmentStore.keyword ? `&keyword=${equipmentStore.keyword.trim()}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/equipment/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/equipment`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/equipment/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/equipment/${id}`, {id});
    }


}

export const equipmentService = new EquipmentService()