import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../common/component/form/Input";
import {roleStore} from "../RoleStore";


const AddOrEditRole = () => {

    const changePermission = (id: any, item: any) => {
        item.permissions.forEach((val:any) => {
            if (val.id == id) {
                val.isActive = !val.isActive
                if (val.isActive) {
                    roleStore.dataRequest.permissions && roleStore.dataRequest.permissions.push(val.code)
                } else {
                    roleStore.dataRequest.permissions && roleStore.dataRequest.permissions.map((value: any, i: number) => {
                        if (value == val.code) {
                            roleStore.dataRequest.permissions.splice(i, 1)
                        }
                    })
                }
            }
        })
    }


    return (
        <div className="modal fade modal_full" id="add_update_role" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <button type="button" className="close d-flex align-items-center" id="close_form_role"
                            data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                        <p className="m-0">Đóng</p>
                    </button>
                    <div className="modal-header pt-0">
                        <h5 className="modal-title w-100 text-center">{roleStore.type == "add" ? 'Thêm' : 'Sửa'} vai trò</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="form-group">
                            <p>Tên chức vụ <span className="text-danger">*</span></p>
                            <Input value={roleStore.dataRequest.name}
                                   onChange={(e: any) => roleStore.dataRequest.name = e.currentTarget.value}
                                   placeholder="Nhập tên chức vụ"/>
                        </div>
                        <div className="list_permission mt-4 mb-4">
                            <div className="d-flex justify-content-start align-items-center flex-wrap">
                                {roleStore.listPermission && roleStore.listPermission.map((item, i) => {
                                    return (
                                        <div className="role_ground" key={i}>
                                            <div className="title w-100 d-flex align-items-center justify-content-between">
                                                <h4 className="mb-0">{item.name}</h4>
                                            </div>
                                            <div className="content w-100 d-flex align-items-center flex-wrap">
                                                {item.permissions && item.permissions.map((child: any, ix: number) => {
                                                    let isActive = false
                                                    roleStore.dataRequest.permissions && roleStore.dataRequest.permissions.map((val: any) => {
                                                        if (child.code == val) {
                                                            isActive = true
                                                        }
                                                    })
                                                    child.isActive = isActive
                                                    return (
                                                        <div className="form-check w-50 d-flex align-items-center mb-3 pl-0" key={ix}>
                                                            <input className="form-check-input m-0" type="checkbox" checked={child.isActive} onClick={() => changePermission(child.id, item)} id={child.code}/>
                                                            <label className="form-check-label mb-0" htmlFor={child.code}>{child.name}</label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="send-request mt-2" onClick={() => roleStore.addOrUpdate()}
                                disabled={roleStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default observer(AddOrEditRole);