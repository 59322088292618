import React from 'react';
import {Icon} from "../Icon";
import {Link} from "react-router-dom";

interface IProps {
    text: string,
    disabled?: boolean,
    className?: string,
    dataTarget?: string,
    onClick?: any,
    isLoading?: boolean,
    type?: string,
    link?: any
}


const ButtonAdd = ({
                       text,
                       dataTarget,
                       disabled = false,
                       className = "button_add",
                       onClick,
                       type = "add",
                       isLoading,
                       link
                   }: IProps) => {

    const renderStartIcon = (): React.ReactNode => {
        switch (type) {
            case "import":
                return <Icon name="file_download" type="outlined"/>;
            case "export_surplus":
                return <Icon name="exit_to_app" type="outlined"/>;
            case "export":
                return <Icon name="file_upload" type="outlined"/>;
            case "add":
                return <i className="fal text-white fa-plus"/>;
            default:
                return ""
        }
    };


    if(link){
        return (
            <Link to={link}>
                <button disabled={disabled} className={`btn ${className}`} onClick={() => onClick()}
                        data-toggle="modal" data-target={`#${dataTarget}`}>{renderStartIcon()}<span
                    className="text-white">{text}</span></button>
            </Link>
        );
    }else return (
        <button disabled={disabled} className={`btn ${className}`} onClick={() => onClick()}
                data-toggle="modal" data-target={`#${dataTarget}`}>{renderStartIcon()}<span
            className="text-white">{text}</span></button>
    );

}

export default ButtonAdd;