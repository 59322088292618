import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {getIDParameter, getUrlParameter} from "../../../common/utils/Utils";
import TabHeaderDetailPack from "./TabHeaderDetailPack";
import {ITabDetailPlanPack, packStore} from "../PackStore";
import DetailPackJob from "./detailPackJob/DetailPackJob";
import DetailPackSupplies from "./detailPackSupplies/DetailPackSupplies";
import {preserveStore} from "../../preserve/PreserveStore";
import {Link} from "react-router-dom";
import {manufacturingStore} from "../../manufacturing/ManufacturingStore";

const DetailPack = (): JSX.Element => {
    useEffect(() => {
        packStore.id = Number(getIDParameter(2));
        manufacturingStore.id = Number(getIDParameter(2)); //use manufacturing in modal create request EI
        packStore.tabActiveDetailPack = getUrlParameter('tab') ?? ITabDetailPlanPack.job
        preserveStore.getDetailPreserves(packStore.id)
    }, [])
    return (
        <div className="batch_supplies">
            <div className="d-flex header_back align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <Link
                        to={`/packge`}>Đóng gói</Link>
                    <i className="fal fa-angle-right"/>
                    <span>{preserveStore.dataAddPlanOrderWork?.name}</span>
                </div>
            </div>
            <TabHeaderDetailPack/>
            <div className='table-responsive'>
                {packStore.tabActiveDetailPack === ITabDetailPlanPack.job &&
                    <DetailPackJob/>}
                {packStore.tabActiveDetailPack === ITabDetailPlanPack.supplies &&
                    <DetailPackSupplies/>}
            </div>
        </div>

    )

}

export default observer(DetailPack);
