import React from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {ITabDetailPlanPack, packStore} from "../PackStore";

const TabHeaderDetailPack = (): JSX.Element => {
    return (
        <div className="tab_header d-flex align-items-center px-3">
            <Link
                to={`/packge/${packStore.id}?tab=${ITabDetailPlanPack.job}`}
                className={`tab ${packStore.tabActiveDetailPack === ITabDetailPlanPack.job && 'active'}`}
                onClick={() => {
                    packStore.tabActiveDetailPack = ITabDetailPlanPack.job;
                }}>
                <span>Công Việc</span>
            </Link>
            <Link
                to={`/packge/${packStore.id}?tab=${ITabDetailPlanPack.supplies}`}
                className={`tab ${packStore.tabActiveDetailPack === ITabDetailPlanPack.supplies && 'active'}`}
                onClick={() => {
                    packStore.tabActiveDetailPack = ITabDetailPlanPack.supplies;
                }}>
                <span>Vật Tư</span>
            </Link>
        </div>
    );
}

export default observer(TabHeaderDetailPack);
