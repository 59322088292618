import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {dateUtils} from "../../../../common/utils/DateUtils";
import {preserveStore} from "../../PreserveStore";
import './DetailPlanPreserve.scss'
import {manufacturingStore} from "../../../manufacturing/ManufacturingStore";

const PlanProduction = (): JSX.Element => {
    useEffect(() => {
        if (preserveStore.id > 0)
            preserveStore.getDataAllDepartment().then(() => {
                preserveStore.getDetailPreserves(preserveStore.id)
            })
    }, [preserveStore.id])
    return (
        <div className="modal_full detail_plan_production">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6 pr-5">
                                <div className="info mb-5">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green">THÔNG TIN LÔ SẢN XUẤT</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Mã lô sản xuất <span className="text-danger">*</span> : </label>
                                        <span>{preserveStore.dataAddPlanOrderWork.name ?? ""}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Thời gian hoàn thành <span className="text-danger">*</span> : </label>
                                        <span>{dateUtils.formatDate(Number(preserveStore.dataAddPlanOrderWork.timestartTmp ?? ""))} -
                                            {preserveStore.dataAddPlanOrderWork.updatedAt && (preserveStore.dataAddPlanOrderWork.jobTypeSuccess === "PACK" || preserveStore.dataAddPlanOrderWork.jobTypeSuccess === "PRESERVE")
                                                ? " " + dateUtils.formatDate(Number(preserveStore.dataAddPlanOrderWork.updatedAt))
                                                : "Chưa kết thúc"}
                                        </span>
                                    </div>
                                    <div className="title_top mt-4 mb-4">
                                        <h5 className="color-green">THÔNG TIN SẢN PHẨM</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Tên sản phẩm <span className="text-danger">*</span> : </label>
                                        <span>{preserveStore.dataAddPlanOrderWork.productName ?? ""}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48  ">
                                        <label>Quy trình <span className="text-danger">*</span> : </label>
                                        <span>{preserveStore.dataAddPlanOrderWork.procedureName ?? ""}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Số lượng dự kiến: </label>
                                        <span>{preserveStore.dataAddPlanOrderWork.quantity ?? ""}</span>
                                    </div>
                                    <div className="title_top mt-4 mb-4">
                                        <h5 className="color-green">NGƯỜI PHỤ TRÁCH</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>SẢN XUẤT: </label>
                                        <span>
                                            {preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[0]?.selectPersonInCharge.map((item, index) => {
                                                return <span>
                                                {
                                                    index !== 0 ?
                                                        `, ${item.label}` : ` ${item.label} `}
                                            </span>
                                            })}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48  ">
                                        <label>ĐÓNG GÓI: </label>
                                        <span>{preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[1]?.selectPersonInCharge.map((item, index) => {
                                            return <span>
                                                {
                                                    index !== 0 ?
                                                        `, ${item.label}` : ` ${item.label} `}
                                            </span>
                                        })}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>XUẤT - NHẬP KHO: </label>
                                        <span>{preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[2]?.selectPersonInCharge.map((item, index) => {
                                            return <span>
                                               {
                                                   index !== 0 ?
                                                       `, ${item.label}` : ` ${item.label} `}
                                            </span>
                                        })}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 pl-5">
                                <div className="info">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green">XUẤT KHO</h5>
                                    </div>
                                    {
                                        manufacturingStore.listSupplies && manufacturingStore.listSupplies.map((item, index) => {
                                            return (
                                                <div className="d-flex flex-wrap item_info  h-48">
                                                    <label>{`${index + 1}. ${item.name}`}</label>
                                                    <span>{`${item.estimatedQuantity} ${item.unit}`}</span>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(PlanProduction);
