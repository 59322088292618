import {observable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from "jquery";
import {signUpStore} from "../auth/signUp/SignUpStore";
import {setPlanService} from "./SetPlanService";
import {IFilter} from "../../common/component/filter/FilterComponent";

export enum ITabPlan {
    show_plan = "show_plan",
    production_plan = "production_plan"
}


class SetPlanStore {
    @observable id: any = '';
    @observable type: any = '';
    @observable totalArea: number = 0
    @observable checkAll: boolean = false;
    @observable date: any = new Date();
    @observable searchYear: any = setPlanStore.date.getFullYear();
    @observable searchName: any = '';
    @observable tabActive: any = ITabPlan.production_plan;
    @observable page: number = 0;
    @observable totalPage: number = 0;
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable dataFilter: IFilter[] = []
    @observable listYear: any[] = [
        {
            id: '2022',
            name: '2022',
        },
        {
            id: '2023',
            name: '2023',
        },
        {
            id: '2024',
            name: '2024',
        },
        {
            id: '2025',
            name: '2025',
        },
        {
            id: '2026',
            name: '2026',
        },
        {
            id: '2027',
            name: '2027',
        },
        {
            id: '2028',
            name: '2028',
        },
        {
            id: '2029',
            name: '2029',
        },
        {
            id: '2030',
            name: '2030',
        }
    ]
    @observable public errors: any = {
        name: "",
        procedure: '',
        year: "",
        expectedOutput: '',
        startDate: "",
        endDate: "",
        land: ''
    }


    @observable dataRequest: any = {
        id: "",
        name: "",
        provinceId: '',
        districtId: '',
        procedureId: '',
        year: "",
        lands: [],
        expectedOutput: '',
        startDate: "",
        endDate: "",
        landIds: [],
    }

    @observable listPlanning: any[] = []
    @observable listLand: any[] = []

    clearError() {
        setPlanStore.totalArea = 0
        setPlanStore.errors = {
            name: "",
            procedure: '',
            year: "",
            expectedOutput: '',
            startDate: "",
            endDate: "",
            land: ''
        }
    }

    clearForm() {
        setPlanStore.clearError()
        setPlanStore.type = 'add'
        setPlanStore.dataRequest = {
            id: "",
            name: "",
            procedureId: '',
            year: "",
            provinceId: '',
            districtId: '',
            expectedOutput: '',
            startDate: "",
            endDate: "",
            lands: [],
            landIds: []
        }
    }

    async getPlanning() {
        setPlanStore.isLoading = true
        const result = await setPlanService.getPlanning()
        setPlanStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            setPlanStore.listPlanning = result.body.data
            setPlanStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getLands(id: any) {
        const result = await setPlanService.getLandPlan(id)
        if (result.status === HttpStatusCode.OK) {
            setPlanStore.dataRequest.landIds = []
            result.body && result.body.map((item: any) => {
                setPlanStore.dataRequest.landIds.push(item.id)
            })
        }
    }


    async getDetail(id: any) {
        setPlanStore.clearError()
        setPlanStore.type = "edit"
        const result = await setPlanService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            setPlanStore.dataRequest = result.body
            setPlanStore.totalArea = result.body.totalArea
            if (result.body.procedure) {
                setPlanStore.dataRequest.procedureId = result.body.procedure.id
            }

        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async checkValidate() {

        setPlanStore.isLoadingBt = true

        setTimeout(() => {
            setPlanStore.isLoadingBt = false
        }, 400)

        let {id, name, procedureId, year, startDate, endDate} = setPlanStore.dataRequest

        if (!year) {
            setPlanStore.errors.year = 'Năm không được để trống!'
            return
        } else setPlanStore.errors.year = ''

        if (!procedureId) {
            setPlanStore.errors.procedure = 'Quy trình không được để trống!'
            return
        } else setPlanStore.errors.procedure = ''

        if (!name) {
            setPlanStore.errors.name = 'Tên kế hoạch không được để trống!'
            return
        } else setPlanStore.errors.name = ''

        if (!startDate) {
            setPlanStore.errors.startDate = 'Ngày bắt đầu không được để trống!'
            return
        } else setPlanStore.errors.startDate = ''

        if (!endDate) {
            setPlanStore.errors.endDate = 'Ngày kết thúc không được để trống!'
            return
        } else setPlanStore.errors.endDate = ''


        setPlanStore.isLoadingBt = false

        setPlanStore.checkAll = false
        setPlanStore.listLand = []
        if(setPlanStore.type == "edit"){
            setPlanStore.getLands(id);
        }

        signUpStore.getProvinces()

        $('#close_add_edit_one').trigger('click')
        $('#add_plan_two').trigger('click')

    }

    async add() {
        let {name, procedureId, year, expectedOutput, startDate, endDate, landIds} = setPlanStore.dataRequest


        if (!expectedOutput) {
            setPlanStore.errors.expectedOutput = 'Sản lượng dự kiến không được để trống!'
            return
        } else setPlanStore.errors.expectedOutput = ''


        let data = {
            name: name,
            procedureId: procedureId,
            year: year,
            expectedOutput: expectedOutput,
            startDate: startDate,
            endDate: endDate,
            landIds: landIds ?? []
        }

        setPlanStore.isLoadingBt = true
        const result = await setPlanService.create(data)
        setPlanStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thiết lập kế hoạch thành công', 1)
            $('#close_add_edit').trigger('click')
            setPlanStore.clearForm()
            await setPlanStore.getPlanning()
        } else {
            toastUtil.error('Thiết lập kế hoạch thất bại', 4)
        }

    }

    async update() {
        let {id, name, procedureId, year, expectedOutput, startDate, endDate, landIds} = setPlanStore.dataRequest

        if (!expectedOutput) {
            setPlanStore.errors.expectedOutput = 'Sản lượng dự kiến không được để trống!'
            return
        } else setPlanStore.errors.expectedOutput = ''


        let data = {
            name: name,
            procedureId: procedureId,
            year: year,
            expectedOutput: expectedOutput,
            startDate: startDate,
            endDate: endDate,
            landIds: landIds ?? []
        }

        setPlanStore.isLoadingBt = true
        const result = await setPlanService.update(id, data)
        setPlanStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật kế hoạch thành công', 2)
            $('#close_add_edit').trigger('click')
            setPlanStore.checkAll = false
            setPlanStore.totalArea = 0
            setPlanStore.clearError()
            setPlanStore.type = 'add'
            setPlanStore.dataRequest.landIds = []
            await setPlanStore.getPlanning()
        } else {
            toastUtil.error('Cập nhật kế hoạch thất bại', 5)
        }
    }

    async delete() {
        setPlanStore.isLoadingBt = true
        const result = await setPlanService.delete(setPlanStore.id)
        setPlanStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa kế hoạch thành công', 3)
            await setPlanStore.getPlanning()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }
}

export const setPlanStore = new SetPlanStore()