import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {roleStore} from "./RoleStore";

const RoleDefault = observer(() => {

    const changePermission = (id: any) => {
        roleStore.listPermission && roleStore.listPermission.map((item: any) => {
            if (item.id == id) {
                item.isActive = !item.isActive
                if (item.isActive) {
                    roleStore.dataRequest.value && roleStore.dataRequest.value.push(item.code)
                } else {
                    roleStore.dataRequest.value && roleStore.dataRequest.value.map((value: any, i: number) => {
                        if (value == item.code) {
                            roleStore.dataRequest.value.splice(i, 1)
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        roleStore.getListPermission()
        roleStore.getRoleDefault()
    }, [])


    return (
        <div className="update_role pl-5 pr-5 pt-4">
            <div className="title mb-4">
                <h4>Quyền mặc định</h4>
            </div>
            <div className="pl-4 pr-4">
                <div className="form-group h-auto mb-4">
                    <p>Phân quyền cho chức vụ <span className="text-danger">*</span></p>
                    <div className="d-flex align-items-center flex-wrap">
                        {roleStore.listPermission && roleStore.listPermission.map((item, i) => {
                            let isActive = false
                            roleStore.dataRequest.value && roleStore.dataRequest.value.map((val: any) => {
                                if (item.code == val) {
                                    isActive = true
                                }
                            })
                            item.isActive = isActive
                            return (
                                <div className="form-check w-25 d-flex align-items-center mb-3 pl-4 pr-4" key={i}>
                                    <input className="form-check-input m-0" type="checkbox" checked={item.isActive}
                                           onClick={() => changePermission(item.id)} id={item.code}/>
                                    <label className="form-check-label mb-0" htmlFor={item.code}>{item.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <button className="send-request mt-5" onClick={() => roleStore.updateRoleDefault()}
                        disabled={roleStore.isLoadingBt}>Cập nhật
                </button>
            </div>
        </div>
    )
})

export default RoleDefault;