import {observable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from "jquery";
import {Moment} from "../../common/utils/Moment";
import {trackingService} from "./TrackingService";
import {infoStore} from "../info/InfoStore";


class TrackingStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable isLoadingDetail: boolean = false
    @observable warningError: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable type: any = ''
    @observable keyword: any = '';
    @observable searchYear: any = '';
    @observable pageHelper: any = '';
    @observable listTracking: any[] = []
    @observable dataFilter: any[] = []
    @observable minEndDate: Date = Moment.getToDay();
    @observable errors: any = {
        name: '',
        productBatchNumber: "",
        productionBatch: '',
        dateManufacture: '',
        quantity: '',
        expiry: '',
        pack: '',
        ingredient: '',
        additives: '',
        uses: '',
        userManual: ''
    }
    @observable dataRequest: any = {
        id: '',
        barcode: '',
        trackingImage: '',
        images: [],
        procedureBatchId: '',
        trackingData: {
            productInfo: {
                name: "",
                quantity: '',
                productionBatch: '',
                dateManufacture: "",
                expiry: ""
            },
            suppliesInfo: {
                ingredient: "",
                supplierIngredient: "",
                addressIngredient: "",
                businessRegistrationIngredient: "",
                additives: "",
                supplierAdditives: "",
                businessRegistrationAdditives: "",
                pack: ""
            }
        },
        instruction: {
            userManual: "",
            uses: "",
            warning: "",
            note: ""
        }
    }

    clearError() {
        trackingStore.errors = {
            name: '',
            productBatchNumber: "",
            productionBatch: '',
            dateManufacture: '',
            quantity: '',
            expiry: '',
            pack: '',
            ingredient: '',
            additives: '',
            uses: '',
            userManual: ''
        }
    }

    clearForm() {
        trackingStore.type = 'add'
        trackingStore.dataRequest = {
            id: '',
            barcode: '',
            trackingImage: '',
            images: [],
            procedureBatchId: '',
            trackingData: {
                productInfo: {
                    name: "",
                    quantity: '',
                    productionBatch: '',
                    dateManufacture: "",
                    expiry: ""
                },
                suppliesInfo: {
                    ingredient: "",
                    supplierIngredient: "",
                    addressIngredient: "",
                    businessRegistrationIngredient: "",
                    additives: "",
                    supplierAdditives: "",
                    businessRegistrationAdditives: "",
                    pack: ""
                }
            },
            instruction: {
                userManual: "",
                uses: "",
                warning: "",
                note: ""
            }
        }
    }


    async getTracking() {
        this.clearError()
        trackingStore.isLoading = true
        const result = await trackingService.getTracking()
        trackingStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            trackingStore.listTracking = result.body.data
            trackingStore.totalPage = result.body.metadata.totalPage
        }
    }


    async getDetail(id: any, isInfo?: boolean) {
        this.clearError()
        trackingStore.type = 'edit'
        trackingStore.isLoadingDetail = true
        const result = await trackingService.getDetail(id)
        trackingStore.isLoadingDetail = false
        if (result.status === HttpStatusCode.OK) {
            isInfo && await infoStore.getInfo(result.body.productionFacilityId)

            let {trackingData, procedureBatch, procedureBatchId, trackingImage, images, instruction} = result.body
            trackingStore.dataRequest = {
                id: result.body.id,
                trackingImage: trackingImage,
                images: images ?? [],
                procedureBatchId: procedureBatchId,
                barcode: result.body.barcode,
                trackingData: {
                    productInfo: {
                        name: procedureBatchId ? procedureBatch.product?.name : trackingData.productInfo?.name,
                        quantity: procedureBatchId ? procedureBatch.quantity : trackingData.productInfo?.quantity,
                        productionBatch: procedureBatchId ? procedureBatch.name : trackingData.productInfo?.productionBatch,
                        dateManufacture: procedureBatchId ? procedureBatch.dateTimeStart : trackingData.productInfo?.dateManufacture,
                        expiry: procedureBatchId ? procedureBatch.product?.expireMonth : trackingData.productInfo?.expiry
                    },
                    suppliesInfo: {
                        ingredient: trackingData?.suppliesInfo?.ingredient ?? '',
                        supplierIngredient: trackingData?.suppliesInfo?.supplierIngredient ?? '',
                        addressIngredient: trackingData?.suppliesInfo?.addressIngredient ?? '',
                        businessRegistrationIngredient: trackingData?.suppliesInfo?.businessRegistrationIngredient ?? '',
                        additives: trackingData?.suppliesInfo?.additives ?? '',
                        supplierAdditives: trackingData?.suppliesInfo?.supplierAdditives ?? '',
                        businessRegistrationAdditives: trackingData?.suppliesInfo?.businessRegistrationAdditives ?? '',
                        pack: trackingData?.suppliesInfo?.pack ?? ''
                    }
                },
                instruction: {
                    userManual: instruction?.userManual ?? '',
                    uses: instruction?.uses ?? '',
                    warning: instruction?.warning ?? '',
                    note: instruction?.note ?? ''
                }
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async addOrUpdateAccess() {
        this.clearError()
        let {id, trackingData, instruction, barcode} = trackingStore.dataRequest
        let data = {
            trackingData: trackingData,
            instruction: instruction,
            barcode: barcode
        }
        trackingStore.warningError = false

        if (!trackingData.productInfo.name) {
            trackingStore.errors.name = "Tên sản phẩm không được để trống!"
            trackingStore.warningError = true
        }

        if (!trackingData.productInfo.productionBatch) {
            trackingStore.errors.productionBatch = "Mã số lô không được để trống!"
            trackingStore.warningError = true
        }

        if (!trackingData.productInfo.dateManufacture) {
            trackingStore.errors.dateManufacture = "Ngày sản xuất không được để trống!"
            trackingStore.warningError = true
        }

        if (!trackingData.productInfo.quantity) {
            trackingStore.errors.quantity = "Số lượng không được để trống!"
            trackingStore.warningError = true
        }

        if (!trackingData.productInfo.expiry) {
            trackingStore.errors.expiry = "Hạn sử dụng không được để trống!"
            trackingStore.warningError = true
        }

        if (!trackingData.suppliesInfo.ingredient) {
            trackingStore.errors.ingredient = "Nguyên liệu không được để trống!"
            trackingStore.warningError = true
        }

        if (!trackingData.suppliesInfo.additives) {
            trackingStore.errors.additives = "Phụ gia không được để trống!"
            trackingStore.warningError = true
        }

        if (!trackingData.suppliesInfo.pack) {
            trackingStore.errors.pack = "Khối lượng tịnh không được để trống!"
            trackingStore.warningError = true
        }

        if (!instruction.uses) {
            trackingStore.errors.uses = "Công dụng không được để trống!"
            trackingStore.warningError = true
        }

        if (!instruction.userManual) {
            trackingStore.errors.userManual = "Hướng dẫn sử dụng không được để trống!"
            trackingStore.warningError = true
        }


        if (trackingStore.warningError) {
            return
        }

        trackingStore.isLoadingBt = true
        var result
        if (trackingStore.type === 'add') {
            result = await trackingService.add(data)
        } else result = await trackingService.update(id, data)

        trackingStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            if (trackingStore.type === 'add') {
                toastUtil.success('Thêm truy xuất thành công!', 1)
                trackingStore.dataRequest.id = result.body.id
                await trackingStore.getTracking()
            } else {
                toastUtil.success('Cập nhật truy xuất thành công!', 1)
                trackingStore.listTracking.map((item) => {
                    if (item.id == id) {
                        item.trackingData.productInfo.name = trackingData.productInfo.name
                        item.trackingData.productInfo.productionBatch = trackingData.productInfo.productionBatch
                    }
                })
            }
        } else {
            toastUtil.warning('Cập nhật truy xuất thất bại', 3)
        }
    }


    async uploadImages() {
        let {id, trackingImage, images} = trackingStore.dataRequest
        let data = {
            trackingImage: trackingImage ?? '',
            images: images ?? []
        }

        trackingStore.isLoadingBt = true
        const result = await trackingService.update(id, data)
        trackingStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật hình ảnh thành công!', 1)
            $('#close_upload_images').trigger('click');
        } else {
            toastUtil.error('Cập nhật hình ảnh thất bại', 3)
        }
    }

}

export const trackingStore = new TrackingStore()
