import React from 'react';

interface IProps {
    value: any,
    onChange?: any
    isError?: any,
    isShowError?: boolean
    isErrorMax?: any,
    type?: any,
    disabled?: any,
    placeholder?: string
    pattern?: string
    onKeyDown?: any,
    min?: number
}

const Input = ({disabled, isError, onChange, placeholder, type, min = 0, value, isErrorMax, isShowError = true, onKeyDown}: IProps) => {

    return (
        <div className="input-group w-100">
            <div className="w-100">
                <input min={min} type={type ?? 'text'} value={value} onChange={(e: any) => onChange(e)}
                       onKeyDown={onKeyDown}
                       disabled={disabled}
                       className={`form-control form-control-lg ${(isError && !value) || isErrorMax ? 'border_error' : ''}`}
                       placeholder={placeholder ?? ''}/>
            </div>
            {(isError && !value && isShowError) && <p className="error">{isError}</p>}
        </div>
    );
}

export default Input;