import React, {useEffect} from "react"
import {batchStore} from "./BatchStore";
import Loading from "../../../../common/component/Loading";
import {dateUtils} from "../../../../common/utils/DateUtils";
import NoContent from "../../../../common/component/NoContent";
import Paginate from "../../../../common/component/Paginate";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {suppliesStore} from "../SuppliesStore";
import {number_format} from "../../../../common/utils/Utils";
import FilterComponent from "../../../../common/component/filter/FilterComponent";


const HistoryPage = () => {

    const changePage = async (data: any) => {
        batchStore.page = data.selected;
        await batchStore.getHistory();
    }

    useEffect(() => {
        if (batchStore.suppliesId) {
            batchStore.getHistory();
        }
    }, [batchStore.suppliesId])

    return (
        <div className="history_batch">
            <div className="d-flex header_back align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <Link
                        to={`/warehouse/supplies?type=${batchStore.typeSupplies}`}>Kho {batchStore.switchName()}</Link>
                    <i className="fal fa-angle-right"/>
                    <span>{suppliesStore.dataRequest.name}</span>
                </div>
                <div className="d-flex align-items-center">
                    <p className="mb-0 mr-3"><strong>Tổng
                        còn: {number_format(suppliesStore.dataRequest.quantity - suppliesStore.dataRequest.quantityUsed)}</strong>
                    </p>
                    <p className="mb-0 mr-4"><strong>Đơn vị: {suppliesStore.dataRequest.unit}</strong></p>
                    <div style={{width: 332}}>
                        {batchStore.isLoading ? <FilterComponent data={batchStore.dataFilter}/> :
                            <FilterComponent data={batchStore.dataFilter}/>}
                    </div>
                </div>
            </div>
            {batchStore.isLoading ? <Loading/> : <>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">Thời gian</th>
                        <th>Lô {batchStore.switchName()}</th>
                        <th className="text-center">Người phụ trách</th>
                        <th className="text-center">Số lượng</th>
                        <th className="text-center">Thao tác</th>
                        <th className="text-center">Ghi chú</th>
                    </tr>
                    </thead>
                    <tbody>
                    {batchStore.listHistory && batchStore.listHistory.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{dateUtils.formatDate(item.createdAt, 'DD/MM/YYYY HH:mm')}</td>
                                <td>{item.batchObj?.batchCode}</td>
                                <td className="text-center">{item.createdUser?.name}</td>
                                <td className="text-center"><span
                                    className={item.action == "IMPORT" ? 'text-success' : 'text-danger'}>{item.action == "IMPORT" ? '+' : '-'} {item.quantity}</span>
                                </td>
                                <td className="text-center">{item.action == "IMPORT" ? 'Nhập kho' : 'Xuất kho'}</td>
                                <td className="text-center">{item.note}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {batchStore.listHistory && batchStore.listHistory.length < 1 && <NoContent/>}
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={batchStore.page} totalPage={batchStore.totalPage} changePage={changePage}/>
                </div>
            </>}
        </div>
    )
}

export default observer(HistoryPage)