import {observable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {IListJobByBatch} from "../preserve/PreserveStore";
import {manufacturingService} from "./ManufacturingService";
import $ from "jquery";
import {toastUtil} from "../../common/utils/ToastUtil";
import {EIBatchService} from "../warehouse/expImpBatch/EIBatchService";
import {expImpBatchStore} from "../warehouse/expImpBatch/EIBatchStore";
import {detailBatchStore} from "../warehouse/expImpBatch/detailEIBatch/DetailEIBatchStore";
import {packStore} from "../pack/PackStore";

export enum ITabDetailPlanManufacturing {
    job = "job",
    supplies = "supplies"
}

export interface IDataSupplies {
    name?: string;
    id?: string | number;
    unit?: string;
    estimatedQuantity: number | string;
    quantity: string | number;
    quantityUsed: string | number;
    err: boolean
    errQuantity?: boolean
}

export interface IDataRequestEISuppliesManufacturing {
    type?: string,
    procedureBatchId?: number | string
    suppliesInformation?:
        {
            id: number | string,
            quantityRequest: number,
        }[]

    note?: string
}

export interface IListEIByProcedureBatchId {
    id: number,
    name: string,
    timeRequest: number | string,
    type: string,
    batchType: string,
    procedureBatch: {
        name: string,
        product: {
            name: string,
            id: number
        }
    },
    sender?: {
        name: string
    },
    state: string,
    note: string
}

class ManufacturingStore {
    @observable id: any = ''
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable procedureBatchId: any = ''
    @observable isLoading: boolean = false
    @observable isLoadingChangeStatus: boolean = false
    @observable isLoadingBtn: boolean = false
    @observable checkCompletePlan: boolean = false
    @observable tabActiveDetailManufacturing: string = ITabDetailPlanManufacturing.job
    @observable listJobDetailManufacturing: IListJobByBatch[] = [];
    @observable listSupplies: IDataSupplies[] = []
    @observable listSuppliesDetail: IDataSupplies[] = []
    @observable listEIByProcedureBatchId: IListEIByProcedureBatchId[] = []
    @observable typeEI: string = ''
    @observable typeModal: string = ''


    @observable dataRequestEISupplies: IDataRequestEISuppliesManufacturing = {}

    @observable public errorsImportProductRequest: any = {
        supplies: '',
        note: '',
        errMax: ''
    }

    clearForm() {
        manufacturingStore.errorsImportProductRequest = {
            supplies: '',
            note: '',
            errMax: ''
        }
        manufacturingStore.listSupplies.map((item, index) => {
            item.err = false
            item.errQuantity = false
            item.estimatedQuantity = ""
        })
        manufacturingStore.dataRequestEISupplies.note = ""
    }

    async getDetailManufacturingJob(id: any) {
        manufacturingStore.isLoading = true
        const result = await manufacturingService.getDeatailJobByBatch(id, "PROCESSING")
        manufacturingStore.isLoading = false
        let checkCount = 0
        manufacturingStore.checkCompletePlan = false
        if (result.status === HttpStatusCode.OK) {
            manufacturingStore.listJobDetailManufacturing = result.body.data
            manufacturingStore.totalPage = result.body.metadata.totalPage
            if (manufacturingStore.listJobDetailManufacturing.length > 0) {
                manufacturingStore.listJobDetailManufacturing.forEach((item, index) => {
                    if (item.status === "COMPLETE") checkCount++
                })
            }
            manufacturingStore.checkCompletePlan = checkCount > 0 && (checkCount === manufacturingStore.listJobDetailManufacturing.length);
        }
    }

    async changeStatusJob(id: any, data: any) {
        const result = await manufacturingService.changeStatusJob(id, data)
        manufacturingStore.isLoadingChangeStatus = false
        if (result.status === HttpStatusCode.OK) {
            manufacturingStore.isLoadingChangeStatus = true
            await manufacturingStore.getDetailManufacturingJob(id)
            manufacturingStore.isLoadingChangeStatus = false
        }
    }

    async getWarehouseRequestByProcedureBatchId() {
        manufacturingStore.isLoading = true
        const result = await EIBatchService.getWarehouseRequest(manufacturingStore.id)
        manufacturingStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            manufacturingStore.listEIByProcedureBatchId = result.body.data
            expImpBatchStore.totalPage = result.body.metadata.totalPage
        }
    }

    async changeTypeJob(id: any, type: any) {
        const result = await manufacturingService.changeTypeJob(id, type)
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm_complete_manufacturing').trigger('click')
            toastUtil.success('Chuyển lô sản xuất sang đóng gói thành công', 3)
            await manufacturingStore.getDetailManufacturingJob(id)
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async EISuppliesManufacturing() {
        let dataSupplies = manufacturingStore.typeEI === "IMPORT" ? manufacturingStore.listSupplies.map((item, index) => {
            return {
                id: item.id,
                quantityRequest: item.estimatedQuantity ? item.estimatedQuantity : 0
            }
        }) : manufacturingStore.listSupplies.map((item, index) => {
            return {
                id: item.id,
                quantityRequest: item.estimatedQuantity ? item.estimatedQuantity : 0
            }
        })

        dataSupplies = dataSupplies.filter((x) => x.quantityRequest)

        if (manufacturingStore.typeEI === "IMPORT") {
            let checkExitsSupplies = !manufacturingStore.listSupplies.map((item, index) => item.err).includes(true)
                && !manufacturingStore.listSupplies.map((item, index) => (item.quantity > 0)).includes(true)
            if (checkExitsSupplies) {
                manufacturingStore.errorsImportProductRequest.supplies = 'Không có vật tư xuất dư !'
                manufacturingStore.errorsImportProductRequest.note = ''
                return
            } else manufacturingStore.errorsImportProductRequest.supplies = ''
        }

        if (dataSupplies.map(x => x.quantityRequest).every(x => x === 0)) {
            manufacturingStore.errorsImportProductRequest.supplies = 'Bạn chưa chọn số lượng thành phần nào !'
            manufacturingStore.errorsImportProductRequest.note = ''
            return
        } else manufacturingStore.errorsImportProductRequest.supplies = ''


        manufacturingStore.errorsImportProductRequest.note = manufacturingStore.errorsImportProductRequest.note.trim()
        manufacturingStore.dataRequestEISupplies.note = manufacturingStore.dataRequestEISupplies.note?.trim()
        if (!manufacturingStore.dataRequestEISupplies.note) {
            manufacturingStore.errorsImportProductRequest.note = `Ghi chú không được để trống!`
            manufacturingStore.errorsImportProductRequest.supplies = ''
            return
        } else manufacturingStore.errorsImportProductRequest.note = ''

        const data: IDataRequestEISuppliesManufacturing = {
            type: manufacturingStore.typeModal === "add" ? manufacturingStore.typeEI : detailBatchStore.listDataDetailEIBatch.type,
            note: manufacturingStore.dataRequestEISupplies.note,
            procedureBatchId: manufacturingStore.id.toString(),
            suppliesInformation: dataSupplies as any
        }
        const result = manufacturingStore.typeModal === "add" ? await manufacturingService.importExportSuppliesManufacturing(data) : await manufacturingService.editImportExportSuppliesManufacturing(data, manufacturingStore.procedureBatchId)
        if (result.status === HttpStatusCode.OK) {
            if (manufacturingStore.typeModal === "add") {
                if (manufacturingStore.typeEI === "EXPORT") {
                    $('#modal-import-manufacturing-supplies').trigger('click')
                    toastUtil.success('Tạo yêu cầu xuất kho thành công', 3)
                } else {
                    $('#modal-export-manufacturing-supplies').trigger('click')
                    toastUtil.success('Tạo yêu cầu xuất dư thành công', 3)
                }
            } else {
                if (detailBatchStore.listDataDetailEIBatch.type === "EXPORT") {
                    $('#modal-import-manufacturing-supplies').trigger('click')
                    toastUtil.success('Sửa yêu cầu xuất kho thành công', 3)
                } else {
                    $('#modal-export-manufacturing-supplies').trigger('click')
                    toastUtil.success('Sửa yêu cầu xuất dư thành công', 3)
                }
            }
            await manufacturingStore.getWarehouseRequestByProcedureBatchId()
            await packStore.getWarehouseRequestByProcedureBatchId()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
}

export const manufacturingStore = new ManufacturingStore()