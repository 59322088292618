import React from 'react';
import {observer} from 'mobx-react';
import {profileStore} from "../profile/ProfileStore";
import "./styles/nav.scss"
import {infoStore} from "../info/InfoStore";
import {Link} from "react-router-dom";
import {checkPermission, urlImage} from "../../common/utils/Utils";
import {Icon} from "../../common/component/Icon";
import NoticePage from "../notice/NoticePage";
import {noticeStore} from "../notice/NoticeStore";
import {PermissionConst} from "../../common/constants/Constants";
import StorageService from "../../common/service/StorageService";


const Nav = () => {

    return (
        <div className="header-top">
            <div className="content-header d-flex align-items-center justify-content-between">
                <h4>{infoStore.dataInfo.name}</h4>
                <div className="d-flex align-items-center">
                    {checkPermission(PermissionConst.farmView) && profileStore.roleUser != "admin" &&
                        <Link to="/farm" className="info_farm" onClick={() => profileStore.link = "/farm"}>
                            <div className={"icon"}>
                                <img
                                    src={`/assets/ico/sidebar/unit${"/farm" == profileStore.link ? '_active' : ''}.svg`}
                                    alt=""/>
                            </div>
                            <span>Thông tin cơ sở</span>
                        </Link>}
                    <div
                        className="user_profile d-flex align-items-center justify-content-between position-relative">
                        <div className="d-flex align-items-center">
                            <div className="avatar">
                                <img
                                    src={profileStore.dataFarm.avatar ? urlImage(profileStore.dataFarm.avatar) : '/assets/images/avatar_default.png'}
                                    alt=""/>
                            </div>
                            <div className="info_name">
                                <p className="name">{profileStore.dataFarm.name}</p>
                                <p className="manage">{profileStore.dataFarm.username}</p>
                            </div>
                        </div>
                        <img className="img_ico" src="/assets/ico/ico_ellipsis.svg" alt=""/>
                        <div className="box_user_info">
                            <a href={`${process.env.REACT_APP_DOMAIN_SSO}/profile?redirectTo=${window.location.origin}&username=${profileStore.dataFarm.username}&service=food`}
                               target='_blank' rel="noreferrer">
                                Tài khoản
                            </a>
                            <a href={`${process.env.REACT_APP_DOMAIN_SSO}/login?redirectTo=${window.location.origin}&service=food`}
                               onClick={() => {
                                   localStorage.removeItem('facilityId')
                                   StorageService.removeToken();
                                   StorageService.removeRefreshToken()
                               }}>
                                Đăng xuất
                            </a>
                        </div>
                    </div>
                    <div className="notice dropdown ml-4">
                        <button id="dropdownNotice" type="button" onClick={() => noticeStore.getNotice()}
                                data-toggle="dropdown">
                            <Icon name="notifications_none" type="round"/>
                            {noticeStore.countNotice.unRead > 0 &&
                                <span className="count_unRead">{noticeStore.countNotice.unRead}</span>}
                        </button>
                        <NoticePage/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default observer(Nav);
