import React from 'react';
import {observer} from "mobx-react";
import QRCode from 'qrcode.react';
import {trackingStore} from "../../TrackingStore";
import DatePickerSingle from "../../../../common/component/DatePickerSingle";
import {uploadImgHelper} from "../../../../common/helpers/UploadImgHelper";
import {urlImage} from "../../../../common/utils/Utils";
import {trackingBatchStore} from "../TrackingBatchStore";
import {numberUtil} from "../../../../common/utils/NumberUtil";


const AddOrEditTrackingQR = observer(() => {

    const handleStartDate = async (startDate: Date) => {
        trackingBatchStore.dataRequest.info.sentDate = startDate.getTime()
    }

    const changeImg = (e: any) => {
        trackingBatchStore.dataRequest.logo = e
    }

    let {batchCode, info} = trackingBatchStore.dataRequest

    let {
        quantityShipped,
        quantityLeft,
        distributingUnitCode,
        distributingAgent,
        sentDate,
        shippingPartner,
        shippingPartnerCode,
        productCode
    } = trackingBatchStore.errors

    let imageSettings = {
        src: urlImage(trackingBatchStore.dataRequest.logo),
        height: 100,
        width: 100
    }

    return (
        <div className="modal fade" id="add_edit_tracking_qr" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: 920}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingStore.dataRequest.procedureBatch?.name ?? trackingStore.dataRequest.trackingData?.productInfo?.name} - {trackingBatchStore.type == 'add' ? 'Thêm' : 'Cập nhật'} mã</h5>
                        <button type="button" className="close" id="close_qr_code" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-0">
                        <div className="content_access pb-0">
                            <div className="d-flex">
                                <div className="QR_code position-relative">
                                    <QRCode id="download_qrCode" imageSettings={imageSettings} level="H"
                                            value={trackingBatchStore.pageHelper}
                                            size={188}/>
                                    {/*{trackingBatchStore.dataRequest.logo && <div*/}
                                    {/*    className="logo_farm position-absolute d-flex align-items-center justify-content-center">*/}
                                    {/*    <img width={100} height={100} src={urlImage(trackingBatchStore.dataRequest.logo)}*/}
                                    {/*         alt=""/>*/}
                                    {/*</div>}*/}
                                </div>
                                <div className="form_access">
                                    <div className="list_info_product">
                                        <div className="info">
                                            <div className="title_top">
                                                <span>Thông tin sản phẩm</span>
                                                <i className='fas fa-plus'
                                                   onClick={() => info.productMore.push({key: '', value: ''})}/>
                                            </div>
                                            <div className="list_content">
                                                {trackingBatchStore.dataRequest.id && <div className="item">
                                                    <label className="title">Mã lô QR</label>
                                                    <input type="text" value={batchCode} onChange={() => {
                                                    }} disabled={true}/>
                                                </div>}
                                                <div className="item">
                                                    <label className="title">Mã nhận diện <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info?.productCode}
                                                           className={`${productCode && !info?.productCode ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.productCode = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Ngày gửi hàng <span
                                                        className="text-danger">*</span></label>
                                                    <DatePickerSingle selected={info?.sentDate} isError={sentDate}
                                                                      onChange={handleStartDate}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Đơn vị vận chuyển <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info?.shippingPartner}
                                                           className={`${shippingPartner && !info?.shippingPartner ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.shippingPartner = e.currentTarget.value}/>
                                                </div>

                                                <div className="item">
                                                    <label className="title">Mã đơn vị vận chuyển <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info?.shippingPartnerCode}
                                                           className={`${shippingPartnerCode && !info?.shippingPartnerCode ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.shippingPartnerCode = e.currentTarget.value}/>
                                                </div>
                                                {info?.productMore && info?.productMore.map((item: any, i: number) => {
                                                    return <div className="item" key={i}>
                                                        <input className="title" type="text" value={item.key}
                                                               onChange={(e: any) => item.key = e.currentTarget.value}/>
                                                        <input type="text" value={item.value}
                                                               onChange={(e: any) => item.value = e.currentTarget.value}/>
                                                        <i className="fas fa-times"
                                                           onClick={() => info.productMore.splice(i, 1)}/>
                                                    </div>
                                                })}
                                            </div>
                                            <div className="title_top">
                                                <span>Thông tin chi tiết</span>
                                                <i className='fas fa-plus'
                                                   onClick={() => info.detailMore.push({key: '', value: ''})}/>
                                            </div>
                                            <div className="list_content">
                                                <div className="item">
                                                    <label className="title">Số lượng đã xuất <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info?.quantityShipped}
                                                           className={`${quantityShipped && !info?.quantityShipped ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.quantityShipped = numberUtil.regexNumber(e)}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Số lượng tồn kho <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info?.quantityLeft}
                                                           className={`${quantityLeft && !info?.quantityLeft ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.quantityLeft = numberUtil.regexNumber(e)}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Đại lý phân khối <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info?.distributingAgent}
                                                           className={`${distributingAgent && !info?.distributingAgent ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.distributingAgent = e.currentTarget.value}/>
                                                </div>
                                                <div className="item">
                                                    <label className="title">Mã đơn vị phân phối <span
                                                        className="text-danger">*</span></label>
                                                    <input type="text" value={info?.distributingUnitCode}
                                                           className={`${distributingUnitCode && !info?.distributingUnitCode ? 'border_error' : ''}`}
                                                           onChange={(e: any) => info.distributingUnitCode = e.currentTarget.value}/>
                                                </div>
                                                {info?.detailMore && info?.detailMore.map((item: any, i: number) => {
                                                    return <div className="item" key={i}>
                                                        <input className="title" type="text" value={item.key}
                                                               onChange={(e: any) => item.key = e.currentTarget.value}/>
                                                        <input type="text" value={item.value}
                                                               onChange={(e: any) => item.value = e.currentTarget.value}/>
                                                        <i className="fas fa-times"
                                                           onClick={() => info.detailMore.splice(i, 1)}/>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="save_qr_code justify-content-center">
                                        <button className="mr-3 action_barcode position-relative">
                                            Chèn logo
                                            <input className="up_img"
                                                   accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                                   title='Logo kích thước 48x48'
                                                   onChange={async (e: any) => await uploadImgHelper.onChangeFileThumb(e, changeImg, 48, 48, 'logo')}/>
                                        </button>
                                        <button className="mr-3 action_barcode bt_remove_outline"
                                                disabled={!trackingBatchStore.dataRequest.logo}
                                                onClick={() => trackingBatchStore.dataRequest.logo = ''}>Xóa logo
                                        </button>

                                        <button className="action_barcode mr-2"
                                                disabled={!trackingBatchStore.dataRequest.id}>{trackingBatchStore.dataRequest.id ?
                                            <a href={trackingBatchStore.pageHelper} target="_blank">Xem kết
                                                quả</a> : 'Xem kết quả'}</button>

                                        <button className="action_barcode"
                                                disabled={!trackingBatchStore.dataRequest.id} data-dismiss="modal"
                                                data-toggle="modal" data-target="#qr_code_tracking">Tải mã
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn send-request"
                                                style={{width: 276}} onClick={() => trackingBatchStore.addEditTracking()}
                                                disabled={trackingBatchStore.isLoadingBt || (trackingBatchStore.type == 'add' && trackingBatchStore.dataRequest.id)}>Lưu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

})

export default AddOrEditTrackingQR;