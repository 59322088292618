import React from 'react';
import {observer} from "mobx-react";
import {detailBatchStore} from "../../../../warehouse/expImpBatch/detailEIBatch/DetailEIBatchStore";
import {dateUtils} from "../../../../../common/utils/DateUtils";
import {Icon} from "../../../../../common/component/Icon";
import {number_format, sttPage} from "../../../../../common/utils/Utils";
import Loading from "../../../../../common/component/Loading";
import NoContent from "../../../../../common/component/NoContent";
import './ModalDetailEIRequest.scss'
import {convertDepartment} from "../../../../warehouse/expImpBatch/detailEIBatch/DetailEIBatchPage";
import CancelExportBatch
    from "../../../../warehouse/expImpBatch/detailEIBatch/components/cancelExportBatch/CancelExportBatch";
import {expImpBatchStore} from "../../../../warehouse/expImpBatch/EIBatchStore";
import ModalImportSuppliesManufacturing from "../modalEI/modalImport/ModalImport";
import ModalExportSuppliesManufacturing from "../modalEI/modalExport/ModalExport";
import $ from "jquery";
import {manufacturingStore} from "../../../ManufacturingStore";
import {packStore} from "../../../../pack/PackStore";

const ModalDetailEIRequest = () => {
    const convertSupplies = (value: string) => {
        switch (value.toUpperCase()) {
            case "PACKAGING":
                return "Bao bì"
            case "INGREDIENT":
                return "Kho nguyên liệu"
            case "EQUIPMENT":
                return "Thiết bị"
            default:
                return "Kho phụ gia"
        }
    }
    return (
        <div className="modal fade" id="modal-detail-request-manufacturing-supplies" role="dialog"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <button type="button" className="close d-flex align-items-center"
                                data-dismiss="modal" aria-label="Close" id="close_detail">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="eibatch-record">
                        <div className="eibatch-record-header-title">
                            <div className="d-flex">
                                <h1 className="eibatch-record-header-title-name">Biên
                                    Bản {detailBatchStore.listDataDetailEIBatch.type === "EXPORT" ? "Xuất Kho" : detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" ? "Xuất Dư" : "Nhập Kho Sản Phẩm"} #{detailBatchStore.listDataDetailEIBatch.id}</h1>
                                <span
                                    className={detailBatchStore.listDataDetailEIBatch.status === "APPROVED" ? "status-done" : detailBatchStore.listDataDetailEIBatch.status === "REJECT" ? "status-cancel" : "status-pendding"}>
                            {detailBatchStore.listDataDetailEIBatch.status === "APPROVED" ? "Hoàn thành" : detailBatchStore.listDataDetailEIBatch.status === "REJECT" ? "Từ chối" : "Đang chờ"}
                        </span>
                            </div>
                            {detailBatchStore.listDataDetailEIBatch.status === "APPROVED" && (
                                <div>
                                    <button className="bt-action-print"
                                            title="In biên bản"
                                            onClick={() => {
                                                window.print();
                                            }}
                                    >
                                        <Icon name="print"
                                              type="outlined"
                                              size={26}/>
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="eibatch-record-header-content">
                            <div className="w-50">
                                <p>Người yêu cầu: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch.sender.name}</span>
                                </p>
                                <p>Bộ phận: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch.sender.department ? convertDepartment(detailBatchStore.listDataDetailEIBatch.sender.department) : "---"}</span>
                                </p>
                                <p>Thời gian yêu cầu: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch.timeRequest ? dateUtils.formatDate(Number(detailBatchStore.listDataDetailEIBatch.timeRequest), 'h:mm DD/MM/YYYY') : "---"}</span>
                                </p>
                                <p>Lý do: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch.note ?? "---"}</span>
                                </p>

                            </div>
                            <div className="w-50">
                                <p>Người bàn giao: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch?.receiver?.name ?? "---"}</span>
                                </p>
                                <p>Bộ phận: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch?.receiver?.department ? convertDepartment(detailBatchStore.listDataDetailEIBatch?.receiver?.department) : "---"}</span>
                                </p>
                                <p>Thời gian xuất: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch?.receiver?.createdAt ? dateUtils.formatDate(Number(detailBatchStore.listDataDetailEIBatch.receiver?.createdAt), 'h:mm DD/MM/YYYY') : "---"}</span>
                                </p>
                            </div>
                        </div>

                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th className="text-center">Tên</th>
                                <th className="text-center">Danh mục</th>
                                <th className="text-center">Số lượng yêu cầu</th>
                                <th className="text-center">Số lượng nhập/xuất</th>
                                <th className="text-center">Lô</th>
                            </tr>
                            </thead>
                            {
                                detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" ?
                                    (
                                        <tbody>
                                        {!detailBatchStore.isLoading && detailBatchStore.listDataDetailEIBatch.importRecordData && detailBatchStore.listDataDetailEIBatch.importRecordData.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center">{sttPage(detailBatchStore.page, i)}</td>
                                                    <td>{item.name}</td>
                                                    <td className="text-center">{item.suppliesType ? convertSupplies(item.suppliesType) : '-'}</td>
                                                    <td className="text-center">{item.quantityRequest ? number_format(item.quantityRequest) : '-'}</td>
                                                    <td className="text-center">{number_format(item.batchInformation?.reduce((acc, obj) => acc + Number(obj.quantity), 0)) ?? '-'}</td>
                                                    <td className="text-center">{item.batchInformation?.reduce((acc, obj, index) => acc + `${index !== 0 ? ', ' : ''}` + (obj.name), '') ?? '-'}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    ) :
                                    (
                                        <tbody>
                                        <tr>
                                            <td className="text-center">{sttPage(detailBatchStore.page, 1)}</td>
                                            <td>{detailBatchStore.listDataDetailEIBatch.nameProduct}</td>
                                            <td className="text-center">{detailBatchStore.listDataDetailEIBatch.nameProduct}</td>
                                            <td className="text-center">{number_format(detailBatchStore.listDataDetailEIBatch.quantityProductRequest) ?? '-'}</td>
                                            <td className="text-center">{number_format(detailBatchStore.listDataDetailEIBatch.quantity) ?? '-'}</td>
                                            <td className="text-center">{detailBatchStore.listDataDetailEIBatch.nameProcedure ?? '-'}</td>
                                        </tr>
                                        </tbody>
                                    )
                            }


                        </table>
                        {detailBatchStore.isLoading && <Loading/>}

                        {!detailBatchStore.isLoading && detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" && detailBatchStore.listDataDetailEIBatch.importRecordData === null &&
                            <NoContent height={200}/>}
                        <div className="note-record-eibatch p-3 my-3">
                            <p>Ghi chú : {detailBatchStore.listDataDetailEIBatch.note}</p>
                        </div>
                        {
                            detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" &&
                            detailBatchStore.listDataDetailEIBatch.status !== "APPROVED" && detailBatchStore.listDataDetailEIBatch.status !== "REJECT" &&
                            (
                                <div className="mt-3 bt-action-EI-Batch">
                                    <button
                                        className="bt-action-accept d-flex justify-content-center align-items-center"
                                        data-toggle="modal" title="Chỉnh sửa"
                                        onClick={async () => {
                                            manufacturingStore.typeModal = "edit"
                                            packStore.typeModal = "edit"
                                            $('#modal-detail-request-manufacturing-supplies').trigger('click')
                                            manufacturingStore.clearForm()
                                            await expImpBatchStore.getDetailWarehouseRequest(Number(manufacturingStore.procedureBatchId))
                                        }}
                                        data-target={`${detailBatchStore.listDataDetailEIBatch.type === "EXPORT" ? "#modal-import-manufacturing-supplies" : "#modal-export-manufacturing-supplies"}`}>
                                        <img src="/assets/ico/action/edit-3.svg"
                                             className="icon-cancel-disable mr-2"
                                             alt=""/> Chỉnh Sửa
                                    </button>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            <CancelExportBatch isLoading={expImpBatchStore.isLoadingBt}/>
            <ModalImportSuppliesManufacturing/>
            <ModalExportSuppliesManufacturing/>
        </div>
    );
}

export default observer(ModalDetailEIRequest);