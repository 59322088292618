import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {IDataBodyRequestAddPlan, preserveStore} from "./PreserveStore";

class PreserveService {
    public getPreserves(size: any, productId?: number): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure_batch?page=${preserveStore.page}&size=${size ?? 10}${preserveStore.keyword ? `&keyword=${preserveStore.keyword.trim()}` : ''}${preserveStore.searchStatus ? `&status=${preserveStore.searchStatus}` : ''}${preserveStore.searchProduct ? `&productId=${productId || preserveStore.searchProduct}` : ''}`);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/procedure_batch/${id}`, {id});
    }

    public getDetailPreserves(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure_batch/${id}`);
    }

    public getDetailJobByBatch(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure_batch/${id}/job`);
    }

    public getUserPreserves(department: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/user?department=${department}`);
    }

    public addPlanPreserve(data: IDataBodyRequestAddPlan): Promise<IApiResponse> {
        return postRequest(`/food/v1/procedure_batch`, data);
    }

    public updatePlanPreserve(id: any, data: IDataBodyRequestAddPlan): Promise<IApiResponse> {
        return putRequest(`/food/v1/procedure_batch/${id}`, data);
    }
}

export const preserveService = new PreserveService();