import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import QRCode from "react-qr-code";
import Paginate from "../../../common/component/Paginate";
import NoContent from "../../../common/component/NoContent";
import {trackingStore} from "../TrackingStore";
import Loading from "../../../common/component/Loading";
import {checkPermission, getIDParameter, number_format, slug, sttPage} from "../../../common/utils/Utils";
import {Link} from "react-router-dom";
import {dateUtils} from "../../../common/utils/DateUtils";
import AddOrEditTrackingQR from "./components/AddOrEditTrackingQR";
import QRCodeTracking from "./components/QRCodeTracking";
import HistoryQr from "./components/HistoryQr";
import "./TrackingBatchStyle.scss"
import {trackingBatchStore} from "./TrackingBatchStore";
import ButtonAdd from "../../../common/component/buttonAdd/ButtonAdd";
import {PermissionConst} from "../../../common/constants/Constants";

const TrackingBatch = () => {

    const addQr = () => {
        trackingBatchStore.pageHelper = `${window.location.origin}/xem-ket-qua-truy-xuat/${slug(trackingStore.dataRequest.procedureBatch?.name ?? trackingStore.dataRequest.trackingData?.productInfo?.name)}/${trackingStore.dataRequest.id}`
        trackingBatchStore.clearForm()
    }

    const changePage = async (data: any) => {
        trackingBatchStore.page = data.selected;
        await trackingBatchStore.getTracking();
    }


    useEffect(() => {
        trackingBatchStore.id = getIDParameter()
        trackingStore.getDetail(trackingBatchStore.id)
        trackingBatchStore.getTracking();
    }, [])


    return (
        <div className="tracking-detail">
            <div className="header_back border-0 d-flex align-items-center justify-content-between">
                <div className="header_left">
                    <div className="d-flex align-items-center">
                        <Link to={`/tracking`}>Truy xuất</Link>
                        <i className="fal fa-angle-right"/>
                        <span>{trackingStore.dataRequest.procedureBatch?.name ?? trackingStore.dataRequest.trackingData?.productInfo?.name}</span>
                    </div>
                </div>
                {checkPermission(PermissionConst.qrCodeAdd) && <ButtonAdd text="Thêm mã QR" className="button_add send-request" dataTarget={'add_edit_tracking_qr'} onClick={addQr}/>}
            </div>

            {trackingBatchStore.isLoading ? <Loading/> :
                <div className='table-responsive'>
                    {trackingBatchStore.listTracking && trackingBatchStore.listTracking.length > 0 ?
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center">Mã lô QR</th>
                                <th className="text-center">Ngày gửi</th>
                                <th className="text-center">Lượt tải</th>
                                <th className="text-center">Mã QR</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {trackingBatchStore.listTracking && trackingBatchStore.listTracking.map((item, i) => {
                                let pageHelper = `${window.location.origin}/truy-xuat-lo/${slug(trackingStore.dataRequest.procedureBatch?.name ?? trackingStore.dataRequest.trackingData?.productInfo?.name)}/${trackingStore.dataRequest.id}/${item.id}`

                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(trackingBatchStore.page, i)}</td>
                                        <td className="text-center">{item.batchCode}</td>
                                        <td className="text-center">{dateUtils.formatDate(item.info?.sentDate)}</td>
                                        <td className="text-center">{number_format(item.countQrExported)}</td>
                                        <td className="text-center">
                                            <div title={"Mã QR truy xuất sản phẩm"}>
                                                <QRCode level="L" value={pageHelper} size={32}/>
                                            </div>
                                        </td>
                                        <td width="20%" className="text-center">
                                            <div className="action">
                                                {checkPermission(PermissionConst.qrCodeUpdate) && <button className="bt_action" title="Sửa" data-toggle="modal"
                                                        data-target="#add_edit_tracking_qr"
                                                        onClick={async () => {
                                                            trackingBatchStore.pageHelper = pageHelper;
                                                            await trackingBatchStore.getDetailTracking(item.id)
                                                        }}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.exportQrCode) && <button className="bt_action" title="Tạo link tải mã qr"
                                                        onClick={async () => {
                                                            trackingBatchStore.pageHelper = pageHelper;
                                                            await trackingBatchStore.getDetailTracking(item.id)
                                                        }}
                                                        data-toggle="modal" data-target="#qr_code_tracking">
                                                    <img src="/assets/ico/action/ico_download.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.exportQrCode) && <button className="bt_action" title="Lịch sử xuất"
                                                        onClick={async () => {
                                                            trackingBatchStore.dataRequest = item;
                                                            trackingBatchStore.idBatch = item.id;
                                                            await trackingBatchStore.getHistoryQr(item.id)
                                                        }}
                                                        data-toggle="modal" data-target="#history_qr">
                                                    <img src="/assets/ico/file_clock.svg" alt=""/>
                                                </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={trackingBatchStore.page} totalPage={trackingBatchStore.totalPage}
                                  changePage={changePage}/>
                    </div>
                </div>}
            <AddOrEditTrackingQR/>
            <QRCodeTracking/>
            <HistoryQr/>
        </div>
    )

}

export default observer(TrackingBatch);