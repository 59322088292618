import React, {useState} from 'react';

interface IProps {
    isError?: any,
    value?: any,
    onChange: any,
    onKeyDown?: any,
    placeholder?: any
}

const InputPassword = ({isError,onChange,placeholder,value,onKeyDown}:IProps) => {
    const [isShow, setIsShow] = useState(false)

    return (
        <>
            <div className="input-group input-password position-relative">
                <input type={!isShow ? 'password' : 'text'} value={value} onChange={(e: any) => onChange(e)} onKeyDown={(e: any) => onKeyDown(e)} className={`form-control form-control-lg ${isError ? 'border_error' : ''}`} placeholder={placeholder ?? "Nhập mật khẩu"}/>
                <i className={`far ${isShow ? 'fa-eye-slash' : 'fa-eye' }`} onClick={() => setIsShow(!isShow)}/>
            </div>
            {isError && <p className="error">{isError}</p>}
        </>
    );
}

export default InputPassword;