import {computed, observable} from "mobx";
import {accountService} from "../auth/AccountService";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";

export enum TabActive {
    Account = "account",
    ChangePass = "changePass"
}


class ProfileStore {
    @observable public link: any = ''
    @observable public roleUser: any = ''
    @observable public listRole: any[] = []
    @observable public isLoading: boolean = false;
    @observable public getProfile?: any
    @observable public dataFarm: any = {
        authenticate: false,
        exp: "",
        facilityId: "",
        roleName: '',
        name: '',
        farmOwner: false,
        iat: "",
        roles: "",
        userId: "",
        username: "",
        status: ""
    }
    @observable public path: any = '';
    @observable public isProfile: boolean = false;
    @observable public isLoadingBt: boolean = false;
    @observable public isOpen: boolean = true;
    @observable public activeTab: string = TabActive.Account;
    @observable public dataRequest: any = {
        birthDay: "",
        name: "",
        email: "",
        avatar: "",
        gender: ''
    };
    @observable public userPassword: any = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
    };
    @observable public errors: any = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
    };

    @computed get getUserData() {
        return this.getProfile
    }

    clearForm() {
        profileStore.errors = {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        }
        profileStore.userPassword = {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        };
        profileStore.dataRequest = {
            birthDay: "",
            name: "",
            email: "",
            avatar: "",
            gender: ''
        }
    }

    //
    // async getProfiles() {
    //
    //     this.isLoading = true;
    //     const response = await accountService.getProfile();
    //     this.isLoading = false;
    //     if (response.status === HttpStatusCode.OK) {
    //         this.isProfile = true;
    //         this.getProfile = response.body;
    //         this.dataRequest = response.body;
    //         if (profileStore.path !== "cms") {
    //             await infoStore.getInfo()
    //         }
    //     } else {
    //         toastUtil.error(response.body.message, 2)
    //     }
    // }
    //
    // async checkToken() {
    //     const result = await accountService.checkToken();
    //     if (result.status == HttpStatusCode.OK) {
    //         profileStore.dataFarm = result.body
    //         if(profileStore.dataFarm.facilityId){
    //             localStorage.setItem('facilityId', result.body.facilityId)
    //         }else {
    //             localStorage.removeItem('facilityId')
    //         }
    //
    //     }
    //
    // }

    async updated() {
        let {name, gender, avatar, email, birthDay, address} = this.dataRequest;

        let filter: RegExp = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        const data: any = {
            birthDay: birthDay ?? '',
            name: name,
            address: address,
            email: email,
            avatar: avatar,
            gender: gender
        }

        if (!filter.test(email)) {
            toastUtil.warning('Email không đúng định dạng!')
            return false;
        }

        profileStore.isLoadingBt = true
        const res = await accountService.updateUser(data);
        profileStore.isLoadingBt = false

        if (res.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật thông tin thành công');
            // await this.getProfiles();
        } else {
            toastUtil.error(res.body.message ?? 'Cập nhật thông tin thất bại', 2);
        }
    }

    async changePassword() {
        let {oldPassword, password, confirmPassword} = this.userPassword

        if (!oldPassword) {
            profileStore.errors.oldPassword = "Mật khẩu hiện tại không được để trống!"
            return
        }

        if (oldPassword.length < 6 || oldPassword.length > 50) {
            profileStore.errors.oldPassword = "Mật khẩu phải từ 6 đến 24 ký tự!"
            return
        } else profileStore.errors.oldPassword = ""

        if (!password) {
            profileStore.errors.password = "Mật khẩu không được để trống!"
            return
        }

        if (password.length < 6 || password.length > 50) {
            profileStore.errors.password = "Mật khẩu phải từ 6 đến 24 ký tự!"
            return
        } else profileStore.errors.password = ""

        if (!confirmPassword) {
            profileStore.errors.confirmPassword = "Mật khẩu xác nhận không được để trống!"
            return
        }

        if (confirmPassword == password) {
            profileStore.errors.confirmPassword = ""
        } else {
            profileStore.errors.confirmPassword = "Mật khẩu và mật khẩu xác nhận không trùng khớp!"
            return
        }


        const data: any = {
            oldPassword: oldPassword,
            password: password,
        }

        profileStore.isLoadingBt = true
        const res = await accountService.changePass(data);
        profileStore.isLoadingBt = false

        if (res.status === HttpStatusCode.OK) {
            toastUtil.success('Đổi mật khẩu thành công');
            profileStore.clearForm()
        } else {
            toastUtil.error(res.body.message ?? 'Đổi mật khẩu thất bại', 2);
        }
    }
}

export const profileStore = new ProfileStore();
