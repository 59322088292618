import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {dateUtils} from "../../../../common/utils/DateUtils";
import {preserveStore} from "../../../preserve/PreserveStore";
import '../../Pack.scss'
import ButtonAdd from "../../../../common/component/buttonAdd/ButtonAdd";
import {packStore} from "../../PackStore";
import ModalConfirmCompletePack from "../modalConfirmCompletePack/ModalConfirmCompletePack";
import Loading from "../../../../common/component/Loading";
import NoContent from "../../../../common/component/NoContent";
import ModalConfirmComplete from "../../../manufacturing/components/modalConfirmComplete/ModalConfirmComplete";
import './DetailPackJob.scss'
import {checkPermission} from "../../../../common/utils/Utils";
import {PermissionConst} from "../../../../common/constants/Constants";

const DetailPackJob = (): JSX.Element => {
    useEffect(() => {
        if (packStore.id) packStore.getDetailPackJob(packStore.id)
    }, [packStore.id])
    return (
        <div className="modal-body popup_detail pt-3">
            <div className="w-auto flex-wrap d-flex py-3 justify-content-between align-items-center">
                <div style={{width: '60%'}} className="d-flex flex-wrap">
                    <p>Sản phẩm: <span
                        className="fw-600 mr-5">{preserveStore?.dataAddPlanOrderWork?.productName || "---"}</span>
                    </p>
                    <p>Quy trình: <span
                        className="fw-600 mr-5">{preserveStore?.dataAddPlanOrderWork?.procedureName || "---"}</span>
                    </p>
                    <p>Số lượng: <span
                        className="fw-600 mr-5">{preserveStore?.dataAddPlanOrderWork?.quantity || "---"}</span>
                    </p>
                    <p>Thời gian: <span
                        className="fw-600">{dateUtils.formatDate(preserveStore?.dataAddPlanOrderWork?.timestartTmp as number, 'hh:mm DD/MM/YYYY') || "---"}</span>
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    {checkPermission(PermissionConst.product_to_warehouse) &&
                        <button disabled={!packStore.checkCompletePlan}
                                className="btn button-export-product-procedureid"
                                data-target='#popup_confirm_complete_pack'
                                data-toggle="modal"
                                onClick={() => {
                                    packStore.dataRequestImportProduct.quantity = ''
                                    packStore.dataRequestImportProduct.note = ''
                                    packStore.errorsImportBatchtRequest.quantity = ''
                                    packStore.errorsImportBatchtRequest.note = ''
                                }}
                        >
                            <img src="/assets/ico/action/rotate-3d.svg"
                                 className="icon-action icon-export-product"
                                 alt="" data-toggle="tooltip" data-placement="top"
                                 title="Xuất Dư"/>
                            Nhập kho sản phẩm
                        </button>}
                    {checkPermission(PermissionConst.approvePackaging) &&
                        <ButtonAdd disabled={!packStore.checkCompletePlan} type="" text="Hoàn Thành Đóng Gói"
                                   dataTarget={'popup_confirm_complete_manufacturing'}
                        />}
                </div>
            </div>
            {packStore.isLoading && !packStore.isLoadingChangeStatus
                ? <Loading/> : <>
                    <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">STT</th>
                            <th className="text-center">Công việc</th>
                            <th className="text-center">Giai đoạn</th>
                            <th className="text-center">Tình trạng</th>
                            <th className="text-center">Người phụ trách</th>
                            <th className="text-center">Thời gian hoàn thành</th>
                            {checkPermission(PermissionConst.approvePackaging) &&
                                <th className="text-center">Thao tác</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {packStore.listJobDetailPack && packStore.listJobDetailPack.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{item?.name ?? ""}</td>
                                    <td className="text-center">{preserveStore.changeStateBatch(item?.type) ?? ""}</td>
                                    <td className={`text-center ${item?.status === "PENDING" ? "pending-status" : "completed-status"}`}>{item?.status === "PENDING" ? "Chưa hoàn thành" : item?.status === "CANCEL" ? "Hủy" : "Hoàn thành"}</td>
                                    <td className="text-center">{item?.user?.name && item?.status === "COMPLETE" ? item?.user?.name : "-"}</td>
                                    <td className="text-center">{item?.user ? dateUtils.formatDate(item?.user.updatedAt) : '-'}</td>
                                    {checkPermission(PermissionConst.approvePackaging) &&
                                        <td className="text-center" id="inputPreview">
                                            <input type="checkbox"
                                                   id={item.id.toString()}
                                                   className="change-status-job"
                                                   value={item.status}
                                                   checked={item.status === "COMPLETE"}
                                                   disabled={packStore.isLoading}
                                                   onChange={async (e) => {
                                                       let data = {
                                                           ids: [item.id],
                                                           status: e.target.value === "COMPLETE" ? "PENDING" : "COMPLETE"
                                                       }
                                                       await packStore.changeStatusJob(packStore.id, data)
                                                   }}
                                            />
                                            <label htmlFor={item.id.toString()}></label>
                                        </td>}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    {!packStore.isLoading && packStore.listJobDetailPack && packStore.listJobDetailPack.length < 1 &&
                        <NoContent/>}
                </>}
            <ModalConfirmCompletePack/>
            <ModalConfirmComplete type="PRESERVE" text="Đóng Gói"
                                  textContent={`Lô sản xuất sẽ được chuyển qua kho chứa để bảo quản ở điều kiện thích hợp.`}/>
        </div>
    )

}

export default observer(DetailPackJob);

