import {observable} from "mobx";
import {suppliesService} from "./SuppliesService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";


export enum TypeSupplies {
    additives = "additives",
    packaging = "packaging",
    ingredient = "ingredient",
    equipment = "equipment",
}


class SuppliesStore {
    @observable tabActive: any = ''
    @observable id: any = '';
    @observable type: any = '';
    @observable keyword: any = '';
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: '',
        name: "",
        unit: "",
        quantityUsed: ''
    }
    @observable listSupplies: any[] = []
    @observable public errors: any = {
        name: '',
        unit: "",
    }

    clearError() {
        suppliesStore.errors = {
            name: '',
            unit: "",
        }
    }

    clearForm() {
        suppliesStore.clearError()
        suppliesStore.type = 'add'
        suppliesStore.dataRequest = {
            id: '',
            name: "",
            unit: ""
        }
    }

    switchName() {
        switch (suppliesStore.tabActive) {
            case TypeSupplies.ingredient :
                return "nguyên liệu"
            case TypeSupplies.packaging :
                return "bao bì"
            case TypeSupplies.additives :
                return "phụ gia"
            case TypeSupplies.equipment :
                return "máy móc"
        }
    }

    async getSupplies(size?: any) {
        suppliesStore.isLoading = true
        const result = await suppliesService.getSupplies(size)
        suppliesStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            suppliesStore.listSupplies = result.body.data
            suppliesStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        suppliesStore.type = "edit"
        suppliesStore.clearError()
        const result = await suppliesService.detailSupplies(id)
        if (result.status === HttpStatusCode.OK) {
            suppliesStore.dataRequest = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async add() {
        let {name, unit} = suppliesStore.dataRequest

        let data = {
            name: name,
            unit: unit,
            type: suppliesStore.tabActive.toUpperCase()
        }

        if (!name) {
            suppliesStore.errors.name = `Tên ${suppliesStore.switchName()} không được để trống!`
            return
        } else suppliesStore.errors.name = ''

        if (!unit) {
            suppliesStore.errors.unit = "Đơn vị không được để trống!"
            return
        } else suppliesStore.errors.unit = ''

        suppliesStore.isLoadingBt = true
        const result = await suppliesService.create(data)
        suppliesStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Thêm ${suppliesStore.switchName()} thành công`, 1)
            $('#close_add_edit').trigger('click')
            suppliesStore.clearForm()
            await suppliesStore.getSupplies()
        } else {
            toastUtil.error(`Thêm ${suppliesStore.switchName()} thất bại`, 4)
        }

    }

    async update() {
        let {id, name, unit} = suppliesStore.dataRequest

        let data = {
            name: name,
            unit: unit,
            type: suppliesStore.tabActive.toUpperCase()
        }

        if (!name) {
            suppliesStore.errors.name = `Tên ${suppliesStore.switchName()} không được để trống!`
            return
        } else suppliesStore.errors.name = ''

        if (!unit) {
            suppliesStore.errors.unit = "Đơn vị không được để trống!"
            return
        } else suppliesStore.errors.unit = ''

        suppliesStore.isLoadingBt = true
        const result = await suppliesService.update(id, data)
        suppliesStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Cập nhật ${suppliesStore.switchName()} thành công`, 2)
            $('#close_add_edit').trigger('click')
            suppliesStore.clearForm()
            await suppliesStore.getSupplies()
        } else {
            toastUtil.error(`Cập nhật ${suppliesStore.switchName()} thất bại`, 5)
        }
    }

    async delete() {
        suppliesStore.isLoadingBt = true
        const result = await suppliesService.delete(suppliesStore.id)
        suppliesStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Xóa ${suppliesStore.switchName()} thành công`, 3)
            $('#close_delete').trigger('click')
            await suppliesStore.getSupplies()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }

}

export const suppliesStore = new SuppliesStore()