import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import {checkPermission, sttPage} from "../../../common/utils/Utils";
import ButtonAdd from "../../../common/component/buttonAdd/ButtonAdd";
import Loading from "../../../common/component/Loading";
import Paginate from "../../../common/component/Paginate";
import NoContent from "../../../common/component/NoContent";
import {roleStore} from "./RoleStore";
import './RoleStyle.scss'
import AddOrEditRole from "./components/AddOrEditRole";
import PopupConfirm from "../../../common/component/PopupConfirm";
import {PermissionConst} from "../../../common/constants/Constants";

const RolePage = () => {

    const changePage = async (data: any) => {
        roleStore.page = data.selected;
        await roleStore.getRoles();
    }

    useEffect(() => {
        roleStore.getRoles().then()
        roleStore.getPermission().then()
    }, [])


    return (
        <div className='role'>
            <FilterComponent label={"Phân quyền"} permissionCode={checkPermission(PermissionConst.owner)} data={roleStore.dataFilter} buttonAdd={<ButtonAdd text="Thêm vai trò" dataTarget={'add_update_role'} onClick={roleStore.clearForm}/>}/>
            {roleStore.isLoading ? <Loading/> : <>
                {roleStore.listRole && roleStore.listRole.length > 0 ?
                    <div className='table-responsive'>
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th className="text-center">Tên chức vụ</th>
                                {/*<th className="text-center">Danh sách quyền</th>*/}
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>

                            {roleStore.listRole.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(roleStore.page, i)}</td>
                                        <td className="text-center">{item.name}</td>
                                        {/*<td className="text-center"><Icon name="checklist" type="outlined"/></td>*/}
                                        <td className="text-center">
                                            <div className="action">
                                                <button className="bt_action" title={'Sửa'} data-toggle="modal"
                                                        data-target={"#add_update_role"}
                                                        onClick={async () => roleStore.getDetailRole(item.id)}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>
                                                <button className="bt_action" title="Xóa"
                                                        onClick={() => roleStore.id = item.id} data-toggle="modal"
                                                        data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Paginate currentPage={roleStore.page} totalPage={roleStore.totalPage}
                                      changePage={changePage}/>
                        </div>
                    </div> : <NoContent/>}
            </>}

            <AddOrEditRole/>
            <PopupConfirm isLoading={roleStore.isLoadingBt} action={roleStore.delete}/>
        </div>
    );

}

export default observer(RolePage);