import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {urlImage} from "../utils/Utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
    url: string;
}

const PDFViewer: React.FC<Props> = ({ url }) => {
    const [numPages, setNumPages] = useState<number>(0);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }

    const isImage = (filename: string): boolean => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

        const extension: any = filename.split('.').pop()?.toLowerCase();

        return imageExtensions.includes(extension);
    }

    return (
        <div className="modal fade" id="pdf_viewer" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: isImage(url) ? 850 :1000}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" id="close_pdf" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {url && <div className="d-flex scroll_file justify-content-center align-items-center">
                        {isImage(url) ? <img src={urlImage(url)} alt=""/> :
                        <Document file={process.env.REACT_APP_API_DOMAIN + '/v1/storage/' + url} renderMode={'canvas'} noData="Chưa có dữ liệu" onLoadSuccess={onDocumentLoadSuccess}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>}
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default PDFViewer;

