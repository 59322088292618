import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../common/component/form/Input";
import {suppliesStore} from "../SuppliesStore";

const AddOrEditSupplies = () => {

    return (
        <div className="modal fade" id="add_or_edit_supplies" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{suppliesStore.type === 'add' ? 'Thêm' : 'Cập nhật'} {suppliesStore.switchName()}</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Tên {suppliesStore.switchName() === "bao bì" ? 'quy cách' : suppliesStore.switchName()}
                                <span className="text-danger">*</span></label>
                            <Input isError={suppliesStore.errors.name}
                                   value={suppliesStore.dataRequest.name}
                                   onChange={(e: any) => suppliesStore.dataRequest.name = e.currentTarget.value}
                                   placeholder={`Nhập tên ${suppliesStore.switchName() === "bao bì" ? 'quy cách' : suppliesStore.switchName()}`}/>
                        </div>
                        <div className="form-group">
                            <label>Đơn vị <span className="text-danger">*</span></label>
                            <Input isError={suppliesStore.errors.unit}
                                   value={suppliesStore.dataRequest.unit}
                                   onChange={(e: any) => suppliesStore.dataRequest.unit = e.currentTarget.value}
                                   placeholder="Nhập đơn vị"/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => suppliesStore.type === "add" ? suppliesStore.add() : suppliesStore.update()}
                                disabled={suppliesStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditSupplies);