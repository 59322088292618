import React from 'react';
import {Icon} from "./Icon";

interface IProps {
    text: string,
    className?: string,
    onClick?: any,
    disabled?: boolean
    width?: any,
    height?: any,
    type?: "ico" | "img",
    dataTarget?: string,
    content_ico?: any
}


const Button = ({className, text,  onClick, type, disabled, dataTarget, width, height, content_ico}:IProps) =>{

    return (
        <button className={`btn bt_global d-flex align-items-center justify-content-center ${className}`} style={{width: width, height: height}} onClick={() => onClick()} disabled={disabled} data-toggle="modal" data-target={`#${dataTarget}`}>
            {type === "ico" ? <Icon name={content_ico} className="mr-1" type={"outlined"} /> : <img className="mr-1" src={`/assets/ico/action/${content_ico}`} alt=""/>}
            {text}
        </button>
    );

}

export default Button;