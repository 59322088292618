import React from 'react';
import ReactPaginate from "react-paginate";
import {observer} from "mobx-react";

interface IProps {
    currentPage: number,
    totalPage: number,
    changePage: any
}

// @ts-ignore
const Paginate = observer(({currentPage,totalPage,changePage}:IProps) => {

    if(totalPage > 0){
        return (
            <ReactPaginate
                previousLabel={<i className={`fal fa-arrow-left ${currentPage == 0 ? 'opacity-30' : ''}`}/>}
                nextLabel={<i className={`fal fa-arrow-right ${currentPage + 1 == totalPage ? 'opacity-30' : ''}`}/>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPage}
                forcePage={currentPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={changePage}
                containerClassName={'pagination'}
                pageClassName={'paginate_button page-item'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
                previousClassName={'paginate_button page-item previous'}
                previousLinkClassName={'page-link'}
                nextClassName={'paginate_button page-item next'}
                nextLinkClassName={'page-link'}
            />
        );
    }else return true
})

export default Paginate;