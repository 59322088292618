import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {accountStore} from "./AccountStore";

class AccountService {

    public getAccount(size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/user?page=${accountStore.page}&size=${size ?? 10}${accountStore.keyword ? `&keyword=${accountStore.keyword.trim()}` : ''}`);
    }

    public detailAccount(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/user/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/user`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/user/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/user/${id}`, {id});
    }

    public role(): Promise<IApiResponse> {
        return getRequest(`/food/v1/role`);
    }


}

export const accountService = new AccountService()