import React, {Component} from 'react';
import {observer} from "mobx-react";
import {IStatus, userStore} from "./UserStore";
import Loading from "../../../common/component/Loading";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import PopupConfirmCms from "./components/PopupConfirmCms";


@observer
class UserActiveComponent extends Component {

    async componentWillMount() {
        userStore.searchStatus = IStatus.ACTIVE
        await userStore.getUsers()
    }


    async changePage(data: any) {
        userStore.page = data.selected;
        await userStore.getUsers();
    }


    render() {
        if (userStore.isLoading) {
            return <Loading/>
        } else return (
            <div className='table-responsive'>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th className="text-center">Họ và tên</th>
                        <th className="text-center">ID đăng nhập</th>
                        <th className="text-center">Tên đơn vị</th>
                        <th className="text-center">Loại hình</th>
                        <th className="text-center">Địa chỉ</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>

                    {userStore.listUser && userStore.listUser.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{item.representativeName}</td>
                                <td className="text-center">{item.phone}</td>
                                <td className="text-center">{item.name}</td>
                                <td className="text-center">{item.businessDescription}</td>
                                <td>{item.address}</td>
                                <td className="text-center">
                                    <button className="bt-outline-action mr-2" data-toggle="modal"
                                            data-target="#popup_confirm"
                                            onClick={() => {userStore.userId = item.id; userStore.status = IStatus.RESET}}>Reset
                                    </button>

                                    <button className="bt-action-delete" data-toggle="modal"
                                            data-target="#popup_confirm"
                                            onClick={() => {userStore.userId = item.id; userStore.status = IStatus.BLOCK}}>Khóa
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                {userStore.listUser && userStore.listUser.length < 1 && <NoContent/>}

                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={userStore.page} totalPage={userStore.totalPage}
                              changePage={this.changePage}/>
                </div>

                <PopupConfirmCms/>
            </div>
        );
    }
}

export default UserActiveComponent;