import React from 'react';
import {observer} from "mobx-react";
import "./LoginStyle.scss";
import {Navigate} from "react-router-dom";
import {profileStore} from "../../profile/ProfileStore";
import StorageService from "../../../common/service/StorageService";
import {Icon} from "../../../common/component/Icon";
import Footer from "./Footer";


const LoginPage = () => {

    if (profileStore.isProfile && StorageService.getToken()) {
        if (profileStore.path === "cms") {
            return <Navigate to={'/cms/users'} replace/>
        } else {
            return <Navigate to={'/'} replace/>
        }
    } else {
        return (
            <div className="login_main">
                <div className="box_1">
                    <div className="container">
                        <div className="row flex-grow">
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <div className="w-100">
                                    <h2>OMCheck</h2>
                                    <h4>Nền tảng quản lý thực phẩm!</h4>
                                    <p>OMCheck dành cho các tổ chức sản xuất, chế biến thực phẩm giúp: cung cấp thông
                                        tin về quá trình hình thành thực phẩm. Ghi chép theo thời gian thực, thể hiện
                                        chi tiết từng khâu trong quy trình sản xuất, chế biến cho một sản phẩm từ lúc
                                        nhập nguyên liệu, sơ chế-chế biến, đóng bao bì, xuất hàng, thu hồi (nếu có).</p>
                                    <ul>
                                        <li>Tiêu chuẩn Truy xuất nguồn gốc (TCVN 12850:2019).</li>
                                        <li>Thông tư 25/2019/TT-BYT của Bộ Y Tế.</li>
                                    </ul>
                                    <div className="d-flex mt-4 align-items-center">
                                        <a className="bt-register mr-3"
                                           href={`${process.env.REACT_APP_DOMAIN_SSO}/register?redirectTo=${window.location.origin}&service=food`}>
                                            <button type="button"
                                                    className="btn bt-login d-flex align-items-center justify-content-center">
                                                Đăng ký
                                            </button>
                                        </a>
                                        <a className="bt-login mr-4"
                                           href={`${process.env.REACT_APP_DOMAIN_SSO}/login?redirectTo=${window.location.origin}&service=food`}>
                                            <button type="button"
                                                    className="btn bt-login d-flex align-items-center justify-content-center">
                                                Đăng nhập ngay <Icon name="arrow_right_alt" type="outlined"
                                                                     className="text-white ml-2"/>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center d-none d-lg-block col-md-6 pl-5">
                                <img src="/assets/images/login/banner_1_omcheck.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box_2" style={{backgroundImage: `url("/assets/images/login/background.svg")`}}>
                    <div className="container h-100">
                        <div className="row flex-grow h-100">
                            <div
                                className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                                <img src="/assets/images/login/banner_2_omcheck.svg" alt=""/>
                            </div>
                            <div className="col-12 col-md-6 pl-5 d-flex align-items-center">
                                <div className="pl-5">
                                    <h2>Chức năng của <br/> OMCheck</h2>
                                    <ul>
                                        <li>Quản lý quy trình sản xuất sản phẩm.</li>
                                        <li>Quản lý lô nguyên liệu, phụ gia</li>
                                        <li>Lập lô sản xuất và theo dõi</li>
                                        <li>Nhật ký điện tử từng công đoạn</li>
                                        <li>Mã truy xuất nguồn gốc (QR)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box_3">
                    <div className="container h-100">
                        <div className="row flex-grow h-100">
                            <div className="col-12 col-md-6 pr-5 d-flex align-items-center justify-content-end">
                                <div className="pr-4">
                                    <h2>Giá trị phần mềm<br/> OmFood mang lại</h2>
                                    <ul>
                                        <li>Theo dõi, quản lý chặt chẽ quá trình sản xuất từng sản phẩm</li>
                                        <li>Theo dõi nguồn gốc thực phẩm, tránh giả mạo</li>
                                        <li>Quản lý kho nguyên liệu, phụ gia, thành phẩm...</li>
                                        <li>Đánh giá và phân tích các rủi ro tiềm ẩn về an toàn thực phẩm <br/>và gợi ý
                                            giải pháp
                                        </li>
                                        <li>Tiết kiệm thời gian và tối ưu hóa quy trình sản xuất</li>
                                        <li>Đáp ứng các yêu cầu của pháp luật về an toàn thực phẩm, <br/>tránh được các
                                            vi phạm và sẵn sàng cho các kiểm tra của cơ<br/> quan chức năng
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-lg-start pl-5">
                                <img src="/assets/images/login/banner_3_omcheck.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box_5">
                    <img className="w-100" style={{height: 776}} src="/assets/images/login/banner_4.webp" alt=""/>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default observer(LoginPage);