import {observable} from "mobx";
import {roleService} from "./RoleService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {unique_arr} from "../../../common/utils/Utils";
import $ from 'jquery'
import {accountStore} from "../account/AccountStore";

class RoleStore {
    @observable page: number = 0;
    @observable totalPage: number = 0
    @observable type: any = ''
    @observable id: any = ''
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        name: "",
        permissions: []
    }
    @observable listRole: any[] = []
    @observable listPermission: any[] = []
    @observable dataFilter: any[] = []

    clearForm() {
        roleStore.type = 'add'
        roleStore.dataRequest = {
            name: "",
            permissions: []
        }
    }

    async getRoles(size?: any) {
        roleStore.isLoading = true
        const result = await roleService.getRoles(size)
        roleStore.isLoading = false
        if(result.status == HttpStatusCode.OK){
            size && result.body.data && result.body.data.map((item: any) => {
                accountStore.listRole.push({label: item.name, value: item.id})
            })
            roleStore.listRole = result.body.data;
            roleStore.totalPage = result.body.metadata.totalPage
        }else {
            toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!', 1)
        }
    }

    async getDetailRole(id: any) {
        roleStore.type = 'edit'
        const result = await roleService.getDetailRole(id)
        if(result.status === HttpStatusCode.OK){
            roleStore.dataRequest = result.body
            // roleStore.listPermission && roleStore.listPermission.map((item: any) => {
            //     item.permissions && item.permissions.map((val: any) => {
            //         val.isActive = false
            //     })
            // })
        }
    }

    async getPermission() {
        const result = await roleService.getPermission()
        if (result.status === HttpStatusCode.OK) {
            roleStore.listPermission = result.body
        }
    }

    async addOrUpdate(){

        let {id, name, permissions} = roleStore.dataRequest;

        let data = {
            name: name,
            permissionCodes: permissions ? unique_arr(permissions) : []
        }

        if(!name){
            toastUtil.warning('Tên vai trò không được để trống!')
            return
        }

        if(permissions && permissions.length < 0 || !permissions){
            toastUtil.warning('Quyền cho chức vụ không được để trống!')
            return
        }


        roleStore.isLoadingBt = true
        let result: any
        if(roleStore.type === "add"){
            result = await roleService.add(data)
        }else {
            result = await roleService.update(id, data)
        }
        roleStore.isLoadingBt = false

        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`${roleStore.type == "add" ? 'Thêm' : 'Cập nhật'} vai trò thành công!`, 1)
            $('#close_form_role').trigger('click')
            await roleStore.getRoles()
        }else {
            toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!', 1)
        }
    }

    async delete(){
        roleStore.isLoadingBt = true
        const result = await roleService.delete(roleStore.id)
        roleStore.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success('Xóa vai trò thành công!', 1)
            $('#popup_confirm').trigger('click')
            roleStore.listRole.map((item, i) => {
                if(item.id === roleStore.id){
                    roleStore.listRole.splice(i, 1)
                }
            })
        }else {
            toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!', 1)
        }
    }

}

export const roleStore = new RoleStore()