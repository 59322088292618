import React from 'react';
import './ButtonGlobal.scss'

interface IProps {
    text?: string,
    className?: string,
    dataTarget?: string,
    onClick?: any,
    ico?: string,
    disabled?: boolean
}


const ButtonGlobal = ({text, dataTarget, className = "button_global", onClick, ico, disabled}: IProps) => {

    return (
        <button className={`btn ${className} button_global`} onClick={() => onClick()} disabled={disabled} data-toggle="modal" data-target={`#${dataTarget}`}>
            {ico && <img src={`/assets/ico/action/${ico}.svg`} alt="" className="icon-action"/>}
            <span className="title-btn">{text}</span>
        </button>
    );
}

export default ButtonGlobal;