import {getRequest, IApiResponse} from "../../../common/helpers/RequestHelper";
import {expImpBatchStore} from "./EIBatchStore";

class ProductService {
    public getWarehouseRequest(procedureBatchId?: number, size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/warehouse_request?page=${expImpBatchStore.page}&size=${size ?? 10}${procedureBatchId ? `&procedureBatchId=${procedureBatchId}` : ''}${expImpBatchStore.keyword ? `&keyword=${expImpBatchStore.keyword.trim()}` : ''}${expImpBatchStore.searchStatus ? `&state=${expImpBatchStore.searchStatus}` : ''}${expImpBatchStore.searchId ? `&id=${expImpBatchStore.searchId.trim()}` : ''}${expImpBatchStore.searchEI ? `&type=${expImpBatchStore.searchEI}` : ''}${expImpBatchStore.searchYear ? `&year=${expImpBatchStore.searchYear}` : ''}`);
    }

    public getDetailWarehouseRequest(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/warehouse_request/${id}`);
    }

    public getListBatchSupliesId(ids: string): Promise<IApiResponse> {
        return getRequest(`/food/v1/batch?suppliesIds=${ids}`);
    }
}

export const EIBatchService = new ProductService();