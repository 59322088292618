import React from 'react';
import {observer} from "mobx-react";
import {batchStore} from "../BatchStore";
import {suppliesStore, TypeSupplies} from "../../SuppliesStore";
import {number_format} from "../../../../../common/utils/Utils";
import {dateUtils} from "../../../../../common/utils/DateUtils";
import {Icon} from "../../../../../common/component/Icon";


const PopupDetail = () => {


    return (
        <div className="modal fade modal_full" id="detail_batch" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <button type="button" className="close d-flex align-items-center" id="close_detail"
                            data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                        <p className="m-0">Đóng</p>
                    </button>
                    <div className="modal-header pt-0">
                        <h5 className="modal-title w-100 text-center">Thông tin lô {batchStore.switchName()}</h5>
                    </div>

                    <div className="modal-body popup_detail pt-3">
                        <div className="d-flex align-items-center mb-3">
                            <div className="d-flex align-items-center mr-5"><strong>Số lượng nhập: <span
                                className="color-green d-inline-block pl-1">{number_format(suppliesStore.dataRequest.quantity)}</span></strong>
                            </div>
                            <div className="d-flex align-items-center"><strong>Số lượng đã xuất: <span
                                className="text-danger d-inline-block pl-1">{number_format(suppliesStore.dataRequest.quantityUsed)}</span></strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 pr-5">
                                <div className="info mb-5">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green">Thông tin lô nhập</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Mã lô: </label>
                                        <span>{batchStore.dataRequest.batchCode}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Thời gian nhập : </label>
                                        <span>{dateUtils.formatDate(batchStore.dataRequest.inputTime)}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info h-48">
                                        <label>Hạn sử dụng: </label>
                                        <span>{dateUtils.formatDate(batchStore.dataRequest.expirationDate)}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info h-48">
                                        <label>Tên {batchStore.switchName()}: </label>
                                        <span>{suppliesStore.dataRequest.name}</span>
                                    </div>
                                    {batchStore.typeSupplies === TypeSupplies.packaging &&
                                        <div className="d-flex flex-wrap item_info h-48">
                                            <label>Khối lượng / {suppliesStore.dataRequest.unit}: </label>
                                            <span>{batchStore.dataRequest.containedVolume}</span>
                                        </div>}

                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Số lượng (Tồn): </label>
                                        <span>{number_format(batchStore.dataRequest.quantity)} {suppliesStore.dataRequest.unit}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Giá nhập: </label>
                                        <span>{number_format(batchStore.dataRequest.price)}</span>
                                    </div>

                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Mã số lô nhà cung ứng: </label>
                                        <span>{batchStore.dataRequest.supplierBatchCode}</span>
                                    </div>

                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Mã số lô nhà sản xuất: </label>
                                        <span>{batchStore.dataRequest.productionBatchCode}</span>
                                    </div>
                                    <div className="title_top mt-4 mb-4">
                                        <h5 className="color-green">Đánh giá chất lượng</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Màu sắc: </label>
                                        <span>{batchStore.dataRequest.qualityEvaluation.color}</span>
                                    </div>
                                    {batchStore.typeSupplies !== TypeSupplies.packaging &&
                                        <div className="d-flex flex-wrap item_info  h-48  ">
                                            <label>Mùi vị: </label>
                                            <span>{batchStore.dataRequest.qualityEvaluation.taste}</span>
                                        </div>}
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Tình trạng: </label>
                                        <span>{batchStore.dataRequest.qualityEvaluation.status}</span>
                                    </div>
                                    {batchStore.typeSupplies !== TypeSupplies.packaging &&
                                        <div className="d-flex flex-wrap item_info  h-48">
                                            <label>Tỉ lệ tạp chất: </label>
                                            <span>{batchStore.dataRequest.qualityEvaluation.impurities}</span>
                                        </div>}
                                </div>
                            </div>
                            <div className="col-6 pl-5">
                                <div className="info">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green">Thông tin đơn vị cung cấp</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Tên công ty/cơ sở: </label>
                                        <span>{batchStore.dataRequest.supplierInformation.companyName}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Địa chỉ: </label>
                                        <span>{batchStore.dataRequest.supplierInformation.address}</span>
                                    </div>
                                    {batchStore.typeSupplies !== TypeSupplies.packaging &&
                                        <div className="d-flex flex-wrap item_info  h-48 ">
                                            <label>Mã vùng trồng: </label>
                                            <span>{batchStore.dataRequest.supplierInformation.plantingAreaCode}</span>
                                        </div>}
                                    <div className="d-flex flex-wrap item_info  h-48  ">
                                        <label>Mã số cơ sở đóng gói: </label>
                                        <span>{batchStore.dataRequest.supplierInformation.packingBaseCode}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn send-request-outline" data-dismiss="modal"
                                    data-toggle="modal" data-target="#add_edit_batch">
                                <Icon name="edit_off" type={"outlined"} className="color-green mr-2"/> Chỉnh sửa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default observer(PopupDetail);