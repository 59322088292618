import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../../common/component/form/Input";
import {accountStore} from "../../AccountStore";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import './AddEditAccount.scss'
import DatePickerSingle from "../../../../../common/component/DatePickerSingle";
import SelectMulti from "../../../../../common/component/form/SelectMulti";
import Loading from "../../../../../common/component/Loading";

const AddEditAccount = () => {
    const handleFilterDate = async (startDate: Date) => {
        accountStore.dataRequest.birthday = startDate.getTime();
    };

    return (
        <div className="modal fade" id="add_edit_account" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{accountStore.typeModal === 'add' ?
                            "Thiết lập tài khoản" : "Chỉnh sửa tài khoản"}</h5>
                        <button type="button" className="close" id="close_add" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {accountStore.isLoadingDetail ? <Loading/> : <div className="modal-body pt-2 pb-0">
                        <div className="d-flex align-items-center">
                            <div className="form-group w-50 px-1">
                                <label>Số điện thoại <span className="text-danger">*</span></label>
                                <Input isError={accountStore.errors.phone} value={accountStore.dataRequest.phone}
                                       onChange={(e: any) => {
                                           if (e.currentTarget.value.length <= 10) {
                                               accountStore.dataRequest.phone = numberUtil.regexNumber(e)
                                           }
                                       }}
                                       disabled={accountStore.typeModal === 'edit'}
                                       placeholder="Nhập số điện thoại"/>
                            </div>
                            <div className="form-group w-50 px-1">
                                <label>Chức vụ <span className="text-danger">*</span></label>
                                <SelectMulti value={accountStore.dataRequest.roles}
                                             callback={(e: any) => accountStore.dataRequest.roles = e}
                                             isError={accountStore.errors.role} options={accountStore.listRole}
                                             placeholder="Chọn chức vụ"/>
                                {!accountStore.dataRequest.roles &&
                                <span className="error text-danger">{accountStore.errors.role}</span>}
                            </div>
                        </div>
                        {accountStore.typeModal === 'edit' &&
                        <div className="pt-2 pb-0 d-flex">
                            <div className="form-group w-50 px-1">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <Input disabled isError={accountStore.errors.name}
                                       value={accountStore.dataRequest.name}
                                       onChange={(e: any) => accountStore.dataRequest.name = e.currentTarget.value}
                                       placeholder="Nhập họ và tên"/>
                            </div>
                            <div className="form-group w-50 px-1">
                                <label>Ngày sinh</label>
                                <DatePickerSingle disabled selected={accountStore.dataRequest.birthday}
                                                  onChange={handleFilterDate}
                                                  placeholder="Chọn ngày sinh"/>
                            </div>
                        </div>
                        }
                    </div>}

                    <div className="modal-footer d-flex justify-content-end">
                        <button type="button" className="btn send-request" style={{width: 373}}
                                onClick={() => {
                                    accountStore.typeModal === 'add' ? accountStore.add() : accountStore.update()
                                }} disabled={accountStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddEditAccount);