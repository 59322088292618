import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {getIDParameter} from "../../common/utils/Utils";
import "./InfoTracking.scss"
import Loading from "../../common/component/Loading";
import {trackingStore} from "../tracking/TrackingStore";
import Content from "./components/Content";

const PreviewTracking = () => {

    useEffect(() => {
        window.scroll(0, 0)
        let idTracking: any = getIDParameter();
        trackingStore.getDetail(idTracking, true)
    }, [])


    return (
        <div className="info_access">
            <div className="container">
                <div className="logo d-flex justify-content-center align-items-center">
                    <img src="/assets/images/logo_login.svg" alt=""/>
                </div>
                {trackingStore.isLoadingDetail ? <Loading/> : <Content/>}
            </div>
        </div>
    );
}

export default observer(PreviewTracking);