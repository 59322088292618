import {observable} from "mobx";
import {IFilter} from "../../../common/component/filter/FilterComponent";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {detailBatchStore} from "./detailEIBatch/DetailEIBatchStore";
import {EIBatchService} from "./EIBatchService";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {manufacturingStore} from "../../manufacturing/ManufacturingStore";

export interface IListEIBatch {
    id: number,
    name: string,
    timeRequest: number | string,
    type: string,
    batchType: string,
    procedureBatch: {
        id: number,
        name: string,
        product: {
            name: string,
            id: number
        }
    },
    receiver?: {
        name: string
    },
    sender: {
        name: string
        department: string
    },
    state: string,
    note: string
}

export interface IListDataDetailEIBatch {
    id: number
    nameProduct: string
    nameCategory: string
    nameProcedure: string
    quantityProductRequest: string | number
    productQuantityExecution: string | number
    procedureBatch: {
        id: number | string
        name: number | string
    }
    quantity: number | string
    startTime: number | string
    ingrendientExported: {
        name: string,
        quantity: number;
    }[]
    status: "APPROVED" | "PENDDING" | "REJECT" | ""
    type: "IMPORT" | "EXPORT" | ""
    batchType: "SUPPLIES" | ""
    reasonReject: string
    note: string
    sender: {
        name: string
        department: string
        createdAt: number | string
    }
    receiver?: {
        name: string
        department: string
        createdAt: number | string
    }
    importRecordData: {
        id: number,
        name: string,
        suppliesType: string,
        quantityRequest: number,
        quantityResponse: number,
        batchName: string;
        batchInformation?: {
            id: number,
            name: string,
            quantity: number
        }[]
    }[],
    timeRequest: number | string
    timeHandle: number | string
    typeCreated: string
}

class EIBatchStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable id: any = ''
    @observable procedureBatch: any = ''
    @observable procedureBatchId: any = ''
    @observable suplieIds: any = ''
    @observable typeModalCancel: string = ''


    @observable page: number = 0
    @observable totalPage: number = 0
    @observable listEIBatch: IListEIBatch[] = []
    @observable dataFilter: IFilter[] = []

    @observable keyword: any = '';
    @observable searchStatus: any = '';
    @observable searchEI: any = '';
    @observable searchYear: any = '';
    @observable searchId: any = '';

    @observable listOptionSearchStatus: any[] = [
        {
            id: 'PENDING',
            name: 'Đang chờ',
        },
        {
            id: 'APPROVED',
            name: 'Hoàn thành',
        },
        {
            id: 'REJECT',
            name: 'Từ chối',
        },
    ]
    @observable listOptionSearchType: any[] = [
        {
            id: 'EXPORT',
            name: 'Xuất kho',
        },
        {
            id: 'IMPORT',
            name: 'Nhập kho',
        },
    ]

    async getWarehouseRequest() {
        expImpBatchStore.isLoading = true
        const result = await EIBatchService.getWarehouseRequest()
        expImpBatchStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            expImpBatchStore.listEIBatch = result.body.data
            expImpBatchStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetailWarehouseRequest(id: any) {
        detailBatchStore.isLoadingGetDetail = true
        const result = await EIBatchService.getDetailWarehouseRequest(id)
        if (result.status === HttpStatusCode.OK) {
            detailBatchStore.totalRemaining = result.body.productQuantity
            detailBatchStore.listDataDetailEIBatch = {
                id: result.body.id,
                nameProduct: result.body.procedureBatch.product.name,
                nameCategory: result.body.procedureBatch.product.category.name,
                quantityProductRequest: result.body.productQuantity,
                productQuantityExecution: result.body.productQuantityExecution,
                nameProcedure: result.body.procedureBatch.name,
                quantity: result.body.procedureBatch.quantity,
                startTime: result.body.procedureBatch.dateTimeStart,
                ingrendientExported: result.body.suppliesInformation ? result.body.suppliesInformation.map((item: any, index: number) => {
                    return {
                        name: item.name,
                        quantity: item.batchInformation?.reduce((acc: number, obj: { quantity: any; }) => acc + Number(obj.quantity), 0),
                    }
                }) : [],
                type: result.body.type,
                batchType: result.body.batchType,
                reasonReject: result.body.reasonReject,
                procedureBatch: result.body.procedureBatch,
                status: result.body.state,
                sender: result.body.sender,
                receiver: result.body.receiver,
                note: result.body.note,
                importRecordData: result.body.suppliesInformation,
                timeRequest: result.body.timeRequest,
                timeHandle: result.body.timeHandle,
                typeCreated: result.body.typeCreated
            }

            if (result.body.suppliesInformation) {
                detailBatchStore.listDataExportBatch = result.body.suppliesInformation.map((item: any, index: number) => {
                    if (index === 0) {
                        expImpBatchStore.suplieIds += `${item.id}`
                    } else expImpBatchStore.suplieIds += `,${item.id}`
                    let batchInfor = item.batchInformation ? item.batchInformation.map((batch: any, indexBatch: number) => {
                        return {
                            id: item.id,
                            quantity: item.quantityRequest,
                            options: [],
                            icon: 'add'
                        }
                    }) : []
                    return {
                        name: item.name,
                        id: item.id,
                        unit: item.unit,
                        selected: 0,
                        selectedBatch: [],
                        request: item.quantityRequest,
                        suppliesInformation: batchInfor
                    }
                });
                if (expImpBatchStore.suplieIds) {
                    const resultBatchInfor = await EIBatchService.getListBatchSupliesId(expImpBatchStore.suplieIds)
                    if (resultBatchInfor.status === HttpStatusCode.OK) {
                        detailBatchStore.listDataExportBatch = detailBatchStore.listDataExportBatch.map((item) => {
                            let dataBatchTmp: { id: number, name: string, maxQuantity: number, isDisabled: boolean }[] = []
                            resultBatchInfor.body.data.forEach((batch: any, indexBatch: number) => {
                                if (batch.suppliesId === item.id) {
                                    dataBatchTmp.push({
                                        id: Number(batch.id),
                                        name: batch.batchCode,
                                        maxQuantity: Number(batch.quantity - batch.quantityUsed),
                                        isDisabled: false
                                    })
                                }
                            })
                            return {
                                ...item,
                                suppliesInformation: [
                                    {
                                        id: "",
                                        nameBatch: "",
                                        quantity: "",
                                        maxQuantity: "",
                                        errQuantity: false,
                                        err: false,
                                        errBatch: false,
                                        options: dataBatchTmp,
                                        icon: 'add',
                                    }
                                ]
                            }
                        })
                        expImpBatchStore.suplieIds = ''
                    }
                }
                manufacturingStore.listSupplies = manufacturingStore.listSupplies.map((item, index) => {
                    const quantityRequestTmp = detailBatchStore.listDataExportBatch?.filter((x) => x.id === item.id)
                    return {
                        ...item,
                        estimatedQuantity: quantityRequestTmp[0]?.request
                    }
                })
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
        detailBatchStore.isLoadingGetDetail = false
    }


}

export const expImpBatchStore = new EIBatchStore()