import {observable} from "mobx";
import $ from "jquery";
import {trackingService} from "../TrackingService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import axios from "axios";
import StorageService from "../../../common/service/StorageService";
import {slug} from "../../../common/utils/Utils";
import {trackingStore} from "../TrackingStore";
import {infoStore} from "../../info/InfoStore";


class TrackingBatchStore {
    @observable id: any = '';
    @observable idBatch: any = '';
    @observable pageHelper: any = '';
    @observable type: any = '';
    @observable quantity: any = '';
    @observable step: number = 1;
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable pageHistory: number = 0
    @observable totalPageHistory: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable listTracking: any[] = []
    @observable listHistoryQr: any[] = []
    @observable errors = {
        quantity: false,
        sentDate: false,
        productCode: false,
        quantityShipped: false,
        quantityLeft: false,
        distributingAgent: false,
        distributingUnitCode: false,
        shippingPartner: false,
        shippingPartnerCode: false,
    }
    @observable dataRequest: any = {
        id: '',
        trackingId: '',
        logo: '',
        qrFile: "",
        countQrExported: '',
        bathCode: '',
        createdAt: '',
        info: {
            quantityShipped: '',
            quantityLeft: '',
            distributingAgent: '',
            distributingUnitCode: '',
            productCode: '',
            sentDate: "",
            shippingPartner: "",
            shippingPartnerCode: "",
            productMore: [],
            detailMore: []
        }
    }

    clearForm() {
        trackingBatchStore.type = 'add'
        trackingBatchStore.dataRequest = {
            id: '',
            trackingId: '',
            logo: '',
            qrFile: "",
            countQrExported: '',
            bathCode: '',
            createdAt: '',
            info: {
                quantityShipped: '',
                quantityLeft: '',
                distributingAgent: '',
                distributingUnitCode: '',
                productCode: '',
                sentDate: "",
                shippingPartner: "",
                shippingPartnerCode: "",
                productMore: [],
                detailMore: []
            }
        }
    }

    clearErrors() {
        trackingBatchStore.errors = {
            quantity: false,
            sentDate: false,
            productCode: false,
            quantityShipped: false,
            quantityLeft: false,
            distributingAgent: false,
            distributingUnitCode: false,
            shippingPartner: false,
            shippingPartnerCode: false,
        }
    }

    async getTracking() {
        trackingBatchStore.isLoading = true
        const result = await trackingService.getTrackingQR()
        trackingBatchStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            trackingBatchStore.listTracking = result.body.data
            trackingBatchStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetailTracking(id: number) {
        trackingBatchStore.type = 'edit'
        trackingBatchStore.clearDownloadQR()
        trackingBatchStore.clearErrors()
        const result = await trackingService.detailTrackingBatch(id)
        if (result.status === HttpStatusCode.OK) {
            trackingBatchStore.dataRequest = result.body
        }
    }

    async getDetailTrackingQr(id: number) {
        const result = await trackingService.detailTrackingQRBatch(id)
        if (result.status === HttpStatusCode.OK) {
            await trackingStore.getDetail(result.body.id)
            trackingBatchStore.dataRequest = result.body;
            await infoStore.getInfo(result.body.productionFacilityId)
            if (!result.body.info) {
                trackingBatchStore.dataRequest.info = {
                    productCode: '',
                    sentDate: '',
                    shippingPartner: '',
                    shippingPartnerCode: '',
                    quantityShipped: '',
                    quantityLeft: '',
                    distributingAgent: '',
                    distributingUnitCode: '',
                    productMore: [],
                    detailMore: []
                }
            }
        }
    }

    async getHistoryQr(id: number) {
        trackingBatchStore.pageHistory = 0
        trackingBatchStore.listHistoryQr = []
        const result = await trackingService.historyQr(id)
        if (result.status === HttpStatusCode.OK) {
            trackingBatchStore.listHistoryQr = result.body.data
            trackingBatchStore.totalPageHistory = result.body.metadata.totalPage
        }
    }

    async readMore() {
        const result = await trackingService.historyQr(trackingBatchStore.idBatch)
        if (result.status === HttpStatusCode.OK) {
            trackingBatchStore.listHistoryQr = trackingBatchStore.listHistoryQr.concat(result.body.data)
        }
    }

    async addEditTracking() {
        trackingBatchStore.clearErrors()
        let {id, info, logo} = trackingBatchStore.dataRequest

        let data = {
            logo: logo,
            info: info
        }

        if (id && !info.productCode) {
            trackingBatchStore.errors.productCode = true
        }

        if (!info.sentDate) {
            trackingBatchStore.errors.sentDate = true
        }

        if (!info.shippingPartner) {
            trackingBatchStore.errors.shippingPartner = true
        }

        if (!info.shippingPartnerCode) {
            trackingBatchStore.errors.shippingPartnerCode = true
        }

        if (!info.quantityShipped) {
            trackingBatchStore.errors.quantityShipped = true
        }

        if (!info.quantityLeft) {
            trackingBatchStore.errors.quantityLeft = true
        }
        if (!info.distributingAgent) {
            trackingBatchStore.errors.distributingAgent = true
        }
        if (!info.distributingUnitCode) {
            trackingBatchStore.errors.distributingUnitCode = true
        }

        if (trackingBatchStore.errors.productCode || trackingBatchStore.errors.distributingAgent || trackingBatchStore.errors.distributingUnitCode || trackingBatchStore.errors.sentDate || trackingBatchStore.errors.quantityLeft || trackingBatchStore.errors.quantityShipped || trackingBatchStore.errors.shippingPartner || trackingBatchStore.errors.shippingPartnerCode) {
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin', 2)
            return
        }

        trackingBatchStore.isLoadingBt = true

        let result: any

        if (trackingBatchStore.type === 'add') {
            result = await trackingService.addTrackingQR(data)
        } else {
            result = await trackingService.editTrackingQR(id, data)
        }
        trackingBatchStore.isLoadingBt = false

        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${trackingBatchStore.type === 'add' ? 'Thêm' : 'Cập nhật'} mã qr thành công!`, 1)
            trackingBatchStore.dataRequest = result.body
            trackingBatchStore.clearDownloadQR()
            trackingBatchStore.pageHelper = `${window.location.origin}/truy-xuat-lo/${slug(trackingStore.dataRequest.procedureBatch?.name ?? trackingStore.dataRequest.trackingData?.productInfo?.name)}/${trackingStore.dataRequest.id}/${result.body.id}`
            await trackingBatchStore.getTracking();
        } else {
            toastUtil.warning(result.body.message ?? `${trackingBatchStore.type === 'add' ? 'Thêm' : 'Cập nhật'} mã qr xuất thất bại`, 3)
        }
    }


    clearDownloadQR() {
        trackingBatchStore.step = 1
        trackingBatchStore.quantity = ''
    }

    async addLinkDownloadQr() {
        trackingBatchStore.errors.quantity = false

        if (!trackingBatchStore.quantity) {
            trackingBatchStore.errors.quantity = true
            toastUtil.warning('Vui lòng nhập số QR tải về!', 1)
            return
        }

        if (Number(trackingBatchStore.quantity) > 1000) {
            trackingBatchStore.errors.quantity = true
            toastUtil.warning('Số QR tải về tối đa là 1.000!', 2)
            return
        }

        trackingBatchStore.isLoadingBt = true
        const result = await trackingService.addLinkDownloadQr(trackingBatchStore.dataRequest.id, trackingBatchStore.quantity)
        trackingBatchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            trackingBatchStore.dataRequest = result.body
            toastUtil.success('Tạo link mã qr thành công!', 1)
            trackingBatchStore.listTracking.map((item) => {
                if (item.id === trackingBatchStore.dataRequest.id) {
                    item.countQrExported = result.body.countQrExported
                }
            })
            trackingBatchStore.step = 2
        } else {
            toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!', 2)
        }
    }

    downloadQR = async (id: any, path: any) => {
        var newHeaders: any;
        if (StorageService.isTokenExits()) {
            newHeaders = {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                Authorization: 'Bearer ' + StorageService.getToken()
            }
        } else {
            newHeaders = {'Content-Type': 'application/json'}
        }
        trackingBatchStore.isLoadingBt = true
        return await axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/food/v1/tracking_batch/${id}${path}`, {
                headers: newHeaders,
                responseType: 'blob'
            })
            .then(({data: blob}) => {
                trackingBatchStore.isLoadingBt = false
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = 'qrFile.zip';
                link.click();
            });
    };

}

export const trackingBatchStore = new TrackingBatchStore()