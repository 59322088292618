import React from 'react';
import {observer} from "mobx-react";
import {suppliesStore} from "../../../../supplies/SuppliesStore";
import Input from "../../../../../../common/component/form/Input";
import {detailBatchStore} from "../../DetailEIBatchStore";
import {expImpBatchStore} from "../../../EIBatchStore";
import {numberUtil} from "../../../../../../common/utils/NumberUtil";
import {number_format} from "../../../../../../common/utils/Utils";

const ImportProduct = () => {
    return (
        <div className="modal fade" id="import_product" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Nhập kho #{expImpBatchStore.id}</h5>
                        <button type="button" className="close" id="close_import_product" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Sản lượng nhập
                                kho
                                <span className="text-danger">*</span></label>
                            <Input
                                type="number"
                                onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                value={detailBatchStore.quantityImportSigleBatch}
                                onChange={(e: any) => {
                                    if (numberUtil.regexNumber(e) <= detailBatchStore.totalRemaining) {
                                        detailBatchStore.quantityImportSigleBatch = numberUtil.regexNumber(e)
                                    }
                                }}
                                placeholder={`Tối đa ${number_format(detailBatchStore.totalRemaining)}`}
                                isError={detailBatchStore.errorsImportProduct.quantity}
                            />
                        </div>
                        <div className="form-group">
                            <label>Ghi chú <span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                value={detailBatchStore.dataRequestExportBatch.note}
                                onChange={(e: any) => detailBatchStore.dataRequestExportBatch.note = e.currentTarget.value}
                                placeholder="Thành phẩm"
                                isError={detailBatchStore.errorsExportBatch.note}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => detailBatchStore.submitImportProduct()}
                                disabled={suppliesStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default observer(ImportProduct);