import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../common/component/form/Input";
import {cateStore} from "../CateStore";


const AddOrEditCate = () => {


    return (
        <div className="modal fade" id="add_or_edit_cate" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{cateStore.type == 'add' ? 'Thêm' : 'Cập nhật'} danh mục sản phẩm</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Danh mục sản phẩm <span className="text-danger">*</span></label>
                            <Input value={cateStore.dataRequest.name}
                                   onChange={(e: any) => cateStore.dataRequest.name = e.currentTarget.value}
                                   placeholder="Nhập tên danh mục"/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => cateStore.type == "add" ? cateStore.add() : cateStore.update()} disabled={cateStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditCate);