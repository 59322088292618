import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {getIDParameter, getUrlParameter} from "../../../common/utils/Utils";
import TabHeaderDetailManufacturing from "./TabHeaderDetailManufacturing";
import DetailManufacturingJob from "./detailManufacturingJob/DetailManufacturingJob";
import {ITabDetailPlanManufacturing, manufacturingStore} from "../ManufacturingStore";
import DetailManufacturingSupplies from "./detailManufacturingSupplies/DetailManufacturingSupplies";
import {preserveStore} from "../../preserve/PreserveStore";
import {Link} from "react-router-dom";

const DetailManufacturing = (): JSX.Element => {
    useEffect(() => {
        manufacturingStore.id = Number(getIDParameter(2));
        manufacturingStore.tabActiveDetailManufacturing = getUrlParameter('tab') ?? ITabDetailPlanManufacturing.job
        preserveStore.getDetailPreserves(manufacturingStore.id)
    }, [])
    return (
        <div className="batch_supplies">
            <div className="d-flex header_back align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <Link
                        to={`/manufacturing`}>Sản xuất</Link>
                    <i className="fal fa-angle-right"/>
                    <span>{preserveStore.dataAddPlanOrderWork?.name}</span>
                </div>
            </div>
            <TabHeaderDetailManufacturing/>
            <div className='table-responsive'>
                {manufacturingStore.tabActiveDetailManufacturing === ITabDetailPlanManufacturing.job &&
                    <DetailManufacturingJob/>}
                {manufacturingStore.tabActiveDetailManufacturing === ITabDetailPlanManufacturing.supplies &&
                    <DetailManufacturingSupplies/>}
            </div>
        </div>

    )

}

export default observer(DetailManufacturing);
