import React from 'react';
import './ModalConfirmLockAccount.scss'
import {accountStore} from "../../AccountStore";

interface IProps {
    type: string
}

const ModalConfirmLockAccount = ({type}: IProps) => {
    return (
        <div className="modal fade" id='popup_confirm_lock' role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" id="close_delete" data-dismiss="modal"
                                aria-label="Close">
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center icon">
                        <img src={`/assets/ico/action/${type === 'lock' ? 'lock_white.svg' : 'unlock_white.svg'}`}
                             alt=""/>
                    </div>
                    <div className="mx-3">
                        <h2 className="text-center mt-4">{`${type === "lock" ? "Khóa Tài Khoản" : "Mở Khóa Tài Khoản"}`}</h2>
                        <p className="text-center mb-5 mx-5 text-content-confirm">{`${type === "lock" ? "Chủ tài khoản sẽ không đăng nhập được vào farm bạn đang quản lý." : "Chủ tài khoản sẽ trở lại hoạt động bình thường farm bạn đang quản lý."}`}</p>
                        <div className="d-flex action align-items-center justify-content-between mx-3 mb-5">
                            <button className="btn btn-cancel w-50 mx-2" data-dismiss="modal">Hủy</button>
                            <button
                                className={`btn ${type === 'lock' ? 'btn-confirm-lock' : 'btn-confirm-unlock'} w-50 mx-2`}
                                onClick={async () => {
                                    accountStore.editStatus = true
                                    accountStore.dataRequest.status = (type === 'lock') ? "BLOCK" : "ACTIVE"
                                    await accountStore.update()
                                }}>Xác
                                nhận
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ModalConfirmLockAccount;