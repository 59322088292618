import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {suppliesStore} from "./SuppliesStore";

class SuppliesService {

    public getSupplies(size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/supplies?type=${suppliesStore.tabActive.toUpperCase()}&page=${suppliesStore.page}&size=${size ?? 10}${suppliesStore.keyword ? `&keyword=${suppliesStore.keyword.trim()}` : ''}`);
    }

    public detailSupplies(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/supplies/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/supplies`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/supplies/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/supplies/${id}`, {id});
    }

}

export const suppliesService = new SuppliesService();