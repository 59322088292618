import React from 'react';
import "./FilterStyle.scss"
import {checkPermission} from "../../utils/Utils";

export interface IFilter {
    label: string,
    type: string,
    value: string,
    callback: any,
    width?: any,
    onChange?: any,
    placeholder?: any,
    listValue?: any[],
}

interface IProps {
    label?: string
    buttonAdd?: any
    permissionCode?: boolean,
    data?: IFilter[]
}

export enum IType {
    date = 'date',
    select = "select",
    search = 'search'
}

const FilterComponent = ({buttonAdd, data, label, permissionCode}: IProps) => {

    const onKeyDown = async (e: any, item: any) => {
        if (e.key === 'Enter') {
            await item.callback()
        }
    }

    const switchCase = (item: IFilter) => {
        switch (item.type) {
            case IType.date:
                return <div className="search_date">
                    <input type="date" value={item.value} onChange={(e: any) => {
                        item.callback(e.currentTarget.value);
                        item.value = e.currentTarget.value
                    }} placeholder={item.placeholder ?? ''}/>
                </div>
            case IType.select:
                return <div className="filter_select">
                    <select onChange={(e: any) => {
                        item.callback(e.currentTarget.value);
                        item.value = e.currentTarget.value
                    }} value={item.value}>
                        <option value="">Chọn</option>
                        {item.listValue && item.listValue.map((item, i) => {
                            return <option value={item.id} selected={item.value === item.id}
                                           key={i}>{item.name}</option>
                        })}
                    </select>
                </div>
            case IType.search:
                return <div className="filter_search position-relative" style={{width: `${item.width ?? '189px'}`}}>
                    <input type="text" onChange={(e: any) => {
                        item.onChange(e.currentTarget.value);
                        item.value = e.currentTarget.value
                    }} onKeyDown={(e: any) => onKeyDown(e, item)} placeholder={item.placeholder ?? ''}/>
                    <i className="far fa-search color-green position-absolute" onClick={() => item.callback()}/>
                </div>
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-between filter_header">
            {label && <h3 className="m-0">{label}</h3>}
            <div className="d-flex align-items-center">
                {data && <img src="/assets/ico/filter.svg" alt=""/>}
                {data && data.map((item, i) => {
                    return <div className="d-flex form_search align-items-center" key={i}>
                        <span>{item.label}:</span>
                        {switchCase(item)}
                    </div>
                })}
                {permissionCode && buttonAdd}
            </div>
        </div>
    );
}

export default FilterComponent;