import React from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {ITabDetailProduct, productStore} from "../../ProductStore";
import {slug} from "../../../../../../common/utils/Utils";


const TabHeaderDetailProduct = () => {
    return (
        <div className="tab_header d-flex align-items-center mb-3 px-3">
            <Link
                to={`/warehouse/category/product/${slug(productStore.productName)}/${productStore.productId}?tab=${ITabDetailProduct.inventory}`}
                className={`tab ${productStore.tabActiveDetailProduct === ITabDetailProduct.inventory && 'active'}`}
                onClick={() => {
                    productStore.tabActiveDetailProduct = ITabDetailProduct.inventory;
                    productStore.page = 0
                }}>
                <span>Tồn kho</span>
            </Link>
            <Link
                to={`/warehouse/category/product/${slug(productStore.productName)}/${productStore.productId}?tab=${ITabDetailProduct.history}`}
                className={`tab ${productStore.tabActiveDetailProduct === ITabDetailProduct.history && 'active'}`}
                onClick={() => {
                    productStore.tabActiveDetailProduct = ITabDetailProduct.history;
                    productStore.page = 0
                }}>
                <span>Lịch sử</span>
            </Link>
        </div>
    );
}

export default observer(TabHeaderDetailProduct);