import React from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {ITabDetailPlanPreserve, preserveStore} from "../../PreserveStore";

const TabHeaderDetailPlan = () => {
    return (
        <div className="tab_header d-flex align-items-center px-3">
            <Link
                to={`/planing/${preserveStore.id}?tab=${ITabDetailPlanPreserve.plan}`}
                className={`tab ${preserveStore.tabActiveDetailPlanPreserve === ITabDetailPlanPreserve.plan && 'active'}`}
                onClick={() => {
                    preserveStore.tabActiveDetailPlanPreserve = ITabDetailPlanPreserve.plan;
                }}>
                <span>Kế Hoạch Sản Xuất</span>
            </Link>
            <Link
                to={`/planing/${preserveStore.id}?tab=${ITabDetailPlanPreserve.tracking}`}
                className={`tab ${preserveStore.tabActiveDetailPlanPreserve === ITabDetailPlanPreserve.tracking && 'active'}`}
                onClick={() => {
                    preserveStore.tabActiveDetailPlanPreserve = ITabDetailPlanPreserve.tracking;
                }}>
                <span>Theo Dõi</span>
            </Link>
        </div>
    );
}

export default observer(TabHeaderDetailPlan);
