import {batchStore} from "./BatchStore";
import Loading from "../../../../common/component/Loading";
import {dateUtils} from "../../../../common/utils/DateUtils";
import NoContent from "../../../../common/component/NoContent";
import Paginate from "../../../../common/component/Paginate";
import PopupConfirm from "../../../../common/component/PopupConfirm";
import React, {useEffect} from "react";
import {observer} from "mobx-react";
import AddOrUpdateBatch from "./components/AddOrUpdateBatch";
import {checkPermission, number_format} from "../../../../common/utils/Utils";
import ExportBatch from "./components/ExportBatch";
import PopupDetail from "./components/PopupDetail";
import {Link} from "react-router-dom";
import {suppliesStore} from "../SuppliesStore";
import FilterComponent from "../../../../common/component/filter/FilterComponent";
import ButtonAdd from "../../../../common/component/buttonAdd/ButtonAdd";
import {PermissionConst} from "../../../../common/constants/Constants";

const InventoryPage = () => {

    const changePage = async (data: any) => {
        batchStore.page = data.selected;
        await batchStore.getBatch(batchStore.suppliesId);
    }

    useEffect(() => {
        if (batchStore.suppliesId) {
            batchStore.getBatch(batchStore.suppliesId);
        }
    }, [batchStore.suppliesId])


    return (
        <div className="batch_">
            <div className="d-flex header_back align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <Link to={`/warehouse/supplies?type=${batchStore.typeSupplies}`}>Kho {batchStore.switchName()}</Link>
                    <i className="fal fa-angle-right"/>
                    <span>{suppliesStore.dataRequest.name}</span>
                </div>
                <div className="d-flex align-items-center">
                    <p className="mb-0 mr-3"><strong>Tổng
                        còn: {number_format(suppliesStore.dataRequest.quantity - suppliesStore.dataRequest.quantityUsed)}</strong>
                    </p>
                    <p className="mb-0 mr-4"><strong>Đơn vị: {suppliesStore.dataRequest.unit}</strong></p>
                    <div style={{width: 332}}>
                        {batchStore.isLoading ? <FilterComponent data={batchStore.dataFilter}/> :
                            <FilterComponent data={batchStore.dataFilter}/>}
                    </div>
                    {checkPermission(PermissionConst.suppliesBatchAdd) && <ButtonAdd text="Thêm lô" dataTarget={'add_edit_batch'} onClick={batchStore.clearForm}/>}
                </div>
            </div>
            {batchStore.isLoading ? <Loading/> : <>
                {batchStore.listBatch && batchStore.listBatch.length > 0 ?
                    <div className='table-responsive'>
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">Ngày nhập</th>
                                <th>Lô {batchStore.switchName()}</th>
                                <th className="text-center">Số lượng</th>
                                <th className="text-center">Hạn sử dụng</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {batchStore.listBatch.map((item, i) => {
                                let totalRemaining = item.quantity - item.quantityUsed

                                return (
                                    <tr key={i}>
                                        <td className="text-center">{dateUtils.formatDate(item.createdAt, 'DD/MM/YYYY HH:mm')}</td>
                                        <td>{item.batchCode}</td>
                                        <td className="text-center">{number_format(totalRemaining)}</td>
                                        <td className="text-center">{dateUtils.formatDate(item.expirationDate)}</td>
                                        <td className="text-center">
                                            <div className="action">
                                                <button className="bt_action" title="Chi tiết" data-toggle="modal"
                                                        data-target="#detail_batch"
                                                        onClick={() => batchStore.getDetail(item.id)}>
                                                    <img src="/assets/ico/action/ico_view.svg" alt=""/>
                                                </button>
                                                {checkPermission(PermissionConst.suppliesBatchAdd) &&<button className="bt_action" title="Tạo trùng" data-toggle="modal"
                                                        data-target="#add_edit_batch"
                                                        onClick={() => batchStore.addDuplicates(item)}>
                                                    <img src="/assets/ico/action/ico_clone.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.suppliesBatchExport) &&<button className="bt_action" data-toggle="modal"
                                                        data-target="#export_batch"
                                                        disabled={totalRemaining < 1 && true}
                                                        title={totalRemaining < 1 ? 'Không còn hàng xuất kho' : 'Xuất kho'}
                                                        onClick={() => {
                                                            batchStore.id = item.id;
                                                            batchStore.totalRemaining = totalRemaining;
                                                            batchStore.clearFormExport()
                                                        }}>
                                                    <img src="/assets/ico/action/ico_export.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.suppliesBatchUpdate) &&<button className="bt_action"
                                                        disabled={item.quantityUsed > 0 || item.quantityUsed}
                                                        title="Sửa"
                                                        data-toggle="modal"
                                                        data-target="#add_edit_batch"
                                                        onClick={() => batchStore.getDetail(item.id)}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.suppliesBatchDelete) && <button className="bt_action" title="Xóa"
                                                        disabled={item.quantityUsed > 0 || item.quantityUsed}
                                                        onClick={() => batchStore.id = item.id}
                                                        data-toggle="modal" data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Paginate currentPage={batchStore.page} totalPage={batchStore.totalPage}
                                      changePage={changePage}/>
                        </div>
                    </div> : <NoContent/>}
            </>}

            <PopupDetail/>
            <AddOrUpdateBatch/>
            <ExportBatch/>
            <PopupConfirm isLoading={batchStore.isLoadingBt} action={batchStore.delete}/>
        </div>
    )
}

export default observer(InventoryPage)