import React from 'react';
import './CancelExportBatch.scss'
import {dateUtils} from "../../../../../../common/utils/DateUtils";
import {detailBatchStore} from "../../DetailEIBatchStore";
import {observer} from "mobx-react";
import {convertDepartment} from "../../DetailEIBatchPage";

interface IProps {
    id?: string,
    isLoading: any
    titlePopup?: string
    titleConfirm?: string
}

const CancelExportBatch = ({
                               id,
                               titlePopup = "Từ chối xuất kho",
                           }: IProps) => {

    return (
        <div className="modal fade" id={id ?? 'cancel-export-batch'} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center">{titlePopup}</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body cancel-ei-inform">
                        <div className="infor-cancel-export my-3">
                            <div className="d-flex flex-column cancel-ei-inform__title">
                                <p className="cancel-ei-inform__item">
                                    Mã yêu cầu
                                </p>
                                <p className="cancel-ei-inform__item">
                                    Người yêu cầu
                                </p>
                                <p className="cancel-ei-inform__item">
                                    Bộ phận
                                </p>
                                <p className="cancel-ei-inform__item">
                                    Thời gian
                                </p>
                            </div>
                            <div className="d-flex flex-column flex-grow-1">
                                <p
                                    className="fw-600 ">:
                                    #000{detailBatchStore.listDataDetailEIBatch.id ?? "---"}
                                </p>
                                <p
                                    className="fw-600 ">: {detailBatchStore.listDataDetailEIBatch.sender.name ?? "---"}
                                </p>
                                <p
                                    className="fw-600">: {detailBatchStore.listDataDetailEIBatch.sender.department ? convertDepartment(detailBatchStore.listDataDetailEIBatch.sender.department) : "---"}
                                </p>
                                <p
                                    className="fw-600 ">: {detailBatchStore.listDataDetailEIBatch.timeRequest ? dateUtils.formatDate(Number(detailBatchStore.listDataDetailEIBatch.timeRequest), ' hh:mm DD/MM/YYYY') : '---'}
                                </p>
                            </div>
                        </div>
                        <div>
                            <label>Ghi chú <span className='text-danger'>*</span></label>
                            <div className="input-group d-flex flex-column">
                                    <textarea value={detailBatchStore.dataRequestExportBatch.note}
                                              onChange={(e: any) => {
                                                  detailBatchStore.dataRequestExportBatch.note = e.currentTarget.value
                                              }}
                                              style={{
                                                  minHeight: 100,
                                                  resize: "none"
                                              }}
                                              className={`form-control form-control-lg`} placeholder="Nhập ghi chú"/>
                                {detailBatchStore.errorsCancelRequestEI.note &&
                                    <span className="error mt-1">{detailBatchStore.errorsCancelRequestEI.note}</span>}
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer d-flex align-items-center justify-content-end button-cancel-container">
                        <button className="btn btn-cancel" data-dismiss="modal">Hủy</button>
                        <button type="button" className="btn btn-confirm-cancel-export"
                                onClick={() => detailBatchStore.submitCancelEIBatch()}
                        >Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default observer(CancelExportBatch);