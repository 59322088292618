import React from 'react';
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {suppliesStore, TypeSupplies} from "../SuppliesStore";

const TabHeader = observer(() => {
    const navigate = useNavigate();

    const changTab = (type: any) => {
        suppliesStore.page = 0
        suppliesStore.listSupplies = []
        suppliesStore.tabActive = type;
        navigate({pathname: '/warehouse/supplies', search: `?type=${type}`});
    }

    return (
        <div className="tab_header d-flex align-items-center">
            <div className={`tab ${suppliesStore.tabActive === TypeSupplies.ingredient ? 'active' : ''}`}
                 onClick={() => changTab(TypeSupplies.ingredient)}>
                <img
                    src={`/assets/ico/setup/ico_medicine${suppliesStore.tabActive === TypeSupplies.ingredient ? '_active' : ''}.svg`}
                    alt=""/>
                <span>Kho nguyên liệu</span>
            </div>

            <div className={`tab ${suppliesStore.tabActive === TypeSupplies.additives ? 'active' : ''}`}
                 onClick={() => changTab(TypeSupplies.additives)}>
                <img
                    src={`/assets/ico/setup/ico_fertilizer${suppliesStore.tabActive === TypeSupplies.additives ? '_active' : ''}.svg`}
                    alt=""/>
                <span>Kho phụ gia</span>
            </div>

            <div className={`tab ${suppliesStore.tabActive === TypeSupplies.packaging ? 'active' : ''}`}
                 onClick={() => changTab(TypeSupplies.packaging)}>
                <img
                    src={`/assets/ico/setup/ico_ingredient${suppliesStore.tabActive === TypeSupplies.packaging ? '_active' : ''}.svg`}
                    alt=""/>
                <span>Bao bì</span>
            </div>

            <div className={`tab ${suppliesStore.tabActive === TypeSupplies.equipment ? 'active' : ''}`}
                 onClick={() => changTab(TypeSupplies.equipment)}>
                <img
                    src={`/assets/ico/setup/ico_equipment${suppliesStore.tabActive === TypeSupplies.equipment ? '_active' : ''}.svg`}
                    alt=""/>
                <span>Thiết bị</span>
            </div>
        </div>
    );

})

export default TabHeader;