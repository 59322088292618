import React from 'react';
import {Link} from "react-router-dom";


const Footer = () => {


    return (
        <div className="footer_om bg-white">
            <div className="footer_content">
                <div className="container d-flex justify-content-between flex-wrap">
                    <div className="content_left">
                        <div className="logo">
                            <img src="/assets/images/logo_login_omcheck.svg" alt=""/>
                        </div>
                        <p>CÔNG TY CỔ PHẦN TẬP ĐOÀN CÔNG NGHỆ OMMANI <br/>MST: 0108160961</p>
                        <p className="d-flex align-items-center"><img className="mr-1" src="/assets/ico/ico_phone.png"
                                                                      alt=""/> Hotline: <a className="ml-1"
                                                                                           href={`tel:+0976 389 199`}>0976
                            389 199</a></p>
                        <p className="d-flex align-items-center"><img className="mr-1" src="/assets/ico/ico_email.png"
                                                                      alt=""/> Email: <a className="ml-1 email"
                                                                                         href={`mailto:info@ommani.vn`}>info@ommani.vn</a>
                        </p>
                        <p>Địa chỉ: <span>Số 1, Trần Nguyên Đán,<br/>Phường Định Công, Quận Hoàng Mai, Thành phố Hà Nội</span>
                        </p>
                    </div>
                    <div className="list_box">
                        <div className="box">
                            <h4>Dịch vụ nổi bật</h4>
                            <div className="content">
                                <Link to="#">Tiêu chuẩn</Link>
                                <Link to="#">Tư vấn</Link>
                                <Link to="#">Ứng dụng số trong sản xuất</Link>
                                <Link to="#">Thương mại</Link>
                                <Link to="#">Quản lý ATTP</Link>
                                <Link to="#">Xúc tiến thương mại</Link>
                            </div>
                        </div>
                        <div className="box">
                            <h4>Ommani</h4>
                            <div className="content">
                                <a href="https://ommani.vn" target="_blank">Về Ommani</a>
                                <Link to="#">Dịch vụ</Link>
                                <Link to="#">Tin tức & Sự kiện</Link>
                            </div>
                        </div>
                        <div className="box">
                            <h4>Chính sách</h4>
                            <div className="content">
                                <Link to="#">Quy định chung</Link>
                                <Link to="#">Bảo mật thông tin</Link>
                                <Link to="#">Hỗ trợ kỹ thuật</Link>
                                <Link to="#">Giải quyết khiếu nại</Link>
                            </div>
                        </div>
                        <div className="box">
                            <h4>Hỗ trợ khách hàng</h4>
                            <div className="content">
                                <Link to="#">Liên hệ</Link>
                                <Link to="#">Góp ý & Khiếu nại</Link>
                                <Link to="#">Tài liệu hướng dẫn sử dụng</Link>
                                <Link to="#">Quy trình làm việc</Link>
                            </div>
                            <div className="d-none d-lg-block">
                                <h4 className="mt-4">Kết nối với chúng tôi</h4>
                                <div className="d-flex list_ico align-items-center">
                                    <a href="#">
                                        <img src="/assets/ico/ico_fb.svg" width={34} height={34} alt=""/>
                                    </a>
                                    <a href="#">
                                        <img className='ml-3 mr-3' src="/assets/ico/ico_yt.svg" width={48} height={34}
                                             alt=""/>
                                    </a>
                                    <a href="#">
                                        <img src="/assets/ico/ico_tiktok.svg" width={30} height={34} alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_right w-100 d-flex align-items-center justify-content-center">
                <span>© 2023 Ommani. All Rights Reserved</span>
            </div>
        </div>
    )

}

export default Footer

