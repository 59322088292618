import React, {useEffect} from "react";
import {observer} from "mobx-react";
import Loading from "../../../../../../common/component/Loading";
import {dateUtils} from "../../../../../../common/utils/DateUtils";
import {checkPermission, number_format} from "../../../../../../common/utils/Utils";
import NoContent from "../../../../../../common/component/NoContent";
import {productStore} from "../../ProductStore";
import ExportProductByBatch from "./ExportProductByBatch/ExportProductByBatch";
import DetailBatchByProductId from "./DetailBathByProductId/DetailBatchByProductId";
import {preserveStore} from "../../../../../preserve/PreserveStore";
import FilterComponent from "../../../../../../common/component/filter/FilterComponent";
import './DetailProduct.scss'
import {PermissionConst} from "../../../../../../common/constants/Constants";

const InventoryPageDetailProduct = () => {
    useEffect(() => {
        if (productStore.productId > 0) {
            productStore.getPreserves(productStore.productId.toString());
        }
    }, [productStore.productId])
    const dataFilter = [
        {
            label: 'Tìm Lô Sản Xuất',
            type: 'search',
            placeholder: 'Nhập từ khóa tìm kiếm',
            value: productStore.keyword,
            onChange: (e: any) => {
                productStore.page = 0
                productStore.keyword = e
            },
            callback: async () => {
                await productStore.getPreserves(productStore.productId.toString());
            }
        }]
    return (
        <div className="invetory-detail-product">
            <FilterComponent
                data={dataFilter}
            />
            {productStore.isLoading ? <Loading/> : <>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7 mx-3'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">Ngày nhập</th>
                        <th className="text-center">Lô sản xuất</th>
                        <th className="text-center">Số lượng</th>
                        <th className="text-center">Hạn sử dụng (tháng)</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productStore.listDataBatchByProductId && productStore.listDataBatchByProductId.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                <td>{item.batchCode}</td>
                                <td className="text-center">{number_format(item.quantity - item.quantityUsed)}</td>
                                <td className="text-center">{item.product ? item.product.expireMonth : '-'}</td>
                                <td className="text-center">
                                    <div className="action">
                                        <button className="bt_action" data-toggle="modal"
                                                data-target="#detail_batch_by_product_id"
                                                onClick={async () => {
                                                    productStore.quantityProduct = item.quantity
                                                    productStore.quantityProductUsed = item.quantityUsed
                                                    await preserveStore.getDataAllDepartment()
                                                    await preserveStore.getDetailPreserves(item.procedureBatch.id)
                                                    await productStore.getSuppliesLogByProcedureBatch(item.procedureBatch.id)
                                                }}>
                                            <img src="/assets/ico/action/ico_detail.svg" alt="" data-toggle="tooltip"
                                                 data-placement="top"
                                                 title="Xem chi tiết"/>
                                        </button>
                                        {checkPermission(PermissionConst.productExport) &&
                                            <button className="bt_action"
                                                    data-toggle="modal"
                                                    data-target="#export_product_by_batch"
                                                    onClick={() => {
                                                        productStore.totalRemaining = item.quantity - item.quantityUsed
                                                        productStore.procedureBatchId = item.procedureBatch.id
                                                        productStore.errorsExportProductByBatch.quatity = ''
                                                        productStore.errorsExportProductByBatch.note = ''
                                                        productStore.dataRequestExportProductByBatch.quantity = ''
                                                        productStore.dataRequestExportProductByBatch.note = ''
                                                    }}
                                            >
                                                <img src="/assets/ico/action/ico_export.svg" alt=""
                                                     data-toggle="tooltip"
                                                     data-placement="top"
                                                     title="Xuất kho"/>
                                            </button>}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {productStore.listDataBatchByProductId && productStore.listDataBatchByProductId.length < 1 &&
                    <NoContent mx3={true}/>}
            </>}
            <div className="d-flex align-items-center justify-content-center mt-4">
            </div>
            <DetailBatchByProductId/>
            <ExportProductByBatch/>
        </div>
    )
}

export default observer(InventoryPageDetailProduct)