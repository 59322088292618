import {
    postRequest,
    IApiResponse,
    getRequest,
    deleteRequest,
    putRequest,
    patchRequest
} from "../../../../common/helpers/RequestHelper";
import {procedureJobStore} from "./ProcedureJobStore";


class ProcedureJobService {
    public getProcedureJob(): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure/${procedureJobStore.procedureId}/job?page=0&size=100`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure/${procedureJobStore.procedureId}/job/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/procedure/${procedureJobStore.procedureId}/job`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/procedure/${procedureJobStore.procedureId}/job/${id}`, data);
    }

    public sortJob(ids: any): Promise<IApiResponse> {
        return patchRequest(`/food/v1/procedure/${procedureJobStore.procedureId}/job/_sort?ids=${ids.toString()}`, {});
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/procedure/${procedureJobStore.procedureId}/job/${id}`, {id});
    }
}

export const procedureJobService = new ProcedureJobService();