import React from 'react';
import {observer} from "mobx-react";
import './AddPlanPersonInCharge.scss'

import {preserveStore} from "../../PreserveStore";
import SelectMulti from "../../../../common/component/form/SelectMulti";
import {ActionButtons} from "../wizardAddPlan/Wizard";
import NoContent from "../../../../common/component/NoContent";

const AddPlanPersonInCharge = (props: any) => {
    const validate = () => {
        let checkValidate = true
        preserveStore.dataAddPlanPersonInCharge.forEach((item, index) => {
            if (item.selectPersonInCharge.length === 0) {
                item.isErr = true
                checkValidate = false
            } else item.isErr = false
        })
        if (!checkValidate) {
            preserveStore.errors.addPlanPersonInCharge = `Vui lòng chọn đầy đủ người phụ trách các quy trình!`
            return
        } else {
            preserveStore.errors.addPlanPersonInCharge = ''
        }
        preserveStore.dataAddPlanPersonInCharge = preserveStore.dataAddPlanPersonInCharge.map((item, index) => {
            const checkAllSelect = item.selectPersonInCharge.map((x) => x.value).includes(-1)
            if (checkAllSelect) {
                return {
                    ...item,
                    selectPersonInCharge: item.personInCharge.slice(1, item.personInCharge.length)
                }
            } else return item
        })
        props.nextStep();
        props.userCallback(preserveStore.dataAddPlanPersonInCharge);
    }
    return (
        <div className="add-plan-person-in-charge-container">
            <div className="add-plan-person-in-charge">
                <div className="w-50">
                    <p>Tên sản phẩm: <span
                        className="fw-600">{preserveStore.dataAddPlanOrderWork.productName || "---"}</span>
                    </p>
                    <p>Tên lô sản xuất: <span
                        className="fw-600">{preserveStore.dataAddPlanOrderWork.name || "---"}</span></p>
                </div>
                <div className="w-50">
                    <p>Quy trình: <span
                        className="fw-600">{preserveStore.dataAddPlanOrderWork.procedureName || "---"}</span></p>
                    <p>Sản lượng dự kiến: <span
                        className="fw-600">{preserveStore.dataAddPlanOrderWork.quantity || "---"}</span></p>
                </div>
            </div>
            <table className='table align-middle table-hover table-rounded border'>
                <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th className="text-center">STT</th>
                    <th className="text-center">Tên quy trình</th>
                    <th className="text-center">Người phụ trách</th>
                </tr>
                </thead>
                <tbody>
                {preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td className="text-center">{i + 1}</td>
                            <td className="text-center">{item.name}</td>
                            <td className="text-center w-50">
                                <SelectMulti
                                    isError={item.isErr}
                                    placeholder="Chọn người phụ trách"
                                    options={item.personInCharge}
                                    value={item.selectPersonInCharge}
                                    callback={(e: any) => {
                                        item.selectPersonInCharge = e
                                        let indexAll = item.selectPersonInCharge.map((item, index) => item.value).indexOf(-1)

                                        if (indexAll === 0 && item.selectPersonInCharge.length > 1) item.selectPersonInCharge = item.selectPersonInCharge.slice(1, item.selectPersonInCharge.length)

                                        if (item.selectPersonInCharge.map((item, index) => item.value).includes(-1)) {
                                            item.selectPersonInCharge = [
                                                {
                                                    value: -1,
                                                    label: "Tất cả"
                                                }
                                            ]
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {preserveStore.errors.addPlanPersonInCharge !== '' &&
                <span className="text-danger">{preserveStore.errors.addPlanPersonInCharge}</span>}
            {preserveStore.isLoading && preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge.length < 1 &&
                <NoContent/>}
            <div className="modal-footer">
                <ActionButtons {...props} nextStep={validate}/>
            </div>
        </div>
    );
}

export default observer(AddPlanPersonInCharge);
