import React, {useEffect} from 'react';
import Loading from "../../../../common/component/Loading";
import {checkPermission, number_format, slug, sttPage} from "../../../../common/utils/Utils";
import NoContent from "../../../../common/component/NoContent";
import Paginate from "../../../../common/component/Paginate";
import PopupConfirm from "../../../../common/component/PopupConfirm";
import ButtonAdd from "../../../../common/component/buttonAdd/ButtonAdd";
import {observer} from "mobx-react";
import {PermissionConst} from "../../../../common/constants/Constants";
import {suppliesStore, TypeSupplies} from "../SuppliesStore";
import AddOrEditSupplies from "../components/AddOrEditSupplies";
import {Link} from "react-router-dom";
import FilterComponent from "../../../../common/component/filter/FilterComponent";


const IngredientPage = () => {

    const changePage = async (data: any) => {
        suppliesStore.page = data.selected;
        await suppliesStore.getSupplies();
    }

    let data = [
        {
            label: 'Tên nguyên liệu',
            type: 'search',
            placeholder: 'Nhập tên nguyên liệu',
            value: suppliesStore.keyword,
            onChange: (e: any) => {
                suppliesStore.keyword = e
            },
            callback: async () => {
                await suppliesStore.getSupplies();
            }
        }
    ]

    useEffect(() => {
        suppliesStore.getSupplies();
        return () => {
            suppliesStore.keyword = ''
        }
    }, [])

    return (
        <div className='ingredient'>
            <FilterComponent data={data} permissionCode={checkPermission(PermissionConst.suppliesAdd)}
                             label="Kho nguyên liệu"
                             buttonAdd={<ButtonAdd text="Thêm nguyên liệu" dataTarget={'add_or_edit_supplies'}
                                                   onClick={suppliesStore.clearForm}/>}/>
            {suppliesStore.isLoading ? <Loading/> : <>
                {suppliesStore.listSupplies && suppliesStore.listSupplies.length > 0 ?
                    <div className='table-responsive'>
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th>Tên nguyên liệu</th>
                                <th className="text-center">Tồn kho</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {suppliesStore.listSupplies.map((item, i) => {
                                let totalRemaining = item.quantity - item.quantityUsed
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(suppliesStore.page, i)}</td>
                                        <td>{item.name}</td>
                                        <td className="text-center">{number_format(totalRemaining)} {item.unit}</td>
                                        <td className="text-center" width="20%">
                                            <div className="action">
                                                {checkPermission(PermissionConst.suppliesBatchView) && <button className="bt_action" title="Chi tiết">
                                                    <Link
                                                        to={`/warehouse/supplies/${slug(item.name)}/${item.id}?type=${TypeSupplies.ingredient}`}>
                                                        <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                    </Link>
                                                </button>}
                                                {checkPermission(PermissionConst.suppliesUpdate) &&
                                                    <button className="bt_action" title="Sửa" data-toggle="modal"
                                                            data-target="#add_or_edit_supplies"
                                                            onClick={() => suppliesStore.getDetail(item.id)}>
                                                        <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                    </button>}
                                                {checkPermission(PermissionConst.suppliesDelete) &&
                                                    <button className="bt_action" title="Xóa"
                                                            onClick={() => suppliesStore.id = item.id}
                                                            data-toggle="modal" data-target="#popup_confirm">
                                                        <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                    </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Paginate currentPage={suppliesStore.page} totalPage={suppliesStore.totalPage}
                                      changePage={changePage}/>
                        </div>
                    </div> : <NoContent/>}
            </>}
            <AddOrEditSupplies/>
            <PopupConfirm isLoading={suppliesStore.isLoadingBt} action={suppliesStore.delete}/>
        </div>
    );

}

export default observer(IngredientPage);
