import React, {useEffect} from 'react';
import Loading from "../../../common/component/Loading";
import ButtonAdd from "../../../common/component/buttonAdd/ButtonAdd";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import PopupConfirm from "../../../common/component/PopupConfirm";
import {observer} from "mobx-react";
import {cateStore} from "./CateStore";
import {checkPermission, number_format, slug, sttPage} from "../../../common/utils/Utils";
import {dateUtils} from "../../../common/utils/DateUtils";
import AddOrEditCate from "./components/AddOrEditCate";
import {Link} from "react-router-dom";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import {productStore} from "./product/ProductStore";
import {PermissionConst} from "../../../common/constants/Constants";


const CatePage = () => {

    const changePage = async (data: any) => {
        cateStore.page = data.selected;
        await cateStore.getCate()
    }

    useEffect(() => {
        cateStore.getCate()

    }, [])

    let data = [
        {
            label: 'Tìm danh mục sản phẩm',
            type: 'search',
            placeholder: 'Nhập danh mục sản phẩm',
            value: cateStore.keyword,
            onChange: (e: any) => {
                cateStore.page = 0
                cateStore.keyword = e
            },
            callback: async () => {
                await cateStore.getCate();
            }
        }
    ]
    return (
        <div className='category'>
            <FilterComponent data={data} permissionCode={checkPermission(PermissionConst.productCateAdd)} label="Danh mục sản phẩm"
                             buttonAdd={<ButtonAdd text="Thêm danh mục" dataTarget={'add_or_edit_cate'}
                                                   onClick={cateStore.clearForm}/>}/>

            {cateStore.isLoading ? <Loading/> : <>
                {cateStore.listCate && cateStore.listCate.length > 0 ?
                    <div className="table-responsive">
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th>Tên danh mục sản phẩm</th>
                                <th className="text-center">Ngày tạo</th>
                                <th className="text-center">Số sản phẩm</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {cateStore.listCate && cateStore.listCate.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(cateStore.page, i)}</td>
                                        <td>{item.name}</td>
                                        <td className="text-center">{item.createdAt ? dateUtils.formatDate(item.createdAt) : '-'}</td>
                                        <td className="text-center">{number_format(item.countProduct)}</td>
                                        <td className="text-center" width="18%">
                                            <div className="action">
                                                {checkPermission(PermissionConst.productView) && <button className="bt_action" title="Chi tiết" onClick={() => {
                                                    productStore.categoryName = item.name
                                                }}>
                                                    <Link to={`/warehouse/category/${slug(item.name)}/${item.id}`}>
                                                        <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                    </Link>
                                                </button>}
                                                {checkPermission(PermissionConst.productCateUpdate) && <button className="bt_action" title="Sửa"
                                                        data-toggle="modal"
                                                        data-target="#add_or_edit_cate"
                                                        onClick={() => cateStore.getDetailCate(item.id)}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.productCateDelete) && <button className="bt_action" title="Xóa"
                                                        onClick={() => cateStore.id = item.id}
                                                        data-toggle="modal" data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Paginate currentPage={cateStore.page} totalPage={cateStore.totalPage}
                                      changePage={changePage}/>
                        </div>
                    </div> : <NoContent/>}
            </>}


            <AddOrEditCate/>

            <PopupConfirm isLoading={cateStore.isLoadingBt} action={cateStore.delete}/>

        </div>
    );
}

export default observer(CatePage);