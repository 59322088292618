import React, {useEffect, useState} from 'react';
import {urlImage} from "../utils/Utils";
import {Icon} from "./Icon";

interface IProps {
    id?: any
    listImg: any[],
    idCarousel?: string
}


const PopupZoom = ({id, idCarousel, listImg}: IProps) => {
    const [list_img, setListImg] = useState<any[]>(listImg)
    const [zoom, setZoom] = useState<boolean>(false)

    useEffect(() => {
        setListImg(listImg)
    }, [listImg])

    return (
        <div className="modal fade popupZoom" id={id ?? `popupZoom`} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <button type="button" className="close_"
                        data-dismiss="modal" aria-label="Close">
                    <Icon name="close" type="round"/>
                </button>
                <div className="modal-content">
                    <div id={idCarousel ?? 'carouselZoomImg'} className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            {listImg && listImg.map((item: any, i) => {
                                return (
                                    <li data-target={`#${idCarousel ?? 'carouselZoomImg'}`} data-slide-to={i} className={`${i === 0 ? 'active' : ''}`}/>
                                )
                            })}
                        </ol>
                        <div className="carousel-inner">
                            {list_img && list_img.map((item: any, i) => {
                                return (
                                    <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                                        <img className={`d-block w-100 ${zoom ? 'zoomOut' : ''}`}
                                             onClick={() => setZoom(!zoom)} src={urlImage(item)} alt=""/>
                                    </div>
                                )
                            })}
                        </div>
                        <a className="carousel-control-prev" href={`#${idCarousel ?? 'carouselZoomImg'}`} role="button" data-slide="prev">
                            <Icon name="arrow_back_ios" type="round"/>
                        </a>
                        <a className="carousel-control-next" href={`#${idCarousel ?? 'carouselZoomImg'}`} role="button" data-slide="next">
                            <Icon name="arrow_forward_ios" type="round"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopupZoom;