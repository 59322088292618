import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {getIDParameter, number_format, urlImage} from "../../common/utils/Utils";
import "./InfoTracking.scss"
import Loading from "../../common/component/Loading";
import {trackingStore} from "../tracking/TrackingStore";
import Content from "./components/Content";
import {trackingBatchStore} from "../tracking/trackingBatch/TrackingBatchStore";
import {profileStore} from "../profile/ProfileStore";
import {dateUtils} from "../../common/utils/DateUtils";

const InfoTracking = () => {

    useEffect(() => {
        window.scroll(0, 0)
        let id: any = getIDParameter();
        trackingBatchStore.getDetailTrackingQr(id)
    }, [])
    let {trackingImage, trackingData} = trackingStore.dataRequest;
    let {info, trackingQrBatch} = trackingBatchStore.dataRequest;

    return (
        <div className="info_access">
            <div className="container pb-4">
                <div className="logo d-flex justify-content-center align-items-center">
                    <img src="/assets/images/logo_access.svg" alt=""/>
                </div>

                {trackingStore.isLoadingDetail ? <Loading/> : <div>
                    <h2>Thông tin sản phẩm</h2>
                    <div className="d-flex info_">
                        <div className="info_unit w-50 pr-4">
                            <div className="info_text">
                                <label>Tên sản phẩm</label>
                                <span>{trackingData?.productInfo?.name}</span>
                            </div>
                            <div className="info_text">
                                <label>Mã lô sản xuất</label>
                                <span>{trackingData?.productInfo?.productionBatch}</span>
                            </div>
                            {profileStore.path == 'truy-xuat' && <div className="info_text">
                                <label>Mã số sản phẩm</label>
                                <span>{trackingQrBatch?.info?.productCode}</span>
                            </div>}
                            <div className="info_text">
                                <label>Số lượng</label>
                                <span>{number_format(trackingData?.productInfo?.quantity)}</span>
                            </div>
                            <div className="info_text">
                                <label>Ngày sản xuất</label>
                                <span>{dateUtils.formatDate(trackingData?.productInfo?.dateManufacture)}</span>
                            </div>
                            <div className="info_text">
                                <label>Hạn sử dụng (Tháng)</label>
                                <span>{trackingData?.productInfo?.expiry}</span>
                            </div>
                            {profileStore.path !== 'xem-ket-qua-truy-xuat' && <>
                                <div className="info_text">
                                    <label>Mã nhận diện</label>
                                    <span>{trackingQrBatch?.info?.productCode}</span>
                                </div>
                                <div className="info_text">
                                    <label>Ngày gửi hàng</label>
                                    <span>{dateUtils.formatDate(trackingQrBatch?.info?.sentDate)}</span>
                                </div>
                                <div className="info_text">
                                    <label>Đơn vị vận chuyển</label>
                                    <span>{trackingQrBatch?.info?.shippingPartner}</span>
                                </div>
                                <div className="info_text">
                                    <label>Mã đơn vị vận chuyển</label>
                                    <span>{trackingQrBatch?.info?.shippingPartnerCode}</span>
                                </div>
                            </>}
                            {
                                info && info.productMore && info.productMore.map((item: any, index: number) => {
                                    return (<div key={index} className="info_text">
                                            <label>{item.key}</label>
                                            <span>{item.value}</span>
                                        </div>
                                    )
                                })

                            }


                        </div>
                        {trackingImage && <div className="w-50 trackingImage pl-4">
                            <img src={urlImage(trackingImage)} alt=""/>
                        </div>}
                    </div>
                    <Content/>
                </div>}
            </div>
        </div>
    );
}

export default observer(InfoTracking);