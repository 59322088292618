import React from 'react';
import {observer} from "mobx-react";
import QRCode from "qrcode.react";
import {toastUtil} from "../../../../common/utils/ToastUtil";
import {number_format, urlImage} from "../../../../common/utils/Utils";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import {dateUtils} from "../../../../common/utils/DateUtils";
import {trackingBatchStore} from "../TrackingBatchStore";


const QRCodeTracking = () => {

    const copyLink = () => {
        var copyText: any = document.getElementById(`path_link`);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        toastUtil.success('Bạn đã sao chép thành công!')
    }


    return (
        <div className="modal fade" id="qr_code_tracking" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: trackingBatchStore.step == 1 ? 554 : 880}} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingBatchStore.step == 1 ? 'Mã truy xuất thông tin' : 'Link tải mã QR'}</h5>
                        <button type="button" className="close" id="close_qr_code" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body d-flex justify-content-center pt-2 mb-3">
                        <div className="form d-flex justify-content-center">
                            {trackingBatchStore.step == 1 && <div className="step_1">
                                <p className="text-center mb-0" style={{fontSize: 16}}>Bạn đang xuất <span
                                    className="color-green font-weight-medium">{number_format(trackingBatchStore.dataRequest.countQrExported)}</span> lượt
                                    ở mã này!</p>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className='text-center position-relative' style={{padding: 25}}>
                                        <QRCode imageSettings={trackingBatchStore.dataRequest.logo} id="download_qrCode"
                                                level="H" value={trackingBatchStore.pageHelper} size={150}/>
                                        <p className="mt-1 mb-0"
                                           style={{fontSize: 12}}>{trackingBatchStore.dataRequest.batchCode}</p>
                                    </div>
                                </div>
                                <div className="d-flex download_qr align-items-center justify-content-center">
                                    <span className="font-weight-medium" style={{fontSize: 14}}>Số QR tải về</span>
                                    <input type="text"
                                           value={trackingBatchStore.quantity ? number_format(trackingBatchStore.quantity) : ''}
                                           onChange={(e: any) => trackingBatchStore.quantity = numberUtil.regexNumber(e)}
                                           className={`text-center ${((!trackingBatchStore.quantity && trackingBatchStore.errors.quantity) || trackingBatchStore.quantity > 1000) ? 'border_error' : ''}`}
                                           placeholder="Tối đa 1.000"
                                           style={{
                                               width: 104,
                                               height: 30,
                                               fontSize: 12,
                                               border: `1px solid #7a7373`,
                                               padding: `0 12px`,
                                               borderRadius: 2,
                                               marginLeft: 14
                                           }}/>
                                </div>
                                <div className="d-flex mt-4 justify-content-center">
                                    <button className="btn send-request" disabled={trackingBatchStore.isLoadingBt}
                                            onClick={() => trackingBatchStore.addLinkDownloadQr()}
                                            style={{width: 200, height: 42}}>Tạo link tải mã
                                    </button>
                                </div>
                            </div>}
                            {trackingBatchStore.step === 2 && <div className="step_2">
                                <p className="text-center mb-1" style={{fontSize: 16}}>Bạn đang xuất <span
                                    className="color-green font-weight-medium">{number_format(trackingBatchStore.dataRequest.countQrExported)}</span> lượt
                                    ở mã này!</p>
                                <div className="link_download_qr">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span
                                            style={{fontSize: 12}}>{dateUtils.formatDate(trackingBatchStore.dataRequest.createdAt, 'DD/MM/YYYY HH:mm')}</span>
                                        <div className="color-green"><span
                                            className="font-weight-medium">{trackingBatchStore.quantity}</span> Mã
                                            QR
                                        </div>
                                    </div>
                                    <div className="d-flex action_ mt-4 align-items-center justify-content-center">
                                        <input type="text" id="path_link" onChange={() => {
                                        }}
                                               value={`${process.env.REACT_APP_API_DOMAIN}/food/v1/tracking_batch/${trackingBatchStore.dataRequest.id}${trackingBatchStore.dataRequest.qrFile}`}/>
                                        <button title="Sao chép" onClick={() => copyLink()}>
                                            <img src="/assets/ico/ico_copy.png" alt=""/>
                                        </button>
                                        <button title="Tải mã" disabled={trackingBatchStore.isLoadingBt}
                                                onClick={async () => await trackingBatchStore.downloadQR(trackingBatchStore.dataRequest.id, trackingBatchStore.dataRequest.qrFile)}>
                                            <img src="/assets/ico/ico_download.png" alt=""/>
                                        </button>
                                    </div>
                                </div>
                                <span className="d-flex justify-content-center mt-4 mb-4 font-italic" style={{
                                    color: 'red',
                                    fontSize: 14
                                }}>Chú ý*: Đường link tải về tồn tại trong 24h</span>
                                <div className="d-flex mt-3 justify-content-center">
                                    <button className="btn send-request-outline mr-4" style={{width: 189, height: 42}}
                                            onClick={() => trackingBatchStore.clearDownloadQR()}>Tạo mã mới
                                    </button>
                                    <button className="btn send-request"
                                            onClick={async () => await trackingBatchStore.getHistoryQr(trackingBatchStore.dataRequest.id)}
                                            data-dismiss="modal" data-toggle="modal" data-target="#history_qr"
                                            style={{width: 189, height: 42}}>Lịch sử
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(QRCodeTracking);