import {getRequest, putRequest, IApiResponse} from "../../common/helpers/RequestHelper";
import {noticeStore} from "./NoticeStore";


class NoticeService {

    public getNotice(): Promise<IApiResponse> {
        return getRequest(`/v1/notification?page=${noticeStore.page}&size=10`)
    }

    public getCountNotice(): Promise<IApiResponse> {
        return getRequest(`/v1/notification/stats`)
    }


    public readAllNotice(): Promise<IApiResponse> {
        return putRequest(`/v1/notification/read_all`, {})
    }

    public readNotice(ids: any): Promise<IApiResponse> {
        return putRequest(`/v1/notification`, {ids: ids})
    }


}


export const noticeService = new NoticeService()