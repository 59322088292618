import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {cateStore} from "./CateStore";

class CateService {

    public getCate(size?: number): Promise<IApiResponse> {
        return getRequest(`/food/v1/category?page=${cateStore.page}&size=${size ?? 10}${cateStore.keyword ? `&keyword=${cateStore.keyword.trim()}` : ''}`);
    }

    public detailCate(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/category/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/category`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/category/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/category/${id}`, {id});
    }

}

export const cateService = new CateService();