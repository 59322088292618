import React from 'react';
import {observer} from "mobx-react";
import {preserveStore} from "../../../../../../preserve/PreserveStore";
import {dateUtils} from "../../../../../../../common/utils/DateUtils";
import {productStore} from "../../../ProductStore";
import './DetailBatchByProductId.scss'
import {number_format} from "../../../../../../../common/utils/Utils";

const DetailBatchByProductId = (): JSX.Element => {
    return (
        <div className="modal modal_full fade" id="detail_batch_by_product_id" role="dialog"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <button type="button" className="close d-flex align-items-center" id="detail_batch_by_product_id"
                            data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                        <p className="m-0">Đóng</p>
                    </button>
                    <div className="d-flex header-log-batch">
                        <span>
                            Số lượng sản xuất :  <span
                            className="fw-600 color-green">{productStore.quantityProduct}</span>
                        </span>
                        <span className="mx-5">
                            Số lượng đã xuất :  <span
                            className="fw-600 color-red">{productStore.quantityProductUsed}</span>
                        </span>
                    </div>
                    <div className="modal-body px-5">
                        <div className="row">
                            <div className="col-6 pr-5">
                                <div className="info mb-5">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green">THÔNG TIN LÔ SẢN XUẤT</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Mã lô sản xuất <span className="text-danger">*</span> : </label>
                                        <span>{preserveStore.dataAddPlanOrderWork.name}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Thời gian hoàn thành <span className="text-danger">*</span> : </label>
                                        <span>{dateUtils.formatDate(Number(preserveStore.dataAddPlanOrderWork.timestartTmp), "HH:MM DD/MM/YYYY")}</span>
                                    </div>
                                    <div className="title_top mt-4 mb-4">
                                        <h5 className="color-green">THÔNG TIN SẢN PHẨM</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Tên sản phẩm <span className="text-danger">*</span> : </label>
                                        <span>{preserveStore.dataAddPlanOrderWork.productName}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48  ">
                                        <label>Quy trình <span className="text-danger">*</span> : </label>
                                        <span>{preserveStore.dataAddPlanOrderWork.procedureName}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>Số lượng dự kiến: </label>
                                        <span>{number_format(preserveStore.dataAddPlanOrderWork.quantity)}</span>
                                    </div>
                                    <div className="title_top mt-4 mb-4">
                                        <h5 className="color-green">NGƯỜI PHỤ TRÁCH</h5>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>SẢN XUẤT: </label>
                                        <span>
                                            {preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[0]?.selectPersonInCharge.map((item, index) => {
                                                return <span>
                                                {
                                                    index !== 0 ?
                                                        `, ${item.label}` : ` ${item.label} `}
                                            </span>
                                            })}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48  ">
                                        <label>ĐÓNG GÓI: </label>
                                        <span>{preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[1]?.selectPersonInCharge.map((item, index) => {
                                            return <span>
                                                {
                                                    index !== 0 ?
                                                        `, ${item.label}` : ` ${item.label} `}
                                            </span>
                                        })}</span>
                                    </div>
                                    <div className="d-flex flex-wrap item_info  h-48">
                                        <label>XUẤT - NHẬP KHO: </label>
                                        <span>{preserveStore.dataAddPlanPersonInCharge && preserveStore.dataAddPlanPersonInCharge[2]?.selectPersonInCharge.map((item, index) => {
                                            return <span>
                                               {
                                                   index !== 0 ?
                                                       `, ${item.label}` : ` ${item.label} `}
                                            </span>
                                        })}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 pl-5">
                                <div className="info">
                                    <div className="title_top mb-4 d-flex">
                                        <h5 className="color-green">THÀNH PHẦN</h5>
                                        <h5 className="color-green batch-log">LÔ SỬ DỤNG</h5>
                                    </div>
                                    {
                                        productStore.listDataSuppliesLogBatchByProcedureBatch && productStore.listDataSuppliesLogBatchByProcedureBatch.map((item, index) => {
                                            return (
                                                <div className="d-flex flex-wrap item_info  h-48">
                                                    <label>{`${index + 1}. ${item.supplies.name}`}</label>
                                                    <div>
                                                        {item.batchLogs && item.batchLogs.map((itemBatch, indexBatch) => {
                                                            return <span
                                                                key={indexBatch}>{itemBatch.batch.batchCode}
                                                                <span>
                                                                    {indexBatch === item.batchLogs.length - 1 ? ` (${number_format(itemBatch.quantity)})` : ` (${number_format(itemBatch.quantity)}); `}

                                                                </span>
                                                            </span>
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(DetailBatchByProductId);
