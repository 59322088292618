import React from 'react';


const Loading = ({height}: any) => {

    return (
        <div className="d-flex align-items-center justify-content-center border" style={{height: height ?? 450}}>
            <div className="dot-opacity-loader my-5">
                <i className="fa fa-spinner fa-spin color-green" style={{fontSize: 42}}/>
            </div>
        </div>
    )
}

export default Loading
