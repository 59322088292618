import React from "react";
import StepWizard from "react-step-wizard";
import {Step, Stepper} from "react-form-stepper";
import './Wizard.scss'
import {detailBatchStore} from "../../../warehouse/expImpBatch/detailEIBatch/DetailEIBatchStore";
import {preserveStore} from "../../PreserveStore";
import AddPlanOrderWork from "../addPlanOrderWork/AddPlanOrderWork";
import AddPlanPersonInCharge from "../addPlanPersonInCharge/AddPlanPersonInCharge";
import AddPlanQuantity from "../addPlanQuantity/AddPlanQuantity";
import 'animate.css';
import {observer} from "mobx-react";
import Loading from "../../../../common/component/Loading";

export const ActionButtons = (props: any) => {
    const handleBack = () => {
        props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
        props.lastStep();
    };

    return (
        <div className="d-flex justify-content-end">
            {props.currentStep > 1 &&
                <button type="button" className="btn button-close-export-batch mt-3 mr-2" onClick={handleBack}>
                    Quay lại
                </button>}
            {props.currentStep < props.totalSteps &&
                <button type="button" className="btn button-add-plan-order-work mt-3"
                        onClick={handleNext}
                        disabled={detailBatchStore.isLoadingBt}
                >Tiếp tục
                </button>}
            {props.currentStep === props.totalSteps &&
                <button type="button" className="btn button-add-plan-order-work mt-3"
                        onClick={handleFinish}
                        disabled={detailBatchStore.isLoadingBt}
                >Hoàn thành
                </button>}
        </div>
    );
};


const WizardAddPlan = () => {

    const custom = {
        enterRight: 'animated fadeIn',
        enterLeft: 'animated fadeIn',
        exitRight: 'animated fadeOut',
        exitLeft: 'animated fadeOut'
    }
    const assignStepWizard = (instance: any) => {
        preserveStore.currentStep = instance
    };

    const setDataOrderWork = (val: any) => {
        preserveStore.dataRequestAddPlan = {
            ...preserveStore.dataRequestAddPlan,
            dataOrderWork: val
        }
    };

    const setDataPersonInCharge = (val: any) => {
        preserveStore.dataRequestAddPlan = {
            ...preserveStore.dataRequestAddPlan,
            dataPersonInCharge: val
        }
    };


    const handleStepChange = (e: any) => {
        preserveStore.currentStep = e.activeStep - 1
    };

    const handleComplete = async () => {
        preserveStore.dataRequestAddPlan = {
            ...preserveStore.dataRequestAddPlan,
            dataQuantity: preserveStore.dataAddPlanQuantity
        }
        await preserveStore.submitAddPlanPreserve()
    };


    return (
        <div className="w-100">
            <Stepper activeStep={preserveStore.currentStep}>
                <Step children={preserveStore.currentStep > 0 ? <img src="/assets/ico/action/check-full.svg" alt=""/> :
                    <img src="/assets/ico/action/progress.svg" alt=""/>}/>
                <Step children={preserveStore.currentStep > 1 ?
                    <img src="/assets/ico/action/check-full.svg" alt=""/> : preserveStore.currentStep === 1 ?
                        <img src="/assets/ico/action/progress.svg" alt=""/> :
                        <img src="/assets/ico/action/check-grey.svg" alt=""/>}/>
                <Step children={preserveStore.currentStep === 2 ? <img src="/assets/ico/action/progress.svg" alt=""/> :
                    <img src="/assets/ico/action/check-grey.svg" alt=""/>}/>
            </Stepper>
            {preserveStore.resetStep ? <Loading/> :
                <StepWizard transitions={custom} instance={assignStepWizard} onStepChange={handleStepChange}>
                    <AddPlanOrderWork userCallback={setDataOrderWork}/>
                    <AddPlanPersonInCharge userCallback={setDataPersonInCharge}/>
                    <AddPlanQuantity completeCallback={handleComplete}/>
                </StepWizard>}
        </div>
    );
};

export default observer(WizardAddPlan);
