import {observable} from "mobx";
import $ from "jquery";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {cateService} from "./CateService";


class CateStore {
    @observable id: any = ''
    @observable type: any = ''
    @observable keyword: any = ''
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: "",
        name: ""
    }
    @observable listCate: any[] = []


    clearForm() {
        cateStore.type = 'add'
        cateStore.dataRequest = {
            id: "",
            name: ""
        }
    }



    async getCate(size?: number) {
        cateStore.isLoading = true
        const result = await cateService.getCate(size)
        cateStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            cateStore.listCate = result.body.data
            cateStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetailCate(id: any) {
        cateStore.type = 'edit'
        const result = await cateService.detailCate(id)
        if (result.status === HttpStatusCode.OK) {
            cateStore.dataRequest = result.body
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async add() {
        let {name} = cateStore.dataRequest

        let data = {
            name: name,
        }

        if(!name){
            toastUtil.warning('Tên danh mục không được để trống!')
            return
        }

        cateStore.isLoadingBt = true
        const result = await cateService.create(data)
        cateStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thiết lập phẩm thành công', 3)
            $('#close_add_edit').trigger('click')
            cateStore.clearForm()
            await cateStore.getCate()
        }else {
            toastUtil.error(result.body.message, 4)
        }

    }

    async update() {
        let {id, name} = cateStore.dataRequest

        let data = {
            name: name,
        }

        if(!name){
            toastUtil.warning('Tên danh mục không dc để trống!')
            return
        }

        cateStore.isLoadingBt = true
        const result = await cateService.update(id, data)
        cateStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật danh mục sản phẩm thành công', 3)
            $('#close_add_edit').trigger('click')
            cateStore.clearForm()
            await cateStore.getCate()
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async delete() {
        cateStore.isLoadingBt = true
        const result = await cateService.delete(cateStore.id)
        cateStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa danh mục sản phẩm thành công', 3)
            await cateStore.getCate()
        }else {
            toastUtil.error(result.body.message, 4)
        }
    }
}

export const cateStore = new CateStore()