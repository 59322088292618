import React, {useEffect} from 'react';
import {checkPermission, sttPage} from "../../../common/utils/Utils";
import Paginate from "../../../common/component/Paginate";
import ButtonAdd from "../../../common/component/buttonAdd/ButtonAdd";
import {observer} from "mobx-react";
import NoContent from "../../../common/component/NoContent";
import Loading from "../../../common/component/Loading";
import {dateUtils} from "../../../common/utils/DateUtils";
import {PermissionConst} from "../../../common/constants/Constants";
import {accountStore} from "./AccountStore";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import ModalConfirmLockAccount from "./components/modalConfirmLockAccount/ModalConfirmLockAccount";
import AddEditAccount from "./components/AddEditAccount/AddEditAccount";
import './AccountPage.scss'
import {roleStore} from "../roles/RoleStore";

const AccountPage = () => {

    const changePage = async (data: any) => {
        accountStore.page = data.selected;
        await accountStore.getAccount();
    }

    const switchType = (type: any) => {
        switch (type) {
            case "MANUFACTURE":
                return "Sản xuất"
            case "PACK":
                return "Đóng gói"
            case "WAREHOUSE":
                return "Kho"
        }
    }

    useEffect(() => {
        accountStore.getAccount().then()
        roleStore.getRoles(40).then()
        accountStore.dataFilter = [
            {
                label: 'Họ và tên',
                type: 'search',
                placeholder: 'Nhập họ và tên',
                value: accountStore.keyword,
                onChange: (e: any) => {
                    accountStore.keyword = e
                },
                callback: async () => {
                    await accountStore.getAccount();
                }
            }
        ]
    }, [])


    return (
        <div className='account'>
            <FilterComponent label={"Tài khoản"} data={accountStore.dataFilter}
                             permissionCode={checkPermission(PermissionConst.accountAdd)}
                             buttonAdd={<ButtonAdd text="Thêm tài khoản" dataTarget={'add_edit_account'}
                                                   onClick={accountStore.clearForm}/>}/>
            {accountStore.isLoading ? <Loading/> : <>
                {accountStore.listAccount && accountStore.listAccount.length > 0 ?
                    <div className='table-responsive'>
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th className="text-center">ID Đăng nhập</th>
                                <th>Họ và tên</th>
                                <th className="text-center">Chức vụ</th>
                                <th className="text-center">Ngày sinh</th>
                                <th className="text-center">Trạng thái</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>

                            {accountStore.listAccount && accountStore.listAccount.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(accountStore.page, i)}</td>
                                        <td className="text-center">{item?.phone}</td>
                                        <td>{item?.name}</td>
                                        <td className="text-center">{item.roles && item.roles.map((val: any, index:number) => <span key={index}>{val.name}{index+1 === item.roles.length ? '' : ','}</span>)}</td>
                                        <td className="text-center">{item?.birthday ? dateUtils.formatDate(item.birthday) : '-'}</td>
                                        <td className={`text-center ${item?.status === "ACTIVE" ? "account_active" : "account_block"}`}>{item?.status === "ACTIVE" ? "Hoạt Động" : "Đã khóa"}</td>
                                        <td className="text-center">
                                            <div className="action">
                                                {checkPermission(PermissionConst.accountUpdate) && <button className="bt_action" title={'Sửa'}
                                                        onClick={async () => {
                                                            accountStore.typeModal = 'edit'
                                                            await accountStore.getDetailAccount(item.id)
                                                        }} data-toggle="modal" data-target="#add_edit_account">
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.approveAccount) && <>
                                                    {
                                                        item?.status === "ACTIVE" ? (
                                                            <button className="bt_action" title={'Khóa tài khoản'}
                                                                    data-toggle="modal"
                                                                    data-target="#popup_confirm_lock"
                                                                    onClick={() => {
                                                                        accountStore.typeConfirmLock = 'lock'
                                                                        accountStore.getDetailAccount(item.id)
                                                                    }}>
                                                                <img src="/assets/ico/action/lock.svg" alt=""/>
                                                            </button>) : (
                                                            <button className="bt_action" title={'Mở khóa tài khoản'}
                                                                    data-toggle="modal"
                                                                    data-target="#popup_confirm_lock"
                                                                    onClick={() => {
                                                                        accountStore.typeConfirmLock = 'unlock'
                                                                        accountStore.getDetailAccount(item.id)
                                                                    }}>
                                                                <img src="/assets/ico/action/unlock.svg" alt=""/>
                                                            </button>
                                                        )
                                                    }
                                                </>}

                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Paginate currentPage={accountStore.page} totalPage={accountStore.totalPage}
                                      changePage={changePage}/>
                        </div>
                    </div> : <NoContent/>}
            </>}

            <button id="account_two" data-toggle="modal" data-target="#add_account_two" style={{opacity: 0}}/>

            <AddEditAccount/>
            <ModalConfirmLockAccount type={accountStore.typeConfirmLock}/>
        </div>
    )

}


export default observer(AccountPage)