import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {ITabDetailPlanPreserve, preserveStore} from "../../PreserveStore";
import TabHeaderDetailPlan from "./TabHeaderDetailPlan";
import PlanProduction from "./PlanProduction";
import TrackingPlan from "./TrackingPlan";
import {getIDParameter, getUrlParameter} from "../../../../common/utils/Utils";
import {Link} from "react-router-dom";

const DetailPlan = (): JSX.Element => {
    useEffect(() => {
        preserveStore.id = Number(getIDParameter(2));
        preserveStore.tabActiveDetailPlanPreserve = getUrlParameter('tab') ?? ITabDetailPlanPreserve.plan
    }, [])
    return (
        <div className="batch_supplies">
            <div className="d-flex header_back align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <Link
                        to={`/planing`}>Lô sản xuất</Link>
                    <i className="fal fa-angle-right"/>
                    <span>{preserveStore.dataAddPlanOrderWork.name}</span>
                </div>
            </div>
            <TabHeaderDetailPlan/>
            <div className='table-responsive'>
                {preserveStore.tabActiveDetailPlanPreserve === ITabDetailPlanPreserve.plan &&
                    <PlanProduction/>}
                {preserveStore.tabActiveDetailPlanPreserve === ITabDetailPlanPreserve.tracking &&
                    <TrackingPlan/>}
            </div>
        </div>

    )

}

export default observer(DetailPlan);
