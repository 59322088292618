import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import {profileStore} from "../profile/ProfileStore";
import LoginPage from "../auth/login/LoginPage";
import InfoTracking from "../infoTracking/InfoTracking";
import InfoTrackingBatchCode from "../infoTracking/InfoTrackingBatchCode";
import PreviewTracking from "../infoTracking/PreviewTracking";

export default function RedirectAuth() {


    if(profileStore.roleUser == 'admin'){
        return(
            <Routes>
                <Route path={`/cms/login`} element={<LoginPage/>}/>
                <Route path="*" element={<Navigate to="/cms/login" replace/>} />
            </Routes>
        )
    }else {
        return (
            <Routes>
                <Route path={`/login`} element={<LoginPage/>}/>
                <Route path={`/xem-ket-qua-truy-xuat/:slug/:id`} element={<PreviewTracking/>}/>
                <Route path={`/truy-xuat/:slug/:batchCode`} element={<InfoTracking/>}/>
                <Route path={`/truy-xuat-lo/:slug/:trackingId/:trackingBatchId`} element={<InfoTrackingBatchCode/>}/>
                <Route path="*" element={<Navigate to="/login" replace/>} />
            </Routes>
        )
    }

}