import {getRequest, postRequest, putRequest, deleteRequest, IApiResponse} from "../../../../common/helpers/RequestHelper";
import {batchStore} from "./BatchStore";

class BatchService {

    public getBatch(id: any, size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/batch?suppliesId=${id}&page=${batchStore.page}&size=${size ?? 10}${batchStore.keyword ? `&batchCode=${batchStore.keyword}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/batch/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/batch`, data);
    }

    public getHistory(id: any, size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/batch/logs?suppliesId=${id}&page=${batchStore.page}&size=${size ?? 10}${batchStore.keyword ? `&batchCode=${batchStore.keyword}` : ''}`);
    }

    public export(id: any, data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/batch/${id}/inventory`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/batch/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/batch/${id}?suppliesId=${batchStore.suppliesId}`, {id});
    }

}

export const batchService = new BatchService()