import {observable} from "mobx";
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";
import $ from "jquery";
import {toastUtil} from "../../../../common/utils/ToastUtil";
import {detailBatchService} from "./DetailEIBatchService";
import {expImpBatchStore, IListDataDetailEIBatch} from "../EIBatchStore";


export interface IDataRequestImportProduct {
    quantity?: string | number;
    note?: string;
}

export interface IDataRequestExportBatchBody {
    type: string,
    state: string,
    procedureBatchId?: number | string
    suppliesInformation?:
        {
            id: number | string,
            quantityRequest: number | string,
            batchRequest: any[]
        }[]

    note?: string
    reasonReject?: string
}


export interface IListDataExportBatch {
    id: number,
    name: string,
    unit: string,
    request: string | number,
    selected: string | number,
    selectedBatch: { value: number, index: number }[],
    suppliesInformation: {
        id: number | string,
        nameBatch: number | string,
        quantity: number | string,
        errQuantity: boolean,
        err: boolean,
        errBatch: boolean,
        maxQuantity: number | string,
        options: { id: number, name: string, maxQuantity: number }[]
        icon?: string
    }[]
}

class DetailEIBatchStore {
    @observable id: number = -1
    @observable idProcedure: number = -1
    @observable isLoading: boolean = false
    @observable isLoadingGetDetail: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable quantityImportSigleBatch: number | string = ""

    @observable isGoToAccept: boolean = false
    @observable isGoToFinish: boolean = false

    @observable totalRemaining: number = 0

    @observable listDataDetailEIBatch: IListDataDetailEIBatch = {
        id: -1,
        nameProduct: "",
        nameCategory: "",
        nameProcedure: "",
        quantityProductRequest: "",
        productQuantityExecution: "",
        quantity: "",
        startTime: "",
        ingrendientExported: [],
        procedureBatch: {
            id: "",
            name: ""
        },
        status: "",
        type: "",
        batchType: "",
        reasonReject: "",
        note: "",
        sender: {
            name: "",
            department: "",
            createdAt: ""
        },
        receiver: {
            name: "",
            department: "",
            createdAt: ""
        },
        importRecordData: [],
        timeRequest: "",
        timeHandle: "",
        typeCreated: ""
    }

    @observable dataRequestExportBatch: IDataRequestExportBatchBody = {
        type: "",
        state: "",
        procedureBatchId: "",
        suppliesInformation: [
            {
                id: "",
                quantityRequest: "",
                batchRequest: []
            }
        ],
        note: ""
    }


    @observable listDataExportBatch: IListDataExportBatch[] = []


    @observable public errorsImportProduct: any = {
        quantity: '',
        note: ''
    }
    @observable public errorsImportSurplus: any = {
        surplus: '',
        note: '',
        quantityMax: '',
        quantity: ''
    }
    @observable public errorsExportBatch: any = {
        batch: '',
        note: '',
        quantityMax: '',
        quantity: ''
    }
    @observable public errorsCancelRequestEI: any = {
        note: ''
    }

    clearFormData() {
        detailBatchStore.dataRequestExportBatch = {
            type: "",
            state: "",
            procedureBatchId: "",
            suppliesInformation: [
                {
                    id: "",
                    quantityRequest: "",
                    batchRequest: []
                }
            ],
            note: ""
        }
        detailBatchStore.errorsImportProduct = {
            quantity: '',
            note: ''
        }
        detailBatchStore.errorsImportSurplus = {
            surplus: '',
            note: ''
        }
        detailBatchStore.errorsExportBatch = {
            batch: '',
            note: ''
        }
        detailBatchStore.errorsCancelRequestEI = {
            note: ''
        }
    }

    async submitImportProduct() {
        if (!detailBatchStore.quantityImportSigleBatch) {
            detailBatchStore.errorsImportProduct.quantity = `Số lượng sản phẩm không được để trống!`
            return
        } else detailBatchStore.errorsImportProduct.quantity = ''

        detailBatchStore.dataRequestExportBatch.note = detailBatchStore.dataRequestExportBatch.note?.trim()
        if (!detailBatchStore.dataRequestExportBatch.note) {
            detailBatchStore.errorsExportBatch.note = `Ghi chú không được để trống!`
            return
        } else detailBatchStore.errorsExportBatch.note = ''
        const data: any = {
            type: "IMPORT",
            procedureBatchId: detailBatchStore.listDataDetailEIBatch.procedureBatch.id,
            quantity: Number(detailBatchStore.quantityImportSigleBatch),
            note: detailBatchStore.dataRequestExportBatch.note
        }
        detailBatchStore.isLoadingBt = true

        const result = await detailBatchService.importProduct(expImpBatchStore.id, data)
        detailBatchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Nhập kho sản phẩm thành công', 3)
            $('#import_product').trigger('click')
            detailBatchStore.idProcedure = expImpBatchStore.id
            await expImpBatchStore.getWarehouseRequest()
            await detailBatchService.getBatchById()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async submitImportBatchSurplus() {
        const data: IDataRequestExportBatchBody = {
            type: "IMPORT",
            state: "APPROVED",
            procedureBatchId: detailBatchStore.listDataDetailEIBatch.procedureBatch.id,
            suppliesInformation: detailBatchStore.listDataExportBatch.map((item, index) => {
                return {
                    id: item.id,
                    quantityRequest: item.request,
                    batchRequest: item.suppliesInformation.filter(x => x.id).map((itemBatch, indexBatch) => {
                        return {
                            id: itemBatch.id,
                            quantity: Number(itemBatch.quantity)
                        }
                    })
                }
            }),
            note: detailBatchStore.dataRequestExportBatch.note
        }
        detailBatchStore.isLoadingBt = true

        const result = await detailBatchService.importExportBatch(data, Number(expImpBatchStore.id))
        detailBatchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Xuất dư thành công', 3)
            $('#surplus-product').trigger('click')
            detailBatchStore.isGoToFinish = true
            detailBatchStore.isGoToAccept = false
            detailBatchStore.idProcedure = expImpBatchStore.id
            await detailBatchService.getBatchById()
            await expImpBatchStore.getWarehouseRequest()
            await expImpBatchStore.getDetailWarehouseRequest(Number(expImpBatchStore.id))

        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    validateLink = () => {
        detailBatchStore.listDataExportBatch.forEach((item, index) => {
            item.suppliesInformation.forEach((itemSupply, indexSupply) => {
                itemSupply.errBatch = !itemSupply.id
            })
        })
        let checkErrorExport = detailBatchStore.listDataExportBatch.map((item, index) => {
            return item.suppliesInformation.map(item => item.errBatch)
                .every(item => item);
        }).every(item => item);
        if (checkErrorExport) {
            return false
        }

        let checkErrQuantity = detailBatchStore.listDataExportBatch.map((item, index) => {
            return item.suppliesInformation.map(item => item.err)
                .includes(true);
        }).includes(true);
        if (checkErrQuantity) {
            return false
        }
        return detailBatchStore.dataRequestExportBatch.note;
    }
    validateExportBatch = () => {
        detailBatchStore.listDataExportBatch.forEach((item, index) => {
            item.suppliesInformation.forEach((itemSupply, indexSupply) => {
                itemSupply.errBatch = !itemSupply.id
            })
        })

        let checkErrorExport = detailBatchStore.listDataExportBatch.map((item, index) => {
            return item.suppliesInformation.map(item => item.errBatch)
                .every(item => item);
        }).every(item => item);

        if (checkErrorExport) {
            detailBatchStore.errorsExportBatch.batch = `Bạn chưa chọn lô sản xuất nào !`
            detailBatchStore.errorsExportBatch.quantity = ''
            detailBatchStore.errorsExportBatch.note = ''
            return false
        } else detailBatchStore.errorsExportBatch.batch = ''


        detailBatchStore.listDataExportBatch.map((item, index) => {
            item.suppliesInformation.map((itemSupply, indexSupply) => {
                if (itemSupply.id)
                    itemSupply.err = !itemSupply.quantity
            })
        })

        let checkErrQuantity = detailBatchStore.listDataExportBatch.map((item, index) => {
            return item.suppliesInformation.map(item => item.err)
                .includes(true);
        }).includes(true);
        if (checkErrQuantity) {
            detailBatchStore.errorsExportBatch.quantity = `Vui lòng nhập số lượng đầy đủ !`
            detailBatchStore.errorsExportBatch.note = ''
            return false
        } else detailBatchStore.errorsExportBatch.quantity = ""


        detailBatchStore.dataRequestExportBatch.note = detailBatchStore.dataRequestExportBatch.note?.trim()
        if (!detailBatchStore.dataRequestExportBatch.note) {
            detailBatchStore.errorsExportBatch.note = `Ghi chú không được để trống!`
            return false
        } else {
            detailBatchStore.errorsExportBatch.note = ''
        }
        return true
    }
    validateImportBatch = () => {
        detailBatchStore.listDataExportBatch.forEach((item, index) => {
            item.suppliesInformation.forEach((itemSupply, indexSupply) => {
                itemSupply.errBatch = !itemSupply.id
            })
        })
        let check = detailBatchStore.listDataExportBatch.map((item, index) => {
            return item.suppliesInformation.map(item => item.errBatch)
                .every(item => item);
        }).every(item => item);

        if (check) {
            detailBatchStore.errorsImportSurplus.surplus = `Bạn chưa chọn lô sản xuất nào !`
            detailBatchStore.errorsImportSurplus.note = ''
            detailBatchStore.errorsImportSurplus.quantity = ""
            return false
        } else detailBatchStore.errorsImportSurplus.surplus = ''

        detailBatchStore.listDataExportBatch.map((item, index) => {
            item.suppliesInformation.map((itemSupply, indexSupply) => {
                if (itemSupply.id)
                    itemSupply.err = !itemSupply.quantity
            })
        })
        let checkErrQuantity = detailBatchStore.listDataExportBatch.map((item, index) => {
            return item.suppliesInformation.map(item => item.err)
                .includes(true);
        }).includes(true);
        if (checkErrQuantity) {
            detailBatchStore.errorsImportSurplus.quantity = `Vui lòng nhập số lượng đầy đủ !`
            detailBatchStore.errorsImportSurplus.surplus = ''
            detailBatchStore.errorsImportSurplus.note = ''
            return false
        } else detailBatchStore.errorsImportSurplus.quantity = ""


        detailBatchStore.dataRequestExportBatch.note = detailBatchStore.dataRequestExportBatch.note?.trim()
        if (!detailBatchStore.dataRequestExportBatch.note) {
            detailBatchStore.errorsImportSurplus.note = `Ghi chú không được để trống!`
            return false
        } else detailBatchStore.errorsImportSurplus.note = ''
        return true
    }


    async submitExportBatch() {
        const data: IDataRequestExportBatchBody = {
            type: "EXPORT",
            state: "APPROVED",
            procedureBatchId: detailBatchStore.listDataDetailEIBatch.procedureBatch.id,
            suppliesInformation: detailBatchStore.listDataExportBatch.map((item, index) => {
                return {
                    id: item.id,
                    quantityRequest: item.request,
                    batchRequest: item.suppliesInformation.filter(x => x.id).map((itemBatch, indexBatch) => {
                        return {
                            id: itemBatch.id,
                            quantity: Number(itemBatch.quantity)
                        }
                    })
                }
            }),
            note: detailBatchStore.dataRequestExportBatch.note
        }
        detailBatchStore.isLoadingBt = true

        const result = await detailBatchService.importExportBatch(data, expImpBatchStore.id)
        detailBatchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Xuất kho thành công', 3)
            $('#export_batch_product').trigger('click')
            detailBatchStore.isGoToFinish = true
            detailBatchStore.isGoToAccept = false
            detailBatchStore.idProcedure = expImpBatchStore.id
            await detailBatchService.getBatchById()
            await expImpBatchStore.getWarehouseRequest()
            await expImpBatchStore.getDetailWarehouseRequest(Number(expImpBatchStore.id))
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async submitCancelEIBatch() {
        detailBatchStore.dataRequestExportBatch.note = detailBatchStore.dataRequestExportBatch.note?.trim()
        if (!detailBatchStore.dataRequestExportBatch.note) {
            detailBatchStore.errorsCancelRequestEI.note = `Ghi chú không được để trống!`
            return
        } else detailBatchStore.errorsCancelRequestEI.note = ''
        const data = detailBatchStore.dataRequestExportBatch.note
        detailBatchStore.isLoadingBt = true

        const result = await detailBatchService.cancelEIBatch(data, Number(expImpBatchStore.id))
        detailBatchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Từ chối thành công', 3)
            $('#cancel-export-batch').trigger('click')
            await expImpBatchStore.getWarehouseRequest()
            await expImpBatchStore.getDetailWarehouseRequest(Number(expImpBatchStore.id))
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
}

export const detailBatchStore = new DetailEIBatchStore()
