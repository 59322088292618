import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {
    checkPermission,
    getIDParameter,
    getUrlParameter,
    number_format,
    slug
} from "../../../../../../common/utils/Utils";
import TabHeaderDetailProduct from "./TabHeaderDetailProduct";
import InventoryPageDetailProduct from "./InventoryPageDetailProduct";
import {ITabDetailProduct, productStore} from "../../ProductStore";
import HistoryPageDetailProduct from "./HistoryPageDetailProduct";
import './DetailProduct.scss'
import AddOrEditProduct from "../addOrEditProduct/AddOrEditProduct";
import {PermissionConst} from "../../../../../../common/constants/Constants";

const DetailProduct = () => {
    useEffect(() => {
        productStore.productId = Number(getIDParameter(5));
        productStore.tabActiveDetailProduct = getUrlParameter('tab') ?? ITabDetailProduct.inventory
        productStore.getDetailProduct(productStore.productId)
    }, [])

    const listPack = productStore.listDataProduct?.filter((item, index) => {
        return item.type === 'Bao bì'
    })
    const listIngredient = productStore.listDataProduct?.filter((item, index) => {
        return item.type === 'Kho nguyên liệu'
    })

    const listAdditives = productStore.listDataProduct?.filter((item, index) => {
        return item.type === 'Kho phụ gia'
    })

    return (
        <div className="batch_supplies">
            <TabHeaderDetailProduct/>
            <div className="infor-detail-product-container">
                <div className="d-flex header_back align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <Link
                            to={`/warehouse/category/${slug(productStore.categoryName)}/${productStore.categoryId}`}>Sản
                            phẩm</Link>
                        <i className="fal fa-angle-right"/>
                        <span>{productStore.productName}</span>
                    </div>
                    {productStore.tabActiveDetailProduct === ITabDetailProduct.inventory && <div className="d-flex">
                        {checkPermission(PermissionConst.productUpdate) &&
                            <div className="edit_product_container">
                                <img src="/assets/ico/action/clipboard-edit.svg" alt="" data-toggle="tooltip"
                                     data-placement="top"
                                     className="icon-action"
                                     title="Sửa sản phẩm"/>
                                <button className="edit_product_button"
                                        data-toggle="modal"
                                        data-target="#add_or_edit_product"
                                        onClick={async () => {
                                            productStore.clearForm()
                                            await productStore.getDetailProduct(productStore.productId).then(() => {
                                                productStore.type = "edit"
                                                productStore.id = productStore.productId

                                            })
                                        }}>
                                    Sửa Sản Phẩm
                                </button>
                            </div>}
                        <div className="edit_product_container">
                            <img src="/assets/ico/action/Group.svg" alt="" data-toggle="tooltip"
                                 data-placement="top"
                                 className="icon-action"
                                 title="Quay Lại"/>
                            <Link
                                to={`/warehouse/category/${slug(productStore.categoryName)}/${productStore.categoryId}`}>
                                <div className="back-product mx-2">Quay Lại</div>
                            </Link>
                        </div>
                    </div>}
                </div>
                {productStore.tabActiveDetailProduct === ITabDetailProduct.inventory &&
                    <div className="infor-detail-eibatch px-1">
                        <div className="d-flex">
                            <div>
                                <span className="fw-600 mr-2">Hạn sử dụng:</span>
                                <span>{productStore?.dataRequest?.expireMonth} (Tháng)</span>
                            </div>
                            <div>
                                <span className="fw-600 ml-5">Tổng còn : </span>
                                <span>{productStore?.quantityRemaining ? number_format(productStore.quantityRemaining) : 0}</span>
                            </div>
                            <div>
                                <span className="fw-600 ml-5">Đơn vị : </span>
                                <span>{productStore?.dataRequest?.unit}</span>
                            </div>

                        </div>
                        <div className="d-flex">
                            <span
                                className="fw-600 mr-3">Thành phần:
                            </span>
                            <div className="d-flex flex-column">
                                {listPack.length > 0 && <span>
                                <span
                                    className="fw-600">Bao bì</span>: {listPack.map((item, index) => {
                                    return <span>
                                    {index === 0 ? `${item.supplies}` : `, ${item.supplies}`}
                                </span>
                                })}</span>}

                                {productStore.listDataEquipmentProduct.length > 0 && <span
                                ><span
                                    className="fw-600">Máy móc</span>: {productStore.listDataEquipmentProduct?.map((item, index) => {
                                    return <span>
                                     {index === 0 ? `${item.supplies}` : `, ${item.supplies}`}
                                </span>
                                })}</span>}

                                {listIngredient.length > 0 && <span
                                ><span
                                    className="fw-600">Nguyên liệu</span>: {listIngredient.map((item, index) => {
                                    if (item.type === 'Kho nguyên liệu') return <span>
                                     {index === 0 ? `${item.supplies}` : `, ${item.supplies}`}
                                </span>
                                })}</span>}

                                {listAdditives.length > 0 && <span
                                ><span
                                    className="fw-600">Phụ gia</span>: {listAdditives.map((item, index) => {
                                    if (item.type === 'Kho phụ gia') return <span>
                                     {index === 0 ? `${item.supplies}` : `, ${item.supplies}`}
                                </span>
                                })}</span>}

                            </div>
                        </div>
                    </div>}
            </div>
            <div className='table-responsive'>
                {productStore.tabActiveDetailProduct === ITabDetailProduct.inventory && <InventoryPageDetailProduct/>}
                {productStore.tabActiveDetailProduct === ITabDetailProduct.history && <HistoryPageDetailProduct/>}
            </div>
            <AddOrEditProduct/>

        </div>
    );
}

export default observer(DetailProduct)
