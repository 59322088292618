import React from 'react';
import {observer} from "mobx-react";
import {uploadImgHelper} from "../../../common/helpers/UploadImgHelper";
import {urlImage} from "../../../common/utils/Utils";
import {trackingStore} from "../TrackingStore";

const UploadImages = () => {


    const changeImg = (e: any) => {
        trackingStore.dataRequest.trackingImage = e
    }

    const uploadImg = (e: any) =>  {
        trackingStore.dataRequest.images.push(e)
    }


    return (
        <div className="modal fade" id="upload_images_access" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingStore.dataRequest.procedureBatch?.name ?? trackingStore.dataRequest.trackingData?.productInfo?.name}</h5>
                        <button type="button" className="close" id="close_upload_images" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="images_product">
                            <div className="images">
                                {trackingStore.dataRequest.trackingImage ?
                                    <img className="img_" src={urlImage(trackingStore.dataRequest.trackingImage)} alt=""/> :
                                    <div className="no_image">
                                        <img src="/assets/ico/ico_album.svg" alt=""/>
                                    </div>
                                }
                                <div className="upload">
                                    <img src="/assets/ico/ico_camera.svg" alt=""/>
                                    <span>Ảnh sản phẩm</span>
                                    <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                           onChange={async (e: any) => await uploadImgHelper.onChangeFileThumb(e, changeImg)}/>
                                </div>
                            </div>
                        </div>

                        <h4>Hình ảnh sản xuất & Kinh doanh</h4>
                        <div className="list_image">
                            <div className="list_">
                                <div className="upload" title="Thêm ảnh nông trường & sản phẩm">
                                    <img src="/assets/ico/ico_cloud_upload.svg" alt=""/>
                                    <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                           onChange={async (e: any) => await uploadImgHelper.onChangeFileThumb(e, uploadImg)}/>
                                </div>
                                {trackingStore.dataRequest.images && trackingStore.dataRequest.images.map((item: any, i: number) => {
                                    return (
                                        <div className="item" key={i}>
                                            <img className="img" src={urlImage(item)} alt=""/>
                                            <div className="close_item"
                                                 onClick={() => trackingStore.dataRequest.images.splice(i, 1)}>
                                                <img src="/assets/ico/ico_close.svg" alt=""/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pt-0">
                        <button type="button" className="btn send-request"
                                onClick={() => trackingStore.uploadImages()}
                                disabled={trackingStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(UploadImages);