import React from 'react';

interface IProps {
    name: string,
    id?: any,
    size?: any,
    type?: "outlined" | "round" | "sharp" | "two-tone",
    className?: string,
    onClick?: any,
    dataTarget?: string,
    index?: number,
}

export const Icon = (item: IProps) => {

    return (
        <i id={item.id} style={{fontSize: item.size}} data-toggle="modal" data-target={`#${item.dataTarget}`}
           className={`material-icons${item.type ? `-${item.type}` : ''} ${item.className ?? ''}`}
           onClick={() => item.onClick ? item.onClick() : {}}>{item.name}
        </i>
    )
}
