import React from 'react';
import './ModalConfirmComplete.scss'
import {manufacturingStore} from "../../ManufacturingStore";

interface IProps {
    text: string,
    textContent: string
    type: string
}

const ModalConfirmComplete = ({text, textContent, type}: IProps) => {
    return (
        <div className="modal fade" id='popup_confirm_complete_manufacturing' role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" id="close_delete" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center icon">
                        <img src="/assets/ico/action/confirm-complete.svg" alt=""/>
                    </div>
                    <div className="mx-3">
                        <h2 className="text-center mt-4">{`Hoàn Thành ${text}`}</h2>
                        <p className="text-center mb-5 mx-5 px-5 ">{textContent}</p>
                        <div className="d-flex action align-items-center justify-content-between mx-3 mb-5">
                            <button className="btn btn-cancel w-50 mx-2" data-dismiss="modal">Hủy</button>
                            <button className="btn btn-confirm w-50 mx-2"
                                    onClick={() => manufacturingStore.changeTypeJob(manufacturingStore.id, type)}>Xác
                                nhận
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ModalConfirmComplete;