import React from 'react';
import {observer} from "mobx-react";
import {procedureStore} from "../ProcedureStore";
import Input from "../../../../common/component/form/Input";
import Select from "../../../../common/component/form/Select";
import {cateStore} from "../../../warehouse/category/CateStore";
import {productStore} from "../../../warehouse/category/product/ProductStore";


const AddOrEditProcedure = () => {


    return (
        <div className="modal fade" id="add_or_edit_procedure" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thiết lập quy trình</h5>
                        <button type="button" className="close" id="close_add_edit" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Tên quy trình <span className="text-danger">*</span></label>
                            <Input value={procedureStore.dataRequest.name} isError={procedureStore.errors.name}
                                   onChange={(e: any) => procedureStore.dataRequest.name = e.currentTarget.value}
                                   placeholder="Nhập tên quy trình"/>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="w-50 pr-3">
                                <div className="form-group">
                                    <label>Danh mục sản phẩm <span className="text-danger">*</span></label>
                                    <Select value={procedureStore.dataRequest.product.categoryId}
                                            options={cateStore.listCate}
                                            onChange={(e: any) => procedureStore.changeCate(e.currentTarget.value)}
                                            placeholder="Chọn danh mục"/>
                                </div>
                            </div>

                            <div className="w-50 pl-3">
                                <div className="form-group">
                                    <label>Sản phẩm <span className="text-danger">*</span></label>
                                    <Select value={procedureStore.dataRequest.product.id}
                                            options={productStore.listProduct}
                                            disabled={!procedureStore.dataRequest.product.categoryId}
                                            onChange={(e: any) => procedureStore.dataRequest.product.id = e.currentTarget.value}
                                            placeholder="Chọn sản phẩm"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Ghi chú</label>
                            <div className="input-group">
                                    <textarea value={procedureStore.dataRequest.note}
                                              onChange={(e: any) => procedureStore.dataRequest.note = e.currentTarget.value}
                                              className={`form-control form-control-lg`} placeholder="Nhập ghi chú"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => procedureStore.addOrUpdate()}
                                disabled={procedureStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditProcedure);