import React, {useEffect} from 'react';
import './SuppliesStyle.scss'
import TabHeader from "./components/TabHeader";
import {observer} from "mobx-react";
import {getUrlParameter} from "../../../common/utils/Utils";
import IngredientPage from "./ingredient/IngredientPage";
import {suppliesStore, TypeSupplies} from "./SuppliesStore";
import AdditivePage from "./additives/AdditivePage";
import EquipmentPage from "./equipment/EquipmentPage";
import PackagingPage from "./packaging/PackagingPage";


const SuppliesPage = () => {

    const switchData = () => {
        switch (suppliesStore.tabActive) {
            case TypeSupplies.additives:
                return <AdditivePage/>
            case TypeSupplies.packaging:
                return <PackagingPage/>
            case TypeSupplies.ingredient:
                return <IngredientPage/>
            case TypeSupplies.equipment:
                return <EquipmentPage/>
        }
    }


    useEffect(() => {
        suppliesStore.tabActive = getUrlParameter('type') ?? TypeSupplies.ingredient

        return () => {
            suppliesStore.tabActive = ''
        }
    }, [])


    return (
        <div className="setup_content">
            <TabHeader/>
            {switchData()}
        </div>
    );
}

export default observer(SuppliesPage);