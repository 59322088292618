import {getRequest, IApiResponse, putRequest} from "../../../../common/helpers/RequestHelper";
import {detailBatchStore, IDataRequestExportBatchBody, IDataRequestImportProduct} from "./DetailEIBatchStore";


class ProductService {
    public getBatchById(size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure_batch?page=${detailBatchStore.page}&size=${size ?? 10}&id=${detailBatchStore.idProcedure}`);
    }

    public importProduct(id: any, data: IDataRequestImportProduct): Promise<IApiResponse> {
        return putRequest(`/food/v1/warehouse_request/${id}/approve?batchType=PRODUCT`, data);
    }

    public importExportBatch(data: IDataRequestExportBatchBody, id: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/warehouse_request/${id}/approve`, data);
    }

    public cancelEIBatch(data: any, id: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/warehouse_request/${id}/reject`, data);
    }
}

export const detailBatchService = new ProductService();