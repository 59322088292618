export default class Constants {
    public static TOKEN_EXPIRE_DAYS: number = 16;
    public static TOKEN_NAME: string = "token";
    public static REFRESH_TOKEN: string = "refreshToken";
    public static API_URL: String = (window as any).API_DOMAIN;
}

export const PermissionConst = {
    accountView: "v_account",
    accountAdd: "a_account",
    accountUpdate: "e_account",
    approveAccount: "approve_account",//Xét duyệt tài khoản
    procedureView: "v_procedure",
    procedureAdd: "a_procedure",
    procedureUpdate: "e_procedure",
    procedureDelete: "d_procedure",
    jobView: 'v_job',
    jobAdd: 'a_job',
    jobUpdate: 'e_job',
    jobDelete: 'd_job',
    planView: "v_plan",
    planAdd: "a_plan",
    planUpdate: "e_plan",
    planDelete: "d_plan",
    productionRequestView: 'v_production_request',
    approveProductionRequest: 'approve_production_request', //Xét duyệt Xuất - Nhập SX
    productCateView: 'v_product_cate',
    productCateAdd: 'a_product_cate',
    productCateUpdate: 'e_product_cate',
    productCateDelete: 'd_product_cate',
    productView: 'v_product',
    productAdd: 'a_product',
    productUpdate: 'e_product',
    productDelete: 'd_product',
    productExport: 'product_export',
    suppliesView: 'v_supplies',
    suppliesAdd: 'a_supplies',
    suppliesUpdate: 'e_supplies',
    suppliesDelete: 'd_supplies',
    suppliesBatchView: 'v_supplies_batch',
    suppliesBatchAdd: 'a_supplies_batch',
    suppliesBatchUpdate: 'e_supplies_batch',
    suppliesBatchDelete: 'd_supplies_batch',
    suppliesBatchExport: 'export_supplies_batch',
    productionView: 'v_production',
    approveProduction: 'approve_production', //Xét duyệt sản xuất
    productionSuppliesRequest: 'production_supplies_request', //Xuất nhập vật tư
    packagingView: 'v_packaging',
    approvePackaging: 'approve_packaging',//Xét duyệt đóng gói
    product_to_warehouse: 'product_to_warehouse',//Nhập kho sản phẩm
    packaging_supplies_request: 'packaging_supplies_request',//Xuất nhập vật tư
    trackingView: 'v_tracking',
    trackingAdd: 'a_tracking',
    trackingUpdate: 'e_tracking',
    qrCodeView: 'v_qr_code',
    qrCodeAdd: 'a_qr_code',
    qrCodeUpdate: 'e_qr_code',
    exportQrCode: 'export_qr_code',
    farmView: "v_farm",
    farmUpdate: "e_farm",
    owner: "owner",
}
