import {observable} from "mobx";
import {IFilter} from "../../common/component/filter/FilterComponent";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {preserveService} from "./PreserveService";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from "jquery";
import {productStore} from "../warehouse/category/product/ProductStore";
import {procedureStore} from "../manage/procedure/ProcedureStore";
import {manufacturingStore} from "../manufacturing/ManufacturingStore";
import {detailBatchStore} from "../warehouse/expImpBatch/detailEIBatch/DetailEIBatchStore";

export interface IListPreserve {
    actualQuantity: number
    status: string
    name: string;
    creator: {
        name: string
    };
    id: number;
    product: {
        name: string;
        id: number;
    };
    dateTimeStart: number;
    state: string;
    jobTypeSuccess: string | null;
    quantity?: number;
    updatedAt?: number;
}

export interface IListJobByBatch {
    id: number,
    name: string,
    procedureBatchId: number,
    productionFacilityId: number,
    dateTimeStart: number,
    dateTimeFinish: number,
    state: string,
    type: string,
    status: string,
    user: {
        name: string,
        updatedAt: number
    }
    supplies: any[],
    updatedAt: number
}

export interface IDataAddPlanOrderWork {
    name?: string;
    category?: number | string;
    productId?: number | string;
    productName?: string;
    procedureId?: number | string;
    procedureName?: string;
    quantity?: number | string;
    timestart?: number | string;
    updatedAt?: number | string;
    timestartTmp?: number | string;
    jobTypeSuccess?: string;
}

export interface IDataAddPlanPersonInCharge {
    name: string;
    key: string;
    isErr: boolean;
    selectPersonInCharge: {
        value: number,
        label: string
    }[];
    personInCharge: {
        value: number,
        label: string
    }[];
}

export interface IDataAddPlanQuantity {
    name?: string;
    id?: string | number;
    unit?: string;
    estimatedQuantity?: number | string;
    errQuantityEstimate?: boolean
}

export interface IDataRequestAddPlan {
    dataOrderWork: IDataAddPlanOrderWork,
    dataPersonInCharge: IDataAddPlanPersonInCharge[],
    dataQuantity: IDataAddPlanQuantity[]
}

export interface IDataPerformers {
    manufactures: number[],
    pack: number[],
    warehouse: number[]
}

export interface IDataBodyRequestAddPlan {
    name: string,
    procedureId: number,
    quantity: number,
    dateTimeStart: number,
    performers: IDataPerformers
    supplies: {
        id: number,
        quantity: number
    }[]
}

export enum ITabDetailPlanPreserve {
    plan = "plan",
    tracking = "tracking"
}

class PreserveStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable resetStep: boolean = false
    @observable type: string = ''
    @observable id: any = ''
    @observable page: number = 0
    @observable currentStep: number = 0
    @observable totalPage: number = 0
    @observable tabActiveDetailPlanPreserve: string = ITabDetailPlanPreserve.plan;
    @observable dataFilter: IFilter[] = []
    @observable keyword: any = '';
    @observable searchStatus: any = '';
    @observable searchProduct: any = '';
    @observable dataAddPlanOrderWork: IDataAddPlanOrderWork = {};
    @observable dataAddPlanPersonInCharge: IDataAddPlanPersonInCharge[] = []
    @observable dataAddPlanQuantity: IDataAddPlanQuantity[] = []
    @observable personInManufactures: {
        value: number,
        label: string
    }[] = []
    @observable personInPack: {
        value: number,
        label: string
    }[] = []
    @observable personInWarehouse: {
        value: number,
        label: string
    }[] = []
    @observable dataRequestAddPlan: IDataRequestAddPlan = {
        dataOrderWork: {},
        dataPersonInCharge: [],
        dataQuantity: []
    }
    @observable personInCharge: {
        value: number,
        label: string
    }[] = []

    @observable selectPersonInCharge: {
        value: number,
        label: string
    }[] = []


    @observable listOptionSearchStatus: any[] = [
        {
            id: 'PREPARATION',
            name: 'Chưa bắt đầu',
        },
        {
            id: 'PACK',
            name: 'Đóng gói',
        },
        {
            id: 'CANCEL',
            name: 'Hủy',
        },
        {
            id: 'PRESERVE',
            name: 'Nhập kho',
        }
    ]
    @observable listPreserves: IListPreserve[] = [];
    @observable IListJobByBatch: IListJobByBatch[] = [];


    @observable public errors: any = {
        addPlanOrderWork: {
            name: '',
            cate: '',
            product: '',
            procedure: '',
            quantity: '',
            timeStart: ''
        },
        addPlanPersonInCharge: '',
        addPlanQuantity: '',
    }

    resetErrors() {
        preserveStore.dataAddPlanPersonInCharge.forEach((item, index) => {
            item.selectPersonInCharge = []
        })
        preserveStore.errors = {
            addPlanOrderWork: {
                name: '',
                cate: '',
                product: '',
                procedure: '',
                quantity: '',
                timeStart: ''
            },
            addPlanPersonInCharge: '',
            addPlanQuantity: '',
            addPlanQuantityMin: ''
        }
    }

    clearForm() {
        preserveStore.currentStep = 0
        preserveStore.type = "add"
        preserveStore.page = 0
        preserveStore.dataAddPlanOrderWork = {
            name: '',
            category: '',
            productId: '',
            procedureId: '',
            quantity: '',
            timestart: ''
        }
        preserveStore.dataAddPlanPersonInCharge.forEach((item, index) => {
            item.selectPersonInCharge = []
        })
        preserveStore.dataAddPlanQuantity.forEach((item, index) => {
            item.estimatedQuantity = ""
        })
        preserveStore.resetErrors()
    }


    changeStateBatch(status: string) {
        switch (status) {
            case "CANCEL" :
                return "Hủy";
            case "COMPLETE" :
                return "Hoàn thành";
            case "PROCESSING" :
                return "Sản xuất";
            case "PACK" :
                return "Đóng gói"
            case "PRESERVE":
                return "Nhập kho"
            default :
                return "Chưa bắt đầu"
        }
    }


    changeColorStateBatch(status: string) {
        switch (status) {
            case "PROCESSING" :
                return "preserve_state_processing";
            case "PACK" :
                return "preserve_state_pack"
            case "PRESERVE":
                return "preserve_state_preserve"
            case "CANCEL":
                return "preserve_state_block"
            default :
                return "preserve_state_preparation"
        }
    }

    async getPreserves(size?: any) {
        preserveStore.isLoading = true
        const result = await preserveService.getPreserves(size)
        preserveStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            preserveStore.listPreserves = result.body.data
            preserveStore.totalPage = result.body.metadata.totalPage
            preserveStore.searchStatus = ''
            preserveStore.keyword = ''
            preserveStore.searchProduct = ''
        }
    }

    async delete() {
        preserveStore.isLoadingBt = true
        const result = await preserveService.delete(preserveStore.id)
        preserveStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Hủy kế hoạch lô sản xuất thành công', 3)
            await preserveStore.getPreserves()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async getUsersDepartment(department?: string) {
        const result = await preserveService.getUserPreserves(department)
        if (result.status === HttpStatusCode.OK) {
            let dataUserByDepartment = result.body.data.map((item: any, index: number) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
            if (department === "MANUFACTURE") preserveStore.personInManufactures = dataUserByDepartment
            else if (department === "PACK") preserveStore.personInPack = dataUserByDepartment
            else preserveStore.personInWarehouse = dataUserByDepartment
        }
    }

    async getDataAllDepartment() {
        preserveStore.resetStep = true
        await preserveStore.getUsersDepartment("MANUFACTURE").then(async () => {
            await preserveStore.getUsersDepartment("PACK").then(async () => {
                await preserveStore.getUsersDepartment("WAREHOUSE").then(() => {
                    preserveStore.dataAddPlanPersonInCharge = [
                        {
                            name: "Sản xuất",
                            key: "manufactures",
                            isErr: false,
                            selectPersonInCharge: [],
                            personInCharge: [{value: -1, label: "Tất cả"}, ...preserveStore.personInManufactures]
                        },
                        {
                            name: "Đóng gói",
                            key: "pack",
                            isErr: false,
                            selectPersonInCharge: [],
                            personInCharge: [{value: -1, label: "Tất cả"}, ...preserveStore.personInPack]
                        },
                        {
                            name: "Xuất nhập kho",
                            key: "warehouse",
                            isErr: false,
                            selectPersonInCharge: [],
                            personInCharge: [{value: -1, label: "Tất cả"}, ...preserveStore.personInWarehouse]
                        }
                    ]
                })
            })

        })
        preserveStore.resetStep = false
    }

    async getDetailTrackingBatch(id: any) {
        preserveStore.isLoading = true
        const result = await preserveService.getDetailJobByBatch(id)
        preserveStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            preserveStore.IListJobByBatch = result.body.data
            preserveStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetailPreserves(id: any) {
        const result = await preserveService.getDetailPreserves(id)
        if (result.status === HttpStatusCode.OK) {
            preserveStore.dataAddPlanOrderWork = {
                name: result.body.name,
                category: result.body.product.category.id,
                productId: result.body.product ? result.body.product.id : "",
                productName: result.body.product ? result.body.product.name : "",
                procedureId: result.body.procedure ? result.body.procedure.id : "",
                procedureName: result.body.procedure ? result.body.procedure.name : "",
                quantity: result.body.quantity,
                timestartTmp: result.body.dateTimeStart,
                updatedAt: result.body.updatedAt,
                jobTypeSuccess: result.body.jobTypeSuccess,
            }
            if (result.body.performers && result.body.performers.length > 0) {
                let tmpManufacture: {
                    value: number,
                    label: string
                }[] = [];
                let tmpPack: {
                    value: number,
                    label: string
                }[] = [];
                let tmpWarehouse: {
                    value: number,
                    label: string
                }[] = [];
                result.body.performers.forEach((item: any, index: number) => {
                    if (item.department === "MANUFACTURE") {
                        tmpManufacture.push({
                            value: item.id,
                            label: item.name
                        })
                    } else if (item.department === "PACK") {
                        tmpPack.push({
                            value: item.id,
                            label: item.name
                        })
                    } else {
                        tmpWarehouse.push({
                            value: item.id,
                            label: item.name
                        })
                    }
                })
                preserveStore.dataAddPlanPersonInCharge.forEach((item) => {
                    if (item.key === "manufactures") {
                        let tmpManifactureSelect = item.personInCharge.slice(1, item.personInCharge.length)
                        tmpManufacture.sort((a, b) => Number(a.value) - Number(b.value));
                        tmpManifactureSelect.sort((a, b) => Number(a.value) - Number(b.value));
                        if (JSON.stringify(tmpManufacture) === JSON.stringify(tmpManifactureSelect)) {
                            item.selectPersonInCharge = [{
                                value: -1,
                                label: "Tất cả"
                            }]
                        } else item.selectPersonInCharge = tmpManufacture

                    } else if (item.key === "pack") {
                        let tmpPackSelect = item.personInCharge.slice(1, item.personInCharge.length)
                        tmpPack.sort((a, b) => Number(a.value) - Number(b.value));
                        tmpPackSelect.sort((a, b) => Number(a.value) - Number(b.value));
                        if (JSON.stringify(tmpPack) === JSON.stringify(tmpPackSelect)) {
                            item.selectPersonInCharge = [{
                                value: -1,
                                label: "Tất cả"
                            }]
                        } else item.selectPersonInCharge = tmpPack
                    } else {
                        let tmpWarehouseSelect = item.personInCharge.slice(1, item.personInCharge.length)
                        tmpWarehouse.sort((a, b) => Number(a.value) - Number(b.value));
                        tmpWarehouseSelect.sort((a, b) => Number(a.value) - Number(b.value));
                        if (JSON.stringify(tmpWarehouse) === JSON.stringify(tmpWarehouseSelect)) {
                            item.selectPersonInCharge = [{
                                value: -1,
                                label: "Tất cả"
                            }]
                        } else item.selectPersonInCharge = tmpWarehouse

                    }
                })
            }
            if (result.body && preserveStore.type === "edit") {
                if (result.body.product.category.id) {
                    productStore.categoryId = result.body.product.category.id
                    procedureStore.searchProduct = Number(result.body.product.id)
                    await productStore.getProducts(1000, false)
                    await procedureStore.getProcedure(1000, false);
                }
                if (result.body.supplies) {
                    preserveStore.dataAddPlanQuantity = result.body.supplies.map((item: any, index: number) => {
                        return {
                            name: item.supplies.name,
                            id: item.supplies.id,
                            unit: item.supplies.unit,
                            estimatedQuantity: item.estimatedQuantity,
                        }
                    })
                }
            }
            if (result.body.supplies) {
                manufacturingStore.listSupplies = result.body.supplies.map((item: any, index: number) => {
                    return {
                        name: item.supplies.name,
                        id: item.supplies.id,
                        unit: item.supplies.unit,
                        estimatedQuantity: item.estimatedQuantity,
                        quantity: item.quantity,
                        err: false,
                        errQuantity: false
                    }
                })
                manufacturingStore.listSuppliesDetail = result.body.supplies.map((item: any, index: number) => {
                    return {
                        name: item.supplies.name,
                        id: item.supplies.id,
                        unit: item.supplies.unit,
                        estimatedQuantity: item.estimatedQuantity,
                        quantity: item.quantity,
                        err: false,
                        errQuantity: false
                    }
                })
                detailBatchStore.listDataExportBatch = detailBatchStore.listDataExportBatch.map((item, index) => {
                    const newSupply = item.suppliesInformation?.map((itemSup, indexSup) => {
                        return {
                            ...itemSup as any,
                            options: itemSup.options.map((itemOption, indexOption) => {
                                return {
                                    ...itemOption as any,
                                    maxQuantity: result.body.supplies?.filter((x: { supplies: { id: any; }; }) => x.supplies.id === item.id)[0].quantity
                                }
                            })
                        }
                    })
                    return {
                        ...item,
                        suppliesInformation: newSupply
                    }
                })
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async submitAddPlanPreserve() {
        preserveStore.dataAddPlanQuantity.forEach((item, index) => {
            item.errQuantityEstimate = !item.estimatedQuantity;
        })
        const checkErrQuantity = preserveStore.dataAddPlanQuantity.map(item => item.errQuantityEstimate)
            .includes(true);
        if (checkErrQuantity) {
            preserveStore.errors.addPlanQuantity = 'Vui lòng nhập đầy đủ số lượng !'
            return
        } else preserveStore.errors.addPlanQuantity = ''

        const tmpDataPerformers: IDataPerformers = {
            manufactures: [],
            pack: [],
            warehouse: []
        }
        preserveStore.dataAddPlanPersonInCharge.forEach((item) => {
            if (item.key === "manufactures") tmpDataPerformers.manufactures = item.selectPersonInCharge.map((person) => {
                return person.value
            })
            else if (item.key === "pack") tmpDataPerformers.pack = item.selectPersonInCharge.map((person) => {
                return person.value
            })
            else tmpDataPerformers.warehouse = item.selectPersonInCharge.map((person) => {
                    return person.value
                })
        })

        const data: IDataBodyRequestAddPlan = {
            name: preserveStore.dataRequestAddPlan.dataOrderWork.name as string,
            procedureId: preserveStore.dataRequestAddPlan.dataOrderWork.procedureId as number,
            quantity: preserveStore.dataRequestAddPlan.dataOrderWork.quantity as number,
            dateTimeStart: preserveStore.dataRequestAddPlan.dataOrderWork.timestartTmp as number,
            performers: tmpDataPerformers,
            supplies: preserveStore.dataAddPlanQuantity.map((item, index) => {
                return {
                    id: Number(item.id),
                    quantity: Number(item.estimatedQuantity)
                }
            })
        }
        preserveStore.isLoadingBt = true
        const result = preserveStore.type === "edit" ? await preserveService.updatePlanPreserve(preserveStore.id, data) : await preserveService.addPlanPreserve(data)
        preserveStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${preserveStore.type === "edit" ? "Sửa" : "Thêm"} kế hoạch thành công`, 3)
            $('#add-plan-order_work').trigger('click')
            await preserveStore.getPreserves()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
}

export const preserveStore = new PreserveStore()