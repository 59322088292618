import {observable} from "mobx";
import {getRequest, putRequest} from "../../common/helpers/RequestHelper"
import {profileStore} from "../profile/ProfileStore";
import {toastUtil} from "../../common/utils/ToastUtil";
import {signUpStore} from "../auth/signUp/SignUpStore";

export enum TypeInfo {
    info = 'info',
    edit = 'edit'
}

class InfoStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable type: string = TypeInfo.info
    @observable error: boolean = false
    @observable dataInfo: any = {
        name: "",
        businessDescription: "",
        foundedYear: '',
        email: "",
        province: {
            id: ''
        },
        district: {
            id: ''
        },
        ward: {
            id: ''
        },
        phone: "",
        address: "",
        mainProduct: "",
        website: "",
        testimonial: '',
        totalRevenue: '',
        mainMarket: '',
        totalLabor: '',
        businessCode: "",
        representativeName: "",
        certificate: '',
        certificates: [],
    }


    async getInfo(id?: any) {
        if (profileStore.dataFarm.facilityId || id) {
            this.isLoading = true
            const result = await getRequest(`/food/v1/product_facility/${id ?? profileStore.dataFarm.facilityId}`)
            this.isLoading = false
            if (result.status == 200) {
                this.dataInfo = result.body
                if(profileStore.path == 'farm'){
                    await signUpStore.getProvinces()
                    if (infoStore.dataInfo.province) {
                        await signUpStore.getDistricts(infoStore.dataInfo.province.id)
                    }
                    if (infoStore.dataInfo.district) {
                        await signUpStore.getWards(infoStore.dataInfo.district.id)
                    }
                }
            }
        }
    }

    async updateInfo() {
        let {
            name,
            foundedYear,
            province,
            district,
            ward,
            email,
            phone,
            address,
            mainProduct,
            website,
            businessCode,
            testimonial,
            totalRevenue,
            businessDescription,
            mainMarket,
            totalLabor,
            representative,
            certificate,
            certificates
        } = infoStore.dataInfo

        let data = {
            name: name,
            foundedYear: Number(foundedYear) ?? null,
            email: email,
            phone: phone,
            address: address,
            provinceId: province.id,
            districtId: district.id,
            wardId: ward.id,
            mainProduct: mainProduct ?? null,
            website: website ?? null,
            testimonial: testimonial ?? null,
            totalRevenue: totalRevenue ?? null,
            mainMarket: mainMarket ?? null,
            totalLabor: totalLabor ?? null,
            businessCode: businessCode,
            businessDescription: businessDescription ?? null,
            representative: representative ?? null,
            certificate: certificate ?? null,
            certificates: certificates ?? null,
        }

        if (!name || !email || !phone || !address || !businessCode) {
            this.error = true
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin', 1)
            return
        }

        this.isLoadingBt = true
        const result = await putRequest(`/food/v1/product_facility/${profileStore.dataFarm.facilityId}`, data)
        this.isLoadingBt = false
        if (result.status == 200) {
            toastUtil.success('Cập nhật thông tin thành công', 2)
            await infoStore.getInfo()
        } else {
            toastUtil.error(result.body.message, 2)
        }
    }

}

export const infoStore = new InfoStore()
