import React from 'react';
import {observer} from "mobx-react";
import {batchStore} from "../BatchStore";
import Input from "../../../../../common/component/form/Input";
import DatePickerSingle from "../../../../../common/component/DatePickerSingle";
import {suppliesStore, TypeSupplies} from "../../SuppliesStore";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import {number_format} from "../../../../../common/utils/Utils";


const AddOrUpdateBatch = () => {

    const handleInputTime = async (startDate: Date) => {
        batchStore.dataRequest.inputTime = startDate.getTime()
        batchStore.minDate = startDate.getTime()
    }

    const handleExpirationDate = async (endDate: Date) => {
        batchStore.dataRequest.expirationDate = endDate.getTime()
    }


    return (
        <div className="modal fade modal_full" id="add_edit_batch" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <button type="button" className="close d-flex align-items-center" id="close_form_batch"
                            data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                        <p className="m-0">Đóng</p>
                    </button>
                    <div className="modal-header pt-0">
                        <h5 className="modal-title w-100 text-center">{batchStore.type === "add" ? 'Thêm' : 'Cập nhật'} lô {batchStore.switchName()}</h5>
                    </div>
                    <div className="modal-body pt-4">
                        <div className="row">
                            <div className="col-6 pr-5">
                                <div className="info mb-4">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green">Thông tin lô nhập</h5>
                                    </div>
                                    <div className="d-flex flex-wrap h-72">
                                        <label>Mã lô <span className="text-danger">*</span></label>
                                        <Input value={batchStore.dataRequest.batchCode}
                                               isError={batchStore.errors.batchCode}
                                               onChange={(e: any) => batchStore.dataRequest.batchCode = e.currentTarget.value}
                                               placeholder="Nhập mã lô"/>
                                    </div>
                                    <div className="d-flex flex-wrap h-72">
                                        <label>Thời gian nhập <span className="text-danger">*</span></label>
                                        <DatePickerSingle selected={batchStore.dataRequest.inputTime}
                                                          maxDate={batchStore.dataRequest.expirationDate}
                                                          isError={batchStore.errors.inputTime}
                                                          onChange={handleInputTime} placeholder="Chọn thời gian nhập"/>
                                    </div>
                                    <div className="d-flex flex-wrap h-72">
                                        <label>Hạn sử dụng <span className="text-danger">*</span></label>
                                        <DatePickerSingle selected={batchStore.dataRequest.expirationDate}
                                                          minDate={batchStore.minDate}
                                                          isError={batchStore.errors.expirationDate}
                                                          onChange={handleExpirationDate}
                                                          placeholder="Chọn hạn sử dụng"/>
                                    </div>
                                    <div className="d-flex flex-wrap"
                                         style={{height: batchStore.typeSupplies === TypeSupplies.packaging ? 72 : 100}}>
                                        <label>Tên {batchStore.switchName()} <span
                                            className="text-danger">*</span></label>
                                        <Input value={suppliesStore.dataRequest.name} disabled={true}/>
                                    </div>

                                    {batchStore.typeSupplies === TypeSupplies.packaging &&
                                        <div className="d-flex flex-wrap mb-4">
                                            <label>Khối lượng / {suppliesStore.dataRequest.unit} <span
                                                className="text-danger">*</span></label>
                                            <Input value={batchStore.dataRequest.containedVolume}
                                                   isError={batchStore.errors.containedVolume}
                                                   onChange={(e: any) => batchStore.dataRequest.containedVolume = e.currentTarget.value}
                                                   placeholder="Nhập khối lượng"/>
                                        </div>}

                                    <div className="d-flex flex-wrap h-72">
                                        <label>Số lượng ({suppliesStore.dataRequest.unit}) <span
                                            className="text-danger">*</span></label>
                                        <Input value={batchStore.dataRequest.quantity}
                                               isError={batchStore.errors.quantity}
                                               onChange={(e: any) => batchStore.dataRequest.quantity = e.currentTarget.value}
                                               placeholder="Nhập số lượng"/>
                                    </div>
                                    <div className="d-flex flex-wrap h-72">
                                        <label>Giá nhập</label>
                                        <Input
                                            value={batchStore.dataRequest.price ? number_format(batchStore.dataRequest.price) : ''}
                                            onChange={(e: any) => batchStore.dataRequest.price = numberUtil.regexNumber(e)}
                                            placeholder="Nhập giá nhập"/>
                                    </div>

                                    <div className="d-flex flex-wrap h-72">
                                        <label>Mã số lô nhà cung ứng <span className="text-danger">*</span></label>
                                        <Input value={batchStore.dataRequest.supplierBatchCode}
                                               isError={batchStore.errors.supplierBatchCode}
                                               onChange={(e: any) => batchStore.dataRequest.supplierBatchCode = e.currentTarget.value}
                                               placeholder="Nhập mã số lô nhà cung ứng"/>
                                    </div>

                                    <div className="d-flex flex-wrap h-72">
                                        <label>Mã số lô nhà sản xuất <span className="text-danger">*</span></label>
                                        <Input value={batchStore.dataRequest.productionBatchCode}
                                               isError={batchStore.errors.productionBatchCode}
                                               onChange={(e: any) => batchStore.dataRequest.productionBatchCode = e.currentTarget.value}
                                               placeholder="Nhập mã số lô nhà sản xuất"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 pl-5">
                                <div className="info">
                                    <div className="title_top mb-4">
                                        <h5 className="color-green">Đánh giá chất lượng</h5>
                                    </div>
                                    <div className="d-flex flex-wrap h-72">
                                        <label>Màu sắc <span className="text-danger">*</span></label>
                                        <Input value={batchStore.dataRequest.qualityEvaluation.color}
                                               onChange={(e: any) => batchStore.dataRequest.qualityEvaluation.color = e.currentTarget.value}
                                               placeholder="Nhập màu sắc"/>
                                    </div>
                                    {batchStore.typeSupplies !== TypeSupplies.packaging &&
                                        <div className="d-flex flex-wrap h-72  ">
                                            <label>Mùi vị</label>
                                            <Input value={batchStore.dataRequest.qualityEvaluation.taste}
                                                   onChange={(e: any) => batchStore.dataRequest.qualityEvaluation.taste = e.currentTarget.value}
                                                   placeholder="Nhập mùi vị"/>
                                        </div>}
                                    <div className="d-flex flex-wrap h-72">
                                        <label>Tình trạng</label>
                                        <Input value={batchStore.dataRequest.qualityEvaluation.status}
                                               onChange={(e: any) => batchStore.dataRequest.qualityEvaluation.status = e.currentTarget.value}
                                               placeholder="Nhập tình trạng"/>
                                    </div>
                                    {batchStore.typeSupplies !== TypeSupplies.packaging &&
                                        <div className="d-flex flex-wrap h-72">
                                            <label>Tỉ lệ tạp chất</label>
                                            <Input value={batchStore.dataRequest.qualityEvaluation.impurities}
                                                   onChange={(e: any) => batchStore.dataRequest.qualityEvaluation.impurities = e.currentTarget.value}
                                                   placeholder="Nhập tỉ lệ tạp chất"/>
                                        </div>}
                                    <div
                                        className={`title_top ${batchStore.typeSupplies === TypeSupplies.packaging ? 'mb-5' : 'mb-4'}`}>
                                        <h5 className="color-green">Thông tin đơn vị cung cấp</h5>
                                    </div>
                                    <div className="d-flex flex-wrap h-72">
                                        <label>Tên công ty/cơ sở <span className="text-danger">*</span></label>
                                        <Input value={batchStore.dataRequest.supplierInformation.companyName}
                                               isError={batchStore.errors.companyName}
                                               onChange={(e: any) => batchStore.dataRequest.supplierInformation.companyName = e.currentTarget.value}
                                               placeholder="Nhập tên công ty ..."/>
                                    </div>
                                    <div className="d-flex flex-wrap h-72">
                                        <label>Địa chỉ <span className="text-danger">*</span></label>
                                        <Input value={batchStore.dataRequest.supplierInformation.address}
                                               isError={batchStore.errors.address}
                                               onChange={(e: any) => batchStore.dataRequest.supplierInformation.address = e.currentTarget.value}
                                               placeholder="Nhập địa chỉ"/>
                                    </div>
                                    {batchStore.typeSupplies !== TypeSupplies.packaging &&
                                        <div className="d-flex flex-wrap h-72 ">
                                            <label>Mã vùng trồng</label>
                                            <Input value={batchStore.dataRequest.supplierInformation.plantingAreaCode}
                                                   onChange={(e: any) => batchStore.dataRequest.supplierInformation.plantingAreaCode = e.currentTarget.value}
                                                   placeholder="Nhập mã vùng trồng"/>
                                        </div>}
                                    <div className="d-flex flex-wrap h-72  ">
                                        <label>Mã số cơ sở đóng gói</label>
                                        <Input value={batchStore.dataRequest.supplierInformation.packingBaseCode}
                                               onChange={(e: any) => batchStore.dataRequest.supplierInformation.packingBaseCode = e.currentTarget.value}
                                               placeholder="Nhập mã số cơ sở đóng gói"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn send-request" onClick={() => batchStore.addOrEdit()}
                                    disabled={batchStore.isLoadingBt}>Hoàn thành
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default observer(AddOrUpdateBatch);