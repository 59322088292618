import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import './styles/sidebar.scss';
import {profileStore} from '../profile/ProfileStore';
import StorageService from '../../common/service/StorageService';
import {IRole, sidebarStore} from './SidebarStore';
import {getPathName} from "../../common/utils/Utils";


const SideBar = () => {

    const checkPath = () => {
        profileStore.path = getPathName();
        const names = window.location.pathname.split("/");
        profileStore.link = profileStore.roleUser === IRole.admin ? window.location.pathname : `/${names[1]}`;
    }

    const handleBackButton = (event: any) => {
        checkPath()
        event.preventDefault();
    };

    useEffect(() => {
        checkPath()
    }, [window.location.pathname]);

    useEffect(() => {
        window.addEventListener('popstate', handleBackButton);
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const isToken = profileStore.roleUser || StorageService.getToken()
    let pathCms = window.location.pathname.split('/')[2]
    const url = profileStore.roleUser === IRole.admin ? '/cms/users' : '/';

    if (isToken) {
        let itemByRole: any
        sidebarStore.data.map((val: any) => {
            if (val.role == profileStore.roleUser) itemByRole = val
        })
        return (
            <div id="sidebar">
                <nav className="sidebar-main">
                    <Link to={url} onClick={() => {
                        profileStore.link = '/';
                        profileStore.path = '/'
                    }} className="logo">
                        <img src="/assets/images/logo_omcheck.svg" alt=""/>
                    </Link>
                    <>
                        <ul className="nav">
                            {itemByRole && itemByRole.menu.map((item: any, key: any) => {
                                if (item.isAccess) {
                                    let navItem = "";
                                    let classActive: any = null
                                    if (item.link == profileStore.link) navItem = "nav-item active"
                                    else navItem = "nav-item"
                                    if ((item.link == '/' && profileStore.path === "dashboard") || (pathCms == "permission" && item.link == '/cms/permission') || (profileStore.path == "warehouse" && item.link == "/warehouse") || (profileStore.path === "planning-detail" && item.link == "/planning") || (profileStore.path == "procedure-detail" && item.link == "/procedure")) {
                                        navItem = "nav-item active"
                                    }
                                    if (item.submenu) {
                                        item.submenu.map((kt: any) => {
                                            if (kt.link == sidebarStore.pathSubmenu || kt.link == window.location.pathname) {
                                                classActive = 'active'
                                            }
                                        })
                                        return (
                                            <li className={`nav-item border-0`} key={key}>
                                                <div className={`position-relative sub_menu ${classActive}`}>
                                                    <i className={`material-icons-round position-absolute`}
                                                       data-toggle="collapse" data-target={`#submenu_${key}`}>
                                                        keyboard_arrow_down
                                                    </i>
                                                    <a className="nav-link" onClick={() => {
                                                        item.isActive = !item.isActive
                                                    }} data-toggle="collapse" href={`#submenu_${key}`}>
                                                        <div className="icon">
                                                            <img
                                                                src={`${item.icon}${classActive ? '_active' : ''}.svg`}
                                                                alt=""/>
                                                        </div>
                                                        <span className="menu-title">{item.name}</span>
                                                    </a>
                                                    <div className={`collapse ${classActive && 'show in'}`}
                                                         id={`submenu_${key}`}>
                                                        {item.submenu.map((val: any, i: number) => {
                                                            if (val.isAccess) {
                                                                return (
                                                                    <Link to={val.link} key={i}
                                                                          onClick={() => sidebarStore.pathSubmenu = val.link}
                                                                          className={`nav-link ${(sidebarStore.pathSubmenu == val.link || val.link == window.location.pathname) && 'active'}`}>
                                                                        <span className="ml">{val.name}</span>
                                                                    </Link>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    } else return (
                                        <li className={`${navItem}`} key={key}>
                                            <div className="line"/>
                                            <Link to={item.link} className="nav-link"
                                                  onClick={() => {
                                                      profileStore.link = item.link;
                                                      sidebarStore.pathSubmenu = ''
                                                  }}>
                                                <div className="icon">
                                                    <img
                                                        src={`${item.icon}${((item.link == profileStore.link) || navItem == "nav-item active") ? '_active' : ''}.svg`}
                                                        alt=""/>
                                                </div>
                                                <span className="menu-title">{item.name}</span>
                                            </Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </>
                </nav>
            </div>
        )

    } else return <nav className="sidebar-main" id="sidebar"/>
}

export default observer(SideBar);



