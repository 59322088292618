import React, {useEffect} from 'react';

import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {preserveStore} from "../preserve/PreserveStore";
import {productStore} from "../warehouse/category/product/ProductStore";
import FilterComponent from "../../common/component/filter/FilterComponent";
import Loading from "../../common/component/Loading";
import NoContent from "../../common/component/NoContent";
import {dateUtils} from "../../common/utils/DateUtils";
import './Pack.scss'
import Paginate from "../../common/component/Paginate";
import {number_format} from "../../common/utils/Utils";

const PackPage = () => {
    const changePage = async (data: any) => {
        preserveStore.page = data.selected;
        await preserveStore.getPreserves(10)
    }
    useEffect(() => {
        preserveStore.getPreserves(10)
        preserveStore.dataFilter = [
            {
                label: 'Lô sản xuất & Sản phẩm',
                type: 'search',
                placeholder: 'Nhập từ khóa tìm kiếm',
                value: preserveStore.keyword,
                onChange: (e: any) => {
                    preserveStore.page = 0
                    preserveStore.keyword = e
                },
                callback: async () => {
                    await preserveStore.getPreserves();
                }
            },
            {
                label: 'Tình trạng',
                type: 'select',
                placeholder: 'Tất cả',
                value: preserveStore.searchStatus,
                listValue: preserveStore.listOptionSearchStatus,
                callback: async (e: any) => {
                    preserveStore.page = 0
                    preserveStore.searchStatus = e
                    await preserveStore.getPreserves();
                }
            },
        ]
        return () => {
            productStore.categoryId = -1
        }
    }, [])


    return (
        <div className='procedures'>
            <div className="d-flex">
                <FilterComponent
                    data={preserveStore.dataFilter}
                    label="ĐÓNG GÓI"
                />
            </div>
            {preserveStore.isLoading ? <Loading/> :
                <div className="table-responsive">
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">Mã lô sản xuất</th>
                            <th className="text-center">Sản phẩm</th>
                            <th className="text-center">Thời gian bắt đầu</th>
                            <th className="text-center">Số lượng</th>
                            <th className="text-center">Tiến độ</th>
                            <th className="text-center">Thời gian hoàn thành</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {preserveStore.listPreserves && preserveStore.listPreserves.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td>{item?.name ?? ""}</td>
                                    <td>{item?.product.name ?? ""}</td>
                                    <td className="text-center">{item?.dateTimeStart ? dateUtils.formatDate(item.dateTimeStart) : '-'}</td>
                                    <td className="text-center">{item?.quantity ? number_format(item.quantity) : ""}</td>
                                    <td className={`text-center ${preserveStore.changeColorStateBatch(item.status)}`}>{item?.status ? preserveStore.changeStateBatch(item.status) : ""}</td>
                                    <td className="text-center">{item?.updatedAt && item?.status !== null && item?.status !== "PREPARATION" ? dateUtils.formatDate(item.updatedAt) : "-"}</td>
                                    <td className="text-center" width="18%">
                                        <Link to={`/packge/${item?.id}`} className="view-detail-pack"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="Xem chi tiết"
                                        >
                                            Chi tiết
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    {!preserveStore.isLoading && preserveStore.listPreserves && preserveStore.listPreserves.length < 1 &&
                        <NoContent/>}
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate currentPage={preserveStore.page} totalPage={preserveStore.totalPage}
                                  changePage={changePage}/>
                    </div>
                </div>}
        </div>
    );
}

export default observer(PackPage);