import {observable} from "mobx";
import {procedureService} from "./ProcedureService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";
import {IFilter} from "../../../common/component/filter/FilterComponent";
import {productStore} from "../../warehouse/category/product/ProductStore";

interface IDataRequest {
    id: "",
    name: "",
    createdAt: '',
    product: {
        categoryId: '',
        id: '',
        name: '',
        createdAt: any
    },
    note: ""
}


class ProcedureStore {
    @observable id: any = '';
    @observable type: any = '';
    @observable searchProduct: any = '';
    @observable page: number = 0;
    @observable isChanged: boolean = false
    @observable totalPage: number = 0;
    @observable isLoading: boolean = false
    @observable keyword: string = ""
    @observable public errors: any = {
        name: "",
        cateId: '',
        productId: ''
    }
    @observable isLoadingBt: boolean = false
    @observable dataRequest: IDataRequest = {
        id: "",
        name: "",
        createdAt: '',
        product: {
            categoryId: '',
            id: '',
            name: '',
            createdAt: ''
        },
        note: ""
    }
    @observable dataRequestJob: any = {
        id: "",
        name: "",
        type: '',
    }
    @observable listProcedure: any[] = []
    @observable listProduct: any[] = []
    @observable dataFilter: IFilter[] = []

    clearError() {
        procedureStore.errors = {
            name: "",
            cateId: '',
            productId: ''
        }
    }

    clearForm() {
        productStore.categoryId = -1
        procedureStore.clearError()
        procedureStore.type = 'add'
        procedureStore.dataRequest = {
            id: "",
            name: "",
            createdAt: '',
            product: {
                categoryId: '',
                id: '',
                name: '',
                createdAt: ''
            },
            note: ""
        }
    }


    async changeCate(value: any) {
        procedureStore.dataRequest.product.id = ''
        productStore.categoryId = value
        await productStore.getProducts()
        procedureStore.dataRequest.product.categoryId = value
    }


    async getProcedure(size?: any, isLoading = true) {
        if (isLoading) procedureStore.isLoading = true
        const result = await procedureService.getProcedure(size)
        procedureStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            procedureStore.listProcedure = result.body.data
            procedureStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        procedureStore.clearError()
        procedureStore.type = "edit"
        const result = await procedureService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            procedureStore.dataRequest = result.body
            if (result.body.product && result.body.product.categoryId) {
                productStore.categoryId = result.body.product.categoryId
                await productStore.getProducts()
            } else {
                procedureStore.dataRequest.product = {categoryId: '', id: '', name: '', createdAt: ''}
            }
        }
    }

    async addOrUpdate() {
        procedureStore.clearError()
        let {id, name, note, product} = procedureStore.dataRequest

        let data = {
            name: name,
            productId: parseInt(product.id),
            note: note
        }
        if (!name) {
            procedureStore.errors.name = 'Tên quy trình không được để trống!'
            return
        }
        if (!product?.categoryId) {
            procedureStore.errors.cateId = 'Danh mục sản phẩm không được để trống!'
            return
        }
        if (!product?.id) {
            procedureStore.errors.productId = 'Sản phẩm không được để trống!'
            return
        }

        let text = procedureStore.type === 'add' ? 'Thêm' : 'Cập nhật'

        procedureStore.isLoadingBt = true
        let result: any
        if (procedureStore.type === "add") {
            result = await procedureService.create(data)
        } else {
            result = await procedureService.update(id, data)
        }
        procedureStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${text} quy trình thành công`, 3)
            $('#close_add_edit').trigger('click')
            procedureStore.clearForm()
            await procedureStore.getProcedure()
        } else {
            toastUtil.error(result.body.message ?? `${text} quy trình thất bại`, 4)
        }
    }

    async delete() {
        procedureStore.isLoadingBt = true
        const result = await procedureService.delete(procedureStore.id)
        procedureStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa quy trình thành công', 5)
            await procedureStore.getProcedure()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }
}

export const procedureStore = new ProcedureStore()
