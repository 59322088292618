import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../../../common/component/Loading";
import {checkPermission, getIDParameter} from "../../../../common/utils/Utils";
import NoContent from "../../../../common/component/NoContent";
import PopupConfirm from "../../../../common/component/PopupConfirm";
import ButtonAdd from "../../../../common/component/buttonAdd/ButtonAdd";
import AddOrEditWork from "./components/AddOrEditWork";
import {procedureJobStore} from "./ProcedureJobStore";
import {profileStore} from "../../../profile/ProfileStore";
import {Link} from "react-router-dom";
import {procedureStore} from "../ProcedureStore";
import {PermissionConst} from "../../../../common/constants/Constants";
import {dateUtils} from "../../../../common/utils/DateUtils";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

const ProcedureJobPage = () => {

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return
        }
        procedureJobStore.reorder(
            result.source.index,
            result.destination.index
        );

        procedureJobStore.isChanged = false
    }

    const returnType = (type: any) => {
        switch (type) {
            case "PREPARATION":
                return 'Chuẩn bị'
            case "PROCESSING":
                return 'Sản xuất'
            case "PACK":
                return 'Đóng gói'
            case "PRESERVE":
                return 'Bảo quản'
        }
    }

    useEffect(() => {
        procedureJobStore.procedureId = getIDParameter(4);
        profileStore.link = `/procedure`
        procedureStore.getDetail(procedureJobStore.procedureId)
        procedureJobStore.getProcedureJob()
    }, [])


    return (
        <div className='procedures'>
            <div className="d-flex header_back pb-2 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <Link to={`/manage/procedure`}>Quy trình</Link>
                    <i className="fal fa-angle-right"/>
                    <span>{procedureStore.dataRequest.name}</span>
                </div>
                <Link to={'/manage/procedure'} className="back">
                    <i className="far fa-arrow-left"/>
                    <span>Quay lại</span>
                </Link>
            </div>
            {checkPermission(PermissionConst.procedureAdd) && <div className="bt_add pt-0 mb-3 justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="d-flex mr-5">
                        <span>Sản phẩm: <strong>{procedureStore.dataRequest.product.name}</strong></span>
                    </div>
                    <div className="d-flex">
                        <span>Ngày tạo: <strong>{procedureStore.dataRequest.createdAt ? dateUtils.formatDate(procedureStore.dataRequest.createdAt) : ''}</strong></span>
                    </div>
                </div>
               <div className="d-flex align-items-center">
                   {checkPermission(PermissionConst.jobUpdate) && <button type="button" onClick={() => procedureJobStore.sortJob()} style={{width: 70, height: 40}}
                           className="btn send-request-outline mr-2"
                           disabled={procedureJobStore.isChanged}>Lưu
                   </button>}
                   {checkPermission(PermissionConst.jobAdd) && <ButtonAdd text="Thêm công việc" dataTarget={'add_or_edit_work'} onClick={procedureJobStore.clearForm}/>}
               </div>
            </div>}

            {procedureJobStore.isLoading ? <Loading/> : <div className="table-responsive">
                {procedureJobStore.listProcedureJob && procedureJobStore.listProcedureJob.length > 0 ?
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>STT</TableCell>
                                    <TableCell className="text-center">Tên công việc</TableCell>
                                    <TableCell className="text-center">Công đoạn</TableCell>
                                    <TableCell className="text-center">Bộ phận</TableCell>
                                    <TableCell className="text-center">Thao tác</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableComponent(onDragEnd)}>
                            {procedureJobStore.listProcedureJob.map((item: any, i: number) => {
                                return (
                                    <TableRow component={DraggableComponent(item.id.toString(), i)} key={i}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell className="text-center">{item.name}</TableCell>
                                        <TableCell className="text-center">{item.stage}</TableCell>
                                        <TableCell className="text-center">{returnType(item.type)}</TableCell>
                                        <TableCell className="text-center" width="20%">
                                            <div className="action">
                                                {checkPermission(PermissionConst.jobUpdate) && <button className="bt_action"
                                                        data-toggle="modal" data-target="#add_or_edit_work"
                                                        onClick={() => procedureJobStore.getDetail(item.id)}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}
                                                {checkPermission(PermissionConst.jobDelete) && <button className="bt_action"
                                                        onClick={() => procedureJobStore.id = item.id}
                                                        data-toggle="modal" data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer> : <NoContent/>}
            </div>}

            <AddOrEditWork/>
            <PopupConfirm isLoading={procedureJobStore.isLoadingBt} action={procedureJobStore.delete}/>
        </div>
    );
}


export default observer(ProcedureJobPage);

// @ts-ignore
const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const DraggableComponent = (id: string, index: number) => (props: any) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
};

const DroppableComponent = (
    onDragEnd: (result: any, provided: any) => void) => (props: any) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
};

