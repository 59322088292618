import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../../../common/component/form/Input";
import NoContent from "../../../../../../common/component/NoContent";
import './ModalExport.scss'
import {IDataSupplies, manufacturingStore} from "../../../../ManufacturingStore";
import {numberUtil} from "../../../../../../common/utils/NumberUtil";

const ModalExportSuppliesManufacturing = () => {
    return (
        <div className="modal fade" id="modal-export-manufacturing-supplies" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">XUẤT DƯ SẢN XUẤT</h5>
                        <button type="button" className="close" id="close_add_edit_product" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="mx-3 w-auto table-responsive custom-scrollbar">
                        <table
                            className='table align-middle table-hover table-rounded border h-50'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th className="text-center">Tên thành phần</th>
                                <th className="text-center">Đơn vị</th>
                                <th className="text-center">Số lượng đã xuất</th>
                                <th className="text-center">Lượng xuất dư</th>
                            </tr>
                            </thead>
                            <tbody>
                            {manufacturingStore.listSupplies && manufacturingStore.listSupplies.map((item: IDataSupplies, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{item.name}</td>
                                        <td className="text-center">{item.unit}</td>
                                        <td className="text-center">{item.quantity}</td>
                                        <td className="text-center input-height-33">
                                            <div className="mr-1">
                                                <Input type="number"
                                                       onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                       disabled={item.quantity === 0}
                                                       value={item.estimatedQuantity}
                                                       placeholder={item.quantity !== 0 ? `Lớn nhất ${item.quantity}` : 'Không có vật tư'}
                                                       isShowError={false}
                                                       onChange={(e: any) => {
                                                           if (numberUtil.regexNumber(e) <= item.quantity) {
                                                               item.estimatedQuantity = numberUtil.regexNumber(e)
                                                           }
                                                       }
                                                       }/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {manufacturingStore.errorsImportProductRequest.supplies &&
                            <span
                                className="error d-flex justify-content-end px-3">{manufacturingStore.errorsImportProductRequest.supplies}</span>}
                        {manufacturingStore.listSupplies && manufacturingStore.listSupplies.length < 1 &&
                            <NoContent height={250}/>}
                        <div className="form-group">
                            <label>Lý do <span className="text-danger">*</span></label>
                            <Input
                                isError={manufacturingStore.errorsImportProductRequest.note}
                                value={manufacturingStore.dataRequestEISupplies.note}
                                onChange={(e: any) => manufacturingStore.dataRequestEISupplies.note = e.currentTarget.value}
                                placeholder="Nhập ghi chú..."/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn button-close-export-batch mt-3 mr-2"
                                data-dismiss="modal"
                                aria-label="Close">
                            Hủy
                        </button>
                        <button type="button" className="btn button-import-surplus mt-3"
                                onClick={async () => await manufacturingStore.EISuppliesManufacturing()}
                                disabled={manufacturingStore.isLoadingBtn}
                        >Gửi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ModalExportSuppliesManufacturing);