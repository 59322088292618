import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {signUpStore} from "./SignUpStore"
import "./SignUpStyle.scss"
import {Navigate} from "react-router-dom";
import {profileStore} from "../../profile/ProfileStore";
import StorageService from "../../../common/service/StorageService";


const SignUpPage = observer(() => {

        useEffect(() => {
            if(signUpStore.confirmAcc){
                signUpStore.getProvinces().then()
            }
            return () => {
                signUpStore.clearForm()
            };
        }, [signUpStore.confirmAcc])

        if (StorageService.getToken() && profileStore.dataFarm.facilityId && profileStore.dataFarm.status !== 'BLOCK') {
            return <Navigate to={'/dashboard'} replace/>
        } else {
            return (
                <div className="signUp_main">
                    <div className="row flex-grow">
                        <div className="col-6 background-signup-container">
                            <img src="/assets/images/banner_login_v1.webp" className="w-100 vh-100"
                                 style={{objectFit: 'cover'}} alt=""/>
                            <div className="download_container">
                                <h2>
                                    DOWNLOAD
                                </h2>
                                <div
                                    className="d-flex justify-content-center align-items-center icon download_container_logo">
                                    <img src="/assets/ico/setup/Logo SmartOCOP-01 1.svg" alt=""/>
                                </div>
                                <div className="d-flex justify-content-between my-2">
                                    <div className="d-flex justify-content-center align-items-center icon mx-1">
                                        <img src="/assets/ico/setup/image 20.svg" alt=""/>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center icon mx-1">
                                        <img src="/assets/ico/setup/image 16.svg" alt=""/>
                                    </div>
                                </div>
                                <p>
                                    Tải xuống để trải nghiệm!
                                </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex pr-4 pt-3 pb-4 w-100 justify-content-end">
                                <span>Bạn đang đăng nhập với tài khoản <span
                                    className='color-green font-weight-medium'>{profileStore.dataFarm.name}</span>!</span>
                                <a className="ml-4 d-flex align-items-center"
                                   href={`${process.env.REACT_APP_DOMAIN_SSO}/login?redirectTo=${window.location.origin}&service=food${profileStore.path === "cms" ? `&cms=true` : ''}`}
                                   onClick={() => {
                                       StorageService.removeToken();
                                       StorageService.removeRefreshToken()
                                   }}>
                                    <img className="mr-2" src="/assets/ico/action/log-out.svg" alt=""/>
                                    Đăng xuất
                                </a>
                            </div>
                            {profileStore.dataFarm.status === 'BLOCK' ?
                                <div className="d-flex h-75 justify-content-center align-items-center">
                                    <div className="confirm_account_container bg-white">
                                        <div className="logo_login"
                                             style={{marginBottom: 29}}>
                                            <img src="/assets/images/logo_login.svg" alt=""/>
                                        </div>
                                        <h1 style={{color: '#E60A32'}}>Tài khoản đã bị khóa!</h1>
                                        <span
                                            className="text_des">Vui lòng liên hệ đến quản lý để được mở khóa tài khoản và <br/>tiếp tục sử dụng.</span>

                                        <div
                                            className="footer_action d-flex align-items-center justify-content-between">
                                            <button type="button" className="btn bt-reload-signup"
                                                    style={{marginTop: 16}}
                                                    disabled={signUpStore.isLoading}
                                                    onClick={async () => {
                                                        await signUpStore.getToken()
                                                    }}>
                                                <img src="/assets/ico/action/timer-reset.svg" alt=""/>
                                                <span className="mx-3">
                                            Tải Lại Trang
                                        </span>
                                            </button>
                                        </div>
                                    </div>
                                </div> : <div className="d-flex justify-content-center align-items-center"
                                              style={{minHeight: `75%`}}>
                                    {!signUpStore.confirmAcc ? <div className="confirm_account_container bg-white">
                                            <div className="logo_login" style={{marginBottom: 65}}>
                                                <img src="/assets/images/logo_login.svg" alt=""/>
                                            </div>
                                            <div>
                                                <h2>Tài khoản chưa được duyệt!</h2>
                                                <p>Vui lòng gửi số điện thoại của bạn đến người có thẩm quyền để được đăng nhập
                                                    và sử dụng các tính năng.</p>
                                            </div>

                                            <div
                                                className="footer_action d-flex align-items-center justify-content-between">
                                                <button type="button" className="btn bt-signUp"
                                                        style={{marginTop: 16}}
                                                        onClick={() => signUpStore.confirmAcc = true}>
                                                    Đăng Ký Farm Mới
                                                </button>
                                                <button type="button" className="btn bt-reload-signup"
                                                        style={{marginTop: 16}}
                                                        disabled={signUpStore.isLoading}
                                                        onClick={async () => {
                                                            await signUpStore.getToken()
                                                        }}>
                                                    <img src="/assets/ico/action/timer-reset.svg" alt=""/>
                                                    <span className="mx-3">
                                            Tải Lại Trang
                                        </span>
                                                </button>
                                            </div>
                                        </div> :
                                        <div className="form_signUp bg-white">
                                            <div className="logo_login d-flex justify-content-center"
                                                 style={{marginBottom: 35}}>
                                                <img src="/assets/images/logo_login.svg" alt=""/>
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-back-confirm-acc"
                                                        onClick={() => signUpStore.confirmAcc = false}>
                                                    <img src="/assets/ico/action/fi_arrow-left.svg" alt=""/>
                                                    Quay Lại
                                                </button>
                                            </div>
                                            <form className="step_2">
                                                <div className="form-group mt-2">
                                                    <label>Tên công ty, HTX, Đơn vị <span
                                                        className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" value={signUpStore.dataRequest.name}
                                                               onChange={(e: any) => {
                                                                   if (e.currentTarget.value.length > 0) {
                                                                       signUpStore.errors.farm_name = ""
                                                                   } else {
                                                                       signUpStore.errors.farm_name = "Tên đơn vị không được để trống!"
                                                                   }
                                                                   signUpStore.dataRequest.name = e.currentTarget.value
                                                               }}
                                                               className={`form-control form-control-lg ${signUpStore.errors.farm_name ? 'border_error' : ''}`}
                                                               placeholder="Nhập tên đơn vị"/>
                                                    </div>
                                                    <p className="error">{signUpStore.errors && signUpStore.errors.farm_name}</p>
                                                </div>
                                                <div className="form-group h-auto">
                                                    <select
                                                        className={`form-control form-control-lg ${signUpStore.errors.type ? 'border_error' : ''}`}
                                                        value={signUpStore.dataRequest.type}
                                                        onChange={(e: any) => {
                                                            signUpStore.dataRequest.type = e.currentTarget.value;
                                                            signUpStore.errors.type = ''
                                                        }}>
                                                        <option value="" disabled>Chọn loại hình</option>
                                                        {signUpStore.listType && signUpStore.listType.map((item: any, i: number) => {
                                                            return (
                                                                <option value={item.type}
                                                                        key={i}>{item.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <p className="error">{signUpStore.errors && signUpStore.errors.type}</p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="w-50 pr-3">
                                                        <div className="form-group h-auto">
                                                            <select
                                                                className={`form-control form-control-lg ${signUpStore.errors.province ? 'border_error' : ''}`}
                                                                value={signUpStore.dataRequest.provinceId}
                                                                onChange={(e: any) => signUpStore.changeProvince(e.currentTarget.value)}>
                                                                <option value="" disabled>Chọn tỉnh thành phố
                                                                </option>
                                                                {signUpStore.listProvince && signUpStore.listProvince.map((item, i) => {
                                                                    return (
                                                                        <option value={item.id}
                                                                                key={i}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <p className="error">{signUpStore.errors && signUpStore.errors.province}</p>
                                                        </div>
                                                    </div>
                                                    <div className="w-50 pl-4">
                                                        <div className="form-group h-auto">
                                                            <select
                                                                className={`form-control form-control-lg ${signUpStore.errors.district ? 'border_error' : ''}`}
                                                                value={signUpStore.dataRequest.districtId}
                                                                onChange={(e: any) => signUpStore.changeDistrict(e.currentTarget.value)}>
                                                                <option value="" disabled>Chọn quận huyện</option>
                                                                {signUpStore.listDistrict && signUpStore.listDistrict.map((item, i) => {
                                                                    return (
                                                                        <option value={item.id}
                                                                                key={i}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <p className="error">{signUpStore.errors && signUpStore.errors.district}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group h-auto">
                                                    <select
                                                        className={`form-control form-control-lg ${signUpStore.errors.ward ? 'border_error' : ''}`}
                                                        value={signUpStore.dataRequest.wardId}
                                                        onChange={(e: any) => {
                                                            signUpStore.dataRequest.wardId = e.currentTarget.value;
                                                            signUpStore.errors.ward = ''
                                                        }}>
                                                        <option value="" disabled>Chọn phường xã</option>
                                                        {signUpStore.listWard && signUpStore.listWard.map((item, i) => {
                                                            return (
                                                                <option value={item.id} key={i}>{item.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <p className="error">{signUpStore.errors && signUpStore.errors.ward}</p>
                                                </div>

                                                <div className="form-group">
                                                    <label>Địa chỉ cụ thể</label>
                                                    <div className="input-group">
                                                        <input type="text" value={signUpStore.dataRequest.address}
                                                               onChange={(e: any) => signUpStore.dataRequest.address = e.currentTarget.value}
                                                               className="form-control form-control-lg"
                                                               placeholder="Nhập số nhà cụ thể"/>
                                                    </div>
                                                </div>

                                                <div
                                                    className="footer_action d-flex align-items-center justify-content-end">
                                                    <button type="button" className="btn bt-signUp"
                                                            style={{marginTop: 16}}
                                                            onClick={() => signUpStore.addFarm()}
                                                            disabled={signUpStore.isLoading}>
                                                        {signUpStore.isLoading ?
                                                            <i className="fa fa-spinner fa-spin"/> : `Hoàn Thành`}
                                                    </button>
                                                </div>

                                            </form>
                                        </div>}
                                </div>}
                        </div>
                    </div>
                </div>
            )
        }
    }
)

export default SignUpPage;