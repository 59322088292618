import React from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {ITabDetailPlanManufacturing, manufacturingStore} from "../ManufacturingStore";

const TabHeaderDetailManufacturing = (): JSX.Element => {
    return (
        <div className="tab_header d-flex align-items-center px-3">
            <Link
                to={`/manufacturing/${manufacturingStore.id}?tab=${ITabDetailPlanManufacturing.job}`}
                className={`tab ${manufacturingStore.tabActiveDetailManufacturing === ITabDetailPlanManufacturing.job && 'active'}`}
                onClick={() => {
                    manufacturingStore.tabActiveDetailManufacturing = ITabDetailPlanManufacturing.job;
                }}>
                <span>Công Việc</span>
            </Link>
            <Link
                to={`/manufacturing/${manufacturingStore.id}?tab=${ITabDetailPlanManufacturing.supplies}`}
                className={`tab ${manufacturingStore.tabActiveDetailManufacturing === ITabDetailPlanManufacturing.supplies && 'active'}`}
                onClick={() => {
                    manufacturingStore.tabActiveDetailManufacturing = ITabDetailPlanManufacturing.supplies;
                }}>
                <span>Vật Tư</span>
            </Link>
        </div>
    );
}

export default observer(TabHeaderDetailManufacturing);
