import {
    deleteRequest,
    getRequest,
    IApiResponse,
    postRequest,
    putRequest
} from "../../../../common/helpers/RequestHelper";
import {
    IDataRequestExportProductBody,
    IDataRequestExportProductByBatchBody,
    IDataRequestProduct,
    productStore
} from "./ProductStore";

class ProductService {
    public getProducts(size?: any, keyword?: string): Promise<IApiResponse> {
        return getRequest(`/food/v1/product?page=${productStore.page}&size=${size ?? 10}&categoryId=${productStore.categoryId}${productStore.keyword ? `&keyword=${productStore.keyword.trim()}` : ''}`);
    }

    public detailProduct(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/product/${id}`);
    }

    public create(data: IDataRequestProduct): Promise<IApiResponse> {
        return postRequest(`/food/v1/product`, data);
    }

    public update(id: any, data: IDataRequestProduct): Promise<IApiResponse> {
        return putRequest(`/food/v1/product/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/product/${id}`, {id});
    }

    public exportProduct(data: IDataRequestExportProductBody): Promise<IApiResponse> {
        return postRequest(`/food/v1/product_batch/_bulk`, data);
    }

    public exportProductByBatch(data: IDataRequestExportProductByBatchBody): Promise<IApiResponse> {
        return postRequest(`/food/v1/product_batch`, data);
    }

    public getProductBatch(productIds?: string): Promise<IApiResponse> {
        return getRequest(`/food/v1/product_batch${productStore.page > -1 ? `?page=${productStore.page}` : ''}${productIds ? `&productIds=${productIds}` : ''}${productStore.keyword ? `&batchCode=${productStore.keyword}` : ''}`);
    }

    public getBatchExportProduct(productIds?: string): Promise<IApiResponse> {
        return getRequest(`/food/v1/product_batch/export${productIds ? `?productIds=${productIds}` : ''}`);
    }

    public getHistoryProductBatch(productId?: number, productBatchId?: number): Promise<IApiResponse> {
        return getRequest(`/food/v1//product_batch_log${productId ? `?productId=${productId}` : ''}${productBatchId ? `&productBatchId=${productBatchId}` : ''}`);
    }

    public getSuppliesLogByProcedureBatch(productBatchId?: number): Promise<IApiResponse> {
        return getRequest(`/food/v1/batch/procedure_batch${productBatchId ? `/${productBatchId}` : ''}`);
    }
}

export const productService = new ProductService();