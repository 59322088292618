import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Loading from "../../../../common/component/Loading";
import NoContent from "../../../../common/component/NoContent";
import Paginate from "../../../../common/component/Paginate";
import PopupConfirm from "../../../../common/component/PopupConfirm";
import ButtonAdd from "../../../../common/component/buttonAdd/ButtonAdd";
import {equipmentStore} from "./EquipmentStore";
import AddOrEditEquipment from "./components/AddOrEditEquipment";
import {checkPermission, number_format, sttPage} from "../../../../common/utils/Utils";
import {PermissionConst} from "../../../../common/constants/Constants";
import FilterComponent from "../../../../common/component/filter/FilterComponent";
import PDFViewer from "../../../../common/component/PDFViewer";
import {Icon} from "../../../../common/component/Icon";


const EquipmentPage = () => {

    const changePage = async (data: any) => {
        equipmentStore.page = data.selected;
        await equipmentStore.getEquipments();
    }

    useEffect(() => {
        equipmentStore.getEquipments()
        return (() => {
            equipmentStore.keyword = ''
        })
    }, [])

    let data: any = [
        {
            label: 'Tên thiết bị',
            type: 'search',
            placeholder: 'Nhập tên thiết bị',
            value: equipmentStore.keyword,
            onChange: (e: any) => {
                equipmentStore.keyword = e
            },
            callback: async () => {
                await equipmentStore.getEquipments();
            }
        }
    ]

    return (
        <div className='equipment'>
            <FilterComponent data={data} permissionCode={checkPermission(PermissionConst.suppliesAdd)} label="Thiết bị"
                             buttonAdd={<ButtonAdd text="Thêm thiết bị" dataTarget={'add_or_edit_equipment'}
                                                   onClick={equipmentStore.clearForm}/>}/>

            {equipmentStore.isLoading ? <Loading/> : <>
                {equipmentStore.listEquipment && equipmentStore.listEquipment.length > 0 ?
                    <div className='table-responsive'>
                        <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">STT</th>
                                <th>Tên thiết bị</th>
                                <th className="text-center">Số lượng</th>
                                <th className="text-center">HDSD</th>
                                <th className="text-center">Giá thành</th>
                                <th className="text-center">Ghi chú</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>

                            {equipmentStore.listEquipment.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(equipmentStore.page, i)}</td>
                                        <td>{item.name}</td>
                                        <td className="text-center">{number_format(item.quantity)} Cái</td>
                                        <td className="text-center">{item.tutorial && item.tutorial[0] &&
                                            <div title="Hướng dẫn sử dụng"><Icon name="description"
                                                                                 dataTarget="pdf_viewer"
                                                                                 onClick={() => equipmentStore.urlViewer = item.tutorial[0]}
                                                                                 type="outlined"/></div>}</td>
                                        <td className="text-center">{item.price ? number_format(item.price) : ""}</td>
                                        <td className="text-center"><p className="des" title={item.note}>{item.note}</p>
                                        </td>
                                        <td className="text-center" width="20%">
                                            <div className="action">
                                                {checkPermission(PermissionConst.suppliesUpdate) &&
                                                    <button className="bt_action" title="Sửa"
                                                            data-toggle="modal"
                                                            data-target="#add_or_edit_equipment"
                                                            onClick={() => equipmentStore.getDetail(item.id)}>
                                                        <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                    </button>}
                                                {checkPermission(PermissionConst.suppliesDelete) &&
                                                    <button className="bt_action" title="Xóa"
                                                            onClick={() => equipmentStore.id = item.id}
                                                            data-toggle="modal" data-target="#popup_confirm">
                                                        <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                    </button>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Paginate currentPage={equipmentStore.page} totalPage={equipmentStore.totalPage}
                                      changePage={changePage}/>
                        </div>
                    </div> : <NoContent/>}

            </>}

            <PDFViewer url={equipmentStore.urlViewer}/>
            <AddOrEditEquipment/>
            <PopupConfirm isLoading={equipmentStore.isLoadingBt} action={equipmentStore.delete}/>

        </div>
    );
}

export default observer(EquipmentPage);