import React, {useEffect} from 'react';
import Loading from "../../../common/component/Loading";
import {checkPermission, slug} from "../../../common/utils/Utils";
import {dateUtils} from "../../../common/utils/DateUtils";
import {Link} from "react-router-dom";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import {observer} from "mobx-react";
import {expImpBatchStore} from "./EIBatchStore";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import {setPlanStore} from "../../setPlan/SetPlanStore";
import ExportBatch from "./detailEIBatch/components/exportBatch/ExportBatch";
import CancelExportBatch from "./detailEIBatch/components/cancelExportBatch/CancelExportBatch";
import './EIBatchPage.scss'
import ImportProduct from "./detailEIBatch/components/importProduct/ImportProduct";
import SurplusProduct from "./detailEIBatch/components/surplusProduct/SurplusProduct";
import {trackingStore} from "../../tracking/TrackingStore";
import {detailBatchStore} from "./detailEIBatch/DetailEIBatchStore";
import {preserveStore} from "../../preserve/PreserveStore";
import {convertDepartment} from "./detailEIBatch/DetailEIBatchPage";
import {PermissionConst} from "../../../common/constants/Constants";


const EIBatchPage = () => {

    const changePage = async (data: any) => {
        expImpBatchStore.page = data.selected;
        await expImpBatchStore.getWarehouseRequest()
    }

    useEffect(() => {
        expImpBatchStore.getWarehouseRequest()
        expImpBatchStore.dataFilter = [
            {
                label: 'Lô',
                type: 'search',
                placeholder: 'Nhập từ khóa tìm kiếm',
                value: expImpBatchStore.keyword,
                onChange: (e: any) => {
                    expImpBatchStore.keyword = e
                    expImpBatchStore.page = 0
                },
                callback: async () => {
                    await expImpBatchStore.getWarehouseRequest()
                }
            },
            {
                label: 'Mã yêu cầu',
                type: 'search',
                placeholder: 'Nhập từ khóa tìm kiếm',
                value: expImpBatchStore.searchId,
                onChange: (e: any) => {
                    expImpBatchStore.searchId = e
                    expImpBatchStore.page = 0
                },
                callback: async () => {
                    await expImpBatchStore.getWarehouseRequest()
                }
            },
            {
                label: 'Tình trạng',
                type: 'select',
                placeholder: 'Tất cả',
                value: expImpBatchStore.searchStatus,
                listValue: expImpBatchStore.listOptionSearchStatus,
                callback: async (e: any) => {
                    expImpBatchStore.searchStatus = e
                    expImpBatchStore.page = 0
                    await expImpBatchStore.getWarehouseRequest()
                }
            },
            {
                label: 'Xuất/Nhập',
                type: 'select',
                placeholder: 'Tất cả',
                value: expImpBatchStore.searchEI,
                listValue: expImpBatchStore.listOptionSearchType,
                callback: async (e: any) => {
                    expImpBatchStore.searchEI = e
                    expImpBatchStore.page = 0
                    await expImpBatchStore.getWarehouseRequest();
                }
            },
            {
                label: 'Năm',
                type: 'select',
                value: trackingStore.searchYear,
                listValue: setPlanStore.listYear,
                callback: async (e: any) => {
                    expImpBatchStore.searchYear = e
                    expImpBatchStore.page = 0
                    await expImpBatchStore.getWarehouseRequest();
                }
            }
        ]
    }, [])


    return (
        <div className='table-responsive'>
            <FilterComponent
                data={expImpBatchStore.dataFilter}
                label="Xuất nhập lô sản xuất"
            />
            {expImpBatchStore.isLoading ? <Loading/> : <>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">Mã yêu cầu</th>
                        <th className="text-center">Thời gian</th>
                        <th className="text-center">Xuất/Nhập</th>
                        <th className="text-center">Lô sản xuất</th>
                        <th className="text-center">Sản phẩm</th>
                        <th className="text-center">Người yêu cầu</th>
                        <th className="text-center">Bộ phận</th>
                        <th className="text-center">Tình trạng</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {expImpBatchStore.listEIBatch && expImpBatchStore.listEIBatch.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">#000{item.id ?? ""}</td>
                                <td className="text-center">{item.timeRequest ? dateUtils.formatDate(Number(item.timeRequest), 'hh:mm DD/MM/YYYY') : '-'}</td>
                                <td className={`text-center ${item.type === "IMPORT" ? "type_import" : "type_export"}`}>
                                    {item.type === "IMPORT" ? "Nhập kho" : "Xuất kho"}
                                </td>
                                <td className="text-center">{item.procedureBatch ? item.procedureBatch.name : '-'}</td>
                                <td className="text-center">{item.procedureBatch ? item.procedureBatch.product.name : '-'}</td>
                                <td className="text-center">{item.sender?.name ?? "-"}</td>
                                <td className={`text-center ${item.sender.department === "MANAGER" ? "fw-600" : ""}`}>{item?.sender.department ? convertDepartment(item.sender.department) : "---"}</td>
                                <td className={`text-center ${item.state === "APPROVED" ? "statement_approved" : item.state === "REJECT" ? "statement_reject" : "statement_pendding"}`}>
                                    {item.state === "APPROVED" ? "Hoàn thành" : (item.state === "REJECT" ? "Từ chối" : "Đang chờ")}
                                </td>
                                <td className="text-center" width="18%">
                                    <Link className="view-detail-eibatch" data-toggle="tooltip" data-placement="top"
                                          title="Xem chi tiết"
                                          onClick={() => {
                                              expImpBatchStore.id = item.id
                                              expImpBatchStore.procedureBatch = item.procedureBatch.name
                                              expImpBatchStore.suplieIds = ''
                                              detailBatchStore.isGoToAccept = false
                                              detailBatchStore.isGoToFinish = false
                                          }}
                                          to={`/warehouse/batch/${slug(item.procedureBatch.name)}/${item.id}`}>
                                        <img src="/assets/ico/action/ico_detail.svg" className="icon-action"
                                             alt=""/>
                                    </Link>
                                    {checkPermission(PermissionConst.approveProductionRequest) &&
                                        <button className="bt-action-accept"
                                                disabled={item.state === "APPROVED" || item.state === "REJECT"}
                                                onClick={async () => {
                                                    detailBatchStore.clearFormData()
                                                    expImpBatchStore.id = item.id
                                                    expImpBatchStore.procedureBatch = item.procedureBatch.name
                                                    expImpBatchStore.procedureBatchId = item.procedureBatch.id
                                                    expImpBatchStore.suplieIds = ''
                                                    await expImpBatchStore.getDetailWarehouseRequest(Number(expImpBatchStore.id))
                                                    if (item.type === "IMPORT") await preserveStore.getDetailPreserves(expImpBatchStore.procedureBatchId)
                                                }}
                                                data-toggle="modal"
                                                data-target={`${item.type === "EXPORT" ? "#export_batch_product" : (item.batchType === "SUPPLIES" ? "#surplus-product" : "#import_product")}`}>
                                            <img src="/assets/ico/action/check-square.svg" className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Đồng ý"/>
                                        </button>}
                                    {checkPermission(PermissionConst.approveProductionRequest) &&
                                        <button className="bt-action-accept"
                                                disabled={item.state === "APPROVED" || item.state === "REJECT"}
                                                onClick={async () => {
                                                    expImpBatchStore.id = item.id
                                                    expImpBatchStore.procedureBatch = item.procedureBatch?.name
                                                    expImpBatchStore.suplieIds = ''
                                                    expImpBatchStore.typeModalCancel = item.type === "IMPORT" ? "Từ chối nhập kho" : "Từ chối xuất kho"
                                                    detailBatchStore.clearFormData()
                                                    await expImpBatchStore.getDetailWarehouseRequest(Number(expImpBatchStore.id))
                                                }}
                                                data-toggle="modal" data-target="#cancel-export-batch">
                                            <img src="/assets/ico/action/x-octagon.svg"
                                                 className="icon-action icon-cancel-disable"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Từ chối"/>
                                        </button>}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {expImpBatchStore.listEIBatch && expImpBatchStore.listEIBatch.length < 1 && <NoContent/>}
            </>}

            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={expImpBatchStore.page} totalPage={expImpBatchStore.totalPage}
                          changePage={changePage}/>
            </div>
            <ImportProduct/>
            <ExportBatch/>
            <SurplusProduct/>
            <CancelExportBatch isLoading={expImpBatchStore.isLoadingBt} titlePopup={expImpBatchStore.typeModalCancel}/>

        </div>
    );
}

export default observer(EIBatchPage);
