import {observable} from "mobx";
import {checkPermission} from "../../common/utils/Utils";
import {PermissionConst} from "../../common/constants/Constants";

export enum IRole {
    admin = 'admin',
    user = 'user'
}

class SidebarStore {
    @observable pathSubmenu: any = ''
    @observable data: any = [
        {
            id: 0,
            role: IRole.admin,
            menu: [
                {
                    id: 1,
                    name: "Chờ xét duyệt",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_smile_plus",
                    link: '/cms/users'
                },
                {
                    id: 1,
                    name: "Đang hoạt động",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_people",
                    link: '/cms/users/active'
                },
                {
                    id: 1,
                    name: "Đang tạm khóa",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_lock",
                    link: '/cms/users/lock'
                },
                {
                    id: 1,
                    name: "Quản lý quyền",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_permission",
                    link: '/cms/permission'
                },
                {
                    id: 1,
                    name: "Quyền mặc định",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/ico_permission_default",
                    link: '/cms/permission-default'
                },
            ]
        },
        {
            id: 1,
            role: IRole.user,
            menu: [
                {
                    id: 0,
                    name: "Tổng quan",
                    isAccess: true,
                    icon: "/assets/ico/sidebar/info",
                    link: '/'
                },
                {
                    id: 2,
                    name: "Thiết lập",
                    isAccess: checkPermission(PermissionConst.owner) || checkPermission(PermissionConst.accountView) || checkPermission(PermissionConst.procedureView),
                    isActive: false,
                    icon: "/assets/ico/sidebar/setup",
                    submenu: [
                        {
                            id: 0,
                            name: "Phân quyền",
                            isAccess: checkPermission(PermissionConst.owner),
                            isActive: false,
                            link: '/manage/role',
                        },
                        {
                            id: 0,
                            name: "Tài khoản",
                            isAccess: checkPermission(PermissionConst.accountView),
                            isActive: false,
                            link: '/manage/account',
                        },
                        {
                            id: 1,
                            name: "Quy trình",
                            isAccess: checkPermission(PermissionConst.procedureView),
                            isActive: false,
                            link: '/manage/procedure',
                        }
                    ]
                },
                {
                    id: 4,
                    name: "Lô SX & Theo dõi",
                    isAccess: checkPermission(PermissionConst.planView),
                    icon: "/assets/ico/sidebar/procedure",
                    link: '/planing'
                },
                {
                    id: 5,
                    name: "Kho",
                    isAccess: checkPermission(PermissionConst.productionRequestView) || checkPermission(PermissionConst.productCateView) || checkPermission(PermissionConst.suppliesView),
                    isActive: false,
                    icon: "/assets/ico/sidebar/warehouse",
                    link: '/warehouse',
                    submenu: [
                        {
                            id: 0,
                            name: "Xuất - Nhập SX",
                            isAccess: checkPermission(PermissionConst.productionRequestView),
                            isActive: false,
                            link: '/warehouse/export_import_batch',
                        },
                        {
                            id: 1,
                            name: "Danh mục sản phẩm",
                            isAccess: checkPermission(PermissionConst.productCateView),
                            isActive: false,
                            link: '/warehouse/product_category',
                        },
                        {
                            id: 0,
                            name: "Vật tư",
                            isAccess: checkPermission(PermissionConst.suppliesView),
                            isActive: false,
                            link: '/warehouse/supplies',
                        }
                    ]
                },
                {
                    id: 6,
                    name: "Sản xuất",
                    isAccess: checkPermission(PermissionConst.productionView),
                    icon: "/assets/ico/sidebar/manufacturing",
                    link: '/manufacturing',
                },
                {
                    id: 7,
                    name: "Đóng gói",
                    isAccess: checkPermission(PermissionConst.packagingView),
                    icon: "/assets/ico/sidebar/packge",
                    link: '/packge',
                },
                {
                    id: 8,
                    name: "Truy xuất",
                    isAccess: checkPermission(PermissionConst.trackingView),
                    icon: "/assets/ico/sidebar/access",
                    link: '/tracking'
                }
            ]
        }
    ];
}

export const sidebarStore = new SidebarStore()