import React from 'react';
import {observer} from "mobx-react";
import Input from "../../../../../common/component/form/Input";
import {batchStore} from "../BatchStore";
import {number_format} from "../../../../../common/utils/Utils";
import {numberUtil} from "../../../../../common/utils/NumberUtil";


const ExportBatch = () => {


    return (
        <div className="modal fade" id="export_batch" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Xuất kho</h5>
                        <button type="button" className="close" id="close_export" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Số lượng xuất <span className="text-danger">*</span></label>
                            <Input value={batchStore.dataExport.quantity} isError={batchStore.errors.exportQuantity}
                                   onChange={(e: any) => {
                                       if (numberUtil.regexNumber(e) <= batchStore.totalRemaining) {
                                           batchStore.dataExport.quantity = numberUtil.regexNumber(e)
                                       }
                                   }}
                                   placeholder={`Tối đa ${number_format(batchStore.totalRemaining)}`}/>
                        </div>
                        <div className="form-group">
                            <label>Ghi chú <span className="text-danger">*</span></label>
                            <Input value={batchStore.dataExport.note} isError={batchStore.errors.exportNote}
                                   onChange={(e: any) => batchStore.dataExport.note = e.currentTarget.value}
                                   placeholder={`Nhập ghi chú`}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => batchStore.export()} disabled={batchStore.isLoadingBt}>Xuất
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ExportBatch);