import React from 'react';
import {observer} from "mobx-react";
import {infoStore, TypeInfo} from "./InfoStore";
import {uploadImgHelper} from "../../common/helpers/UploadImgHelper";
import {Link} from "react-router-dom";
import Input from "../../common/component/form/Input";
import {numberUtil} from "../../common/utils/NumberUtil";
import {checkPermission, urlImage} from "../../common/utils/Utils";
import {PermissionConst} from "../../common/constants/Constants";
import {signUpStore} from "../auth/signUp/SignUpStore";
import Select from "../../common/component/form/Select";

const EditInfo = () => {

    const uploadImg = (e: any) => {
        if (!infoStore.dataInfo.certificates) {
            infoStore.dataInfo.certificates = []
        }
        infoStore.dataInfo.certificates.push(e)
    }


    return (
        <>
            <div className="d-flex mb-3 justify-content-between align-items-center">
                <Link to={`/farm`} onClick={async () => {infoStore.type = TypeInfo.info; await infoStore.getInfo()}}
                      className="d-flex edit-info mb-4 align-items-center">
                    <i className="far fa-arrow-left"/>
                    <span>Quay lại</span>
                </Link>
                {checkPermission(PermissionConst.farmUpdate) &&
                <button className="btn send-request" style={{width: 180}} onClick={() => infoStore.updateInfo()}
                        disabled={infoStore.isLoadingBt}>Lưu thiết lập</button>}
            </div>
            <h2>Thông tin HTX</h2>
            <div className="d-flex">
                <div className="w-50 pr-3">
                    <div className="d-flex align-items-center form-info">
                        <label>Tên đơn vị <span className="text-danger">*</span></label>
                        <Input value={infoStore.dataInfo.name}
                               onChange={(e: any) => infoStore.dataInfo.name = e.currentTarget.value}
                               isError={infoStore.error} placeholder="Nhập tên đơn vị"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Tên đại diện <span className="text-danger">*</span></label>
                        <Input value={infoStore.dataInfo.representative}
                               onChange={(e: any) => infoStore.dataInfo.representative = e.currentTarget.value}
                               isError={infoStore.error} placeholder="Nhập tên đại diện"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Số điện thoại <span className="text-danger">*</span></label>
                        <Input value={infoStore.dataInfo.phone}
                               onChange={(e: any) => infoStore.dataInfo.phone = numberUtil.regexNumber(e)}
                               isError={infoStore.error} placeholder="Nhập số điện thoại"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Email <span className="text-danger">*</span></label>
                        <Input value={infoStore.dataInfo.email}
                               onChange={(e: any) => infoStore.dataInfo.email = e.currentTarget.value}
                               isError={infoStore.error} placeholder="Nhập email"/>
                    </div>


                    <div className="d-flex align-items-center form-info">
                        <label>Địa chỉ <span className="text-danger">*</span></label>
                        <div className="d-flex input_group justify-content-between">
                            <Select value={infoStore.dataInfo.province.id} className="mr-3"
                                    onChange={async (e: any) => {
                                        infoStore.dataInfo.province.id = e.currentTarget.value
                                        infoStore.dataInfo.district.id = '';
                                        infoStore.dataInfo.ward.id = ''
                                        await signUpStore.getDistricts(infoStore.dataInfo.province.id)
                                    }} options={signUpStore.listProvince} placeholder="Tỉnh / Thành phố"/>
                            <Select value={infoStore.dataInfo.district.id} className="mr-3"
                                    onChange={async (e: any) => {
                                        infoStore.dataInfo.district.id = e.currentTarget.value
                                        infoStore.dataInfo.ward.id = '';
                                        await signUpStore.getWards(infoStore.dataInfo.district.id)
                                    }}
                                    options={signUpStore.listDistrict} placeholder="Quận / Huyện"/>
                            <Select value={infoStore.dataInfo.ward.id}
                                    onChange={async (e: any) => infoStore.dataInfo.ward.id = e.currentTarget.value}
                                    options={signUpStore.listWard} placeholder="Phường / Xã"/>
                        </div>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label/>
                        <Input value={infoStore.dataInfo.address}
                               onChange={(e: any) => infoStore.dataInfo.address = e.currentTarget.value}
                               isError={infoStore.error} placeholder="Nhập địa chỉ cụ thể"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Đăng ký kinh doanh <span className="text-danger">*</span></label>
                        <Input value={infoStore.dataInfo.businessCode}
                               onChange={(e: any) => infoStore.dataInfo.businessCode = e.currentTarget.value}
                               isError={infoStore.error} placeholder="Nhập số đăng ký kinh doanh"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Website</label>
                        <Input value={infoStore.dataInfo.website}
                               onChange={(e: any) => infoStore.dataInfo.website = e.currentTarget.value}
                               placeholder="Nhập link website"/>
                    </div>
                </div>
                <div className="w-50 pl-3">
                    <div className="d-flex align-items-center form-info">
                        <label>Loại hình</label>
                        <Input value={infoStore.dataInfo.businessDescription}
                               onChange={(e: any) => infoStore.dataInfo.businessDescription = e.currentTarget.value}
                               placeholder="Nhập loại hình doanh nghiệp"/>

                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Sản phẩm chính</label>
                        <Input value={infoStore.dataInfo.mainProduct}
                               onChange={(e: any) => infoStore.dataInfo.mainProduct = e.currentTarget.value}
                               placeholder="Nhập sản phẩm chính"/>
                    </div>

                    <div className="d-flex align-items-center form-info">
                        <label>Tổng doanh thu</label>
                        <Input value={infoStore.dataInfo.totalRevenue}
                               onChange={(e: any) => infoStore.dataInfo.totalRevenue = e.currentTarget.value}
                               placeholder="Nhập tổng doanh thu"/>

                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Thị trường chính</label>
                        <Input value={infoStore.dataInfo.mainMarket}
                               onChange={(e: any) => infoStore.dataInfo.mainMarket = e.currentTarget.value}
                               placeholder="Nhập thị trường chính"/>

                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Tổng nhân viên</label>
                        <Input value={infoStore.dataInfo.totalLabor}
                               onChange={(e: any) => infoStore.dataInfo.totalLabor = e.currentTarget.value}
                               placeholder="Nhập tổng nhân viên"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Giấy chứng nhận</label>
                        <Input value={infoStore.dataInfo.testimonial}
                               onChange={(e: any) => infoStore.dataInfo.testimonial = e.currentTarget.value}
                               placeholder="Nhập tên giấy chứng nhận"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Năm thành lập</label>
                        <Input value={infoStore.dataInfo.foundedYear}
                               onChange={(e: any) => infoStore.dataInfo.foundedYear = e.currentTarget.value}
                               placeholder="Nhập năm thành lập"/>
                    </div>
                    <div className="d-flex align-items-center form-info">
                        <label>Chứng chỉ</label>
                        <Input value={infoStore.dataInfo.certificate}
                               onChange={(e: any) => infoStore.dataInfo.certificate = e.currentTarget.value}
                               placeholder="Nhập chứng chỉ"/>
                    </div>
                </div>
            </div>
            <>
                <h2>Giấy chứng nhận</h2>
                <div className="scroll_certificate">
                    <div className="list_certificate">
                        <div className="upload" title="Upload giấy chứng nhận">
                            <img src="/assets/ico/ico_cloud_upload.svg" alt=""/>
                            <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                   onChange={async (e: any) => await uploadImgHelper.onChangeFileThumb(e, uploadImg)}/>
                        </div>
                        {infoStore.dataInfo.certificates && infoStore.dataInfo.certificates.map((item: any, i: number) => {
                            return (
                                <div className="item" key={i}>
                                    <img src={urlImage(item)} alt=""/>
                                    <div className="close_item"
                                         onClick={() => infoStore.dataInfo.certificates.splice(i, 1)}>
                                        <img src="/assets/ico/ico_close.svg" alt=""/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        </>
    );
}

export default observer(EditInfo);
