import React, {useEffect} from 'react';
import Loading from "../../common/component/Loading";
import NoContent from "../../common/component/NoContent";
import {observer} from "mobx-react";
import FilterComponent from "../../common/component/filter/FilterComponent";
import ButtonAdd from "../../common/component/buttonAdd/ButtonAdd";
import {preserveStore} from "./PreserveStore";
import {checkPermission, number_format} from "../../common/utils/Utils";
import {PermissionConst} from "../../common/constants/Constants";
import {dateUtils} from "../../common/utils/DateUtils";
import './PreservePage.scss'
import AddPlan from "./components/addPlan/AddPlan";
import {cateStore} from "../warehouse/category/CateStore";
import {productStore} from "../warehouse/category/product/ProductStore";
import PopupConfirm from "../../common/component/PopupConfirm";
import {Link} from "react-router-dom";
import Paginate from "../../common/component/Paginate";

const PreservePage = () => {
    const changePage = async (data: any) => {
        preserveStore.page = data.selected;
        await preserveStore.getPreserves(10)
    }
    useEffect(() => {
        preserveStore.getPreserves(10)
        cateStore.getCate(1000);
        preserveStore.dataFilter = [
            {
                label: 'Lô sản xuất & Sản Phẩm',
                type: 'search',
                placeholder: 'Nhập từ khóa tìm kiếm',
                value: preserveStore.keyword,
                onChange: (e: any) => {
                    preserveStore.keyword = e
                },
                callback: async () => {
                    await preserveStore.getPreserves();
                }
            },
            {
                label: 'Tình trạng',
                type: 'select',
                placeholder: 'Tất cả',
                value: preserveStore.searchStatus,
                listValue: preserveStore.listOptionSearchStatus,
                callback: async (e: any) => {
                    preserveStore.searchStatus = e
                    await preserveStore.getPreserves();
                }
            },
        ]
        return () => {
            productStore.categoryId = -1
        }
    }, [])


    return (
        <div className='preservePage'>
            <div className="d-flex">
                <FilterComponent buttonAdd={<ButtonAdd text="Thêm kế hoạch" dataTarget={'add-plan-order_work'}
                                                       onClick={async () => {
                                                           await preserveStore.getDataAllDepartment()
                                                           preserveStore.clearForm()
                                                       }}/>}
                                 permissionCode={checkPermission(PermissionConst.planAdd)}
                                 data={preserveStore.dataFilter}
                                 label="Lô sản xuất"
                />
            </div>
            {preserveStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">Mã lô sản xuất</th>
                        <th className="text-center">Sản phẩm</th>
                        <th className="text-center">Người lập</th>
                        <th className="text-center">Thời gian bắt đầu</th>
                        <th className="text-center">Tình trạng</th>
                        <th className="text-center d-flex flex-column">Số lượng <span> (Thực tế / Dự kiến)</span>
                        </th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!preserveStore.isLoading && preserveStore.listPreserves && preserveStore.listPreserves.length > 0 && preserveStore.listPreserves.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{item?.name ?? ""}</td>
                                <td className="text-center">{item?.product.name ?? ""}</td>
                                <td className="text-center">{item?.creator ? item?.creator.name : ""}</td>
                                <td className="text-center">{item?.dateTimeStart ? dateUtils.formatDate(item?.dateTimeStart) : '-'}</td>
                                <td className={`text-center ${preserveStore.changeColorStateBatch(item?.status)}`}>{item?.status ? preserveStore.changeStateBatch(item?.status) : ''}</td>
                                <td className="text-center">
                                    {item?.actualQuantity ?
                                        <b>{number_format(item.actualQuantity)}</b> : "?"} / {item?.quantity ? number_format(item.quantity) : "?"}
                                </td>
                                <td className="text-center" width="18%">
                                    <Link to={`/planing/${item.id}`} className="view-detail-preserve"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Xem chi tiết"
                                          onClick={
                                              () => preserveStore.id = item.id
                                          }
                                    >
                                        <img src="/assets/ico/action/ico_detail.svg" alt="" className="icon-action"/>
                                    </Link>
                                    {
                                        checkPermission(PermissionConst.planUpdate) &&
                                        <button className="bt-action-accept"
                                                onClick={async () => {
                                                    preserveStore.currentStep = 0
                                                    preserveStore.type = "edit"
                                                    preserveStore.id = item.id
                                                    preserveStore.resetErrors()
                                                    await preserveStore.getDataAllDepartment()
                                                    await preserveStore.getDetailPreserves(item.id)
                                                }}
                                                disabled={preserveStore.changeStateBatch(item.status) !== "Chưa bắt đầu"}
                                                data-toggle="modal" data-target="#add-plan-order_work">
                                            <img src="/assets/ico/action/ico_edit.svg"
                                                 className="icon-action-preserve"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Sửa"/>
                                        </button>
                                    }
                                    {
                                        checkPermission(PermissionConst.planDelete) &&
                                        <button className="bt-action-accept"
                                                disabled={preserveStore.changeStateBatch(item.status) !== "Chưa bắt đầu"}
                                                onClick={() => preserveStore.id = item.id}
                                                data-toggle="modal" data-target="#popup_confirm">
                                            <img src="/assets/ico/action/x-octagon.svg" className="icon-action-preserve"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Hủy"/>
                                        </button>
                                    }

                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {!preserveStore.isLoading && preserveStore.listPreserves && preserveStore.listPreserves.length < 1 &&
                    <NoContent/>}
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Paginate currentPage={preserveStore.page} totalPage={preserveStore.totalPage}
                              changePage={changePage}/>
                </div>
                <AddPlan/>
                <PopupConfirm titleConfirm="Bạn có chắc chắn hủy lô sản xuất ?" titlePopup="Xác nhận hủy"
                              isLoading={preserveStore.isLoadingBt}
                              action={preserveStore.delete}/>

            </div>}
        </div>
    );
}

export default observer(PreservePage);