import {observable} from "mobx";
import {getRequest} from "../../common/helpers/RequestHelper";


interface IData{
    id: '',
    countProduct: '',
    countIngredient: '',
    countUserManufacture: '',
    countSumUser: '',
    countAdditives: '',
    countPackaging: '',
    countUserPack: '',
    countUserWarehouse: '',
    countEquipment: '',countUserManage: ''
}

class DashboardStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable dataDashboard: IData = {
        id: '',
        countProduct: '',
        countIngredient: '',
        countUserManufacture: '',
        countSumUser: '',
        countAdditives: '',
        countPackaging: '',
        countUserPack: '',
        countUserWarehouse: '',
        countEquipment: '',
        countUserManage: ''
    }

    async getDashboard() {
        this.isLoading = true
        const result = await getRequest(`/food/v1/statistic`)
        this.isLoading = false

        if (result.status == 200) {
            this.dataDashboard = result.body
        }
    }

}

export const dashboardStore = new DashboardStore()
