import {observable} from "mobx";
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../../common/utils/ToastUtil";
import $ from "jquery"
import {equipmentService} from "./EquipmentService";


class EquipmentStore {
    @observable id: any = '';
    @observable type: any = '';
    @observable keyword: any = '';
    @observable urlViewer: any = '';
    @observable isLoading: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoadingBt: boolean = false
    @observable errors: any = {
        name: '',
        quantity: ''
    }
    @observable dataRequest: any = {
        id: '',
        name: '',
        nameFile: '',
        tutorial: [],
        note: '',
        quantity: '',
        price: '',

    }
    @observable listEquipment: any[] = []

    clearForm() {
        equipmentStore.errors = {
            name: '',
            quantity: '',
        }
        equipmentStore.type = 'add'
        equipmentStore.dataRequest = {
            id: '',
            name: '',
            nameFile: '',
            tutorial: [],
            quantity: '',
            price: '',
            note: ''
        }
    }

    async getEquipments(size?: any) {
        equipmentStore.isLoading = true
        const result = await equipmentService.getEquipments(size)
        equipmentStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            equipmentStore.listEquipment = result.body.data
            equipmentStore.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        this.clearForm()
        equipmentStore.type = "edit"
        const result = await equipmentService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            equipmentStore.dataRequest = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async add() {

        let {name, note, quantity, price, tutorial} = equipmentStore.dataRequest

        let data = {
            name: name,
            quantity: Number(quantity),
            price: Number(price),
            tutorial: tutorial,
            note: note
        }


        if (!name) {
            equipmentStore.errors.name = 'Tên thiết bị không được để trống!'
            return
        } else equipmentStore.errors.name = ''

        if (!quantity) {
            equipmentStore.errors.quantity = 'Số lượng không được để trống!'
            return
        } else equipmentStore.errors.quantity = ''

        equipmentStore.isLoadingBt = true
        const result = await equipmentService.create(data)
        equipmentStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm thiết bị thành công', 1)
            $('#close_add_edit_equipment').trigger('click')
            equipmentStore.clearForm()
            await equipmentStore.getEquipments()
        } else {
            toastUtil.error('Thêm thiết bị thất bại', 4)
        }

    }

    returnNameFileUpload(url: any) {
        const string = url;
        const underscoreIndex = string.indexOf('_');
        let extension: any = string.slice(underscoreIndex + 1);
        return extension
    }

    async update() {

        let {id, name, note, quantity, price, tutorial} = equipmentStore.dataRequest

        let data = {
            name: name,
            quantity: Number(quantity),
            price: Number(price),
            tutorial: tutorial,
            note: note
        }

        if (!name) {
            equipmentStore.errors.name = 'Tên thiết bị không được để trống!'
            return
        } else equipmentStore.errors.name = ''

        if (!quantity) {
            equipmentStore.errors.quantity = 'Số lượng không được để trống!'
            return
        } else equipmentStore.errors.quantity = ''


        equipmentStore.isLoadingBt = true
        const result = await equipmentService.update(id, data)
        equipmentStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật thiết thành công', 2)
            $('#close_add_edit_equipment').trigger('click')
            equipmentStore.clearForm()
            await equipmentStore.getEquipments()
        } else {
            toastUtil.error('Cập nhật thiết bị thất bại', 5)
        }
    }

    async delete() {
        equipmentStore.isLoadingBt = true
        const result = await equipmentService.delete(equipmentStore.id)
        equipmentStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa thiết bị thành công', 3)

            await equipmentStore.getEquipments()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }


}

export const equipmentStore = new EquipmentStore()