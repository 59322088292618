import React from 'react';
import './ModalConfirmCompletePack.scss'
import {packStore} from "../../PackStore";
import Input from "../../../../common/component/form/Input";
import {observer} from "mobx-react";
import {numberUtil} from "../../../../common/utils/NumberUtil";

const ModalConfirmCompletePack = () => {
    return (
        <div className="modal fade" id='popup_confirm_complete_pack' role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" id="close_delete" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center icon">
                        <img src="/assets/ico/action/confirm-complete.svg" alt=""/>
                    </div>
                    <div className="mx-3">
                        <h2 className="text-center mt-4">Chuyển Lô Sản Xuất Sang Kho Chứa</h2>
                        <p className="text-center mb-5 mx-3">Lô sản xuất sẽ được chuyển qua kho chứa để bảo quản ở điều
                            kiện thích hợp.</p>
                        <div className="form-group">
                            <label>Nhập số sản phẩm <span className="text-danger">*</span></label>
                            <Input
                                type="number"
                                isError={packStore.errorsImportBatchtRequest.quantity}
                                onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                value={packStore.dataRequestImportProduct.quantity}
                                onChange={(e: any) => {
                                    if (e.currentTarget.value === "" || numberUtil.regexNumber(e) > 0) {
                                        packStore.dataRequestImportProduct.quantity = numberUtil.regexNumber(e)
                                    }
                                }}
                                placeholder="Tối thiểu 1"/>
                        </div>
                        <div className="form-group">
                            <label>Lý do <span className="text-danger">*</span></label>
                            <Input
                                isError={packStore.errorsImportBatchtRequest.note}
                                value={packStore.dataRequestImportProduct.note}
                                onChange={(e: any) => packStore.dataRequestImportProduct.note = e.currentTarget.value}
                                placeholder="Nhập kho ..."/>
                        </div>
                        <div className="d-flex action align-items-center justify-content-between mx-3 mb-3">
                            <button className="btn btn-cancel w-50 mx-2" data-dismiss="modal">Hủy</button>
                            <button className="btn btn-confirm w-50 mx-2"
                                    onClick={() => packStore.changeTypeJob(packStore.id)}>Xác
                                nhận
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default observer(ModalConfirmCompletePack);