import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {detailBatchStore} from "./DetailEIBatchStore";
import NoContent from "../../../../common/component/NoContent";
import Loading from "../../../../common/component/Loading";
import {checkPermission, getIDParameter, number_format, sttPage} from "../../../../common/utils/Utils";
import {dateUtils} from "../../../../common/utils/DateUtils";
import ImportProduct from "./components/importProduct/ImportProduct";
import ExportBatch from "./components/exportBatch/ExportBatch";
import SurplusProduct from "./components/surplusProduct/SurplusProduct";
import {Icon} from "../../../../common/component/Icon";
import {expImpBatchStore} from "../EIBatchStore";
import CancelExportBatch from "./components/cancelExportBatch/CancelExportBatch";
import {Link} from "react-router-dom";
import './DetailEIBatchPage.scss'
import {PermissionConst} from "../../../../common/constants/Constants";

export const convertDepartment = (value: string) => {
    switch (value.toUpperCase()) {
        case "MANUFACTURE":
            return "Sản xuất"
        case "PACK":
            return "Đóng gói"
        case "MANAGER":
            return "Quản lý"
        default:
            return "Kho"
    }
}
const DetailEIBatchPage = () => {
    const convertSupplies = (value: string) => {
        switch (value.toUpperCase()) {
            case "PACKAGING":
                return "Bao bì"
            case "INGREDIENT":
                return "Kho nguyên liệu"
            case "EQUIPMENT":
                return "Thiết bị"
            default:
                return "Kho phụ gia"
        }
    }
    useEffect(() => {
        expImpBatchStore.id = getIDParameter(4)
        if (expImpBatchStore.id > 0 && !detailBatchStore.isGoToAccept)
            expImpBatchStore.getDetailWarehouseRequest(Number(expImpBatchStore.id))
    }, [])
    return (
        <>
            <div className='table-responsive eibatch-record-container'>
                <div className="infor-detail-eibatch-container">
                    <div>
                        <div className="d-flex header_back align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <Link
                                    to={`/warehouse/export_import_batch`}>Xuất Nhập Sản Xuất</Link>
                                <i className="fal fa-angle-right"/>
                                <span>Mã Xuất/Nhập #000{detailBatchStore.listDataDetailEIBatch.id}</span>
                            </div>
                        </div>
                        <div className="infor-detail-eibatch px-3">
                            <div className="my-1">
                            <span>Sản phẩm: <span
                                className="fw-600">{detailBatchStore.listDataDetailEIBatch.nameProduct ? detailBatchStore.listDataDetailEIBatch.nameProduct : '-'}<span
                                className="mx-3">|</span></span></span>
                                <span>Quy trình: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch.nameProcedure ? detailBatchStore.listDataDetailEIBatch.nameProcedure : '-'}</span><span
                                    className="mx-3">|</span></span>
                                <span>Số lượng: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch.quantity ? number_format(detailBatchStore.listDataDetailEIBatch.quantity) : '-'}</span><span
                                    className="mx-3">|</span></span>
                                <span>Thời gian bắt đầu: <span
                                    className="fw-600">{detailBatchStore.listDataDetailEIBatch.startTime ? dateUtils.formatDate(Number(detailBatchStore.listDataDetailEIBatch.startTime), 'h:mm DD/MM/YYYY') : "-"}</span></span>
                            </div>
                            <div className="my-1">
                                {detailBatchStore.listDataDetailEIBatch.ingrendientExported.some(x => x.quantity) ?
                                    <span>Thành phần (Đã xuất) : {detailBatchStore.listDataDetailEIBatch.ingrendientExported?.map((item, index) => {
                                        if (item.quantity) {
                                            return <span key={index} className="mr-4">
                                    {index + 1 + ". "}
                                                <span className="fw-600">{item.name}</span>
                                                {": " + (item.quantity ? number_format(item.quantity) : '-')}
                                </span>
                                        }
                                    })}</span> : ""}

                            </div>
                        </div>
                    </div>
                    <div className="edit_product_container mx-3 my-2">
                        <img src="/assets/ico/action/Group.svg" alt="" data-toggle="tooltip"
                             data-placement="top"
                             className="icon-action"
                             title="Quay Lại"/>
                        <Link
                            to={`/warehouse/export_import_batch`}>
                            <div className="back-product mx-2">Quay Lại</div>
                        </Link>
                    </div>
                </div>
                <div className="eibatch-record">
                    <div className="eibatch-record-header-title">
                        <div className="d-flex">
                            <h1 className="eibatch-record-header-title-name">Biên
                                Bản {detailBatchStore.listDataDetailEIBatch.type === "EXPORT" ? "Xuất Kho" : detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" ? "Xuất Dư" : "Nhập Kho Sản Phẩm"} #000{detailBatchStore.listDataDetailEIBatch.id}</h1>
                            {(!detailBatchStore.isGoToAccept || detailBatchStore.isGoToFinish) && <span
                                className={detailBatchStore.listDataDetailEIBatch.status === "APPROVED" ? "status-done" : detailBatchStore.listDataDetailEIBatch.status === "REJECT" ? "status-cancel" : "status-pendding"}>
                            {detailBatchStore.listDataDetailEIBatch.status === "APPROVED" ? "Hoàn thành" : detailBatchStore.listDataDetailEIBatch.status === "REJECT" ? "Từ chối" : "Đang chờ"}
                        </span>}

                        </div>
                        {detailBatchStore.listDataDetailEIBatch.status === "APPROVED" && <div>
                            <button className="bt-action-print"
                                    title="In biên bản"
                                    onClick={() => {
                                        window.print();
                                    }}
                                    data-toggle="modal">
                                <Icon name="print"
                                      type="outlined"
                                      size={26}/>
                            </button>
                        </div>}
                    </div>
                    <p style={{fontWeight: 800}}>**********</p>

                    <div className="eibatch-record-header-content">
                        <div className="w-50">
                            <p>Người yêu cầu: <span
                                className="fw-600">{detailBatchStore.listDataDetailEIBatch.sender.name}</span></p>
                            <p>Bộ phận: <span
                                className="fw-600">{detailBatchStore.listDataDetailEIBatch.sender.department ? convertDepartment(detailBatchStore.listDataDetailEIBatch.sender.department) : "---"}</span>
                            </p>
                            <p>Thời gian yêu cầu: <span
                                className="fw-600">{detailBatchStore.listDataDetailEIBatch.timeRequest ? dateUtils.formatDate(Number(detailBatchStore.listDataDetailEIBatch.timeRequest), 'h:mm DD/MM/YYYY') : "---"}</span>
                            </p>
                            <p>Lý do: <span
                                className="fw-600">{detailBatchStore.listDataDetailEIBatch.typeCreated === "OWNER_AUTO" ? `Thêm mới cho kế hoạch ${detailBatchStore.listDataDetailEIBatch.procedureBatch.name}` : detailBatchStore.listDataDetailEIBatch.note ?? "---"}</span>
                            </p>

                        </div>
                        <div className="w-50">
                            <p>Người bàn giao: <span
                                className="fw-600">{detailBatchStore.listDataDetailEIBatch?.receiver?.name ?? "---"}</span>
                            </p>
                            <p>Bộ phận: <span
                                className="fw-600">{detailBatchStore.listDataDetailEIBatch?.receiver?.department ? convertDepartment(detailBatchStore.listDataDetailEIBatch.sender.department) : "---"}</span>
                            </p>
                            <p>Thời gian xuất: <span
                                className="fw-600">{detailBatchStore.listDataDetailEIBatch?.timeHandle ? dateUtils.formatDate(Number(detailBatchStore.listDataDetailEIBatch?.timeHandle), 'h:mm DD/MM/YYYY') : "---"}</span>
                            </p>
                        </div>
                    </div>

                    <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th className="text-center">STT</th>
                            <th className="text-center">Tên</th>
                            <th className="text-center">Danh mục</th>
                            <th className="text-center">Số lượng yêu cầu</th>
                            <th className="text-center">Số lượng nhập/xuất</th>
                            <th className="text-center">Lô</th>
                        </tr>
                        </thead>
                        {
                            detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" ?
                                (
                                    <tbody>
                                    {!detailBatchStore.isGoToAccept ?
                                        (!detailBatchStore.isLoading && detailBatchStore.listDataDetailEIBatch.importRecordData && detailBatchStore.listDataDetailEIBatch.importRecordData.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center">{sttPage(detailBatchStore.page, i)}</td>
                                                    <td>{item.name ?? ""}</td>
                                                    <td className="text-center">{item.suppliesType ? convertSupplies(item.suppliesType) : '-'}</td>
                                                    <td className="text-center">{number_format(item.quantityRequest) ?? '-'}</td>
                                                    <td className="text-center">{number_format(item.batchInformation?.reduce((acc, obj) => acc + Number(obj.quantity), 0)) ?? '-'}</td>
                                                    <td className="text-center">{item.batchInformation?.reduce((acc, obj, index) => acc + `${index !== 0 ? ', ' : ''}` + (obj.name) + ` (${obj.quantity})`, '') ?? '-'}</td>
                                                </tr>
                                            )
                                        }))
                                        : (detailBatchStore.listDataExportBatch && detailBatchStore.listDataExportBatch.map((itemBatch, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{sttPage(detailBatchStore.page, index)}</td>
                                                    <td>{itemBatch.name ?? ""}</td>
                                                    <td className="text-center">{itemBatch.name ?? ""}</td>
                                                    <td className="text-center">{itemBatch.request ? number_format(itemBatch.request) : '-'}</td>
                                                    <td className="text-center">{itemBatch.suppliesInformation ? number_format(itemBatch.suppliesInformation.reduce((acc, obj) => acc + Number(obj.quantity), 0)) : '-'}</td>
                                                    <td className="text-center">{itemBatch.suppliesInformation ? itemBatch.suppliesInformation.reduce((acc, obj, index) => acc + `${index !== 0 ? ', ' : ''}` + obj.nameBatch + ` (${obj.quantity})`, '') : '-'}</td>
                                                </tr>
                                            )
                                        }))
                                    }
                                    </tbody>
                                ) :
                                (
                                    <tbody>
                                    <tr>
                                        <td className="text-center">{1}</td>
                                        <td>{detailBatchStore.listDataDetailEIBatch.nameProduct ?? ""}</td>
                                        <td className="text-center">{detailBatchStore.listDataDetailEIBatch.nameCategory ?? ""}</td>
                                        <td className="text-center">{number_format(detailBatchStore.listDataDetailEIBatch.quantityProductRequest) ?? '-'}</td>
                                        <td className="text-center">{number_format(detailBatchStore.listDataDetailEIBatch.productQuantityExecution) ?? '-'}</td>
                                        <td className="text-center">{detailBatchStore.listDataDetailEIBatch.nameProcedure ?? '-'}</td>
                                    </tr>
                                    </tbody>
                                )

                        }


                    </table>
                    {detailBatchStore.isLoading && <Loading/>}
                    {!detailBatchStore.isLoading && detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" && detailBatchStore.listDataDetailEIBatch.importRecordData === null &&
                        <NoContent/>}
                    {!detailBatchStore.isGoToAccept ? (
                        detailBatchStore.listDataDetailEIBatch.reasonReject &&
                        <div className="note-record-eibatch p-3">
                            <p>Ghi chú
                                : {detailBatchStore.listDataDetailEIBatch.reasonReject}</p>
                        </div>) : (<div className="note-record-eibatch p-3">
                        <p>Ghi chú
                            : {detailBatchStore.dataRequestExportBatch.note}</p>
                    </div>)}
                    {
                        (detailBatchStore.listDataDetailEIBatch.status !== "APPROVED" && detailBatchStore.listDataDetailEIBatch.status !== "REJECT") &&
                        (
                            !detailBatchStore.isGoToAccept ?
                                (<div className="bt-action-EI-Batch mt-3">
                                    {checkPermission(PermissionConst.approveProductionRequest) &&
                                        <button
                                            className="bt-action-cancel d-flex justify-content-center align-items-center"
                                            data-toggle="modal" data-target="#cancel-export-batch" title="Từ chối"
                                            onClick={async () => {
                                                detailBatchStore.clearFormData()
                                                await expImpBatchStore.getDetailWarehouseRequest(Number(expImpBatchStore.id))
                                            }}>
                                            <img src="/assets/ico/action/cancel-white.svg"
                                                 className="mr-2"
                                                 alt=""/> Từ Chối
                                        </button>}
                                    {checkPermission(PermissionConst.approveProductionRequest) &&
                                        <button
                                            className="bt-action-accept d-flex justify-content-center align-items-center"
                                            data-toggle="modal" title="Đồng ý"
                                            onClick={async () => {
                                                detailBatchStore.clearFormData()
                                                await expImpBatchStore.getDetailWarehouseRequest(Number(expImpBatchStore.id))
                                            }}
                                            data-target={`${detailBatchStore.listDataDetailEIBatch.type === "EXPORT" ? "#export_batch_product" : (detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" ? "#surplus-product" : "#import_product")}`}>
                                            <img src="/assets/ico/action/accept-white.svg"
                                                 className="icon-cancel-disable mr-2"
                                                 alt=""/> Đồng Ý
                                        </button>}
                                </div>) : (
                                    <div className="bt-action-EI-Batch mt-3">
                                        <button className="bt-action-back d-flex justify-content-center align-items-center"
                                                data-toggle="modal" title="Quay lại"
                                                data-target={`${detailBatchStore.listDataDetailEIBatch.type === "EXPORT" ? "#export_batch_product" : (detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES" ? "#surplus-product" : "#import_product")}`}
                                        >
                                            Quay lại
                                        </button>
                                        {checkPermission(PermissionConst.approveProductionRequest) &&
                                            <button
                                                className="bt-action-confirm d-flex justify-content-center align-items-center"
                                                title="Xác nhận"
                                                onClick={async () => {
                                                    if (detailBatchStore.listDataDetailEIBatch.type === "EXPORT") {
                                                        await detailBatchStore.submitExportBatch()
                                                    } else if (detailBatchStore.listDataDetailEIBatch.batchType === "SUPPLIES") {
                                                        await detailBatchStore.submitImportBatchSurplus()
                                                    }
                                                }}>
                                                <img src="/assets/ico/action/check.svg"
                                                     className="icon-cancel-disable mr-2"
                                                     alt=""/>Xác nhận
                                            </button>}
                                    </div>)
                        )
                    }

                </div>

            </div>
            <ImportProduct/>
            <ExportBatch/>
            <SurplusProduct/>
            <CancelExportBatch isLoading={expImpBatchStore.isLoadingBt}/>
        </>
    );
}
export default observer(DetailEIBatchPage)