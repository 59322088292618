import humps from 'humps';
import axios, {Method} from "axios";
import $ from 'jquery'
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import Constants from "../constants/Constants";
import {toastUtil} from "../utils/ToastUtil";
import createAuthRefreshInterceptor from "axios-auth-refresh";

export interface IApiResponse {
    readonly status: number;
    readonly body: any;
}

export interface IBodyError {
    readonly errorCode: number;
    readonly message: string
}

// @ts-ignore
const refreshAuthLogic = failedRequest => axios.post(process.env.REACT_APP_API_DOMAIN + '/sso/v1/auth/refresh-token', {'refreshToken': StorageService.getRefreshToken()}).then(response => {
    StorageService.setToken(response.data.token);
    failedRequest.response.config.headers['token'] = response.data.token;
    return Promise.resolve();
}).catch(function (error) {
    localStorage.removeItem('facilityId')
    StorageService.removeToken();
    StorageService.removeRefreshToken();
    window.location.href = '/login'
    return Promise.reject();
});

let API_DOMAIN = process.env.REACT_APP_API_DOMAIN

export function newHeaders(){
    var newHeaders: any;
    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            "Authorization": 'Bearer ' + StorageService.getToken()
        }
        createAuthRefreshInterceptor(axios, refreshAuthLogic, {
            pauseInstanceWhileRefreshing: true
        });
        
    } else {
        newHeaders = {'Content-Type': 'application/json'}
    }

    return newHeaders
}

function responseError(error: any){
    if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED) {
        toastUtil.error('Phiên đăng nhập hết hạn', 1);
        localStorage.removeItem('facilityId')
        StorageService.removeToken();
        StorageService.removeRefreshToken();
        window.location.href = '/login'
    } else if (error.response && error.response.status === HttpStatusCode.FORBIDDEN) {
        toastUtil.error('Không có quyền truy cập', 2);
    }

    let bodyError: IBodyError;

    bodyError = {
        errorCode: error.response.data.errorCode,
        message: error.response.data.message
    }

    const apiResponse: IApiResponse = {
        status: error.response.status,
        body: bodyError
    };

    return apiResponse
}

export async function getRequest(path: string): Promise<IApiResponse> {
    return await axios.get(API_DOMAIN + path, {headers: newHeaders()} )
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                };
                return apiResponse;
            },
            (error) => {
                return responseError(error)
            },
        )
}


export async function postRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "POST", params);
}

export function apiCall(path: string, _method: Method = "POST", _params: object): Promise<IApiResponse> {

    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: JSON.stringify(_params),
            headers: newHeaders(),
            method: _method,
            url: API_DOMAIN + path
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });
            })
            .catch(function (error) {
                resolve(responseError(error));
            });

    });
}

export async function putRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "PUT", params);
}

export async function patchRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "PATCH", params);
}

export async function deleteRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "DELETE", params);
}

export async function exportData(path: string, name: string): Promise<any> {
    var newHeaders: any = {'Content-Type': 'text/html'};

    if (StorageService.isTokenExits()) {
        newHeaders[Constants.TOKEN_NAME] = StorageService.getToken();
    }

    return await axios.get(API_DOMAIN + path, {headers: newHeaders, responseType: "blob"})
        .then(
            (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${name}.xlsx`)
                document.body.appendChild(link)
                link.click();
                $('#close_add').trigger('click')
                toastUtil.success('Successful data export');
            },
            (error) => {
                toastUtil.error(error.response.data.message)
            }
        );
}
