import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {procedureStore} from "./ProcedureStore";


class ProcedureService {

    public getProcedure(size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure?page=${procedureStore.page}&size=${size ?? 10}${procedureStore.searchProduct ? `&productId=${procedureStore.searchProduct}` : ''}${procedureStore.keyword ? `&keyword=${procedureStore.keyword.trim()}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure/${id}`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/procedure`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/procedure/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/procedure/${id}`, {id});
    }
}

export const procedureService = new ProcedureService();