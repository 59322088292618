import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {getIDParameter, getUrlParameter} from "../../../../common/utils/Utils";
import {batchStore, ITabBatch} from "./BatchStore";
import {suppliesStore, TypeSupplies} from "../SuppliesStore";
import "./BatchStyle.scss"
import TabHeader from "./components/TabHeader";
import InventoryPage from "./InventoryPage";
import HistoryPage from "./HistoryPage";
import {profileStore} from "../../../profile/ProfileStore";


const BatchPage = () => {

    useEffect(() => {
        profileStore.link = `/warehouse/supplies`;
        batchStore.suppliesId = getIDParameter(4);
        batchStore.tabActive = getUrlParameter('tab') ?? ITabBatch.inventory
        batchStore.typeSupplies = getUrlParameter('type') ?? TypeSupplies.ingredient
        suppliesStore.getDetail(batchStore.suppliesId)
        batchStore.dataFilter = [
            {
                label: 'Tìm lô',
                type: 'search',
                placeholder: 'Nhập lô',
                value: batchStore.keyword,
                onChange: (e: any) => {
                    batchStore.keyword = e
                },
                callback: async () => {
                    if(batchStore.tabActive == ITabBatch.inventory){
                        await batchStore.getBatch(batchStore.suppliesId);
                    }else {
                        await batchStore.getHistory();
                    }
                }
            }
        ]
    }, [])


    return (
        <div className="batch_supplies">
            <TabHeader/>
            <div className='content_'>
                {batchStore.tabActive === ITabBatch.inventory && <InventoryPage/>}
                {batchStore.tabActive === ITabBatch.history && <HistoryPage/>}
            </div>
        </div>
    );
}

export default observer(BatchPage)
