import React from 'react';
import {observer} from "mobx-react";
import './NotiStyle.scss'
import {Icon} from "../../common/component/Icon";
import {noticeStore} from "./NoticeStore";
import {dateUtils} from "../../common/utils/DateUtils";
import {getPathName, slug, urlImage} from "../../common/utils/Utils";
import {Link} from "react-router-dom";



const NoticePage = observer(() => {
    let showMoreTable = React.createRef<HTMLDivElement>();

    const handleOnScroll = (e: any) =>{
        if (showMoreTable.current) {
            const self = e.currentTarget;
            if (($(self).height() as number + self.scrollTop) === self.scrollHeight) {
                noticeStore.page ++
                noticeStore.page < noticeStore.totalPage && noticeStore.readMore();
            }
        }
    }


    try{
        return (
            <div className="dropdown-menu nav_notification" style={{height: window.innerHeight - 102}} aria-labelledby="dropdownNotice">
                <div className="title">
                    <h4>Thông báo</h4>
                    {noticeStore.listNotice && noticeStore.listNotice.length > 0 && <button type="button" className="check" disabled={noticeStore.countNotice.unRead < 1} onClick={() => noticeStore.sendAllRead()}>
                        <span>Đánh dấu đã đọc</span>
                        <Icon name="check" type="outlined"/>
                    </button>}
                </div>
                {noticeStore.listNotice && noticeStore.listNotice.length > 0 ? <div className="list_notice" ref={showMoreTable} onScroll={(e: any) => handleOnScroll(e)} style={{height: window.innerHeight - (102 + 45)}}>
                    {noticeStore.listNotice.map((item, i) => {
                        if(getPathName() == 'question'){
                            return (
                                <a href={`/question/${slug(item.notification.data.postname)}/${item.notification.data.id}`} onClick={() => noticeStore.sendRead(item.id)} className="noti position-relative" key={i}>
                                    <div className="avatar">
                                        {item.notification.data.useravatar && <img src={urlImage(item.notification.data.useravatar)} alt=""/>}
                                    </div>
                                    <div className="content">
                                        <p className="text"><strong>{item.notification.data.username}</strong> đã bình luận bài viết <strong>{item.notification.data.postname}</strong></p>
                                        {!item.read && <span className="read"/>}
                                        <span className="time">{dateUtils.formatDate(item.createdAt, 'DD/MM/YYYY hh:mm A')}</span>
                                    </div>
                                </a>
                            )
                        }else {
                            return (
                                <Link to={`/question/${slug(item.notification.data.postname)}/${item.notification.data.id}`} onClick={() => noticeStore.sendRead(item.id)} className="noti position-relative" key={i}>
                                    <div className="avatar">
                                        {item.notification.data.useravatar && <img src={urlImage(item.notification.data.useravatar)} alt=""/>}
                                    </div>
                                    <div className="content">
                                        <p className="text"><strong>{item.notification.data.username}</strong> đã bình luận bài viết <strong>{item.notification.data.postname}</strong></p>
                                        {!item.read && <span className="read"/>}
                                        <span className="time">{dateUtils.formatDate(item.createdAt, 'DD/MM/YYYY hh:mm A')}</span>
                                    </div>
                                </Link>
                            )
                        }

                    })}
                </div> : <div className="no_content text-center" style={{height: window.innerHeight - (102 + 45)}}>
                    <img src="/assets/ico/ico_no_noti.svg" alt=""/>
                    <p className="w-100">Bạn chưa có thông báo!</p>
                </div>}
            </div>
        );
    }catch (e) {
        return null
    }
})

export default NoticePage;