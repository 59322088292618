import {observable} from "mobx";
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../../common/utils/ToastUtil";
import $ from "jquery";
import {procedureJobService} from "./ProcedureJobService";


class ProcedureJobStore {
    @observable procedureId: any = '';
    @observable id: any = '';
    @observable type: any = '';
    @observable step_active: any = 1;
    @observable isLoading: boolean = false
    @observable isChanged: boolean = true
    @observable listType: any[] = [
        {
            name: 'Chuẩn bị',
            id: 'PREPARATION'
        },
        {
            name: 'Sản xuất',
            id: 'PROCESSING'
        },
        {
            name: 'Đóng gói',
            id: 'PACK'
        },
        {
            name: 'Bảo quản',
            id: 'PRESERVE'
        }
    ]
    @observable public errors: any = {
        name: "",
        type: '',
        stage: ""

    }
    @observable isLoadingBt: boolean = false
    @observable dataRequest: any = {
        id: '',
        name: '',
        type: '',
        sort: '',
        stage: ''
    }
    @observable listProcedureJob: any[] = []

    clearError() {
        procedureJobStore.errors = {
            name: "",
            type: '',
            stage: ''
        }
    }

    clearForm() {
        procedureJobStore.clearError()
        procedureJobStore.type = 'add'
        procedureJobStore.dataRequest = {
            id: "",
            name: "",
            type: '',
            sort: '',
            stage: ""
        }
    }


    reorder = async (startIndex: number, endIndex: number) => {
        const results: any[] = Array.from(procedureJobStore.listProcedureJob);
        const [removed] = results.splice(startIndex, 1);
        results.splice(endIndex, 0, removed);
        //resort
        results.forEach(function (item, index) {
            item.sort = index
        });
        procedureJobStore.listProcedureJob = results;

    };

    async sortJob() {
        const idS: number[] = procedureJobStore.listProcedureJob.map(item => item.id);
        const response = await procedureJobService.sortJob(idS);
        if (response.status === HttpStatusCode.OK) {
            procedureJobStore.isChanged = true
        } else {
            toastUtil.error(response.body.message ? response.body.message : 'Error.')
        }
    }


    async getProcedureJob() {
        procedureJobStore.isLoading = true
        const result = await procedureJobService.getProcedureJob()
        procedureJobStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            procedureJobStore.listProcedureJob = result.body.data
        }

    }

    async getDetail(id: any) {
        procedureJobStore.clearError()
        procedureJobStore.type = "edit"
        const result = await procedureJobService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            procedureJobStore.dataRequest = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }

    }

    async addOrEdit() {
        procedureJobStore.clearError()
        let {id, name, type, stage, sort} = procedureJobStore.dataRequest

        let data = {
            name: name,
            stage: stage,
            type: type,
            sort: sort,
            procedureId: procedureJobStore.procedureId
        }

        if(procedureJobStore.type === 'add'){
            if(procedureJobStore.listProcedureJob.length < 1){
                data.sort = 0
            }else {
                if(!sort && sort !== 0){
                    data.sort = procedureJobStore.listProcedureJob.pop().sort + 1
                }
            }
        }else if(procedureJobStore.type === 'edit'){
            if(!sort && sort !== 0){
                data.sort = procedureJobStore.listProcedureJob.pop().sort + 1
            }
        }
        
        if (!name) {
            procedureJobStore.errors.name = 'Tên công việc không được để trống!'
        }
        if (!stage) {
            procedureJobStore.errors.stage = 'Công đoạn không được để trống!'
        }
        if (!type) {
            procedureJobStore.errors.type = 'Bộ phận không được để trống!'
        }

        if (!name || !stage || !type) {
            return
        }

        procedureJobStore.isLoadingBt = true
        let result: any
        if (procedureJobStore.type == 'add') {
            result = await procedureJobService.create(data)
        } else {
            result = await procedureJobService.update(id, data)
        }
        procedureJobStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thiết lập công việc thành công', 1)
            $('#close_add_edit').trigger('click')
            procedureJobStore.clearForm()
            if (procedureJobStore.type == 'add') {
                await procedureJobStore.getProcedureJob()
            } else {
                procedureJobStore.listProcedureJob.map((item) => {
                    if (item.id === id) {
                        item.name = name
                        item.type = type
                        item.stage = stage
                        item.sort = sort ? sort : null
                    }
                })
            }
        } else {
            toastUtil.error("Thiết lập công việc thất bại", 2)
        }

    }


    async delete() {
        procedureJobStore.isLoadingBt = true
        const result = await procedureJobService.delete(procedureJobStore.id)
        procedureJobStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_delete').trigger('click')
            toastUtil.success('Xóa công việc thành công', 5)
            await procedureJobStore.getProcedureJob()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }
}

export const procedureJobStore = new ProcedureJobStore()
