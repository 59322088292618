import {getRequest, IApiResponse, patchRequest, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {IDataRequestEISuppliesManufacturing} from "./ManufacturingStore";

class ManufacturingService {
    public getDeatailJobByBatch(id: any, type: string): Promise<IApiResponse> {
        return getRequest(`/food/v1/procedure_batch/${id}/job?type=${type}`);
    }

    public changeStatusJob(id: any, data: any): Promise<IApiResponse> {
        return patchRequest(`/food/v1/procedure_batch/${id}/job/_bulk`, data);
    }

    public changeTypeJob(id: any, type: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/procedure_batch/${id}/change_job_type?type=${type}`, {});
    }

    public importExportSuppliesManufacturing(data: IDataRequestEISuppliesManufacturing): Promise<IApiResponse> {
        return postRequest(`/food/v1/warehouse_request`, data);
    }

    public editImportExportSuppliesManufacturing(data: IDataRequestEISuppliesManufacturing, id: number): Promise<IApiResponse> {
        return putRequest(`/food/v1/warehouse_request/${id}`, data);
    }
}

export const manufacturingService = new ManufacturingService();