import React from 'react'
import {number_format, urlImage} from "../../../common/utils/Utils";
import {observer} from "mobx-react";
import QRCode from "qrcode.react";
import {trackingStore} from "../../tracking/TrackingStore";
import {profileStore} from "../../profile/ProfileStore";
import {dateUtils} from "../../../common/utils/DateUtils";
import {infoStore} from "../../info/InfoStore";
import PopupZoom from "../../../common/component/PopupZoom";
import {trackingBatchStore} from "../../tracking/trackingBatch/TrackingBatchStore";


const Content = () => {

    let {trackingImage, trackingData, instruction, images} = trackingStore.dataRequest;

    let {info, trackingQrBatch} = trackingBatchStore.dataRequest

    let dataInfo: any

    if (profileStore.path == 'truy-xuat') {
        dataInfo = trackingQrBatch?.info
    } else if (profileStore.path == 'truy-xuat-lo') {
        dataInfo = info
    }

    return (
        <div>
            {profileStore.path !== "truy-xuat" &&
                <div>
                    <h2>Thông tin sản phẩm</h2>
                    <div className="d-flex info_">
                        <div className="info_unit w-50 pr-4">
                            <div className="info_text">
                                <label>Tên sản phẩm</label>
                                <span>{trackingData?.productInfo?.name}</span>
                            </div>
                            <div className="info_text">
                                <label>Mã lô sản xuất</label>
                                <span>{trackingData?.productInfo?.productionBatch}</span>
                            </div>
                            {profileStore.path == 'truy-xuat' && <div className="info_text">
                                <label>Mã số sản phẩm</label>
                                <span>{info?.productCode}</span>
                            </div>}
                            <div className="info_text">
                                <label>Số lượng</label>
                                <span>{number_format(trackingData?.productInfo?.quantity)}</span>
                            </div>
                            <div className="info_text">
                                <label>Ngày sản xuất</label>
                                <span>{dateUtils.formatDate(trackingData?.productInfo?.dateManufacture)}</span>
                            </div>
                            <div className="info_text">
                                <label>Hạn sử dụng (Tháng)</label>
                                <span>{trackingData?.productInfo?.expiry}</span>
                            </div>

                            {profileStore.path !== 'xem-ket-qua-truy-xuat' && <>
                                <div className="info_text">
                                    <label>Mã nhận diện</label>
                                    <span>{info?.productCode}</span>
                                </div>
                                <div className="info_text">
                                    <label>Ngày gửi hàng</label>
                                    <span>{dateUtils.formatDate(info?.sentDate)}</span>
                                </div>
                                <div className="info_text">
                                    <label>Đơn vị vận chuyển</label>
                                    <span>{info?.shippingPartner}</span>
                                </div>
                                <div className="info_text">
                                    <label>Mã đơn vị vận chuyển</label>
                                    <span>{info?.shippingPartnerCode}</span>
                                </div>
                            </>}
                            {
                                info && info.productMore && info.productMore.map((item: any, index: number) => {
                                    return (<div key={index} className="info_text">
                                            <label>{item.key}</label>
                                            <span>{item.value}</span>
                                        </div>
                                    )
                                })

                            }
                        </div>
                        {trackingImage && <div className="w-50 trackingImage pl-4">
                            <img src={urlImage(trackingImage)} alt=""/>
                        </div>}
                    </div>
                </div>}
            <div className="d-flex d-lg-none align-items-center justify-content-center mb-5">
                <QRCode id="download_qrCode" level="L" value={window.location.href} size={150}/>
            </div>
            <h2>Thông tin chi tiết</h2>
            <div className="d-flex info_">
                <div className="info_unit w-50 pr-4">
                    <div className="info_text">
                        <label>Nguyên liệu</label>
                        <span>{trackingData?.suppliesInfo.ingredient}</span>
                    </div>
                    <div className="info_text">
                        <label>Đơn vị CCNL</label>
                        <span>{trackingData?.suppliesInfo.supplierIngredient}</span>
                    </div>
                    <div className="info_text">
                        <label>Địa chỉ NL</label>
                        <span>{trackingData?.suppliesInfo.addressIngredient}</span>
                    </div>
                    <div className="info_text">
                        <label>Số ĐKKD đơn vị CCNL</label>
                        <span>{trackingData?.suppliesInfo.businessRegistrationIngredient}</span>
                    </div>
                    <div className="info_text">
                        <label>Phụ gia</label>
                        <span>{trackingData?.suppliesInfo.additives}</span>
                    </div>
                    <div className="info_text">
                        <label>Đơn vị CCPG</label>
                        <span>{trackingData?.suppliesInfo.supplierAdditives}</span>
                    </div>
                    <div className="info_text">
                        <label>Số ĐKKD đơn vị CCPG</label>
                        <span>{trackingData?.suppliesInfo.businessRegistrationAdditives}</span>
                    </div>
                    <div className="info_text">
                        <label>Khối lượng tịnh</label>
                        <span>{trackingData?.suppliesInfo.pack}</span>
                    </div>
                    <div className="info_text">
                        <label>Công dụng</label>
                        <span>{instruction?.uses}</span>
                    </div>
                    <div className="info_text">
                        <label>Hướng dẫn sử dụng</label>
                        <span>{instruction?.userManual}</span>
                    </div>
                    <div className="info_text">
                        <label>Cảnh báo</label>
                        <span>{instruction?.warning}</span>
                    </div>
                </div>
                <div className="info_unit w-50 pl-4">
                    {profileStore.path !== 'xem-ket-qua-truy-xuat' && <>
                        <div className="info_text">
                            <label>Số lượng đã xuất lô</label>
                            <span>{number_format(dataInfo?.quantityShipped)}</span>
                        </div>
                        <div className="info_text">
                            <label>Số lượng tồn kho</label>
                            <span>{number_format(dataInfo?.quantityLeft)}</span>
                        </div>
                        <div className="info_text">
                            <label>Đại lý phân khối</label>
                            <span>{dataInfo?.distributingAgent}</span>
                        </div>
                        <div className="info_text">
                            <label>Mã đơn vị phân khối</label>
                            <span>{dataInfo?.distributingUnitCode}</span>
                        </div>
                    </>}
                    {info && info.detailMore && info.detailMore.map((item: any, i: number) => {
                        return (
                            <div className="info_text" key={i}>
                                <label>{item.key}</label>
                                <span>{item.value}</span>
                            </div>
                        )
                    })}
                </div>
            </div>

            <h2>Thông tin sơ sở sản xuất</h2>
            <div className="d-flex info_">
                <div className="info_unit">
                    <div className="info_text">
                        <label>Tên cơ sở</label>
                        <span>{infoStore.dataInfo.name}</span>
                    </div>
                    <div className="info_text">
                        <label>Tên đại diện</label>
                        <span>{infoStore.dataInfo.representative}</span>
                    </div>
                    <div className="info_text">
                        <label>Địa chỉ</label>
                        <span>{infoStore.dataInfo.address}{infoStore.dataInfo.address && ','} {infoStore.dataInfo.ward.name}, {infoStore.dataInfo.district.name}, {infoStore.dataInfo.province.name}</span>
                    </div>
                    <div className="info_text">
                        <label>Số điện thoại</label>
                        <span>{infoStore.dataInfo.phone}</span>
                    </div>
                    <div className="info_text">
                        <label>Đăng ký kinh doanh</label>
                        <span>{infoStore.dataInfo.businessCode}</span>
                    </div>
                </div>
            </div>

            {instruction && instruction.note && <>
                <h2>Ghi chú</h2>
                <div className="info_unit">
                    <label>{instruction.note}</label>
                </div>
            </>}

            {images && images.length > 0 && <>
                <h2>Nông trường & Sản phẩm</h2>
                <div className="scroll_images">
                    <div className="list_images" style={{width: `${(images.length * 278) - 24}px`}}>
                        {images.map((item: any, i: number) => {
                            return (
                                <div className="item cursor_pointer" data-toggle="modal"
                                     data-target="#popupZoom_1" key={i}>
                                    <img src={urlImage(item)} alt=""/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>}

            {infoStore.dataInfo.certificates && infoStore.dataInfo.certificates.length > 0 && <>
                <h2>Giấy chứng nhận</h2>
                <div className="scroll_certificate">
                    <div className="list_certificate"
                         style={{width: `${(infoStore.dataInfo.certificates.length * 278) - 24}px`}}>
                        {infoStore.dataInfo.certificates.map((item: any, i: number) => {
                            return (
                                <div className="item cursor_pointer" data-toggle="modal"
                                     data-target="#popupZoom" key={i}>
                                    <img src={urlImage(item)} alt=""/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>}

            <PopupZoom listImg={images} id="popupZoom_1" idCarousel="carouselZoomImg2"/>
            <PopupZoom listImg={infoStore.dataInfo.certificates}/>
        </div>
    )

}

export default observer(Content)
