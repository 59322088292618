import React from 'react';
import Select from "react-select";
import {observer} from "mobx-react";
import makeAnimated from 'react-select/animated';

interface IProps {
    value: any,
    options: {
        label: any,
        value: any
    }[],
    disabled?: boolean,
    noOptionsMessage?: string,
    placeholder?: string,
    callback: any,
    isMulti?: boolean,
    loading?: boolean,
    isError?: any,
}


const SelectMulti = ({value, isMulti = true, options, disabled, loading, noOptionsMessage, placeholder, callback, isError}: IProps) => {

    const changeSelect = (data: any) => {
        callback && callback(data)
    }
    const animatedComponents = makeAnimated();


    return (
        <Select
            value={value} onChange={(data: any) => changeSelect(data)}
            isMulti={isMulti}
            isClearable
            isSearchable
            isLoading={loading}
            closeMenuOnSelect
            isDisabled={disabled}
            options={options}
            className={`basic-multi-select ${(isError) ? 'border_error' : ''}`}
            menuPortalTarget={document.body}
            placeholder={placeholder}
            components={animatedComponents}
            maxMenuHeight={150}
            menuPlacement="auto"
            styles={{
                menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    backgroundColor: "papayawhip",
                })
            }}
            noOptionsMessage={() => noOptionsMessage ?? "Không có dữ liệu"}
        />
    );
}

export default observer(SelectMulti);