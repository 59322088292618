import React from 'react';

interface IProps {
    message?: any,
    height?: any,
    widthImg?: any
    mx3?: boolean
}


const NoContent = ({message, height, widthImg, mx3 = false}: IProps) => {

    return (
        <div className={`d-flex align-items-center justify-content-center border w-100 ${mx3 ? "mx-3" : ""}`}
             style={{height: height ?? 450}}>
            <div className="text-center">
                <div className="d-flex align-items-center justify-content-center" style={{marginBottom: 35}}>
                    <img src="/assets/ico/no_content.svg" width={widthImg ?? 115} alt=""/>
                </div>
                <h6 className="w-100 text-center"
                    style={{fontSize: 16, color: "#888888"}}>{message ?? 'Không có nội dung hiển thị!'}</h6>
            </div>
        </div>
    )
}

export default NoContent;
