import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import PopupConfirm from "../../../common/component/PopupConfirm";
import ButtonAdd from "../../../common/component/buttonAdd/ButtonAdd";
import Loading from "../../../common/component/Loading";
import {checkPermission, slug, sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import {procedureStore} from "./ProcedureStore";
import Paginate from "../../../common/component/Paginate";
import AddOrEditProcedure from "./components/AddOrEditProcedure";
import {Link} from "react-router-dom";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import {dateUtils} from "../../../common/utils/DateUtils";
import {PermissionConst} from "../../../common/constants/Constants";
import {cateStore} from "../../warehouse/category/CateStore";
import {productStore} from "../../warehouse/category/product/ProductStore";


const ProcedurePage = observer(() => {

    const changePage = async (data: any) => {
        procedureStore.page = data.selected;
        await procedureStore.getProcedure();
    }

    useEffect(() => {
        procedureStore.getProcedure()
        procedureStore.dataFilter = [
            {
                label: 'Tên sản phẩm',
                type: 'search',
                placeholder: 'Nhập tên sản phẩm',
                value: procedureStore.keyword,
                onChange: (e: any) => {
                    procedureStore.keyword = e
                },
                callback: async () => {
                    await procedureStore.getProcedure();
                }
            }
        ]
        cateStore.getCate(1000)
        return () => {
            productStore.categoryId = -1
        }
    }, [])

    return (
        <div className='procedures'>
            <FilterComponent permissionCode={checkPermission(PermissionConst.procedureAdd)} label='Quy Trình'
                             buttonAdd={<ButtonAdd text="Thêm quy trình" dataTarget={'add_or_edit_procedure'}
                                                   onClick={procedureStore.clearForm}/>}
                             data={procedureStore.dataFilter}/>
            {procedureStore.isLoading ?
                <Loading/> : procedureStore.listProcedure && procedureStore.listProcedure.length > 0 ?
                    <div className="table-responsive">
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center">Quy trình</th>
                                <th className="text-center">Sản phẩm</th>
                                <th className="text-center">Ngày tạo</th>
                                <th className="text-center">Ghi chú</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {procedureStore.listProcedure && procedureStore.listProcedure.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-center">{sttPage(procedureStore.page, index)}</td>
                                    <td>{item.name}</td>
                                    <td className="text-center">{item.product?.name}</td>
                                    <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                    <td className="text-center">{item.note}</td>
                                    <td className="text-center" width="22%">
                                        <div className="action">
                                            {checkPermission(PermissionConst.jobView) && <button className="bt_action" title="Chi tiết">
                                                <Link to={`/manage/procedure-detail/${slug(item.name)}/${item.id}`}>
                                                    <img src="/assets/ico/action/ico_detail.svg" alt=""/>
                                                </Link>
                                            </button>}
                                            {checkPermission(PermissionConst.procedureUpdate) &&
                                                <button className="bt_action" title="Sửa" data-toggle="modal"
                                                        data-target="#add_or_edit_procedure"
                                                        onClick={() => procedureStore.getDetail(item.id)}>
                                                    <img src="/assets/ico/action/ico_edit.svg" alt=""/>
                                                </button>}
                                            {checkPermission(PermissionConst.procedureDelete) &&
                                                <button className="bt_action" title="Xóa"
                                                        onClick={() => procedureStore.id = item.id} data-toggle="modal"
                                                        data-target="#popup_confirm">
                                                    <img src="/assets/ico/action/ico_trash.svg" alt=""/>
                                                </button>}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <Paginate currentPage={procedureStore.page} totalPage={procedureStore.totalPage}
                                      changePage={changePage}/>
                        </div>
                    </div> : <NoContent/>}
            <AddOrEditProcedure/>
            <PopupConfirm isLoading={procedureStore.isLoadingBt} action={procedureStore.delete}/>
        </div>
    );
})


export default ProcedurePage;

