import React, {useEffect} from "react"
import {observer} from "mobx-react";
import Loading from "../../../../../../common/component/Loading";
import {dateUtils} from "../../../../../../common/utils/DateUtils";
import NoContent from "../../../../../../common/component/NoContent";
import {productStore} from "../../ProductStore";
import {number_format} from "../../../../../../common/utils/Utils";


const HistoryPageDetailProduct = () => {
    useEffect(() => {
        productStore.getHistoryProductBatch(productStore.productId)
    }, [])
    return (
        <div className="history_batch">
            {productStore.isLoading ? <Loading/> : <>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7 mx-3 my-2'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">Thời gian</th>
                        <th className="text-center">Lô sản xuất</th>
                        <th className="text-center">Người phụ trách</th>
                        <th className="text-center">Số lượng</th>
                        <th className="text-center">Thao tác</th>
                        <th className="text-center">Ghi chú</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productStore.listDataHistoryBatchByProductId && productStore.listDataHistoryBatchByProductId.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{item.createdAt ? dateUtils.formatDate(item.createdAt, "DD/MM/YYYY hh:mm ") : '-'}</td>
                                <td>{item.productBatch ? item.productBatch.batchCode : '-'}</td>
                                <td className="text-center">{item.receiver ? item.receiver.name : '-'}</td>
                                <td className={`text-center ${item.action === "IMPORT" ? 'text-success' : 'text-danger'}`}>{item.action === "IMPORT" ? '+' : '-'}{item.currentQuantity ? number_format(item.currentQuantity) : '-'}
                                </td>
                                <td className="text-center">{item.action === "IMPORT" ? 'Nhập kho' : 'Xuất kho'}</td>
                                <td className="text-center">{item.note}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {productStore.listDataHistoryBatchByProductId && productStore.listDataHistoryBatchByProductId.length < 1 &&
                    <NoContent/>}
                <div className="d-flex align-items-center justify-content-center mt-4">
                </div>
            </>}
        </div>
    )
}

export default observer(HistoryPageDetailProduct)