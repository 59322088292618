import React from 'react';
import {IListDataProductTemp, productStore} from "../../ProductStore";
import {observer} from "mobx-react";
import Input from "../../../../../../common/component/form/Input";
import Select from "../../../../../../common/component/form/Select";
import {suppliesStore} from "../../../../supplies/SuppliesStore";
import {equipmentStore} from "../../../../supplies/equipment/EquipmentStore";
import Button from "../../../../../../common/component/Button";
import NoContent from "../../../../../../common/component/NoContent";
import './AddOrEditProduct.scss'
import {numberUtil} from "../../../../../../common/utils/NumberUtil";

const AddOrEditProduct = () => {
    const handleChangeSupplies = async (e: any) => {
        suppliesStore.tabActive = e.currentTarget.value;
        productStore.listDataProductTemp.type = e.currentTarget.selectedOptions[0].text
        suppliesStore.tabActive !== "equipment" ? await suppliesStore.getSupplies() : await equipmentStore.getEquipments();
    }
    const handleChangeDataRequest = async (e: any) => {
        productStore.listDataProductTemp.supplies_id = Number(e.currentTarget.value)
        productStore.listDataProductTemp.supplies = e.currentTarget.selectedOptions[0].text
    }
    return (
        <div className="modal fade" id="add_or_edit_product" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{productStore.type === 'add' ? 'Thêm' : 'Cập nhật'} sản phẩm</h5>
                        <button type="button" className="close" id="close_add_edit_product" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Tên sản phẩm <span className="text-danger">*</span></label>
                                    <Input value={productStore.dataRequest.name}
                                           onChange={(e: any) => productStore.dataRequest.name = e.currentTarget.value}
                                           placeholder="Nhập tên sản phẩm"
                                           isError={productStore.errors.name}
                                    />
                                </div>
                            </div>
                            <div className="w-50 pl-2 d-flex">
                                <div className="form-group w-50 px-1">
                                    <label>Hạn sử dụng (Tháng) <span className="text-danger">*</span></label>
                                    <Input value={productStore.dataRequest.expireMonth}
                                           type="number"
                                           onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                           onChange={(e: any) => productStore.dataRequest.expireMonth = e.currentTarget.value}
                                           placeholder="Nhập số tháng"
                                           isError={productStore.errors.expireMonth}
                                    />
                                </div>
                                <div className="form-group w-50 px-1">
                                    <label>Đơn vị <span className="text-danger">*</span></label>
                                    <Input value={productStore.dataRequest.unit}
                                           type="text"
                                           onChange={(e: any) => productStore.dataRequest.unit = e.currentTarget.value}
                                           placeholder="Nhập đơn vị"
                                           isError={productStore.errors.unit}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex align-items-center">
                            <div className="w-50 pr-2">
                                <div className="form-group">
                                    <label>Sử dụng vật tư <span className="text-danger">*</span></label>
                                    <Select value={suppliesStore.tabActive}
                                            onChange={handleChangeSupplies}
                                            placeholder="Chọn vật tư" options={productStore.listSupplies}
                                            isError={productStore.errors.tabActive}
                                    />
                                </div>
                            </div>
                            <div className="w-50 pl-2">
                                <div className="form-group">
                                    <label>Tên vật tư <span className="text-danger">*</span></label>
                                    <Select value={productStore.listDataProductTemp.supplies_id}
                                            onChange={handleChangeDataRequest}
                                            placeholder="Tên vật tư"
                                            options={suppliesStore.tabActive !== "equipment" ? suppliesStore.listSupplies : equipmentStore.listEquipment}
                                            disabled={suppliesStore.isLoading || equipmentStore.isLoading}
                                            isError={productStore.errors.supplies_id}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bt-add d-flex justify-content-end mx-3 mb-3">
                        <Button text="Thêm" className="text-white bg-green px-3" onClick={productStore.addTempProduct}/>
                    </div>
                    {productStore.errors.data && <span className="error mx-3 my-3">{productStore.errors.data}</span>}
                    <div className="mx-3 w-auto table-responsive custom-scrollbar">
                        <table
                            className='table align-middle table-hover table-rounded border h-50'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th className="text-center">Vật liệu</th>
                                <th className="text-center">Loại</th>
                                <th className="text-center">Số lượng sử dụng <span className="text-danger">*</span></th>
                                {productStore.listDataProduct.length > 0 && <th className="text-center"/>}
                            </tr>
                            </thead>
                            <tbody>
                            {productStore.listDataAll && productStore.listDataAll.map((item: IListDataProductTemp, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center">{item.supplies}</td>
                                        <td className="text-center">{item.type}</td>
                                        <td className="text-center w-50 input-quatity-product">
                                            <Input type="number"
                                                   onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                   value={item.quantity}
                                                   isShowError={false}
                                                   isError={item.quantity_error}
                                                   placeholder="Tối thiểu 1"
                                                   onChange={(e: any) => {
                                                       if (e.currentTarget.value === "" || numberUtil.regexNumber(e) > 0) {
                                                           item.quantity = numberUtil.regexNumber(e)
                                                       }
                                                   }
                                                   }/>
                                        </td>
                                        <td className="text-center d-flex justify-content-center">
                                            <img src="/assets/ico/action/ico_trash.svg" alt=""
                                                 className="icon-delete-product"
                                                 onClick={() => {
                                                     productStore.listDataAll.splice(index, 1)
                                                 }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {productStore.listDataProduct && productStore.listDataEquipmentProduct && productStore.listDataProduct.length < 1 && productStore.listDataEquipmentProduct.length < 1 &&
                            <NoContent height={250}/>}
                    </div>
                    {productStore.errors.quantity &&
                        <span
                            className="error d-flex justify-content-end px-5">{productStore.errors.quantity}</span>}
                    <div className="modal-footer">
                        <button type="button" className="btn send-request mt-3"
                                onClick={() => productStore.submit()}
                                disabled={productStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddOrEditProduct);