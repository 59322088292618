import React from 'react';
import {observer} from "mobx-react";
import WizardAddPlan from "../wizardAddPlan/Wizard";


const AddPlan = () => {

    return (
        <div className="modal fade" id="add-plan-order_work" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Lập lệnh sản xuất</h5>
                        <button type="button" className="close" id="close_add_edit_product" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <WizardAddPlan/>
                </div>
            </div>
        </div>

    );
}

export default observer(AddPlan);