import {getRequest, postRequest, deleteRequest, putRequest, IApiResponse} from "../../common/helpers/RequestHelper";
import {setPlanStore} from "./SetPlanStore";

class SetPlanService {
    public getPlanning(): Promise<IApiResponse> {
        return getRequest(`/v1/plan?page=${setPlanStore.page}&size=10${setPlanStore.searchYear ? `&year=${setPlanStore.searchYear}` : ''}${setPlanStore.searchName ? `&name=${setPlanStore.searchName.trim()}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/plan/${id}`);
    }

   public getLandPlan(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/land/${id}/plan`);
    }

    public create(data: any): Promise<IApiResponse> {
        return postRequest(`/v1/plan`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/v1/plan/${id}`, data);
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/v1/plan/${id}`, {id});
    }
}

export const setPlanService = new SetPlanService()