import {getRequest, postRequest, putRequest, IApiResponse, deleteRequest} from "../../../common/helpers/RequestHelper";
import {roleStore} from "./RoleStore";

class RoleService {

    public getRoles(size?: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/role?page=${roleStore.page}&size=${size ?? 10}`)
    }

    public getPermission(): Promise<IApiResponse> {
        return getRequest(`/food/v1/role/permission_group`)
    }

    public getDetailRole(id: number): Promise<IApiResponse> {
        return getRequest(`/food/v1/role/${id}`)
    }

    public add(data: any): Promise<IApiResponse> {
        return postRequest('/food/v1/role', data)
    }

    public update(id: number, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/role/${id}`, data)
    }

    public delete(id: number): Promise<IApiResponse> {
        return deleteRequest(`/food/v1/role/${id}`, {id: id})
    }

}

export const roleService = new RoleService()