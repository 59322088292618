import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {productStore} from "./ProductStore";
import ButtonAdd from "../../../../common/component/buttonAdd/ButtonAdd";
import Paginate from "../../../../common/component/Paginate";
import NoContent from "../../../../common/component/NoContent";
import PopupConfirm from "../../../../common/component/PopupConfirm";
import AddOrEditProduct from "./components/addOrEditProduct/AddOrEditProduct";
import Loading from "../../../../common/component/Loading";
import {checkPermission, getIDParameter, number_format, slug, sttPage} from "../../../../common/utils/Utils";
import {PermissionConst} from "../../../../common/constants/Constants";
import {dateUtils} from "../../../../common/utils/DateUtils";
import {profileStore} from "../../../profile/ProfileStore";
import ExportProduct from "./components/exportProduct/ExportProduct";
import {Link} from "react-router-dom";
import FilterComponent from "../../../../common/component/filter/FilterComponent";


const ProductPage = () => {
    const changePage = async (data: any) => {
        productStore.page = data.selected;
        await productStore.getProducts();
    }
    useEffect(() => {
        profileStore.link = `/warehouse`
        productStore.categoryId = Number(getIDParameter(4));
        productStore.getProducts();
        return () => {
            productStore.clearForm()
        }
    }, [])
    const dataFilter = [
        {
            label: 'Tìm Sản Phẩm',
            type: 'search',
            placeholder: 'Nhập từ khóa tìm kiếm',
            value: productStore.keyword,
            onChange: (e: any) => {
                productStore.page = 0
                productStore.keyword = e
            },
            callback: async () => {
                await productStore.getProducts(1000, true, productStore.keyword);
            }
        }]
    return (
        <>
            <div className='table-responsive'>
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex header_back align-items-center justify-content-between w-50">
                        <div className="d-flex align-items-center">
                            <Link
                                to={`/warehouse/product_category`}>Danh mục</Link>
                            <i className="fal fa-angle-right"/>
                            <span>{productStore.categoryName}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end w-50">
                        <div className="d-flex justify-content-end">
                            <FilterComponent
                                data={dataFilter}
                            />
                        </div>
                        {checkPermission(PermissionConst.productAdd) && <div className="bt_add add_or_edit_product">
                            <ButtonAdd text="Thêm sản phẩm" dataTarget="add_or_edit_product"
                                       onClick={productStore.clearForm}/>
                        </div>}
                    </div>
                </div>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th>Tên sản phẩm</th>
                        <th className="text-center">Tồn kho</th>
                        <th className="text-center">Ngày tạo</th>
                        {(checkPermission(PermissionConst.productUpdate) || checkPermission(PermissionConst.productDelete)) &&
                            <th className="text-center">Thao tác</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {!productStore.isLoading && productStore.listProduct && productStore.listProduct.map((item, i) => {

                        return (
                            <tr key={i}>
                                <td className="text-center">{sttPage(productStore.page, i)}</td>
                                <td>{item.name}</td>
                                <td className="text-center">{item?.quantity ? number_format(item.quantity) : 0}</td>
                                <td className="text-center">{item.createdAt ? dateUtils.formatDate(item.createdAt) : '-'}</td>
                                <td className="text-center" width="18%">
                                    <div className="action">
                                        <Link to={`/warehouse/category/product/${slug(item.name)}/${item.id}`}
                                              onClick={async () => {
                                                  productStore.productName = item.name
                                                  productStore.productId = item.id
                                              }}>
                                            <button className="bt_action">
                                                <img src="/assets/ico/action/ico_detail.svg" alt=""
                                                     data-toggle="tooltip" data-placement="top"
                                                     title="Xem chi tiết"/>
                                            </button>
                                        </Link>
                                        {checkPermission(PermissionConst.productUpdate) &&
                                            <button className="bt_action"
                                                    data-toggle="modal"
                                                    data-target="#add_or_edit_product"
                                                    onClick={async () => {
                                                        productStore.clearForm()
                                                        await productStore.getDetailProduct(item.id).then(() => {
                                                            productStore.id = item.id
                                                            productStore.type = "edit"
                                                        })
                                                    }}>
                                                <img src="/assets/ico/action/ico_edit.svg" alt="" data-toggle="tooltip"
                                                     data-placement="top"
                                                     title="Sửa"/>
                                            </button>}
                                        {checkPermission(PermissionConst.productExport) &&
                                            <button className="bt_action"
                                                    data-toggle="modal"
                                                    data-target="#export_product"
                                                    onClick={async () => {
                                                        productStore.clearForm()
                                                        productStore.id = item.id;
                                                        await productStore.getProductBatchExport(item.id.toString())
                                                    }}>
                                                <img src="/assets/ico/action/ico_export.svg" alt=""
                                                     data-toggle="tooltip"
                                                     data-placement="top"
                                                     title="Xuất kho"/>
                                            </button>}
                                        {checkPermission(PermissionConst.productDelete) &&
                                            <button className="bt_action"
                                                    onClick={() => productStore.id = item.id}
                                                    data-toggle="modal" data-target="#popup_confirm">
                                                <img src="/assets/ico/action/ico_trash.svg" alt="" data-toggle="tooltip"
                                                     data-placement="top"
                                                     title="Xóa"/>
                                            </button>}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {productStore.isLoading && <Loading/>}

                {!productStore.isLoading && productStore.listProduct && productStore.listProduct.length < 1 &&
                    <NoContent/>}

            </div>
            <div className="d-flex align-items-center justify-content-center mt-4">
                <Paginate currentPage={productStore.page} totalPage={productStore.totalPage} changePage={changePage}/>
            </div>

            <AddOrEditProduct/>
            <ExportProduct/>
            <PopupConfirm isLoading={productStore.isLoadingBt} action={productStore.delete}/>
        </>
    );
}

export default observer(ProductPage)