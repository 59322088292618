import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import ProfilePage from "../profile/component/ProfilePage";
import StorageService from "../../common/service/StorageService";
import UsersComponent from "../admin/users/UsersComponent";
import UserLockComponent from "../admin/users/UserLockComponent";
import UserActiveComponent from "../admin/users/UserActiveComponent";
import Dashboard from "../dashboard/Dashboard";
import {profileStore} from "../profile/ProfileStore";
import RoleComponent from "../admin/role/RoleComponent";
import UpdateRole from "../admin/role/components/UpdateRole";
import RoleDefault from "../admin/role/RoleDefault";
import PackPage from "../pack/PackPage";
import ManufacturingPage from "../manufacturing/ManufacturingPage";
import PreservePage from "../preserve/PreservePage";
import AccountPage from "../manage/account/AccountPage";
import SuppliesPage from "../warehouse/supplies/SuppliesPage";
import ProcedurePage from "../manage/procedure/ProcedurePage";
import ProcedureJobPage from "../manage/procedure/procedureJob/ProcedureJobPage";
import InfoPage from "../info/InfoPage";
import CatePage from "../warehouse/category/CatePage";
import ProductPage from "../warehouse/category/product/ProductPage";
import EIBatchPage from "../warehouse/expImpBatch/EIBatchPage";
import BatchPage from "../warehouse/supplies/batch/BatchPage";
import DetailEIBatchPage from "../warehouse/expImpBatch/detailEIBatch/DetailEIBatchPage";
import TrackingPage from "../tracking/TrackingPage";
import InfoTracking from "../infoTracking/InfoTracking";
import DetailProduct from "../warehouse/category/product/components/detailProduct/DetailProduct";
import PreviewTracking from "../infoTracking/PreviewTracking";
import InfoTrackingBatchCode from "../infoTracking/InfoTrackingBatchCode";
import TrackingBatch from "../tracking/trackingBatch/TrackingBatch";
import DetailPlan from "../preserve/components/detailPlan/DetailPlan";
import DetailManufacturing from "../manufacturing/components/DetailManufacturing";
import DetailPack from "../pack/components/DetailPack";
import RolePage from "../manage/roles/RolePage";
import {checkPermission} from "../../common/utils/Utils";
import {PermissionConst} from "../../common/constants/Constants";


export default function Redirect() {

    if (profileStore.roleUser === 'admin') {
        return (
            <Routes>
                <Route path={`/profile`} element={<ProfilePage/>}/>
                <Route path={`/cms/users`} element={<UsersComponent/>}/>
                <Route path={`/cms/users/active`} element={<UserActiveComponent/>}/>
                <Route path={`/cms/users/lock`} element={<UserLockComponent/>}/>
                <Route path={`/cms/permission`} element={<RoleComponent/>}/>
                <Route path={`/cms/permission-default`} element={<RoleDefault/>}/>
                <Route path={`/cms/permission/:slug/:id`} element={<UpdateRole/>}/>
                <Route path="*" element={<Navigate to="/cms/users" replace/>}/>
            </Routes>
        )
    } else {
        let linkDefault = <Navigate to={'/'} replace/>
        return (
            <Routes>
                <Route index element={<Dashboard/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route path={`/`} element={<Dashboard/>}/>
                </Route>
                <Route path={'/'} element={<Dashboard/>}/>
                <Route path={'/dashboard'} element={<Dashboard/>}/>
                <Route path={'/farm'} element={checkPermission(PermissionConst.farmView) ? <InfoPage/> : linkDefault}/>
                <Route path={`/profile`} element={<ProfilePage/>}/>
                <Route path={`/manage/role`} element={checkPermission(PermissionConst.owner) ? <RolePage/> : linkDefault}/>
                <Route path={`/manage/account`} element={checkPermission(PermissionConst.accountView) ? <AccountPage/> : linkDefault}/>
                <Route path={`/manage/procedure`} element={checkPermission(PermissionConst.procedureView) ? <ProcedurePage/> : linkDefault}/>
                <Route path={`/manage/procedure-detail/:slug/:id`} element={checkPermission(PermissionConst.jobView) ? <ProcedureJobPage/> : linkDefault}/>
                <Route path={`/warehouse/export_import_batch`} element={checkPermission(PermissionConst.productionRequestView) ? <EIBatchPage/> : linkDefault}/>
                <Route path={`/warehouse/product_category`} element={checkPermission(PermissionConst.productCateView) ? <CatePage/> : linkDefault}/>
                <Route path={`/warehouse/category/:slug/:id`} element={checkPermission(PermissionConst.productView) ? <ProductPage/> : linkDefault}/>
                <Route path={`/warehouse/category/product/:slug/:id`} element={checkPermission(PermissionConst.productView) ? <DetailProduct/> : linkDefault}/>
                <Route path={`/warehouse/batch/:slug/:id`} element={<DetailEIBatchPage/>}/>
                <Route path={`/warehouse/supplies`} element={checkPermission(PermissionConst.suppliesView) ? <SuppliesPage/> : linkDefault}/>
                <Route path={`/warehouse/supplies/:slug/:id`} element={checkPermission(PermissionConst.suppliesBatchView) ? <BatchPage/> : linkDefault}/>
                <Route path={`/planing`} element={checkPermission(PermissionConst.planView) ? <PreservePage/> : linkDefault}/>
                <Route path={`/planing/:id`} element={<DetailPlan/>}/>
                <Route path={`/tracking`} element={checkPermission(PermissionConst.trackingView) ? <TrackingPage/> : linkDefault}/>
                <Route path={`/tracking-batch/:slug/:id`} element={checkPermission(PermissionConst.qrCodeView) ? <TrackingBatch/> : linkDefault}/>
                <Route path={`/xem-ket-qua-truy-xuat/:slug/:id`} element={<PreviewTracking/>}/>
                <Route path={`/truy-xuat/:slug/:batchCode`} element={<InfoTracking/> }/>
                <Route path={`/truy-xuat-lo/:slug/:trackingId/:trackingBatchId`} element={<InfoTrackingBatchCode/>}/>
                <Route path={`/manufacturing`} element={checkPermission(PermissionConst.productionView) ? <ManufacturingPage/> : linkDefault}/>
                <Route path={`/manufacturing/:id`} element={<DetailManufacturing/>}/>
                <Route path={`/packge`} element={checkPermission(PermissionConst.packagingView) ? <PackPage/> : linkDefault}/>
                <Route path={`/packge/:id`} element={<DetailPack/>}/>
                <Route path="*" element={linkDefault}/>
            </Routes>
        )
    }

}


const ProtectedRoute = ({children}: any) => {
    if (!StorageService.getToken()) {
        return <Navigate to={`/login`} replace/>;
    }
    return children;
}
