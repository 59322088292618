import React from 'react';
import {observer} from "mobx-react";
import './AddPlanOrderWork.scss'
import Input from "../../../../common/component/form/Input";
import Select from "../../../../common/component/form/Select";
import {productStore} from "../../../warehouse/category/product/ProductStore";
import {cateStore} from "../../../warehouse/category/CateStore";
import DatePickerSingle from "../../../../common/component/DatePickerSingle";
import {preserveStore} from "../../PreserveStore";
import {ActionButtons} from "../wizardAddPlan/Wizard";
import {procedureStore} from "../../../manage/procedure/ProcedureStore";
import {dateUtils} from "../../../../common/utils/DateUtils";

const AddPlanOrderWork = (props: any) => {

    const handleFilterDate = async (startDate: Date) => {
        preserveStore.dataAddPlanOrderWork.timestartTmp = startDate.getTime();
        preserveStore.dataAddPlanOrderWork.timestart = dateUtils.formatDate(startDate.getTime(), "YYYY/MM/DD");

    };
    const handleChangeCategory = async (e: any) => {
        preserveStore.dataAddPlanOrderWork.category = Number(e.currentTarget.value)
        productStore.categoryId = e.currentTarget.value
        preserveStore.dataAddPlanOrderWork.productId = ""
        preserveStore.dataAddPlanOrderWork.procedureId = ""
        await productStore.getProducts()
    }
    const handleChangeProduct = async (e: any) => {
        preserveStore.dataAddPlanOrderWork.productId = Number(e.currentTarget.value)
        preserveStore.dataAddPlanOrderWork.procedureId = ""
        preserveStore.dataAddPlanOrderWork.productName = e.currentTarget.selectedOptions[0].text
        procedureStore.searchProduct = Number(e.currentTarget.value)
        await procedureStore.getProcedure(1000, false).then(() => {
            procedureStore.searchProduct = ''
        })

    }
    const validate = () => {
        if (!preserveStore.dataAddPlanOrderWork.name) {
            preserveStore.errors.addPlanOrderWork.name = `Tên lô sản xuất không được để trống!`
            return
        } else preserveStore.errors.addPlanOrderWork.name = ''
        if (preserveStore.type !== "edit") {
            if (!preserveStore.dataAddPlanOrderWork.category) {
                preserveStore.errors.addPlanOrderWork.cate = `Vui lòng chọn danh mục sản phẩm!`
                return
            } else {
                preserveStore.errors.addPlanOrderWork.cate = ''
                if (!preserveStore.dataAddPlanOrderWork.productId) {
                    preserveStore.errors.addPlanOrderWork.product = `Vui lòng chọn tên sản phẩm!`
                    return
                } else {
                    preserveStore.errors.addPlanOrderWork.product = ''
                    if (!preserveStore.dataAddPlanOrderWork.procedureId) {
                        preserveStore.errors.addPlanOrderWork.procedure = `Vui lòng chọn quy trình!`
                        return
                    } else preserveStore.errors.addPlanOrderWork.procedure = ''
                }
            }
        }
        if (!preserveStore.dataAddPlanOrderWork.quantity) {
            preserveStore.errors.addPlanOrderWork.quantity = `Sản lượng không được để trống!`
            return
        } else preserveStore.errors.addPlanOrderWork.quantity = ''
        if (!preserveStore.dataAddPlanOrderWork.timestartTmp) {
            preserveStore.errors.addPlanOrderWork.timeStart = `Thời gian bắt đầu không được để trống!`
            return
        } else preserveStore.errors.addPlanOrderWork.timeStart = ''

        props.nextStep();
        props.userCallback(preserveStore.dataAddPlanOrderWork);
    }
    return (
        <div className="mx-3">
            <div className="mx-3 w-auto table-responsive">
                <div className="form-group mt-3">
                    <label>Mã lô sản xuất <span className="text-danger">*</span></label>
                    <Input
                        value={preserveStore.dataAddPlanOrderWork.name}
                        isError={preserveStore.errors.addPlanOrderWork.name}
                        onChange={(e: any) => preserveStore.dataAddPlanOrderWork.name = e.currentTarget.value}
                        placeholder="Nhập tên lô sản xuất ..."/>
                </div>
            </div>
            <div className="mx-3 d-flex">
                <div className="w-50 pr-2">
                    <div className="form-group">
                        <label>Danh mục sản phẩm <span className="text-danger">*</span></label>
                        <Select value={preserveStore.dataAddPlanOrderWork.category}
                                onChange={handleChangeCategory}
                                placeholder="Chọn danh mục"
                                options={cateStore.listCate}
                                isError={preserveStore.errors.addPlanOrderWork.cate}
                                disabled={preserveStore.type === "edit"}
                        />
                    </div>
                </div>
                <div className="w-50 pr-2">
                    <div className="form-group">
                        <label>Sản phẩm <span className="text-danger">*</span></label>
                        <Select value={preserveStore.dataAddPlanOrderWork.productId}
                                onChange={handleChangeProduct}
                                placeholder="Chọn sản phẩm"
                                options={productStore.listProduct}
                                disabled={!preserveStore.dataAddPlanOrderWork.category || preserveStore.type === "edit"}
                                isError={preserveStore.errors.addPlanOrderWork.product}
                        />
                    </div>
                </div>
            </div>
            <div className="mx-3 d-flex">
                <div className="w-50 pr-2">
                    <div className="form-group">
                        <label>Quy trình <span className="text-danger">*</span></label>
                        <Select value={preserveStore.dataAddPlanOrderWork.procedureId}
                                onChange={(e: any) => {
                                    preserveStore.dataAddPlanOrderWork.procedureName = e.currentTarget.selectedOptions[0].text
                                    preserveStore.dataAddPlanOrderWork.procedureId = Number(e.currentTarget.value)
                                }}
                                placeholder="Chọn quy trình"
                                disabled={
                                    !preserveStore.dataAddPlanOrderWork.category
                                    || !preserveStore.dataAddPlanOrderWork.productId
                                    || preserveStore.type === "edit"
                                }
                                options={procedureStore.listProcedure}
                                isError={preserveStore.errors.addPlanOrderWork.procedure}
                        />
                    </div>
                </div>
                <div className="w-50 pr-2">
                    <div className="form-group">
                        <label>Sản lượng<span className="text-danger">*</span></label>
                        <Input
                            type="number"
                            min={0}
                            disabled={!preserveStore.dataAddPlanOrderWork.productId}
                            onKeyDown={(evt: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            value={preserveStore.dataAddPlanOrderWork.quantity}
                            onChange={async (e: any) => {
                                preserveStore.dataAddPlanOrderWork.quantity = e.currentTarget.value
                                await productStore.getDetailProduct(preserveStore.dataAddPlanOrderWork.productId).then(() => {
                                    preserveStore.dataAddPlanQuantity = productStore.listDataProduct.map((item, index) => {
                                        return {
                                            name: item.supplies,
                                            id: item.supplies_id,
                                            unit: item.unit,
                                            estimatedQuantity: Number(item.quantity) * Number(preserveStore.dataAddPlanOrderWork.quantity),
                                        }
                                    })
                                })
                            }}
                            placeholder="Nhập sản lượng dự kiến ..."
                            isError={preserveStore.errors.addPlanOrderWork.quantity}
                        />
                    </div>
                </div>
            </div>
            <div className="mx-3 w-auto table-responsive">
                <div className="form-group mt-2">
                    <label>Thời gian bắt đầu <span className="text-danger">*</span></label>
                    <DatePickerSingle selected={preserveStore.dataAddPlanOrderWork.timestartTmp}
                                      onChange={handleFilterDate} placeholder="Chọn ngày bắt đầu"
                                      showTimeSelect
                                      dateFormat={"HH:mm dd/MM/yyyy"}
                                      isError={preserveStore.errors.addPlanOrderWork.timeStart}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <ActionButtons {...props} nextStep={validate}/>
            </div>
        </div>
    );
}

export default observer(AddPlanOrderWork);