import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {dateUtils} from "../../../../common/utils/DateUtils";
import {preserveStore} from "../../../preserve/PreserveStore";
import {manufacturingStore} from "../../ManufacturingStore";
import '../../Manufacturing.scss'
import ButtonAdd from "../../../../common/component/buttonAdd/ButtonAdd";
import ModalConfirmComplete from "../modalConfirmComplete/ModalConfirmComplete";
import NoContent from "../../../../common/component/NoContent";
import Loading from "../../../../common/component/Loading";
import {checkPermission} from "../../../../common/utils/Utils";
import {PermissionConst} from "../../../../common/constants/Constants";

const DetailManufacturingJob = (): JSX.Element => {
    useEffect(() => {
        if (manufacturingStore.id) manufacturingStore.getDetailManufacturingJob(manufacturingStore.id)
    }, [manufacturingStore.id])
    return (
        <div className="modal-body popup_detail pt-3">
            <div className="w-100 d-flex flex-wrap py-3 justify-content-between align-items-center">
                <div className="w-75 d-flex flex-wrap">
                    <p>Sản phẩm: <span
                        className="fw-600 mr-5">{preserveStore.dataAddPlanOrderWork?.productName || "---"}</span>
                    </p>
                    <p>Quy trình: <span
                        className="fw-600 mr-5">{preserveStore.dataAddPlanOrderWork?.procedureName || "---"}</span>
                    </p>
                    <p>Số lượng: <span
                        className="fw-600 mr-5">{preserveStore.dataAddPlanOrderWork?.quantity || "---"}</span>
                    </p>
                    <p>Thời gian: <span
                        className="fw-600">{dateUtils.formatDate(preserveStore.dataAddPlanOrderWork?.timestartTmp as number, 'hh:mm DD/MM/YYYY') || "---"}</span>
                    </p>
                </div>
                {checkPermission(PermissionConst.approveProduction) &&
                    <ButtonAdd disabled={!manufacturingStore.checkCompletePlan} type="" text="Hoàn Thành Sản Xuất"
                               dataTarget={'popup_confirm_complete_manufacturing'}
                    />}
            </div>
            {manufacturingStore.isLoading && !manufacturingStore.isLoadingChangeStatus ? <Loading/> : <>
                <table className='table align-middle table-hover table-rounded border gy-7 gs-7'>
                    <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">STT</th>
                        <th className="text-center">Công việc</th>
                        <th className="text-center">Giai đoạn</th>
                        <th className="text-center">Tình trạng</th>
                        <th className="text-center">Người phụ trách</th>
                        <th className="text-center">Thời gian hoàn thành</th>
                        {checkPermission(PermissionConst.approveProduction) &&
                            <th className="text-center">Thao tác</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {manufacturingStore.listJobDetailManufacturing && manufacturingStore.listJobDetailManufacturing.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td>{item?.name ?? ""}</td>
                                <td className="text-center">{preserveStore.changeStateBatch(item?.type) ?? ""}</td>
                                <td className={`text-center ${item?.status === "PENDING" ? "pending-status" : "completed-status"}`}>{item?.status === "PENDING" ? "Chưa hoàn thành" : item?.status === "CANCEL" ? "Hủy" : "Hoàn thành"}</td>
                                <td className="text-center">
                                    {item?.user?.name && item?.status === "COMPLETE" ? item.user.name : "-"}
                                </td>
                                <td className="text-center">{item?.updatedAt ? dateUtils.formatDate(item?.updatedAt) : '-'}</td>
                                {checkPermission(PermissionConst.approveProduction) &&
                                    <td className="text-center" id="inputPreview">
                                        <input type="checkbox"
                                               id={item.id.toString()}
                                               className="change-status-job"
                                               value={item.status}
                                               checked={item.status === "COMPLETE"}
                                               disabled={manufacturingStore.isLoading}
                                               onChange={async (e) => {
                                                   let data = {
                                                       ids: [item.id],
                                                       status: e.target.value === "COMPLETE" ? "PENDING" : "COMPLETE"
                                                   }
                                                   await manufacturingStore.changeStatusJob(manufacturingStore.id, data)
                                               }}
                                        />
                                        <label htmlFor={item.id.toString()}/>
                                    </td>}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {!manufacturingStore.isLoading && manufacturingStore.listJobDetailManufacturing && manufacturingStore.listJobDetailManufacturing.length < 1 &&
                    <NoContent/>}
                <ModalConfirmComplete type="PACK" text="Sản Xuất"
                                      textContent={`Lô sản xuất sẽ được chuyển qua quy trình đóng gói để
                                      thực hiện các bước tiếp theo.`}/>
            </>}
        </div>
    )

}

export default observer(DetailManufacturingJob);

