import {getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {trackingStore} from "./TrackingStore";
import {trackingBatchStore} from "./trackingBatch/TrackingBatchStore";


class TrackingService {
    public getTracking(): Promise<IApiResponse> {
        return getRequest(`/food/v1/tracking?page=${trackingStore.page}&size=10${trackingStore.keyword ? `&keyword=${trackingStore.keyword.trim()}` : ''}`);
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/tracking/${id}`);
    }

    public add(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/tracking`, data);
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/tracking/${id}`, data);
    }

    public getTrackingQR(): Promise<IApiResponse> {
        return getRequest(`/food/v1/tracking/${trackingBatchStore.id}/qr_batch?page=${trackingBatchStore.page}&size=10`)
    }

    public detailTrackingBatch(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/tracking_batch/${id}`)
    }

    public detailTrackingQRBatch(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/tracking/qr/${id}`)
    }

    public historyQr(id: any): Promise<IApiResponse> {
        return getRequest(`/food/v1/tracking_batch/${id}/qr_files?page=${trackingBatchStore.pageHistory}&size=5`)
    }

    public addTrackingQR(data: any): Promise<IApiResponse> {
        return postRequest(`/food/v1/tracking/${trackingBatchStore.id}/qr_batch`, data);
    }

    public editTrackingQR(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/food/v1/tracking_batch/${id}`, data);
    }

    public addLinkDownloadQr(id: any, quantity: number): Promise<IApiResponse> {
        return getRequest(`/food/v1/tracking_batch/${id}/qr_export?quantity=${quantity}`);
    }
}

export const trackingService = new TrackingService()